/* eslint-disable no-useless-escape */
import { object, string } from 'yup'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const format = /^[\!@\#\$\%\^\&\*\_\(\)\-\+\=\~\`\.\w]*$/
export const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/

const primarySchema = object().shape({
  //   first_name: string().min(3).required('First Name Required'),
  //   last_name: string().min(3).required('Last Name Required'),
  phone_number: string()
    .min(8)
    .matches(/^\+?[01-9]+$/, 'Phone number is not valid'),
  email: string().email().required('Enter Company Email'),
  gender: string().required().oneOf(['male', 'female']),
  password: string()
    .required('Password is required')
    .matches(
      passwordFormat,
      'Password must include both lower and upper case characters, include at least one number, include at least one special character, be at least 8 characters long.'
    )
    .test(
      'special-chars',
      'Password must contain special characters',
      function (value) {
        return format.test(value as unknown as string)
      }
    ),
})

export default primarySchema
