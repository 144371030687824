import { addDataToQueryString } from './../helpers/strings'
import { PaginatedData } from './api.types'
import api from './api'
import { GET_LANGUAGE_LIST, SET_PREFERRED_LANGUAGE } from './endpoints'

export interface LanguageList {
  preferred_language: Language[]
}

export interface Language {
  name: string
  code: string
}

export interface LanguageDTO {
  preferred_language: string
}

export const getLanguageList = async () => {
  return (await api.get<{ data: any }>(GET_LANGUAGE_LIST)).data
}

export const setPreferredLanguage = async (data: LanguageDTO) => {
  return await api.post<{ data: LanguageDTO }>(
    `${SET_PREFERRED_LANGUAGE}`,
    data
  )
}
