import api from './api'
import { PaginatedData } from './api.types'
import {
  GET_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS_COUNT,
  READ_NOTIFICATIONS,
} from './endpoints'

export interface INotifications {
  tag: string
  title: string
  message: string
  timestamp: string
  unread: boolean
}

export interface IReadNotification {}

export type PageDTO = {
  page?: number
  alumnus_id?: number
}

export interface IUnreadCount {
  unread_count: number
}
export const getNotifications = async () => {
  return (
    await api.get<{ data: PaginatedData<INotifications> }>(GET_NOTIFICATIONS)
  ).data
}
export const getNotificationsUnread = async () => {
  return (await api.get<{ data: IUnreadCount }>(GET_UNREAD_NOTIFICATIONS_COUNT))
    .data
}

export const readNotifications = async () => {
  return await api.post<{ data: void }>(READ_NOTIFICATIONS, {})
}
