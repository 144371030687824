import { optionsApiSlice } from './optionsSlice'
import { AxiosError } from 'axios'
import { ToastNotify } from '@/common/toastManager'
import { profileApiSlice } from './profileSlice'
import { MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit'
import { configureStore, createAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer, { authApiSlice } from './authSlice'
import jobsReducer, { recruitmentApiSlice } from './recruitementSlice'
import messageReducer, { messagesApiSlice } from './engagementSlice'

import { dashboardApiSlice } from './dashboardSlice'
import { recruitersListSlice } from './recruitersSlice'
// import { enagagementApiSlice } from './engagementSlice'
import { reportApiSlice } from './reportSlice'
import { alumniApiSlice } from './alumniSlice'
import recruiterReducer from './alumniSlice'
import { ratingApiSlice } from './ratingSlice'
import { notificationApiSlice } from './notificationSlice'
import { testApiSlice } from './testSlice'
import { auditLogApiSlice } from './auditLogSlice'
import { forumApiSlice } from './forumSlice'
import { languageApiSlice } from './languageSlice'

export const resetStore = createAction('resetStore')

export const errorHandler = (error: AxiosError) => {
  throw new Error((error as any).response.data.message)
}

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger =
  (api: MiddlewareAPI) => (next: any) => (action: any) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
      console.warn('We got a rejected action!')
      ToastNotify('warn', {
        message: action.error.data.message,
      })
    }

    return next(action)
  }
const rootReducer = combineReducers({
  auth: authReducer,
  jobs: jobsReducer,
  recruiter: recruiterReducer,
  // dashboard: dashboardReducer,

  messages: messageReducer,

  [authApiSlice.reducerPath]: authApiSlice.reducer,
  [profileApiSlice.reducerPath]: profileApiSlice.reducer,
  [recruitmentApiSlice.reducerPath]: recruitmentApiSlice.reducer,
  [messagesApiSlice.reducerPath]: messagesApiSlice.reducer,
  [optionsApiSlice.reducerPath]: optionsApiSlice.reducer,
  [dashboardApiSlice.reducerPath]: dashboardApiSlice.reducer,
  [recruitersListSlice.reducerPath]: recruitersListSlice.reducer,
  // [enagagementApiSlice.reducerPath]: enagagementApiSlice.reducer,
  [reportApiSlice.reducerPath]: reportApiSlice.reducer,
  [alumniApiSlice.reducerPath]: alumniApiSlice.reducer,
  [ratingApiSlice.reducerPath]: ratingApiSlice.reducer,
  [notificationApiSlice.reducerPath]: notificationApiSlice.reducer,
  [testApiSlice.reducerPath]: testApiSlice.reducer,
  [auditLogApiSlice.reducerPath]: auditLogApiSlice.reducer,
  [forumApiSlice.reducerPath]: forumApiSlice.reducer,
  [languageApiSlice.reducerPath]: languageApiSlice.reducer,
})
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    'auth',
    authApiSlice.reducerPath,
    profileApiSlice.reducerPath,
    recruitmentApiSlice.reducerPath,
    dashboardApiSlice.reducerPath,
    messagesApiSlice.reducerPath,
    optionsApiSlice.reducerPath,
    recruitersListSlice.reducerPath,
    // enagagementApiSlice.reducerPath,
    reportApiSlice.reducerPath,
    alumniApiSlice.reducerPath,
    ratingApiSlice.reducerPath,
    notificationApiSlice.reducerPath,
    testApiSlice.reducerPath,
    auditLogApiSlice.reducerPath,
    forumApiSlice.reducerPath,
    languageApiSlice.reducerPath,
  ], //Contain only slices name that we don't want to store
}

const appReducer: typeof rootReducer = (state, action) => {
  if (action.type === resetStore.type) {
    return rootReducer(undefined, action)
  }
  return rootReducer(state, action)
}
const persistedReducer = persistReducer(persistConfig, appReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authApiSlice.middleware,
      profileApiSlice.middleware,
      recruitmentApiSlice.middleware,
      messagesApiSlice.middleware,
      optionsApiSlice.middleware,
      dashboardApiSlice.middleware,
      recruitersListSlice.middleware,
      // enagagementApiSlice.middleware,
      reportApiSlice.middleware,
      alumniApiSlice.middleware,
      ratingApiSlice.middleware,
      notificationApiSlice.middleware,
      testApiSlice.middleware,
      auditLogApiSlice.middleware,
      forumApiSlice.middleware,
      languageApiSlice.middleware,
    ]),
})
export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
