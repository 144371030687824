import iconComponents from '@/assets/icons/iconComponents'
import Image from '@/common/Image'

const Header = () => {
  return (
    <header className='w-full px-4 overlay py-[26px] sm:px-8 sm:py-10 lg:px-10 lg:py-6 h-full flex justify-start items-start bg-jaa-shades-white'>
      {/* <iconComponents.util.LogoIcon className='h-[69px] w-[137px]' /> */}
      <Image src='/Logo_Full Color.png' className='h-[99px] w-[381px]' />
    </header>
  )
}

export default Header
