import { Job } from '@/apis/recruitementApis'
import Modal from '@/common/modal/Modal'
import ModalHeader from '@/common/modal/ModalHeader'
import JobDetail from '../../jobs/details/JobDetail'

type IOpenable = {
  data?: Job
  open: boolean
  onClose: () => void
}

const ShowJobPost = (props: IOpenable) => {
  const { open, onClose, data } = props

  return (
    <Modal open={open} onClose={onClose} variant='lg'>
      <div className='flex flex-col items-start p-4 md:p-6 gap-10 w-full'>
        <ModalHeader title='Job Posting' onClose={onClose} />
        <JobDetail className='max-h-[603px]' hasApplied job={data} />
      </div>
    </Modal>
  )
}

export default ShowJobPost
