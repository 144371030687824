import iconComponents from '@/assets/icons/iconComponents'
import composeEventHandlers from '@/helpers/composeEventHandlers'
import { capitalize } from '@/helpers/strings'
import { useComposeRefs } from '@/hooks/useComposeRefs'
import useHover from '@/hooks/useHover'
import clsx from 'clsx'
import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Typography from '../Typography'
import { toastEmitter } from './emitter'
import { NotifyTypes, ToastContainerProps, ToastItem } from './toast.types'

type ToastProps = ToastItem & { id: string; GlobalProps: Record<string, any> }

const BASE_CLASSES =
  'rounded-lg cursor-pointer flex justify-start max-w-full items-start  min-h-12 my-2 mx-4 max-h-[146px] overflow-auto scroller'
const ToastIconsComponent: Record<NotifyTypes, (props: any) => any> = {
  error: iconComponents.toast.ErrorIcon,
  info: iconComponents.toast.InfoIcon,
  success: iconComponents.toast.SuccessIcon,
  warn: iconComponents.toast.WarnIcon,
}
const backgroundColors: Record<NotifyTypes, string> = {
  error: '#E11900',
  info: '#F7F7F7',
  success: '#4CAF50',
  warn: '#FF9D00',
}

const iconBackgrounds: Record<NotifyTypes, string> = {
  error: '#8E0F00',
  info: '#C5C5C5',
  success: ' #306E32',
  warn: '#A16300',
}
const Toast = forwardRef<unknown, ToastProps>(
  (
    {
      id,
      message,
      timeout,
      onClose,
      content,
      type,
      showIcon = true,
      title,
      allowCancel = true,
      autoClose,
      GlobalProps,
    },
    forwardedRef
  ) => {
    const [divRef, isHovering] = useHover<HTMLDivElement>()
    const [globalProps, setGlobalProps] = useState<ToastContainerProps>(
      GlobalProps as ToastContainerProps
    )
    const [toastHeight, setToastHeight] = useState('52px')
    const timeStartRef = useRef(new Date().getTime())
    const clickHandler = () => {
      toastEmitter.emit('decrement', id)
    }

    useEffect(() => {
      const acceptProps = (payload: Record<string, any>) => {
        console.log(payload, 'PAYLOAD')
        setGlobalProps(payload)
      }
      toastEmitter.on('globalProps', acceptProps)
      return () => {
        toastEmitter.off('globalProps', acceptProps)
      }
    }, [])

    useEffect(() => {
      timeStartRef.current = new Date().getTime()
    }, [])
    useEffect(() => {
      if (!(autoClose || globalProps.autoClose)) return
      // let timeLeft =
      //   (timeout || globalProps.timeout || 5000) -
      //   (new Date().getTime() - timeStartRef.current)
      let timer = setTimeout(
        clickHandler,
        timeout || globalProps.timeout || 5000
      )
      return () => {
        clearTimeout(timer)
      }
    }, [isHovering])
    const ToastIcon = ToastIconsComponent[type]
    const refCb = useComposeRefs(
      forwardedRef,
      useCallback((el: any) => {
        if (el === null) return
        divRef.current = el
        setToastHeight(
          window.getComputedStyle(divRef.current).getPropertyValue('height')
        )
      }, [])
    )
    return (
      <div
        role='alertdialog'
        aria-labelledby={id}
        style={{
          boxShadow: '0px 8px 12px rgba(4, 4, 4, 0.08)',
          backgroundColor: backgroundColors[type],
        }}
        className={clsx(BASE_CLASSES, 'whitespace-normal')}
        ref={refCb}
        onClick={composeEventHandlers(onClose, clickHandler)}
      >
        {showIcon && (
          <div
            style={{
              backgroundColor: iconBackgrounds[type],
              height: toastHeight,
            }}
            className='w-11 flex justify-center items-center rounded-tl-lg rounded-bl-lg'
          >
            {<ToastIcon width={21} height={21} />}
          </div>
        )}
        <div className={`flex-1 px-4 py-3`}>
          {!!(title && content) ? (
            <div id={id} className='w-full flex flex-col gap-2'>
              <div className='w-full flex justify-between items-center gap-6'>
                <Typography
                  as='h5'
                  style={{
                    color:
                      type === 'info'
                        ? 'var(--shades-black)'
                        : 'var(--shades-white)',
                  }}
                >
                  {title}
                </Typography>
                {allowCancel && (
                  <iconComponents.util.CancelIcon
                    width={20}
                    height={20}
                    stroke={type === 'info' ? '#181818' : 'white'}
                    role='button'
                    onClick={composeEventHandlers(onClose, clickHandler)}
                  />
                )}
              </div>
              <Typography
                as='p'
                style={{
                  color:
                    type === 'info'
                      ? 'var(--shades-black)'
                      : 'var(--shades-white)',
                }}
                className='font-normal text-base leading-[29px] whitespace-normal'
              >
                {capitalize(content)}
              </Typography>
            </div>
          ) : (
            <div
              id={id}
              className='w-full flex justify-between items-center gap-6'
            >
              <Typography
                as='h5'
                style={{
                  color:
                    type === 'info'
                      ? 'var(--shades-black)'
                      : 'var(--shades-white)',
                }}
              >
                {message}
              </Typography>
              {allowCancel && (
                <iconComponents.util.CancelIcon
                  width={20}
                  height={20}
                  stroke={type === 'info' ? '#181818' : 'white'}
                  role='button'
                  onClick={composeEventHandlers(onClose, clickHandler)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
)
Toast.displayName = 'Toast'
export default Toast
