import { addDataToQueryString } from './../helpers/strings'

import { PaginatedData } from './api.types'
import api from './api'
import {
  DELISTED_CONTENT,
  FORUM_ACTIVITY,
  FORUM_RESTRICTION,
  POSTS,
  POST_BY_COHORT,
  POST_COMMENT,
  POST_MEDIA,
  REPORT_CONTENT,
} from './endpoints'

export type ReportDTO = {
  content: string
  object_id: number
  reported_for: string
  reason: string
  id?: number
  created_at?: number
}

export type ForumActivityDTO = {
  activity_type: 'post' | 'comment' | 'upvote' | 'all'
  user_id: number
}
export type ActivityAction =
  | 'post'
  | 'comment'
  | 'post upvote'
  | 'comment upvote'
export type ForumActivityResponse = {
  content: POST | (COMMENT & { post: POST })
  action: ActivityAction
  date: string
}

export type ReadContentDTO = {
  content: 'post' | 'comment'
  content_id: number
}
export type RestrictDTO = {
  content: 'post' | 'comment'
  object_id: number
  duration: number
}

export type PostDTO = {
  title?: string
  body: string
  category: 'general' | 'cohort'
  reposted_from?: number
}
export type RepostDTO = { body: string; postId: number }
export type CommentDTO = {
  body: string
  post: number
}
export type PostPage = {
  page: number
  category?: 'general' | 'cohort'
  cohort?: number
  search?: string
  start_date?: string
  end_date?: string
}
export type ReportPage = {
  page: number
  resolved: boolean
  search?: string
}
export type POST = {
  id: number
  user_id: number
  first_name: string
  last_name: string
  profile_pic: string
  cohort: string
  program: string
  media: Media[]
  num_upvotes: string
  num_downvotes: string
  num_comments: string
  vote: string
  original_post?: POST
  title: string
  body: string
  created_at: string
  reposted_from: number
  category: 'general' | 'cohort'
}

export interface Media {
  id: number
  file: string
  file_type: string
  post: number
}

export type COMMENT = {
  id: number
  first_name: string
  last_name: string
  profile_pic: string
  cohort: string
  program: string
  media: CMedia[]
  num_upvotes: string
  num_downvotes: string
  vote: string
  body: string
  created_at: string
  post: number
  user_id: number
}

export type POSTS_BY_CATEGORY = {
  general_count: number
  cohorts_count: Record<'cohort' | 'post_count', number>[]
}
interface CMedia {
  id: number
  file_type: string
  file: string
  comment: number
}
export type VOTE = {
  first_name: string
  last_name: string
  cohort: string
  profile_pic: string
  last_seen?: string
  just_posted?: string
}

export type REPORT = {
  id: number
  content: string
  resolution: string
  reporting_user: ReportUser
  reported_user: ReportUser
  object_id: number
  reported_for: string
  reason: string
  resolved: boolean
  created_at: string
  resolved_date: string
  reported_content?: POST | COMMENT
}

interface ReportUser {
  first_name: string
  last_name: string
  profile_pic: string
  online: boolean
  cohort: number
}

export const createPost = async (data: PostDTO) => {
  return await api.post<{ data: POST }>(POSTS, data)
}

export const deletePost = async (postId: number) => {
  return await api.delete<{ data: any }>(POSTS + postId + '/', {})
}

export const addMediaToPost = async (data: FormData) => {
  return await api.post<{ data: Media }>(POST_MEDIA, data)
}
export const getPosts = async (payload: PostPage) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<POST> }>(POSTS + (qs ? `?${qs}` : ''))
  ).data
}
export const getPost = async (postId: number) => {
  return (await api.get<{ data: POST }>(POSTS + `${postId}/`)).data
}
export const upVotePost = async (postId: number) => {
  return await api.post<{ data: POST }>(POSTS + `${postId}/upvote/`, {})
}
export const downVotePost = async (postId: number) => {
  return await api.post<{ data: POST }>(POSTS + `${postId}/downvote/`, {})
}

export const getPostUpvotes = async (data: PostPage & { postId: number }) => {
  const { postId, ...rest } = data
  const qs = addDataToQueryString('', rest)
  return (
    await api.get<{ data: PaginatedData<VOTE> }>(
      POSTS + `${postId}/upvotes/?${qs}`
    )
  ).data
}
export const getPostDownvotes = async (data: PostPage & { postId: number }) => {
  const { postId, ...rest } = data
  const qs = addDataToQueryString('', rest)
  return (
    await api.get<{ data: PaginatedData<VOTE> }>(
      POSTS + `${postId}/downvotes/?${qs}`
    )
  ).data
}

export const getCommentUpvotes = async (
  data: PostPage & { commentId: number }
) => {
  const { commentId, ...rest } = data
  const qs = addDataToQueryString('', rest)
  return (
    await api.get<{ data: PaginatedData<VOTE> }>(
      POST_COMMENT + `${commentId}/upvotes/?${qs}`
    )
  ).data
}
export const getCommentDownvotes = async (
  data: PostPage & { commentId: number }
) => {
  const { commentId, ...rest } = data
  const qs = addDataToQueryString('', rest)
  return (
    await api.get<{ data: PaginatedData<VOTE> }>(
      POST_COMMENT + `${commentId}/downvotes/?${qs}`
    )
  ).data
}

export const getPostComment = async (data: PostPage & { postId: number }) => {
  const { postId, ...rest } = data
  const qs = addDataToQueryString('', rest)
  return (
    await api.get<{ data: PaginatedData<COMMENT> }>(
      POSTS + `${postId}/comments/?${qs}`
    )
  ).data
}

export const createComment = async (data: CommentDTO) => {
  return await api.post<{ data: COMMENT }>(POST_COMMENT, data)
}
export const deleteComment = async (commentId: number) => {
  return await api.delete<{ data: any }>(POST_COMMENT + commentId + '/', {})
}

export const upVoteComment = async (commentId: number) => {
  return await api.post<{ data: COMMENT }>(
    POST_COMMENT + `${commentId}/upvote/`,
    {}
  )
}
export const downVoteComment = async (commentId: number) => {
  return await api.post<{ data: COMMENT }>(
    POST_COMMENT + `${commentId}/downvote/`,
    {}
  )
}
export const reportContent = async (report: ReportDTO) => {
  return await api.post<{ data: ReportDTO }>(REPORT_CONTENT, report)
}
export const resolveContent = async (reportId: number) => {
  return await api.post<{ data: REPORT }>(
    REPORT_CONTENT + `${reportId}/resolve/`,
    {}
  )
}

export const getReports = async (data: ReportPage) => {
  const qs = addDataToQueryString('', data)
  return (
    await api.get<{ data: PaginatedData<REPORT> }>(REPORT_CONTENT + `?${qs}`)
  ).data
}

export const getReport = async (reportId: number) => {
  return (await api.get<{ data: REPORT }>(REPORT_CONTENT + `${reportId}/`)).data
}

export const repostPost = async (data: RepostDTO) => {
  const { postId, ...rest } = data
  return await api.post<{ data: POST }>(POSTS + `${postId}/repost/`, rest)
}

export const restrictUser = async (data: RestrictDTO) => {
  return await api.post<{ data: RestrictDTO }>(FORUM_RESTRICTION, data)
}

export const getPostsByCategory = async () => {
  return (await api.get<{ data: POSTS_BY_CATEGORY }>(POST_BY_COHORT)).data
}

export const getForumActivity = async (data: ForumActivityDTO) => {
  const qs = addDataToQueryString('', data)
  return (
    await api.get<{ data: PaginatedData<ForumActivityResponse> }>(
      FORUM_ACTIVITY + `?${qs}`
    )
  ).data
}
export const getForumDelistedContent = async () => {
  return (await api.get<{ data: (COMMENT | POST)[] }>(DELISTED_CONTENT)).data
}

export const readDelistedContent = async (data: ReadContentDTO) => {
  return await api.post<{ data: any }>(DELISTED_CONTENT + 'read/', data)
}
