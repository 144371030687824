import { useState } from 'react'
import { getUserType, getAccessTokens } from './../../store/authSlice'
import { useAppSelector } from './../../store/hooks'
import { useLocation, useNavigate } from 'react-router'
import { useEffect } from 'react'

import useRefreshToken from './useRefreshToken'
import { axiosInstance } from '../api'
import { HttpStatusCode } from '../constants'
import { Storage } from '@/helpers/Storage'
import { withAsync } from '@/helpers/withAsync'
import useNetworkStatus from '@/hooks/useNetworkStatus'
import { ToastNotify } from '@/common/toastManager'

const useAutomaticRefresh = () => {
  const isOnline = useNetworkStatus()
  const [isDone, setIsDone] = useState(false)
  const userType =
    useAppSelector(getUserType) || Storage.getItem('USER_TYPE') || 'alumni'
  const accessToken = useAppSelector(getAccessTokens)

  const refresh = useRefreshToken()
  // const navigate = useNavigate()
  // const location = useLocation()
  useEffect(() => {
    // setIsDone(false)
    // if (!isOnline) {
    //   setIsDone(true)
    //   return
    // }
    // if (window.location.pathname.includes('auth')) {
    //   return
    // }
    const requestInterceptors = axiosInstance.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          if (accessToken)
            config.headers['Authorization'] = `Bearer ${accessToken}`
          if (
            config.url?.includes('auth/token/refresh/') ||
            config.url?.includes('account/auth/login')
          ) {
            delete config.headers['Authorization']
          }
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    const responseInterceptors = axiosInstance.interceptors.response.use(
      (response) => {
        return response
      },
      (() => {
        let sent = false
        return async (error) => {
          const prevRequest = error?.config

          if (
            (error?.response?.status === HttpStatusCode.Forbidden ||
              error?.response?.status === HttpStatusCode.Un_Authorized) &&
            !prevRequest?.sent &&
            !sent
          ) {
            prevRequest.sent = true
            sent = true
            const { error, response: newAccessToken } = await withAsync(() =>
              refresh()
            )
            if (error) {
              return Promise.reject(error)
            }
            prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
            return axiosInstance(prevRequest)
          }
          // if (
          //   (error?.response?.status === HttpStatusCode.Forbidden ||
          //     error?.response?.status === HttpStatusCode.Un_Authorized) &&
          //   prevRequest?.sent
          // ) {
          //   prevRequest.sent = false
          //   navigate(`/${userType}/auth/sign-in/`, {
          //     state: {
          //       from: location.pathname,
          //     },
          //   })
          // }

          return Promise.reject(error)
        }
      })()
    )
    setIsDone(true)
    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptors)
      axiosInstance.interceptors.request.eject(requestInterceptors)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType, accessToken])
  return [axiosInstance, isDone]
}

export default useAutomaticRefresh
