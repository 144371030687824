import React, { FC } from 'react'
import iconComponents from '@/assets/icons/iconComponents'
import { Outlet } from 'react-router'
import { UserTypes } from '@/apis/authApis'
import useMediaQuery from '@/hooks/useMediaQuery'
import Image from '@/common/Image'

type SuccessLayoutProps = {
  userType: UserTypes
}
const SuccessLayout: FC<SuccessLayoutProps> = ({ userType }) => {
  const isMobile = useMediaQuery('(max-width: 640px)')
  const isTablet = useMediaQuery('(max-width: 768px)')
  return (
    <div
      style={{
        gridTemplateRows: isMobile
          ? '4.7rem 1fr'
          : isTablet
          ? '10rem 1fr'
          : '10rem 1fr',
      }}
      className='grid h-screen w-screen grid-cols-1'
    >
      <div className='w-full col-span-1 px-4 pt-[26px] sm:px-8 sm:py-20 lg:p-10 h-full flex justify-start items-center bg-jaa-shades-white'>
        {/* <iconComponents.util.LogoIcon className='h-[69px] w-[137px]' /> */}
        <Image
          src='/Logo_Full Color.png'
          className='h-[99px] w-[381px] object-cover object-center'
        />
      </div>
      <main>{<Outlet />}</main>
    </div>
  )
}

export default SuccessLayout
