import AlumniCreateImage from './alumni-create.png'
import Alumni from './alumni.png'
import Alumni1 from './alumni1.png'
import Alumni2 from './alumni2.png'
import Alumni3 from './alumni3.png'
import Alumni4 from './alumni4.png'
import Recruiter1 from './recruiter1.png'
import Recruiter2 from './recruiter2.png'
import Recruiter3 from './recruiter3.png'
import Recruiter4 from './recruiter4.png'
import RecruiterCreate from './recruiter-create.png'
import Admin from './admin.png'
import AdminSignin from './admin-signin.png'

import CompanyInfo from './company-info.png'

const imagesUrl = {
  Alumni,
  Alumni1,
  Alumni2,
  Alumni3,
  Alumni4,
  AlumniCreateImage,
  Recruiter1,
  Recruiter2,
  Recruiter3,
  Recruiter4,
  RecruiterCreate,
  Admin,
  AdminSignin,
  CompanyInfo,
}

export default imagesUrl
