// import { addDataToQueryString } from './../helpers/strings'
import { PaginatedData } from './api.types'
import api from './api'
import { ALL_RECRUITERS } from './endpoints'

import { IRecruiterProfile } from './profileApis'
import { Company } from './profileApis'
import { StringSchema } from 'yup'
import { addDataToQueryString } from '@/helpers/strings'
import { RECRUITER_PRIMARY_OWNER } from './endpoints'

export interface RecruitersList {
  status: boolean
  message: string
  data: {
    count: number
    next: any
    previous: any
    results: IRecruiterProfile[]
  }
}

// export interface BlockRecruiterDTO {
//   action: 'block' | 'unblock'
//   company_id: number
// }

export type RecruitersListDTO = {
  page?: number
  company?: string | string[]
  role?: string
  search?: string
  blocked?: string
  export?: string
  id?: number
}

export const getRecruiterList = async (payload: RecruitersListDTO) => {
  const qs = addDataToQueryString('', payload)

  return (
    await api.get<{ data: PaginatedData<IRecruiterProfile> }>(
      ALL_RECRUITERS + (qs ? '?' + qs : '')
    )
  ).data
}
export const getRecruiter = async (id: number) => {
  return (await api.get<{ data: IRecruiterProfile }>(`${ALL_RECRUITERS}/${id}`))
    .data
}

export const getBlockedRecruiterList = async (payload: RecruitersListDTO) => {
  const qs = addDataToQueryString('', payload)

  return (
    await api.get<{ data: PaginatedData<RecruitersList> }>(
      `${ALL_RECRUITERS}${`?blocked=true` + (qs ? '&' + qs : '')}`
    )
  ).data
}

export const getProfileCompletionValue = async (id: any) => {
  return (
    await api.get<{ data: IRecruiterProfile }>(
      `${ALL_RECRUITERS}/${id}/profile-completion`
    )
  ).data
}

// export const blockRecruiter = async (data: BlockRecruiterDTO) => {
//   return await api.patch<{ data: BlockRecruiterDTO }>(
//     `${RECRUITER_PRIMARY_OWNER}toggle-block/`,
//     data
//   )
// }
