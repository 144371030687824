import { Industry, RecruiterPrimaryDTO } from '@/apis/authApis'
import Button from '@/common/button'
import FormGroup from '@/common/Input/FormGroup'
import { InputDataList } from '@/common/Input/InputDataList'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import InputWithChips from '@/common/Input/InputWithChips'
import SelectInput from '@/common/Input/SelectInput'
import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import { withAsync } from '@/helpers/withAsync'
import { useCountries } from '@/hooks/useCountryStates'
import useQueryParams from '@/hooks/useQueryParams'
import createCompanySchema from '@/pages/auth/validationschemas/company.create.validator'
import { useRecruiterCreateCompanyMutation } from '@/store/authSlice'
import { FormikHelpers, useFormik } from 'formik'
import { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { INest } from './user.type'
import { useJobFiltersQuery } from '@/store/recruitementSlice'

const initialState: RecruiterPrimaryDTO = {
  registration_number: '',
  name: '',
  company_role: '',
  industry: '',
  roles_hiring: [],
  country: '',
  user_id: 0,
}
const KnowRecruiter = (props: INest) => {
  const countries = useCountries()[0]

  const { user } = useQueryParams()
  const { pathname } = useLocation()
  const { goToNextStep, step } = props
  const navigate = useNavigate()
  const [createCompany, { isLoading: isSending }] =
    useRecruiterCreateCompanyMutation()
  const { data: filterOptions, isLoading: isLoadingIndustries } =
    useJobFiltersQuery()
  const submitHandler = async (
    values: RecruiterPrimaryDTO,
    { setSubmitting, resetForm }: FormikHelpers<RecruiterPrimaryDTO>
  ) => {
    if (!values.user_id) {
      return ToastNotify('error', {
        message:
          'Please Kindly Revisit this page by clicking on the mail sent to you.',
      })
    }
    const { error } = await withAsync(() => createCompany(values).unwrap())
    if (error) {
      return ToastNotify('error', {
        message: (error as any).message,
      })
    }
    goToNextStep()
    resetForm()
    setSubmitting(false)
    navigate(pathname + '?page=' + (step + 1), {
      replace: true,
    })
  }
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    isValid,
    setFieldValue,
  } = useFormik<RecruiterPrimaryDTO>({
    initialValues: initialState,
    onSubmit: submitHandler,
    validationSchema: createCompanySchema(
      countries.map((country: any) => country.name)
    ),
  })
  useEffect(() => {
    setFieldValue('user_id', parseInt(user))
  }, [user, setFieldValue])

  return (
    <div className='w-full flex flex-col gap-4 justify-start items-start z-0'>
      {' '}
      <header className='flex flex-col gap-1 w-full'>
        <Typography as='h2' className='h3 text-jaa-shades-black  sm:h2'>
          Let&apos;s Know you
        </Typography>
        <Typography as='p' className='text-jaa-shades-gray-2.5 font-normal sm'>
          Additional details about you and your company
        </Typography>
      </header>
      <form
        className='grid grid-rows-4 grid-cols-1 w-full gap-y-4 relative'
        onSubmit={handleSubmit}
      >
        <FormGroup id='role' label='Company Role' className='col-span-1 '>
          <InputWithAdornment
            name='company_role'
            placeholder='Your role in organization'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.company_role}
            error={
              errors.company_role &&
              (touched.company_role as unknown as string) &&
              errors.company_role
            }
          />
        </FormGroup>
        <FormGroup id='company' label='Company Name' className='col-span-1 '>
          <InputWithAdornment
            name='name'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={
              errors.name && (touched.name as unknown as string) && errors.name
            }
          />
        </FormGroup>
        <FormGroup id='country' label='Country' className='col-span-1 '>
          <SelectInput
            options={countries.map((country: any) => country.name)}
            menuClassName={'w-[380px] bg-jaa-shades-white'}
            placeholder='Select...'
            className='select h-[55px] md'
            name='country'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.country}
            error={
              errors.country &&
              (touched.country as unknown as string) &&
              errors.country
            }
          />
        </FormGroup>
        <FormGroup
          id='registration_number'
          label='Company Registeration Number'
          className='col-span-1 '
        >
          <InputWithAdornment
            type='registration_number'
            name='registration_number'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.registration_number}
            error={
              errors.registration_number &&
              (touched.registration_number as unknown as string) &&
              errors.registration_number
            }
          />
        </FormGroup>
        <FormGroup
          id='industry'
          label='Industry'
          className='col-span-1 '
          required
        >
          {/* <SelectInput
            name='industry'
            placeholder='Select...'
            // optionsText={(el) => el.label}
            // optionsValue={(el) => el.value}
            options={Object.keys(Industry)}
            menuClassName={'w-[380px] bg-jaa-shades-white'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.industry}
            error={
              errors.industry &&
              (touched.industry as unknown as string) &&
              errors.industry
            }
            className='w-full select'
          /> */}
          <InputDataList
            data={isLoadingIndustries ? [] : filterOptions?.industry || []}
            text={values.industry}
            setText={(e) => {
              setFieldValue('industry', e)
            }}
          />
        </FormGroup>
        {/* <FormGroup
          id='newindustry'
          label='New Industry'
          className='col-span-1 '
        >
          <InputWithAdornment
            name='new_industry'
            placeholder='Add Industry'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.new_industry}
            error={
              errors.new_industry &&
              (touched.new_industry as unknown as string) &&
              errors.new_industry
            }
          />
        </FormGroup> */}
        <FormGroup
          id='roles_hiring_for'
          label='Roles Hiring For'
          required
          className='col-span-1 '
        >
          <InputWithChips
            values={values.roles_hiring}
            setSelectedValues={useCallback(
              (values: string[]) => setFieldValue('roles_hiring', values),
              []
            )}
            className='w-full bg-[#f7f7f7]  md rounded min-h-[120px] max-h-[240px] overflow-auto scrollbar-[2px] scrollbar-thumb-jaa-teal-40 scrolllbar-track-jaa-shades-white focus-within:scrollbar-track-[#f7f7f7] ring-offset focus-within:bg-white focus-within:ring-4 border border-transparent focus-within:border-jaa-teal-80 focus-within:ring-jaa-teal-20'
          />
        </FormGroup>
        <Button
          loading={isSending}
          disabled={
            (Object.values(touched).filter((touch) => touch).length > 0 &&
              !isValid) ||
            isSending
          }
          type='submit'
          label='Continue'
          className='mt-6 '
        />
      </form>
    </div>
  )
}

export default KnowRecruiter
