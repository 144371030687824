import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  AlumniReportData,
  RecruiterReportData,
  getAlumniReportData,
  getRecruiterReportData,
  GenderReportResponse,
  USER_TYPES,
  getGenderReportData,
  getUserGrowthReportData,
  getUserPerCountryReportData,
  UserGrowthDTO,
  UserGrowthReportResponse,
  UserPerCountryReportResponse,
  ForumEngagement,
  getFroumEngagementData,
} from '@/apis/reportApis'

export const reportApiSlice = createApi({
  reducerPath: 'reportApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['AlumniReport', 'RecruiterReport', 'Report', 'ForumEngagement'],

  endpoints: (builder) => ({
    getRecruiterReportData: builder.query<RecruiterReportData, number>({
      queryFn: async (id) => {
        try {
          return await getRecruiterReportData(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['RecruiterReport'],
    }),
    getAlumniReportData: builder.query<AlumniReportData, number>({
      queryFn: async (id) => {
        try {
          return await getAlumniReportData(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['AlumniReport'],
    }),
    getGenderReportData: builder.query<GenderReportResponse, USER_TYPES>({
      queryFn: async (userType) => {
        try {
          return await getGenderReportData(userType)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Report'],
    }),
    getUserGrowthReportData: builder.query<
      UserGrowthReportResponse,
      UserGrowthDTO
    >({
      queryFn: async (payload) => {
        try {
          return await getUserGrowthReportData(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Report'],
    }),
    getUserPerCountryReportData: builder.query<
      UserPerCountryReportResponse,
      USER_TYPES
    >({
      queryFn: async (payload) => {
        try {
          return await getUserPerCountryReportData(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Report'],
    }),
    getForumEngagements: builder.query<ForumEngagement, void>({
      queryFn: async (id) => {
        try {
          return await getFroumEngagementData()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['ForumEngagement'],
    }),
  }),
})

export const {
  useGetRecruiterReportDataQuery,
  useGetAlumniReportDataQuery,
  useGetGenderReportDataQuery,
  useGetUserGrowthReportDataQuery,
  useGetUserPerCountryReportDataQuery,
  useGetForumEngagementsQuery,
} = reportApiSlice

export const resetReportApiSlice = () => reportApiSlice.util.resetApiState()
