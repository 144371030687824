import { getUniques } from '@/helpers/array'
import { useCallback, useState } from 'react'

const useToggleFields = <FS = {}>(intialField?: FS) => {
  const [fields, setFields] = useState<FS[]>(intialField ? [intialField] : [])
  const addField = useCallback((field: FS, key?: string) => {
    setFields((fields) => {
      if (!key) return fields.concat(field)
      const allFields = fields.concat(field)

      return getUniques(allFields, key)
    })
  }, [])
  const removeField = useCallback(
    (field: FS | string | number, key?: string) => {
      if (typeof field !== 'object' && key) {
        return setFields((prev) => {
          const newFileds = [...prev]
          return newFileds.filter((_field) => {
            return _field[key as keyof FS] !== field
          })
        })
      } else if (typeof field === 'object') {
        setFields((prev) => prev.filter((_field) => _field !== field))
      }
    },
    []
  )
  const updateField = useCallback(
    (update: Partial<FS>, field: string | number, key?: string) => {
      return setFields((prev) => {
        const newFileds = [...prev]
        const fieldIdx = newFileds.findIndex((_field) => {
          return _field[key as keyof FS] === field
        })
        if (fieldIdx === -1) return prev
        newFileds.splice(fieldIdx, 1, {
          ...prev[fieldIdx],
          ...update,
        })
        return newFileds
      })
    },
    []
  )
  return { fields, addField, removeField, updateField, setFields } as const
}

export default useToggleFields
