import { useAppSelector } from '@/store/hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import LoadingText from '../../components/LoadingText'
import UserRepresentation from '../../components/UserRepresentation'
import UserWelcome from '../../components/UserWelcome'
import { useTranslation } from 'react-i18next'

const RecruiterAccountCreated = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)
  const {
    first_name,
    last_name,
    admin_role,
    company_role,
    recruiter_role,
    company,
    primary_admin,
    name,
  } = useAppSelector((state) => state.auth as any)
  const navigate = useNavigate()
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
      navigate('/recruiter/app/dashboard', {
        replace: true,
      })
    }, 5000)
    return () => {
      clearInterval(timer)
    }
  }, [])
  const fullName = (() => {
    if (first_name || last_name) return `${first_name} ${last_name}`
    return primary_admin?.first_name + ' ' + primary_admin?.last_name
  })()

  //Todo: Do any neceessary setup here before entering application
  return (
    <div className='p-4 sm:px-[20%] flex flex-col lg:px-[30%] gap-14'>
      <UserWelcome
        welcomeText={t('app.auth.recruiter_welcome_text')}
        userType={company?.name || name}
        description={t('app.auth.recruiter_welcome_text_desc')}
      />
      <UserRepresentation
        name={`${fullName}`}
        position={
          company_role ||
          recruiter_role?.replace?.('_', ' ') ||
          primary_admin?.company_role ||
          ''
        }
      />
      <LoadingText loading={isLoading} text={t('app.auth.finishing_setup')} />
    </div>
  )
}

export default RecruiterAccountCreated
