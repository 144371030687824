import api from './api'
import { DASHBOARD_DATA } from './endpoints'
import { addDataToQueryString } from '../helpers/strings'
import { PaginatedData } from './api.types'

export type DashboardData = {
  total_recruiters: number
  total_alumni: number
  total_engaged_alumni: number
  total_hired_alumni: number
  recent_alumni: [
    {
      id: number
      first_name: string
      last_name: string
      email: string
    }
  ]
  recent_recruiters: [{ id: number; company: string; email: string }]
  engaged_recruiters: number
  active_pipelines: number
  bio_views: number
  viewing_industries: [
    {
      name: string
      count: number
      degree: number
    }
  ]
  viewing_companies: number
  relevant_skills: string[]
}

export const getDashboardData = async () => {
  return (await api.get<{ data: DashboardData }>(DASHBOARD_DATA)).data
}
