import { addDataToQueryString } from './../helpers/strings'
import { PaginatedData } from './api.types'
import api from './api'
import {
  ALL_JOBS,
  ALL_PIPELINES,
  ALUMNI_DETAIL,
  JOB_APPLICATIONS,
  JOB_IMAGES,
  RECRUITER_RATE,
  RECRUITMENT_DASHBOARD,
} from './endpoints'
import { IRecruiterProfile } from './profileApis'

export interface IFilter {
  job_type: string[]
  work_policy: string[]
  company_size: string[]
  industry: string[]
  salary_range: string[]
  job_role: string[]
  currency: { name: string; value: string }[]
}

export type JobMatchDTO = {
  job: number
  application_status: 'application' | 'engaged' | 'accepted'
}
export type JobSearchDTO = {
  page?: number
  page_size?: number
  job_type?: string[]
  work_policy?: string[]
  company_size?: string[]
  industry?: string[]
  job_title?: string
  company_id?: number
  location?: string
  salary_range?: string[]
  published?: boolean
  status?: 'Open' | 'Archived'
}

export type Job = {
  images: { id: number; image: string }[]
  id: number
  company_size: string
  company_name: string
  company_logo: string
  title: string
  description: string
  job_type: string
  closed_date?: string
  work_policy: string
  years_of_experience: number
  location: string
  salary_range: string
  salary_currency: string
  published: boolean
  created_at: string
  updated_at: string
  recruiter?: IRecruiterProfile
  pipeline: Pipeline
  status?: string
  candidates?: {
    count: number
    avatars: string[]
  }
}

export type RatingDTO = {
  number: number
  reasons: string[]
  job_application: number
}
export type CreateJobDTO = {
  requirements?: string[]
  title: string
  description: string
  job_type: string
  work_policy: string
  closed_date: string
  years_of_experience: number
  location: string
  salary_range: string
  salary_currency: string
  published?: boolean
  company?: number
  pipeline: number
  images?: (File | string)[]
}
export type Pipeline = {
  id: number
  stages: Stage[]
  stage_order: number[]
  name: string
  created_at: string
  candidates?: {
    avatars: string[]
    total_candidates: number
  }
  activities: {
    count: number
    data: Activity[]
  }
}
export interface Activity {
  recipient: Recipient
  action: string
  actor: Actor
  timestamp: string
}

interface Actor {
  first_name: string
  last_name: string
}

interface Recipient {
  first_name: string
  last_name: string
  profile_pic?: string
}
export interface Stage {
  id?: number
  num_of_applications?: number
  name: string
  description: string
  _order?: number
  pipeline: number
  applicants?: ApplicantSummary[]
}

export interface StageOrder {
  pipeline: number
  order: number[]
}

export interface Applicant {
  id: number
  job: Job
  alumnus: string
  alumnus_id: number
  alumnus_user_id: number
  stage: string
  stage_id: number
  dropped: boolean
  date_applied: string
  date_completed: string
  date_rejected: string
  recruiter: number
}
export interface StageResponse {
  id: number
  num_of_applications: string
  name: string
  description: string
  _order: number
  pipeline: number
}

export type JobApplicantDTO = {
  page: number
  page_size: number
  archived?: boolean
  alumnus_id?: number
}
export type MoveAlumniDTO = {
  alumnus: number
  job: number
  stage?: number
}

interface AverageRating {
  count: number
  average: number
}

interface CV {
  filename: string
  url: string
}

type Gender = 'male' | 'female'
export interface ALUMNI {
  id: number
  user_id: number
  phone_number: string
  profile_pic: string
  skills: string[]
  gender: Gender
  job_title: string
  country: string
  region: string
  program: string
  cohort: number
  cv: CV
  video_bio: string
  work_availability: string
  website: string
  twitter: string
  figma: string
  instagram: string
  facebook: string
  linkedin: string
  bio_viewers: Bioviewers
  last_updated: string
  first_name?: string
  last_name?: string
  email?: string
  hired_application?: HiredApplication
  engagement?: EngagedApplicant
  profile_completion: number
  average_rating?: AverageRating
}

interface HiredApplication {
  job: string
  company: string
  recruiter: string
  date_hired: string
  status: string
}
interface EngagedApplicant {
  status: string
  company: string
  recruiter: string
  last_engaged?: any
  date_engaged: string
}
export interface ApplicantSummary {
  id: number
  user_id: number
  job_id: number
  job_application: number
  first_name: string
  last_name: string
  profile_pic: string
  job_title: string
  years_of_experience: string
  pipeline: Pipeline
  stage?: any
  accepted: boolean
  recruiter?: any
  hired: boolean
}

interface Bioviewers {}

export const getRecruiterPostedJobs = async (payload: JobSearchDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<Job> }>(ALL_JOBS + (qs ? '?' + qs : ''))
  ).data
}
export const getJobFilters = async () => {
  return (await api.get<{ data: IFilter }>(ALL_JOBS + 'filters/')).data
}

export const deleteJobImage = async (data: { id: number }) => {
  return await api.delete<any>(JOB_IMAGES + data.id + '/')
}
export const recruiterPostJob = async (data: FormData) => {
  return await api.post<{ data: CreateJobDTO & { id: number } }>(ALL_JOBS, data)
}

export const getJob = async (id: number) => {
  return (await api.get<{ data: Job }>(ALL_JOBS + id + '/')).data
}

export const getJobsApplications = async (data: JobApplicantDTO) => {
  const qs = addDataToQueryString('', data)
  return (
    await api.get<{ data: PaginatedData<Applicant> }>(
      JOB_APPLICATIONS + '?' + qs
    )
  ).data
}
export const getJobApplication = async (id: number) => {
  return (await api.get<{ data: Applicant }>(JOB_APPLICATIONS + id + '/')).data
}

export const getJobMatches = async (data: JobMatchDTO) => {
  const qs = addDataToQueryString('', data)
  return (
    await api.get<{ data: PaginatedData<ApplicantSummary> }>(
      ALL_JOBS + 'matches/?' + qs
    )
  ).data
}

export const updateRecruiterJob = async (data: FormData) => {
  return await api.patch<{ data: CreateJobDTO & { id: number } }>(
    ALL_JOBS + data.get('id') + '/',
    data
  )
}

export const deleteRecruiterJob = async (id: number) => {
  return (await api.delete<{ data: Job }>(ALL_JOBS + id + '/')).data
}

export const archiveRecruiterJob = async (id: number) => {
  return (await api.post<{ data: Job }>(ALL_JOBS + id + '/archive/', {})).data
}

export const unarchiveRecruiterJob = async (data: {
  job: number
  closed_date: string
}) => {
  const { job: id, ...rest } = data
  return (await api.post<{ data: Job }>(ALL_JOBS + id + '/un-archive/', rest))
    .data
}

export const getRecruitmentMetrics = async () => {
  return (await api.get<{ data: any }>(RECRUITMENT_DASHBOARD)).data
}
export const alumniApplyForJob = async (jobId: number) => {
  return await api.post<{ data: Applicant }>(ALL_JOBS + jobId + '/apply/', {})
}
export const recruiterMoveAlumniToPipeline = async (data: MoveAlumniDTO) => {
  return await api.post<{ data: MoveAlumniDTO }>(
    ALL_PIPELINES + 'add-alumnus/',
    data
  )
}
export const recruiterMoveAlumniAcrossPipeline = async (
  data: MoveAlumniDTO
) => {
  return await api.post<{ data: MoveAlumniDTO }>(
    ALL_PIPELINES + 'move-alumnus/',
    data
  )
}
export const recruiterHireAlumni = async (data: MoveAlumniDTO) => {
  return await api.post<{ data: MoveAlumniDTO }>(
    ALL_PIPELINES + 'hire-alumnus/',
    data
  )
}
export const recruiterRejectsApplication = async (payload: {
  job: number
  message?: string
}) => {
  return (
    await api.patch<{ data: any }>(
      JOB_APPLICATIONS + payload.job + '/reject/',
      { message: payload.message }
    )
  ).data
}

export const getJobsPipeline = async (payload: Pick<JobSearchDTO, 'page'>) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<Pipeline> }>(
      ALL_PIPELINES + (qs ? '?' + qs : '?page=1')
    )
  ).data
}

export const getJobPipeline = async (payload: { id: number }) => {
  return (await api.get<{ data: Pipeline }>(ALL_PIPELINES + payload.id + '/'))
    .data
}
export const getPipelineActivities = async (payload: {
  pipeline: number
  page: number
}) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<Activity> }>(
      ALL_PIPELINES + 'activities/?' + qs
    )
  ).data
}
export const createPipeline = async (data: { name: string }) => {
  return await api.post<{ data: Pipeline }>(ALL_PIPELINES, data)
}
export const updatePipeline = async (data: { name: string; id: number }) => {
  return await api.patch<{ data: Pipeline }>(
    ALL_PIPELINES + data.id + '/',
    data
  )
}

export const addStageToPipeline = async (data: Stage) => {
  return await api.post<{ data: StageResponse }>(
    ALL_PIPELINES + 'stages/',
    data
  )
}

export const updateStage = async (data: Partial<Stage> & { id: number }) => {
  const { id, ...rest } = data
  return await api.patch<{ data: StageResponse }>(
    ALL_PIPELINES + 'stages/' + id + '/',
    rest
  )
}

export const updateStageOrder = async (data: StageOrder) => {
  return await api.post<{ data: StageOrder }>(
    ALL_PIPELINES + 'stage-order/',
    data
  )
}

export const deleteStage = async (data: { id: number }) => {
  const { id } = data
  return await api.delete(ALL_PIPELINES + 'stages/' + id + '/')
}

export const getAlumni = async (payload: { id: number }) => {
  return (await api.get<{ data: ALUMNI }>(ALUMNI_DETAIL + payload.id + '/'))
    .data
}

export const getHiredAlumni = async (payload: {
  page: number
  page_size?: number
  company: number[]
}) => {
  const qs = addDataToQueryString('', { ...payload, report: 'hired' })
  return (
    await api.get<{ data: PaginatedData<ALUMNI> }>(ALUMNI_DETAIL + `?${qs}`)
  ).data
}

export const createRating = async (data: RatingDTO) => {
  return await api.post<{ data: RatingDTO }>(RECRUITER_RATE, data)
}
