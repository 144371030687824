import { POST } from '@/apis/forumApis'
import { formatDistanceToNow } from 'date-fns'

export const isNow = (post: POST) => {
  return Date.parse(post.created_at)
    ? formatDistanceToNow(new Date(post.created_at), {
        includeSeconds: true,
      }) === 'less than 5 seconds'
    : false
}
