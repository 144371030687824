import { addDataToQueryString } from '@/helpers/strings'
import api from './api'
import { ALL_ALUMNI_LIST } from './endpoints'
import { IAlumniProfile } from './profileApis'
import { PaginatedData } from './api.types'

export type Option = {
  id: number
  name: string
}

export interface IFilter {
  company?: CompanyFilter[]
  cohort?: number[]
  program?: string[]
  report?: string[]
}
export type CompanyFilter = {
  id: number
  name: string
}

export type DashboardData = {
  engaged_recruiters: number
  active_pipelines: number
  bio_views: number
  viewing_industries: [
    {
      name: string
      count: number
      degree: number
    }
  ]
  viewing_companies: number
  relevant_skills: string[]
}

export type AlumniListDTO = {
  page?: number
  search?: string
  online?: boolean
  company?: number[] | number
  cohort?: (number | string)[]
  program?: string[]
  country?: string
}

export type downloadOption = {
  export: string
}

export interface AlumniList {
  status?: boolean
  message?: string
  data: {
    count: number
    next: any
    previous: any
    results: IAlumniProfile[]
  }
}

export const getAllAlumniData = async (payload: AlumniListDTO) => {
  const qs = addDataToQueryString('', payload)

  return (
    await api.get<{ data: any }>(`${ALL_ALUMNI_LIST}${qs ? '?' + qs : ''}`)
  )?.data
}

export const getRegisteredAlumniData = async (payload: AlumniListDTO) => {
  const qs = addDataToQueryString('', payload)

  return (
    await api.get<{ data: any }>(
      `${ALL_ALUMNI_LIST}${'?report=registered' + (qs ? '&' + qs : '')}`
    )
  ).data
}

export const getAppliedAlumniData = async (payload: AlumniListDTO) => {
  const qs = addDataToQueryString('', { ...payload, applied: true })

  return (
    await api.get<{ data: PaginatedData<IAlumniProfile> }>(
      `${ALL_ALUMNI_LIST}${qs ? '?' + qs : ''}`
    )
  ).data
}
export const getBlockedAlumniData = async (payload: AlumniListDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: any }>(
      `${ALL_ALUMNI_LIST}${'?report=blocked' + (qs ? '&' + qs : '')}`
    )
  ).data
}
export const getRejectedAlumniData = async (payload: AlumniListDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<IAlumniProfile> }>(
      `${ALL_ALUMNI_LIST}${'?report=rejected' + (qs ? '&' + qs : '')}`
    )
  ).data
}
export const getEngagedAlumniData = async (payload: AlumniListDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: any }>(
      `${ALL_ALUMNI_LIST}${'?report=engaged' + (qs ? '&' + qs : '')}`
    )
  ).data
}
export const downloadAlumniData = async (payload: downloadOption) => {
  const qs = addDataToQueryString('', payload)
  return await api.get<{ data: any }>(`${ALL_ALUMNI_LIST}${qs ? '?' + qs : ''}`)
}
export const getHiredAlumniData = async (payload: AlumniListDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<IAlumniProfile> }>(
      `${ALL_ALUMNI_LIST}${'?report=hired' + (qs ? '&' + qs : '')}`
    )
  ).data
}
export const getAlumniInCohort = async (payload: AlumniListDTO) => {
  const qs = addDataToQueryString('', {
    ...payload,
    cohort: [payload.cohort],
  })
  return (
    await api.get<{ data: PaginatedData<IAlumniProfile> }>(
      `${ALL_ALUMNI_LIST}${qs ? '?' + qs : ''}`
    )
  ).data
}
export const getAlumniOnline = async (payload: AlumniListDTO) => {
  const qs = addDataToQueryString('', {
    ...payload,
  })
  return (
    await api.get<{ data: PaginatedData<IAlumniProfile> }>(
      `${ALL_ALUMNI_LIST}${qs ? '?' + qs : ''}`
    )
  ).data
}

export const getAlumniProfileCompletionData = async (
  payload: AlumniListDTO
) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: any }>(
      `${ALL_ALUMNI_LIST}${'?report=profile_completion' + (qs ? '&' + qs : '')}`
    )
  ).data
}

export const getAlumniFilters = async () => {
  return (await api.get<{ data: IFilter }>(ALL_ALUMNI_LIST + 'filters/')).data
}
