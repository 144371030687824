import Button from '@/common/button'
import Checkbox from '@/common/Input/Checkbox'
import ModalHeader from '@/common/modal/ModalHeader'
import { Storage } from '@/helpers/Storage'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const DONT_SHOW_LOGOUT_MODAL = 'DONT_SHOW_LOGOUT_MODAL'
const LogoutWarning: FC<{
  onClose: () => void
  logoutAction: () => void
}> = ({ onClose, logoutAction }) => {
  const { t } = useTranslation()

  const [dontShowPassword, setDontShowPassword] = useState(
    Boolean(Storage.getItem(DONT_SHOW_LOGOUT_MODAL))
  )
  const dontShowHandler = (checked: boolean) => {
    Storage.setItem(DONT_SHOW_LOGOUT_MODAL, checked)
    setDontShowPassword(checked)
  }
  return (
    <div className='py-7 w-full px-7 flex flex-col gap-10 z-30'>
      <ModalHeader title={`${t('app.log_out_question')}`} onClose={onClose} />
      <div className='flex flex-col gap-4 justify-start items-start'>
        <p className='text-jaa-shades-black font-light  md'>
          {t('app.log_out_message')}
        </p>
        <div className='flex justify-start items-center gap-2 cursor-pointer'>
          <Checkbox
            id='remember'
            checked={dontShowPassword}
            onChange={(e) => dontShowHandler(e.currentTarget.checked)}
          />
          <label htmlFor='remember' className='font-light sm -mt-2'>
            {t('app.dont_show')}
          </label>
        </div>
      </div>
      <div className='flex justify-start items-center gap-2.5'>
        <Button
          onClick={logoutAction}
          className='capitalize min-w-[160px] px-3 md whitespace-nowrap'
          label={`${t('app.log_out_yes')}`}
        />
        <Button
          variant='cancel'
          onClick={onClose}
          className='capitalize w-[190px] px-2 text-base bg-[#F7F7F7] text-point-primary-text whitespace-nowrap'
          label={`${t('app.cancel')}`}
        />
      </div>
    </div>
  )
}

export default LogoutWarning
