import { getCountries, getWorkRoles } from '@/apis/optionsApis'
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'

export const optionsApiSlice = createApi({
  reducerPath: 'optionsApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Options'],

  endpoints: (builder) => ({
    getCountries: builder.query<string[], void>({
      queryFn: async () => {
        try {
          return await getCountries()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Options'],
    }),
    getWorkRoles: builder.query<string[], void>({
      queryFn: async () => {
        try {
          return await getWorkRoles()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Options'],
    }),
  }),
})
export const {
  useGetCountriesQuery,
  useGetWorkRolesQuery,
  usePrefetch: usePrefetchOptions,
} = optionsApiSlice
export const resetoptionsApiSlice = () => optionsApiSlice.util.resetApiState()
