import { ALL_COUNTRIES, WORK_ROLES } from './endpoints'
import api from './api'

export const getCountries = async () => {
  return (await api.get<{ data: string[] }>(ALL_COUNTRIES)).data
}

export const getWorkRoles = async () => {
  return (await api.get<{ data: string[] }>(WORK_ROLES)).data
}
