import Typography from '@/common/Typography'
import { FormikHelpers, useFormik } from 'formik'
import { SigninDTO } from '@/apis/authApis'
import signInSchema from '../../validationschemas/signin.validator'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import Button from '@/common/button'
import { useSignInMutation } from '@/store/authSlice'

import { useLocation, useNavigate } from 'react-router'
import { withAsync } from '@/helpers/withAsync'
import { ToastNotify } from '@/common/toastManager'
import { BackendToUI } from '@/pages/conversion'
import { useToggleState } from '@/hooks/useToggleState'
import { VerificationCodeEntry } from '../../recruiter/signup/primary/PrimaryAdminDetails'
import iconComponents from '@/assets/icons/iconComponents'
import { useTranslation } from 'react-i18next'

const initialState: SigninDTO = {
  email: '',
  password: '',
}

const AdminSignIn = () => {
  const { t } = useTranslation()

  const {
    state: showOtpEntry,
    open: openOtpEntry,
    close: closeOtpEntry,
  } = useToggleState(false)
  const { state: showPassword, toggle: toggleShowPassword } =
    useToggleState(false)
  const { state: RouteState } = useLocation()
  const { from } = RouteState ?? {}

  const navigate = useNavigate()
  const [signIn, { isLoading }] = useSignInMutation()
  const submitHandler = async (
    values: SigninDTO,
    { setSubmitting, resetForm }: FormikHelpers<SigninDTO>
  ) => {
    const { error, response } = await withAsync(
      () => signIn({ ...values, user_type: 'admin' }).unwrap(),
      () => setSubmitting(false)
    )
    if (error) {
      return ToastNotify('error', {
        message: (error as any).message,
      })
    }
    const { data, message } = (response as any) || {}

    if ((data as any).send_otp) {
      ToastNotify('info', {
        message,
      })
      openOtpEntry()
      return
    }
    navigate(
      from || `/${BackendToUI[data?.user_type as 'admin']}/success/welcome`,
      {
        replace: true,
      }
    )
  }
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    isValid,
  } = useFormik<SigninDTO>({
    initialValues: initialState,
    onSubmit: submitHandler,
    validationSchema: signInSchema,
  })

  return (
    <div className='px-4 w-full flex flex-col gap-6 sm:gap-10 justify-start items-center sm:px-[15%] mb-12'>
      <header className='flex flex-col gap-1 w-full'>
        <Typography as='h2' className='h3 text-jaa-shades-black  sm:h2'>
          {t('app.auth.signin')}
        </Typography>
        {/* <Typography as='p' className='text-jaa-shades-gray-2.5 font-normal sm'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta non,
          libero. Ac.
        </Typography> */}
      </header>
      <form
        onSubmit={handleSubmit}
        className='grid grid-rows-4 grid-cols-1 w-full gap-y-4'
      >
        <FormGroup
          id='email'
          label={t('app.profile.email')}
          className='row-start-1 row-span-1'
        >
          <InputWithAdornment
            name='email'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={
              errors.email &&
              (touched.email as unknown as string) &&
              errors.email
            }
          />
        </FormGroup>
        <FormGroup
          id='password'
          label={t('app.form_labels.password')}
          className='row-start-2 row-span-1'
        >
          <InputWithAdornment
            type={showPassword ? 'text' : 'password'}
            right={
              <button
                type='button'
                onClick={toggleShowPassword}
                className='h-full flex items-center justify-center'
              >
                {showPassword ? (
                  <iconComponents.util.EyeCloseIcon className='stroke-jaa-shades-black fill-jaa-shades-black' />
                ) : (
                  <iconComponents.util.EyeOpenIcon className='stroke-jaa-shades-black fill-jaa-shades-black' />
                )}
              </button>
            }
            name='password'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={
              errors.password &&
              (touched.password as unknown as string) &&
              errors.password
            }
          />
        </FormGroup>
        <Button
          disabled={isLoading || !isValid}
          loading={isLoading}
          fullWidth
          type='submit'
          label={t('app.auth.signin')}
          className='row-start-3 row-span-1 mt-14'
        />
        <Button
          onClick={() => navigate('/admin/auth/forgot-password')}
          disabled={isLoading}
          fullWidth
          role='link'
          label={t('app.form_labels.forgot_password')}
          variant='empty'
          className='row-start-4 row-span-1'
        />
      </form>

      <VerificationCodeEntry
        email={values.email}
        open={showOtpEntry}
        onClose={closeOtpEntry}
        nextAction={() => {
          closeOtpEntry()
        }}
      />
    </div>
  )
}

export default AdminSignIn
