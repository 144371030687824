import iconComponents from '@/assets/icons/iconComponents'
import { useState } from 'react'
import { useToggleState } from '@/hooks/useToggleState'
import { useNavigate } from 'react-router'
import { useAppDispatch } from '@/store/hooks'
import { useGetNotificationUnreadQuery } from '@/store/notificationSlice'
import { useTranslation } from 'react-i18next'
import NotificationsList from './components/NotificationModal'

const Notifications = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const appDispatch = useAppDispatch()
  const unreadCount = 4
  const [showModal, setShowModal] = useState(false)
  const { data } = useGetNotificationUnreadQuery()

  const toggleModal = () => {
    // setUnreadCount(0)
  }

  const {
    open: openNotifications,
    close: closeNotifications,
    state: isViewNotifications,
  } = useToggleState(false)

  return (
    <li className='lg:self-center justify-self-center flex flex-col list-none'>
      <div
        className='flex gap-3 items-center py-2 -mt-[7px]'
        onClick={() => openNotifications()}
      >
        <div className='relative w-8 h-8' role='button'>
          <iconComponents.util.NotificationIcon className='w-6 h-6  absolute top-[7px]' />
          {data?.unread_count ? (
            <span className='absolute -top-0 left-[11px] border border-jaa-shades-white bg-jaa-red-500 align-middle text-jaa-shades-white rounded-full font-medium text-[12px] w-5 h-5 p-1 flex justify-center items-center'>
              {data?.unread_count > 9 ? '9+' : data?.unread_count}
            </span>
          ) : null}
        </div>
        <span className='text-jaa-shades-gray-3 font-normal md lg:sm lg:hidden inline-block capitalize '>
          {t('app.notifications')}
        </span>
      </div>
      {/* <div className='fixed top-0 right-0 w-1/4 h-1/4 bg-white z-10'> */}
      {/* Modal content goes here */}
      <NotificationsList
        open={isViewNotifications}
        onClose={closeNotifications}
      />
      {/* </div> */}
    </li>
  )
}

export default Notifications
