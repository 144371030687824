import { Job } from '@/apis/recruitementApis'
import Button from '@/common/button'
import Modal from '@/common/modal/Modal'

import ModalHeader from '@/common/modal/ModalHeader'
import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import { withAsync } from '@/helpers/withAsync'
import { BackendToUI } from '@/pages/conversion'
import { useDeleteRecruiterJobMutation } from '@/store/recruitementSlice'
import clsx from 'clsx'
import { useNavigate } from 'react-router'

type DeleteProps = {
  open: boolean
  onClose: () => void
  data: Job
}
const DeleteJob = (props: DeleteProps) => {
  const { open, onClose, data } = props
  const navigate = useNavigate()
  const [deleteJob, { isLoading: isDeleting }] = useDeleteRecruiterJobMutation()
  const deleteHandler = async () => {
    const { error, response } = await withAsync(() =>
      deleteJob(data.id).unwrap()
    )
    if (error) {
      return ToastNotify('error', {
        message: (error as any).message || 'Ooops... could not delete job',
      })
    }
    ToastNotify('info', {
      message: (response as any)?.message || 'Successfully deleted the job',
    })
    navigate(`/${BackendToUI.recruiter}/app/recruitment`)
    onClose()
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      variant='md'
      className='flex flex-col p-4 sm:p-6 gap-9 bg-white w-full'
    >
      <ModalHeader
        title='Delete Job Post'
        subText='Are you sure you want to delete this job post? Once you delete it, it cannot be undone. This action will remove the post and all associated data from our platform. Please confirm your decision below.'
        onClose={onClose}
      />
      <div className='w-full p-4'>
        <div className='flex flex-col items-start w-full gap-4 rounded-xl border border-jaa-shades-gray-5 p-4'>
          <Typography
            as='h4'
            className='text-jaa-shades-gray-4 h4 font-medium w-full'
          >
            {data?.title}
          </Typography>
          <div className='flex flex-col gap-2 items-start w-full'>
            <Typography
              as='h6'
              className='text-jaa-shades-black  font-medium h6'
            >
              DESCRIPTION
            </Typography>
            <Typography
              as='p'
              className='text-jaa-shades-gray-2.5 sm font-normal w-full h-[125px] overflow-hidden text-ellipsis'
            >
              {data?.description}
            </Typography>
          </div>
          <div className='w-full grid grid-cols-1 lg:grid-cols-12 gap-4 place-items-start'>
            <div className='col-span-1 lg:col-span-5 flex flex-col items-start gap-2'>
              <Typography
                as='h6'
                className='text-jaa-shades-black  font-medium h6'
              >
                JOB TYPE
              </Typography>
              <span className='bg-[#EFCEAF4D] py-1 px-5 gap-2.5 h-[33px] rounded text-jaa-orange-100 font-normal sm uppercase'>
                {data?.job_type}
              </span>
            </div>
            <div className='col-span-1 lg:col-span-7 flex flex-col items-start gap-2'>
              <Typography
                as='h6'
                className='text-jaa-shades-black  font-medium h6'
              >
                Applicants
              </Typography>

              {data?.candidates?.count ? (
                <div className='w-full flex  items-center h-12 justify-start'>
                  <div className='flex  items-start py-2 lg:py-0 h-full'>
                    {data.candidates.avatars
                      .slice(0, 4)
                      .map((imageUrl, idx) => (
                        <img
                          key={idx}
                          className={clsx(
                            'border-2 rounded-full border-jaa-shades-white w-8 h-8 object-cover object-center ',
                            idx > 0 && ' -ml-2'
                          )}
                          src={imageUrl || '/defaultImage.png'}
                          alt='representation of applicant'
                        />
                      ))}
                  </div>
                  <Typography
                    as='h6'
                    className='text-jaa-shades-gray-2.5 font-medium h6 lg:-mt-3 pl-4'
                  >
                    {data.candidates.count} Candidate
                    {data.candidates.count === 1 ? '' : 's'}
                  </Typography>
                </div>
              ) : (
                <Typography className='text-jaa-shades-gray-2.5 sm font-medium h6 !normal-case '>
                  No Candidates yet
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='flex justify-start items-center w-full gap-2 p-[12px_0px_0px] '>
        <Button
          label='Yes, delete'
          className='w-[192px] !bg-jaa-red-500 !uppercase'
          loading={isDeleting}
          disabled={isDeleting}
          onClick={deleteHandler}
        />
        <Button
          label='Cancel'
          variant='cancel'
          className='!uppercase w-[160px]'
          onClick={onClose}
          disabled={isDeleting}
        />
      </div>
    </Modal>
  )
}

export default DeleteJob
