import {
  ForumActivityResponse,
  ReadContentDTO,
  readDelistedContent,
} from './../apis/forumApis'
import {
  POST,
  PostDTO,
  Media,
  PostPage,
  addMediaToPost,
  createPost,
  getPost,
  getPosts,
  getPostComment,
  COMMENT,
  CommentDTO,
  createComment,
  downVoteComment,
  downVotePost,
  getPostUpvotes,
  reportContent,
  ReportDTO,
  RepostDTO,
  repostPost,
  VOTE,
  upVoteComment,
  upVotePost,
  getPostDownvotes,
  getCommentUpvotes,
  getCommentDownvotes,
  getReports,
  REPORT,
  ReportPage,
  getReport,
  deletePost,
  deleteComment,
  resolveContent,
  RestrictDTO,
  restrictUser,
  POSTS_BY_CATEGORY,
  getPostsByCategory,
  ForumActivityDTO,
  getForumActivity,
  getForumDelistedContent,
} from '@/apis/forumApis'

import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { PaginatedData } from '@/apis/api.types'

export const forumApiSlice = createApi({
  reducerPath: 'forumApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Forum', 'Comment', 'Delisted'],

  endpoints: (builder) => ({
    getFeeds: builder.query<PaginatedData<POST>, PostPage>({
      queryFn: async (payload) => {
        try {
          return await getPosts(payload)
        } catch (error) {
          throw new Error(JSON.stringify((error as any).response))
        }
      },
      providesTags: ['Forum'],
    }),
    getFeed: builder.query<POST, number>({
      queryFn: async (id) => {
        try {
          return await getPost(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Forum'],
    }),
    createFeed: builder.mutation<{ data: POST }, PostDTO>({
      queryFn: async (data) => {
        try {
          return await createPost(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Forum'],
    }),
    deleteFeed: builder.mutation<{ data: any }, number>({
      queryFn: async (data) => {
        try {
          return await deletePost(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Forum'],
    }),
    addMediaToPost: builder.mutation<{ data: Media }, FormData>({
      queryFn: async (data) => {
        try {
          return await addMediaToPost(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Forum'],
    }),
    upVotePost: builder.mutation<{ data: POST }, number>({
      queryFn: async (data) => {
        try {
          return await upVotePost(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },

      invalidatesTags: ['Forum'],
    }),
    downVotePost: builder.mutation<{ data: POST }, number>({
      queryFn: async (data) => {
        try {
          return await downVotePost(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },

      invalidatesTags: ['Forum'],
    }),
    getPostUpvotes: builder.query<
      PaginatedData<VOTE>,
      PostPage & { postId: number }
    >({
      queryFn: async (payload) => {
        try {
          return await getPostUpvotes(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Forum', 'Comment'],
    }),
    getPostDownvotes: builder.query<
      PaginatedData<VOTE>,
      PostPage & { postId: number }
    >({
      queryFn: async (payload) => {
        try {
          return await getPostDownvotes(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Forum', 'Comment'],
    }),
    getFeedComments: builder.query<
      PaginatedData<COMMENT>,
      PostPage & { postId: number }
    >({
      queryFn: async (payload) => {
        try {
          return await getPostComment(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Forum', 'Comment'],
    }),
    createComment: builder.mutation<{ data: COMMENT }, CommentDTO>({
      queryFn: async (data) => {
        try {
          return await createComment(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Comment', 'Forum'],
    }),
    deleteComment: builder.mutation<{ data: any }, number>({
      queryFn: async (data) => {
        try {
          return await deleteComment(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Comment', 'Forum'],
    }),
    upVoteComment: builder.mutation<{ data: COMMENT }, number>({
      queryFn: async (data) => {
        try {
          return await upVoteComment(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },

      invalidatesTags: ['Comment'],
    }),
    downVoteComment: builder.mutation<{ data: COMMENT }, number>({
      queryFn: async (data) => {
        try {
          return await downVoteComment(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },

      invalidatesTags: ['Comment'],
    }),
    getCommentUpvotes: builder.query<
      PaginatedData<VOTE>,
      PostPage & { commentId: number }
    >({
      queryFn: async (payload) => {
        try {
          return await getCommentUpvotes(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Forum', 'Comment'],
    }),
    getCommentDownvotes: builder.query<
      PaginatedData<VOTE>,
      PostPage & { commentId: number }
    >({
      queryFn: async (payload) => {
        try {
          return await getCommentDownvotes(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Forum', 'Comment'],
    }),
    reportContent: builder.mutation<{ data: ReportDTO }, ReportDTO>({
      queryFn: async (data) => {
        try {
          return await reportContent(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },

      invalidatesTags: ['Comment', 'Forum'],
    }),
    resolveContent: builder.mutation<{ data: REPORT }, number>({
      queryFn: async (data) => {
        try {
          return await resolveContent(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },

      invalidatesTags: ['Comment', 'Forum'],
    }),
    getContentReports: builder.query<PaginatedData<REPORT>, ReportPage>({
      queryFn: async (payload) => {
        try {
          return await getReports(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Forum'],
    }),
    getContentReport: builder.query<REPORT, number>({
      queryFn: async (payload) => {
        try {
          return await getReport(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Forum'],
    }),
    repostFeed: builder.mutation<{ data: POST }, RepostDTO>({
      queryFn: async (data) => {
        try {
          return await repostPost(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },

      invalidatesTags: ['Forum'],
    }),
    restrictUser: builder.mutation<{ data: RestrictDTO }, RestrictDTO>({
      queryFn: async (data) => {
        try {
          return await restrictUser(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },

      invalidatesTags: ['Forum'],
    }),
    getPostsByCategory: builder.query<POSTS_BY_CATEGORY, void>({
      queryFn: async (data) => {
        try {
          return await getPostsByCategory()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },

      providesTags: ['Forum'],
    }),
    getForumActivity: builder.query<
      PaginatedData<ForumActivityResponse>,
      ForumActivityDTO
    >({
      queryFn: async (data) => {
        try {
          return await getForumActivity(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },

      providesTags: ['Forum'],
    }),
    getDelistedContent: builder.query<(COMMENT | POST)[], void>({
      queryFn: async (data) => {
        try {
          return await getForumDelistedContent()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },

      providesTags: ['Delisted'],
    }),
    readDelistedContent: builder.mutation<{ data: any }, ReadContentDTO>({
      queryFn: async (data) => {
        try {
          return await readDelistedContent(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },

      invalidatesTags: ['Delisted'],
    }),
  }),
})
export const {
  useGetFeedQuery,
  useGetFeedsQuery,
  useCreateFeedMutation,
  useDeleteFeedMutation,
  useAddMediaToPostMutation,
  useUpVotePostMutation,
  useDownVotePostMutation,
  useGetPostUpvotesQuery,
  useGetPostDownvotesQuery,
  useGetFeedCommentsQuery,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useDownVoteCommentMutation,
  useUpVoteCommentMutation,
  useGetCommentDownvotesQuery,
  useGetCommentUpvotesQuery,
  useReportContentMutation,
  useGetContentReportQuery,
  useGetContentReportsQuery,
  useRepostFeedMutation,
  useResolveContentMutation,
  useRestrictUserMutation,
  useGetPostsByCategoryQuery,
  useGetDelistedContentQuery,
  useGetForumActivityQuery,
  useReadDelistedContentMutation,
  usePrefetch: usePrefetchForum,
} = forumApiSlice
export const invalidateForumApi = forumApiSlice.util.invalidateTags
export const resetForumApiSlice = () => forumApiSlice.util.resetApiState()
