import { ReactComponent as InfoIcon } from './toast/info.svg'
import { ReactComponent as SuccessIcon } from './toast/success.svg'
import { ReactComponent as WarnIcon } from './toast/warn.svg'
import { ReactComponent as ErrorIcon } from './toast/error.svg'

import { ReactComponent as CancelIcon } from './utils/cancel.svg'
import { ReactComponent as LogoIcon } from './utils/logo.svg'
import { ReactComponent as MenuIcon } from './utils/menu.svg'
import { ReactComponent as NotificationIcon } from './utils/notification.svg'
import { ReactComponent as MoreHorizIcon } from './utils/morehoriz.svg'
import { ReactComponent as DropdownIcon } from './utils/dropdown.svg'
import { ReactComponent as SearchIcon } from './utils/search.svg'
import { ReactComponent as ArrowLeftIcon } from './utils/arrowleft.svg'
import { ReactComponent as ArrowRightIcon } from './utils/arrowright.svg'
import { ReactComponent as UserIcon } from './utils/user.svg'
import { ReactComponent as UnEvenBarsIcon } from './utils/unevenbars.svg'
import { ReactComponent as CheckedIcon } from './utils/checked.svg'
import { ReactComponent as UncheckedIcon } from './utils/unchecked.svg'
import { ReactComponent as KeyIcon } from './utils/key.svg'
import { ReactComponent as UnlockIcon } from './utils/unlock.svg'
import { ReactComponent as ImportIcon } from './utils/import.svg'
import { ReactComponent as AddcircleIcon } from './utils/addcircle.svg'
import { ReactComponent as AddIcon } from './utils/add.svg'
import { ReactComponent as EyeOpenIcon } from './utils/eyeopen.svg'
import { ReactComponent as EyeCloseIcon } from './utils/eyeclose.svg'
import { ReactComponent as EmailIcon } from './utils/email.svg'
import { ReactComponent as Indeterminatecon } from './utils/indeterminate.svg'
import { ReactComponent as DeleteIcon } from './utils/delete.svg'
import { ReactComponent as EditIcon } from './utils/edit.svg'
import { ReactComponent as ArrowUpRightIcon } from './utils/arrowupright.svg'
import { ReactComponent as ArrowDownIcon } from './utils/arrowdown.svg'
import { ReactComponent as UploadIcon } from './utils/upload.svg'
import { ReactComponent as DownloadIcon } from './utils/download.svg'
import { ReactComponent as CalendarIcon } from './utils/calendar.svg'
import { ReactComponent as ChevronLeftIcon } from './utils/chevronleft.svg'
import { ReactComponent as ChevronRightIcon } from './utils/chevronright.svg'
import { ReactComponent as ChevronDownIcon } from './utils/chevrondown.svg'
import { ReactComponent as MoreVertIcon } from './utils/morevert.svg'
import { ReactComponent as CheckMarkIcon } from './utils/checkmark.svg'
import { ReactComponent as AddFileIcon } from './utils/addfile.svg'
import { ReactComponent as ExpandIcon } from './utils/expand.svg'
import { ReactComponent as InformationIcon } from './utils/info.svg'
import { ReactComponent as MessageIcon } from './utils/message.svg'
import { ReactComponent as UserAltIcon } from './utils/useralt.svg'
import { ReactComponent as LocationIcon } from './utils/location.svg'
import { ReactComponent as TwoUserIcon } from './utils/twouser.svg'
import { ReactComponent as FilterIcon } from './utils/filter.svg'
import { ReactComponent as MaximizeIcon } from './utils/maximize.svg'
import { ReactComponent as UserCancelIcon } from './utils/usercancel.svg'
import { ReactComponent as GoodIcon } from './utils/good.svg'
import { ReactComponent as GlobeIcon } from './utils/globe.svg'

import { ReactComponent as DashboardIcon } from './navigation/dashboard.svg'
import { ReactComponent as RecruitersIcon } from './navigation/recruiters.svg'
import { ReactComponent as RecruitementIcon } from './navigation/recruitement.svg'
import { ReactComponent as AlumniIcon } from './navigation/alumni.svg'
import { ReactComponent as AuditlogsIcon } from './navigation/auditlogs.svg'
import { ReactComponent as EngagementIcon } from './navigation/engagement.svg'
import { ReactComponent as ForumIcon } from './navigation/forum.svg'
import { ReactComponent as LogoutIcon } from './navigation/logout.svg'
import { ReactComponent as ReportsIcon } from './navigation/reports.svg'
import { ReactComponent as SettingsIcon } from './navigation/settings.svg'
import { ReactComponent as SkilltestIcon } from './navigation/skilltest.svg'
import { ReactComponent as ChatIcon } from './navigation/messages.svg'

import { ReactComponent as EmptyProfileIcon } from './profile/empty.svg'
import { ReactComponent as KeyOpenIcon } from './profile/keyopen.svg'
import { ReactComponent as FacebookIcon } from './profile/facebook.svg'
import { ReactComponent as FigmaIcon } from './profile/figma.svg'
import { ReactComponent as FigmaColorIcon } from './profile/figmacolor.svg'
import { ReactComponent as TwitterIcon } from './profile/twitter.svg'
import { ReactComponent as LinkedinIcon } from './profile/linkedin.svg'
import { ReactComponent as InstagramIcon } from './profile/instagram.svg'
import { ReactComponent as PlayVideoIcon } from './profile/playvideo.svg'
import { ReactComponent as CheckboxIcon } from './profile/checkbox.svg'
import { ReactComponent as UncheckboxIcon } from './profile/uncheckbox.svg'
import { ReactComponent as RadioUncheckboxIcon } from './profile/radiounchecked.svg'
import { ReactComponent as RadioCheckboxIcon } from './profile/radiochecked.svg'
import { ReactComponent as EmptyFileIcon } from './profile/emptyfile.svg'
import { ReactComponent as SuccessMarkIcon } from './profile/successmark.svg'
import { ReactComponent as MakeDefaultIcon } from './profile/makedefault.svg'
import { ReactComponent as ProfileEditIcon } from './profile/profileedit.svg'
import { ReactComponent as DocumentIcon } from './profile/document.svg'
import { ReactComponent as BriefCaseIcon } from './profile/briefcase.svg'
import { ReactComponent as MoneyStackIcon } from './profile/moneystack.svg'
import { ReactComponent as ChangePicIcon } from './profile/changepic.svg'
import { ReactComponent as ExperienceIcon } from './profile/experience.svg'
import { ReactComponent as EducationIcon } from './profile/education.svg'
import { ReactComponent as FilledStarIcon } from './profile/filledstar.svg'
import { ReactComponent as OutlinedStarIcon } from './profile/outlinedstar.svg'

import { ReactComponent as JobsIcon } from './recruitment/jobs.svg'
import { ReactComponent as PipelineIcon } from './recruitment/pipeline.svg'
import { ReactComponent as EmptyPageIcon } from './recruitment/empty.svg'
import { ReactComponent as PreferenceIcon } from './recruitment/preference.svg'
import { ReactComponent as SkillsIcon } from './recruitment/skills.svg'
import { ReactComponent as PortfolioIcon } from './recruitment/portfolio.svg'
import { ReactComponent as ExperienceAlumniIcon } from './recruitment/experience.svg'
import { ReactComponent as EducationAlumniIcon } from './recruitment/education.svg'
import { ReactComponent as PlayIcon } from './recruitment/play.svg'
import { ReactComponent as PauseIcon } from './recruitment/pause.svg'
import { ReactComponent as MuteIcon } from './recruitment/mute.svg'
import { ReactComponent as ExpandVideoIcon } from './recruitment/expand.svg'
import { ReactComponent as CompressIcon } from './recruitment/compress.svg'
import { ReactComponent as BuildingIcon } from './recruitment/building.svg'
import { ReactComponent as ArchiveIcon } from './recruitment/archive.svg'
import { ReactComponent as EnvelopeIcon } from './recruitment/envelope.svg'
import { ReactComponent as CheckIcon } from './recruitment/check.svg'
import { ReactComponent as UncheckIcon } from './recruitment/uncheck.svg'
import { ReactComponent as MessagesIcon } from './recruitment/messages.svg'
import { ReactComponent as EmptyFilterIcon } from './recruitment/emptysearch.svg'
import { ReactComponent as RatingIcon } from './recruitment/star.svg'

import { ReactComponent as PostIcon } from './reports/post.svg'
import { ReactComponent as CommentIcon } from './reports/comment.svg'
import { ReactComponent as LikeIcon } from './reports/likes.svg'
import { ReactComponent as ReportedIcon } from './reports/reported.svg'

import { ReactComponent as NoChatIcon } from './engagement/nochat.svg'
import { ReactComponent as UncheckedRadioAudit } from './audit/unchecked.svg'
import { ReactComponent as CheckedRadioAudit } from './audit/checked.svg'

import { ReactComponent as CompletedIcon } from './skill_test/completed.svg'
import { ReactComponent as Top5Icon } from './skill_test/top5.svg'
import { ReactComponent as Top10Icon } from './skill_test/top10.svg'
import { ReactComponent as Top20Icon } from './skill_test/top20.svg'
import { ReactComponent as Top30Icon } from './skill_test/top30.svg'
import { ReactComponent as TestFilterIcon } from './skill_test/filter.svg'
import { ReactComponent as CloudUploadIcon } from './skill_test/cloudupload.svg'
import { ReactComponent as DefaultUploadIcon } from './skill_test/defaultupload.svg'
import { ReactComponent as WarningIcon } from './skill_test/warning.svg'
import { ReactComponent as TestRadiocheckedIcon } from './skill_test/radiochecked.svg'

import { ReactComponent as DownVoteIcon } from './forum/downvote.svg'
import { ReactComponent as UpVoteIcon } from './forum/upvote.svg'
import { ReactComponent as ForumCommentIcon } from './forum/comment.svg'
import { ReactComponent as ShareIcon } from './forum/share.svg'
import { ReactComponent as TipIcon } from './forum/tip.svg'
import { ReactComponent as BoldIcon } from './forum/bold.svg'
import { ReactComponent as ItalizeIcon } from './forum/italize.svg'
import { ReactComponent as StrikeIcon } from './forum/strike.svg'
import { ReactComponent as ImageIcon } from './forum/image.svg'
import { ReactComponent as VideoIcon } from './forum/video.svg'
import { ReactComponent as OrderedlistIcon } from './forum/orderedlist.svg'
import { ReactComponent as UnorderedlistIcon } from './forum/unorderedlist.svg'
import { ReactComponent as SendIcon } from './forum/send.svg'
import { ReactComponent as CommentAltIcon } from './forum/comment-alt.svg'
import { ReactComponent as CheckedRadioIcon } from './forum/checked-radio.svg'
import { ReactComponent as UnCheckedRadioIcon } from './forum/unchecked-radio.svg'
import { ReactComponent as SuspendIcon } from './forum/warn.svg'
import { ReactComponent as BadgeBase1Icon } from './forum/bargebase1.svg'
import { ReactComponent as BadgeBase2Icon } from './forum/bargebase2.svg'

import { ReactComponent as EnglishIcon } from './language/en.svg'
import { ReactComponent as FrenchIcon } from './language/fr.svg'

import { ReactComponent as CaretIcon } from './landingpage/caret.svg'
import { ReactComponent as JaaLogo } from './landingpage/jaa-logo.png.svg'
import { ReactComponent as JaaHeroImage } from './landingpage/jaa-hero.svg'
import { ReactComponent as FacBookIcon } from './landingpage/fbook.svg'
import { ReactComponent as LandingTwitterIcon } from './landingpage/twitter.svg'
import { ReactComponent as LandingLinkedinIcon } from './landingpage/linkdn.svg'
import { ReactComponent as YoutubeIcon } from './landingpage/ytube.svg'
import { ReactComponent as InstaIcon } from './landingpage/insta.svg'
import { ReactComponent as YellowTickIcon } from './landingpage/arrow.svg'
import { ReactComponent as ProfileIcon } from './landingpage/profile.svg'
import { ReactComponent as CaseIcon } from './landingpage/briefcase.svg'
import { ReactComponent as MicrosoftIcon } from './landingpage/microsoft.svg'
import { ReactComponent as ExonMobil } from './landingpage/exon.svg'
import { ReactComponent as CiscoIcon } from './landingpage/cisco.svg'
import { ReactComponent as SalesForceIcon } from './landingpage/force.svg'
import { ReactComponent as GoogleIcon } from './landingpage/google.svg'
import { ReactComponent as UnileverIcon } from './landingpage/unilever.svg'
import { ReactComponent as FirstbankIcon } from './landingpage/firstbank.svg'
import { ReactComponent as CatIcon } from './landingpage/cat.svg'

import { ReactComponent as EllipseIcon } from './404/ellipse.svg'

const iconComponents = {
  toast: {
    InfoIcon,
    SuccessIcon,
    WarnIcon,
    ErrorIcon,
  },
  util: {
    CancelIcon,
    AddFileIcon,
    LogoIcon,
    MenuIcon,
    NotificationIcon,
    MoreHorizIcon,
    MoreVertIcon,
    DropdownIcon,
    SearchIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    UserIcon,
    TwoUserIcon,
    UnEvenBarsIcon,
    CheckedIcon,
    UncheckedIcon,
    Indeterminatecon,
    KeyIcon,
    UnlockIcon,
    ImportIcon,
    AddcircleIcon,
    AddIcon,
    EyeCloseIcon,
    EyeOpenIcon,
    EmailIcon,
    DeleteIcon,
    EditIcon,
    ArrowUpRightIcon,
    UploadIcon,
    DownloadIcon,
    CalendarIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    CheckMarkIcon,
    ExpandIcon,
    MaximizeIcon,
    InfoIcon: InformationIcon,
    MessageIcon,
    UserAltIcon,
    LocationIcon,
    FilterIcon,
    UserCancelIcon,
    GoodIcon,
    GlobeIcon,
    ArrowDownIcon,
  },
  navigation: {
    DashboardIcon,
    AlumniIcon,
    AuditlogsIcon,
    EngagementIcon,
    ForumIcon,
    LogoutIcon,
    RecruitementIcon,
    RecruitersIcon,
    ReportsIcon,
    SettingsIcon,
    SkilltestIcon,
    ChatIcon,
  },
  profile: {
    EmptyProfileIcon,
    KeyOpenIcon,
    LinkedinIcon,
    FacebookIcon,
    FigmaIcon,
    FigmaColorIcon,
    TwitterIcon,
    InstagramIcon,
    PlayVideoIcon,
    CheckboxIcon,
    UncheckboxIcon,
    RadioCheckboxIcon,
    RadioUncheckboxIcon,
    EmptyFileIcon,
    SuccessMarkIcon,
    MakeDefaultIcon,
    ProfileEditIcon,
    BriefCaseIcon,
    MoneyStackIcon,
    DocumentIcon,
    ChangePicIcon,
    ExperienceIcon,
    EducationIcon,
    FilledStarIcon,
    OutlinedStarIcon,
  },
  recruitment: {
    JobsIcon,
    PipelineIcon,
    EmptyPageIcon,
    SkillsIcon,
    EducationIcon: EducationAlumniIcon,
    ExperienceIcon: ExperienceAlumniIcon,
    PreferenceIcon,
    PortfolioIcon,
    PlayIcon,
    PauseIcon,
    ExpandIcon: ExpandVideoIcon,
    CompressIcon,
    MuteIcon,
    BuildingIcon,
    ArchiveIcon,
    EnvelopeIcon,
    CheckIcon,
    UncheckIcon,
    MessagesIcon,
    EmptyFilterIcon,
    RatingIcon,
  },

  reports: {
    PostIcon,
    CommentIcon,
    LikeIcon,
    ReportedIcon,
  },

  engagement: {
    NoChatIcon,
  },

  test: {
    CompletedIcon,
    Top5Icon,
    Top10Icon,
    Top20Icon,
    Top30Icon,
    TestFilterIcon,
    CloudUploadIcon,
    DefaultUploadIcon,
    WarningIcon,
    TestRadiocheckedIcon,
  },
  audit: {
    UncheckedRadioAudit,
    CheckedRadioAudit,
  },
  forum: {
    UpVoteIcon,
    DownVoteIcon,
    CommentIcon: ForumCommentIcon,
    ShareIcon,
    TipIcon,
    BoldIcon,
    StrikeIcon,
    UnorderedlistIcon,
    OrderedlistIcon,
    ImageIcon,
    VideoIcon,
    ItalizeIcon,
    SendIcon,
    CommentAltIcon,
    CheckedRadioIcon,
    UnCheckedRadioIcon,
    SuspendIcon,
    BadgeBase1Icon,
    BadgeBase2Icon,
  },

  language: {
    EnglishIcon,
    FrenchIcon,
  },
  landing: {
    CaretIcon,
    JaaLogo,
    JaaHeroImage,
    FacBookIcon,
    TwitterIcon: LandingTwitterIcon,
    LinkedinIcon: LandingLinkedinIcon,
    YoutubeIcon,
    InstaIcon,
    YellowTickIcon,
    ProfileIcon,
    CaseIcon,
    MicrosoftIcon,
    ExonMobil,
    CiscoIcon,
    SalesForceIcon,
    GoogleIcon,
    UnileverIcon,
    FirstbankIcon,
    CatIcon,
  },

  notFound: {
    EllipseIcon,
  },
}
export default iconComponents
