import { useTranslation } from 'react-i18next'
import { FaqCard } from './faqCard'

export const Faqs = () => {
  const { t } = useTranslation()
  const Items = [
    {
      id: 1,
      title: `${t('app.landing.have_to_pay')}`,
      text: `${t('app.landing.have_to_pay_text')}`,
    },

    {
      id: 2,
      title: `${t('app.landing.skills_available')}`,
      text: `${t('app.landing.skills_available_text')}`,
    },
    {
      id: 3,
      title: `${t('app.landing.troubleshoot')}`,
      linktext: (
        <p className='text-base font-normal'>
          {t('app.landing.troubleshoot_text')}
          {
            <span style={{ color: '#00A0AE' }} className='ml-1'>
              jacareerconnect@ja-africa.org
            </span>
          }
        </p>
      ),
    },
    {
      id: 4,
      title: `${t('app.landing.candidates')}`,
      text: `${t('app.landing.candidates_text')}`,
    },
  ]
  return (
    <div
      id='faq'
      className='p-7 md:p-24 flex flex-col justify-center items-center'
    >
      <div className='w-full max-w-screen-2xl space-y-12 '>
        <header className='flex items-start justify-start flex-col w-full space-y-4'>
          <h2 className='font-bold text-jaa-black text-[30px] md:text-4xl'>
            {t('app.landing.faq')}
          </h2>
        </header>
        <section className='grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2'>
          {Items.map((item) => (
            <FaqCard
              key={item.id}
              title={item.title}
              text={item.text}
              linktext={item.linktext}
            />
          ))}
        </section>
      </div>
    </div>
  )
}
