import Typography from '@/common/Typography'
import { BackendToUI } from '@/pages/conversion'
import { useAppSelector } from '@/store/hooks'
import { usePrefetchProfile } from '@/store/profileSlice'
import clsx from 'clsx'
import { forwardRef } from 'react'
import { useNavigate } from 'react-router'

const UserInfo = forwardRef<HTMLDivElement, { onClose?: () => void }>(
  (props, ref) => {
    const { onClose } = props
    const navigate = useNavigate()

    const userData: any = useAppSelector((state) => state.auth)
    const prefetchProfile = usePrefetchProfile(
      (BackendToUI[userData.user_type as 'admin'] + 'Profile') as any
    )
    const prefetchAlumniPreference = usePrefetchProfile('alumniPreference')
    const prefetchAlumniExperience = usePrefetchProfile('alumniWorkExperience')
    const prefetchAlumniEducation = usePrefetchProfile('alumniEducation')
    const prefetchAlumniPortfolio = usePrefetchProfile('alumniPortfolio')
    const prefetchHandler = () => {
      BackendToUI[userData.user_type as 'admin'] === 'alumni'
        ? (() => {
            prefetchProfile(userData.id)
            prefetchAlumniEducation(userData.id)
            prefetchAlumniPortfolio(userData.id)
            prefetchAlumniExperience(userData.id)
            prefetchAlumniPreference(userData.id)
          })()
        : prefetchProfile(userData.id)
    }
    return (
      <div
        ref={ref}
        onMouseEnter={prefetchHandler}
        onTouchStart={prefetchHandler}
        onClick={() => {
          navigate(
            `/${
              BackendToUI[
                (userData as any).primary_admin
                  ? ((userData as any).primary_admin
                      .user_type as unknown as 'admin')
                  : (userData.user_type as 'admin')
              ]
            }/app/profile`
          )
          onClose?.()
        }}
        className='w-full flex border-t border-jaa-shades-gray-1 pt-6 gap-2.5 h-[62px] cursor-pointer items-center sm:pt-0 sm:border-none sm:w-auto sm:min-w-[130px] sm:justify-end lg:gap-2.5 lg:border-t lg:h-[62px] lg:p-6 lg:pb-0 lg:w-full lg:border-jaa-shades-gray-1 lg:justify-start'
      >
        <div
          className={clsx(
            'w-8 h-8 rounded-full border flex justify-center items-center relative',
            userData.profile_pic ? 'border-none' : ''
          )}
        >
          <img
            src={userData.profile_pic || '/defaultImage.png'}
            alt='Profile of User'
            className='w-8 h-8 object-cover rounded-full'
            onError={(e) => {
              e.currentTarget.src = '/defaultImage.png'
            }}
          />
          <span className='absolute w-2 h-2 right-0 bottom-0 bg-[#12B76a] rounded-full border border-jaa-shades-white'></span>
        </div>
        <div className='flex flex-col justify-center items-start'>
          <Typography
            as='strong'
            className='sm font-bold text-jaa-shades-gray-4 capitalize whitespace-nowrap'
          >
            {(userData as any).primary_admin
              ? `${(userData as any).primary_admin.first_name}  ${
                  (userData as any).primary_admin.last_name
                }`
              : userData.first_name + ' ' + userData.last_name}
          </Typography>
          <Typography
            as='span'
            className='xs font-normal text-jaa-shades-gray-3 capitalize'
          >
            {userData.role ||
              ((userData as any).primary_admin
                ? (userData as any)?.primary_admin?.company_role
                : userData?.company_role ||
                  userData.recruiter_role ||
                  userData.user_type)}
          </Typography>
        </div>
      </div>
    )
  }
)

UserInfo.displayName = 'UserInfo'

export default UserInfo
