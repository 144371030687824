import { IRecruiterProfile } from '@/apis/profileApis'

import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { PaginatedData } from '@/apis/api.types'

import {
  RecruitersList,
  RecruitersListDTO,
  getBlockedRecruiterList,
  getRecruiter,
  getRecruiterList,
} from '@/apis/recruitersApis'
import { BlockUserDTO, blockUser } from '@/apis/authApis'

export const recruitersListSlice = createApi({
  reducerPath: 'recruitersListApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Recruiters', 'BlockedRecruiters'],

  endpoints: (builder) => ({
    getRecruitersList: builder.query<
      PaginatedData<IRecruiterProfile>,
      RecruitersListDTO
    >({
      queryFn: async (data) => {
        try {
          return await getRecruiterList(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Recruiters'],
    }),
    getRecruiter: builder.query<IRecruiterProfile, number>({
      queryFn: async (id) => {
        try {
          return await getRecruiter(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Recruiters'],
    }),
    blockUser: builder.mutation<{ data: BlockUserDTO }, BlockUserDTO>({
      queryFn: async (data) => {
        try {
          return await blockUser(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Recruiters'],
    }),
    getBlockedRecruiterList: builder.query<
      PaginatedData<RecruitersList>,
      RecruitersListDTO
    >({
      queryFn: async (data) => {
        try {
          return await getBlockedRecruiterList(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['BlockedRecruiters'],
    }),
  }),
})
export const {
  useGetRecruitersListQuery,
  useGetBlockedRecruiterListQuery,
  useGetRecruiterQuery,
  useBlockUserMutation,
} = recruitersListSlice

export const resetrecruitersListSlice = () =>
  recruitersListSlice.util.resetApiState()
