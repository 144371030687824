import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import { withAsync } from '@/helpers/withAsync'
import {
  useAcceptInvitationMutation,
  useRecoverPasswordMutation,
} from '@/store/authSlice'
import { ChangeEvent, FormEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
  format,
  passwordFormat,
} from '../../validationschemas/primaryrecruiter.validator'

const AdminCreatePassword = () => {
  const { code } = useParams()
  const [error, setError] = useState('')
  const [passwords, setPasswords] = useState({
    new_password: '',
    verify_password: '',
  })

  const navigate = useNavigate()
  const [createPassword, { isLoading: isSending }] =
    useAcceptInvitationMutation()
  const submitHandler = async (e: FormEvent) => {
    e.preventDefault()
    if (
      passwords.new_password !== passwords.verify_password ||
      !passwords.new_password.trim()
    ) {
      return ToastNotify('warn', {
        message: 'Password does not match',
      })
    }
    const { error } = await withAsync(() =>
      createPassword({
        code: code as string,
        password: passwords.new_password,
      })
    )

    if (error) {
      return ToastNotify('error', {
        message: (error as any)?.message || 'Ooops try again.',
      })
    }

    ToastNotify('success', {
      message: 'Password has been created Successfully',
    })
    navigate('/admin/auth/sign-in')
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e
    setPasswords((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <div className='px-4 w-full flex flex-col gap-6 sm:gap-10 justify-start items-center sm:px-[19%] mb-14'>
      <div className='w-[72px] h-[72px] bg-jaa-teal-20 rounded-full sm:w-[96px] sm:h-[96px] flex justify-center items-center'>
        <iconComponents.util.UnlockIcon
          stroke='var(--teal-100)'
          className='w-[28px] h-[31px] sm:w-8 sm:h-h-9'
        />
      </div>
      <header className='flex flex-col gap-1 w-full self-start'>
        <Typography as='h2' className='h3 text-jaa-shades-black  sm:h2'>
          Create New Password
        </Typography>
        <Typography as='p' className='text-jaa-shades-gray-2.5 font-normal sm'>
          Choose a new password here, then log in to your account.
        </Typography>
      </header>
      <form
        onSubmit={submitHandler}
        className='flex flex-col w-full justify-center items-center gap-4'
      >
        <FormGroup id='new_password' label='New Password'>
          <InputWithAdornment
            name='new_password'
            type='password'
            value={passwords.new_password}
            onChange={handleChange}
            onBlur={() => {
              if (!passwords.new_password.match(passwordFormat)) {
                return setError(
                  'Password must have 8 or more characters, at least one uppercase letter, and one number.'
                )
              }
              if (!format.test(passwords.new_password)) {
                return setError(
                  'Password cannot contain special characters other than _ @ . -'
                )
              }
              setError('')
            }}
            error={error && error}
          />
        </FormGroup>
        <FormGroup id='verify_password' label='Verify Password'>
          <InputWithAdornment
            name='verify_password'
            type='password'
            value={passwords.verify_password}
            onChange={handleChange}
          />
        </FormGroup>
        <Button
          disabled={isSending}
          loading={isSending}
          type='submit'
          label='Create Password'
          className='w-full lg:w-1/2 !uppercase mt-8'
        />
      </form>
    </div>
  )
}

export default AdminCreatePassword
