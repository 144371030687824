import { POST } from '@/apis/forumApis'
import Typography from '@/common/Typography'
import { getUserType } from '@/store/authSlice'
import { useAppSelector } from '@/store/hooks'
import { format, formatDistanceToNow } from 'date-fns'
import { FC } from 'react'
import { isNow } from '../helpers'

type FeedCardProps = {
  post: POST
}

const FeedHeader: FC<FeedCardProps> = ({ post }) => {
  const fullName = `${post.first_name} ${post.last_name}`
  const userType = useAppSelector(getUserType)
  const type = post.category
  return (
    <header className='flex items-center gap-3'>
      <div className='w-11 h-11'>
        <img
          src={post.profile_pic || '/defaultImage.png'}
          alt={post.first_name}
          onError={(e) => {
            e.currentTarget.src = '/defaultImage.png'
            const target: any = e.target
            target.src = '/defaultImage.png'
            e.target = target
          }}
          className='w-full h-full max-h-full max-w-full object-cover object-center rounded-full'
        />
      </div>
      <hgroup className='flex flex-col items-start gap-0.5 flex-1'>
        <Typography
          as='h5'
          className='flex items-center gap-1 whitespace-nowrap'
        >
          <span className='capitalize font-semibold md text-jaa-black '>
            {fullName}
          </span>
          {isNow(post) && (
            <span className='flex items-center gap-1'>
              <span className='w-1 h-1 rounded-full bg-jaa-shades-gray-2.5'></span>
              <span className='text-jaa-shades-gray-2.5 font-normal sm'>
                just now
              </span>
            </span>
          )}
        </Typography>
        {(type === 'general' || userType === 'admin') && (
          <Typography as='p' className='h6 text-jaa-shades-gray-2.5 capitalize'>
            {post.cohort ? `Cohort ${post.cohort}` : post.program}
          </Typography>
        )}
        {!isNow(post) && (
          <footer className='sm:hidden flex items-center py-3 gap-1'>
            <span className='w-1 h-1 rounded-full bg-jaa-shades-gray-2.5'></span>
            <time
              dateTime={post.created_at}
              className='font-normal sm text-jaa-shades-gray-2.5'
            >
              {Date.parse(post.created_at)
                ? format(new Date(post.created_at), 'h:mm aa, MMM dd, yyyy')
                : ''}
            </time>
          </footer>
        )}
      </hgroup>
    </header>
  )
}

export default FeedHeader
