import { UserTypes } from '@/apis/authApis'
import imagesUrl from '@/assets/images/imagesUrl'
import { Storage } from '@/helpers/Storage'
import useMediaQuery from '@/hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Banner from './components/Banner'
import Header from './components/Header'
import useAutoSlider from '@/hooks/useAutoSlider'

type AuthLayoutProps = {
  userType: UserTypes
}
type AuthPagesUrl =
  | 'sign-in'
  | 'sign-up'
  | 'forgot-password'
  | 'reset-password'
  | 'primary'
  | 'member'
  | 'create-password'
  | 'accept-invite'

const RecruterSigninImages = [
  imagesUrl.Recruiter1,
  imagesUrl.Recruiter2,
  imagesUrl.Recruiter3,
  imagesUrl.Recruiter4,
]
const AlumniImages = [
  imagesUrl.Alumni1,
  imagesUrl.Alumni2,
  imagesUrl.Alumni3,
  imagesUrl.Alumni4,
]
const AuthLayout = (props: AuthLayoutProps) => {
  const isMobile = useMediaQuery('(max-width: 640px)')
  const isTablet = useMediaQuery('(max-width: 1023px)')

  const { pathname } = useLocation()
  const currentUrl: AuthPagesUrl = pathname
    .split('/')
    .slice(-1)[0] as AuthPagesUrl
  const userType: UserTypes = props.userType.toLowerCase?.() as UserTypes
  const [currentPage, setCurrentPage] = useState<AuthPagesUrl>(currentUrl)

  const bgImages: Record<UserTypes, Record<string, string>> = {
    admin: {
      'forgot-password': imagesUrl.Admin,
      'reset-password': imagesUrl.Admin,
      'sign-in': imagesUrl.AdminSignin,
      'create-password': imagesUrl.Admin,
    },
    alumni: {
      'reset-password': imagesUrl.Alumni1,
      'sign-in': imagesUrl.Alumni,
      'accept-invite': imagesUrl.AlumniCreateImage,
      'create-password': imagesUrl.Alumni1,
    },
    recruiter: {
      'reset-password': imagesUrl.Recruiter1,
      'sign-in': imagesUrl.Recruiter1,
      primary: imagesUrl.RecruiterCreate,
      member: imagesUrl.Recruiter1,
    },
  }
  useEffect(() => {
    if (!pathname) return
    setCurrentPage(currentUrl as AuthPagesUrl)
  }, [pathname, currentUrl])
  useEffect(() => {
    Storage.setItem('USER_TYPE', userType)
  }, [userType])
  const { step: currentBg } = useAutoSlider(4)

  if (!Object.keys(bgImages[userType]).includes(currentPage))
    return <Navigate to='/not-found' />

  return (
    <div
      style={{
        gridTemplateRows: isMobile
          ? '2rem 1fr'
          : isTablet
          ? '8rem 1fr'
          : '6rem 1fr',
        gridTemplateColumns: isMobile
          ? 'repeat(1, minmax(0, 1fr))'
          : isTablet
          ? 'repeat(1, minmax(0, 1fr))'
          : 'repeat(2, minmax(0, 1fr))',
      }}
      className={'grid min-h-screen min-w-screen w-full overflow-hidden'}
    >
      <div
        style={{
          gridColumn: 'span 1 / span 1',
          gridColumnStart: '1',
          gridRow: 'span 1 / span 1',
          gridRowStart: '1',
        }}
        className='fixed top-0 left-0 w-full lg:w-1/2 z-20 '
      >
        <Header />
      </div>
      <main
        style={{
          gridColumn: isMobile
            ? 'span 1 / span 1'
            : isTablet
            ? 'repeat(1, minmax(0, 1fr))'
            : 'span 1 / span 1',
          gridColumnStart: isMobile
            ? '1'
            : isTablet
            ? 'repeat(1, minmax(0, 1fr))'
            : '1',
          gridRow: isMobile
            ? 'span 1 / span 1'
            : isTablet
            ? 'repeat(1, minmax(0, 1fr))'
            : 'span 1 / span 1',
          gridRowStart: isMobile
            ? '2'
            : isTablet
            ? 'repeat(1, minmax(0, 1fr))'
            : '2',
        }}
        className='mt-12'
      >
        <Outlet />
      </main>
      <div
        className='hidden lg:block lg:fixed lg:top-0 lg:right-0 lg:max-w-[50%]'
        style={{
          gridColumn: isMobile
            ? 'repeat(1, minmax(0, 1fr))'
            : isTablet
            ? 'repeat(1, minmax(0, 1fr))'
            : 'span 1 / span 1',
          gridColumnStart: isMobile
            ? 'repeat(1, minmax(0, 1fr))'
            : isTablet
            ? 'repeat(1, minmax(0, 1fr))'
            : '2',
          gridRow: isMobile
            ? 'repeat(1, minmax(0, 1fr))'
            : isTablet
            ? 'repeat(1, minmax(0, 1fr))'
            : 'span 2 / span 2',
          gridRowStart: isMobile
            ? 'repeat(1, minmax(0, 1fr))'
            : isTablet
            ? 'repeat(1, minmax(0, 1fr))'
            : '1',
          width: isMobile ? '100%' : isTablet ? '100%' : '50%',
        }}
      >
        <Banner
          image={
            userType === 'recruiter'
              ? RecruterSigninImages[currentBg]
              : userType === 'alumni' &&
                ['sign-in', 'accept-invite'].includes(currentPage)
              ? AlumniImages[currentBg]
              : bgImages[userType][currentPage]
          }
        />
      </div>
    </div>
  )
}
export default AuthLayout
