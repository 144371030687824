import { POST } from '@/apis/forumApis'
import Typography from '@/common/Typography'
import { format } from 'date-fns'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { isNow } from '../helpers'
import FeedGallery from './FeedGallery'

type FeedCardProps = {
  post: POST
}
const FeedContent: FC<
  FeedCardProps & { onGalleryClick: (index: number) => void; url?: string }
> = ({ post, onGalleryClick, url }) => {
  const navigate = useNavigate()
  const type = post.category
  const hasSharedPost = !!post?.original_post && type === 'cohort'
  return (
    <main className='w-full'>
      {hasSharedPost ? (
        <>
          <Typography
            onClick={() =>
              navigate(url || `/admin/app/forum/${post.id}?type=${type}`)
            }
            as='p'
            className='p-[0px_0px_12px] sm md:md font-normal text-[#040404]'
          >
            {post.body}
          </Typography>
          <SharedFeed
            post={post.original_post!}
            onGalleryClick={onGalleryClick}
          />
        </>
      ) : (
        <>
          <Typography
            as='h2'
            onClick={() =>
              navigate(url || `/admin/app/forum/${post.id}?type=${type}`)
            }
            className='md font-medium text-jaa-shades-black w-full p-[8px_0px_4px]'
          >
            {post.title}
          </Typography>
          <div className='flex flex-col-reverse md:flex-col'>
            <FeedGallery media={post.media} onGalleryClick={onGalleryClick} />
            <p
              dangerouslySetInnerHTML={{ __html: post.body }}
              className='sm md:md font-normal text-jaa-shades-black'
            ></p>
          </div>
          {!isNow(post) && (
            <footer className='hidden sm:flex items-center py-3 gap-1'>
              <span className='w-1 h-1 rounded-full bg-jaa-shades-gray-2.5'></span>
              <time
                dateTime={post.created_at}
                className='font-normal sm text-jaa-shades-gray-2.5'
              >
                {Date.parse(post.created_at)
                  ? format(new Date(post.created_at), 'h:mm aa, MMM dd, yyyy')
                  : ''}
              </time>
            </footer>
          )}
        </>
      )}
    </main>
  )
}

const SharedFeed: FC<
  Pick<FeedCardProps, 'post'> & { onGalleryClick: (index: number) => void }
> = ({ post, onGalleryClick }) => {
  const fullName = `${post.first_name} ${post.last_name}`
  return (
    <section className='w-full bg-jaa-shades-white mt-4 isolate flex flex-col items-start rounded-lg border border-[#D9D9D9] md:border-[#F9FAFB] md:gap-1 px-4 py-2 md:px-6'>
      <header className='flex flex-col items-start gap-2 md:p-[8px_0px_4px] md:gap-2.5 w-full'>
        <hgroup className='flex flex-col items-start gap-0.5'>
          <Typography
            as='h5'
            className='flex items-center gap-1 whitespace-nowrap'
          >
            <span className='capitalize font-semibold md text-jaa-black '>
              {fullName}
            </span>
          </Typography>
          <Typography as='p' className='h6 text-jaa-shades-gray-2.5'>
            {post.cohort ? `Cohort ${post.cohort}` : post.program}
          </Typography>
        </hgroup>
        <Typography as='h2' className='md font-semibold text-[#040404] w-full'>
          {post.title}
        </Typography>
      </header>
      <main className='w-full flex flex-col gap-1'>
        <FeedGallery media={post.media} onGalleryClick={onGalleryClick} />
        <p
          dangerouslySetInnerHTML={{ __html: post.body }}
          className='sm md:md font-normal text-jaa-shades-black'
        ></p>
      </main>
      <footer className='flex items-center py-3 gap-1'>
        <span className='w-1 h-1 rounded-full bg-jaa-shades-gray-2.5'></span>
        <time
          dateTime={post.created_at}
          className='font-normal sm text-jaa-shades-gray-2.5'
        >
          {Date.parse(post.created_at)
            ? format(new Date(post.created_at), 'h:mm aa, MMM dd, yyyy')
            : ''}
        </time>
      </footer>
    </section>
  )
}

export default FeedContent
