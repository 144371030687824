import { useAppDispatch, useAppSelector } from './../../store/hooks'
import api from '../api'
import { Tokens } from '../authApis'
import { REFRESH } from '../endpoints'
import { useCallback } from 'react'
import { setCredentials } from '@/store/authSlice'

const useRefreshToken = () => {
  const authState = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()

  const refresh = useCallback(async () => {
    const { data } = await api.post<{ data: Tokens }>(
      REFRESH,
      {},
      {
        withCredentials: true,
      }
    )
    dispatch(setCredentials({ ...authState, access: data.data.access }))

    return data.data.access
    // dispatch(setCredentials({ ...authState, access: accessToken }))
    // return accessToken
  }, [authState, dispatch])
  return refresh
}

export default useRefreshToken
