import { Storage } from '@/helpers/Storage'
import { getAccessTokens } from '@/store/authSlice'
import { useAppSelector } from '@/store/hooks'
import { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router'

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.classList.add('scroller')
    document.body.classList.add('scrollbar')
  }, [pathname])

  return null
}

const PublicRoutes = () => {
  const { pathname } = useLocation()
  const accessToken = useAppSelector(getAccessTokens)
  const userType = Storage.getItem('USER_TYPE') || 'alumni'
  const isSuccessPage =
    pathname.split('/').includes('success') ||
    pathname.split('/').includes('auth')
  return isSuccessPage || !accessToken ? (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  ) : (
    <Navigate to={`/${userType}/app/dashboard`} />
  )
}

export default PublicRoutes
