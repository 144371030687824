import { useStepper } from '@/hooks/useStepper'
import { useToggleState } from '@/hooks/useToggleState'
import { useCallback, useEffect, useRef } from 'react'

const useAutoSlider = (
  totalSteps: number,
  duration = 5000,
  initialStep = 0
) => {
  const { step, setStep } = useStepper(initialStep)
  const {
    state: isPlaying,
    open: playAnimation,
    close: pauseAnimation,
  } = useToggleState(true)
  const timerRef = useRef<ReturnType<typeof setInterval>>()

  const pauseHandler = useCallback(() => {
    pauseAnimation()
    clearInterval(timerRef.current)
  }, [pauseAnimation])
  const playHandler = useCallback(() => {
    playAnimation()
    timerRef.current = setInterval(() => {
      setStep((prev) => (prev + 1) % totalSteps)
    }, duration)
  }, [setStep, playAnimation, duration, totalSteps])
  useEffect(() => {
    playHandler()
    return () => {
      pauseHandler()
    }
  }, [pauseHandler, playHandler])
  return {
    isPlaying,
    pause: pauseHandler,
    play: playHandler,
    step,
    setStep,
  } as const
}

export default useAutoSlider
