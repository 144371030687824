import { RecruiterSignupDTO } from '@/apis/authApis'
import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import SelectInput from '@/common/Input/SelectInput'
import Modal from '@/common/modal/Modal'
import ModalHeader from '@/common/modal/ModalHeader'
import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import composeEventHandlers from '@/helpers/composeEventHandlers'
import { isValidEmail } from '@/helpers/strings'
import { withAsync } from '@/helpers/withAsync'
import { useToggleState } from '@/hooks/useToggleState'
import primarySchema from '@/pages/auth/validationschemas/primaryrecruiter.validator'
import {
  useRecruiterRequestOTPMutation,
  useRecruiterSignupMutation,
  useRecruiterVerifyOTPMutation,
} from '@/store/authSlice'
import { FormikHelpers, useFormik } from 'formik'
import { ChangeEvent, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import KeyCodeInput from './KeyCodeInput'
import { INest } from './user.type'

const initialState: RecruiterSignupDTO & {
  full_name: string
} = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  gender: '' as 'male',
  phone_number: '',
  full_name: '',
}
const PrimaryAdminDetails = (props: INest) => {
  const { pathname } = useLocation()
  const { goToNextStep, step } = props
  const [recruiterSignUp, { isLoading: isSending }] =
    useRecruiterSignupMutation()
  const { state: showPassword, toggle: toggleShow } = useToggleState(false)
  const {
    state: showVerificationPrompt,
    open: openVerificationPromt,
    close: closeVerificationPromt,
  } = useToggleState(false)
  const {
    state: showOTPPrompt,
    open: openOTPPromt,
    close: closeOTPPromt,
  } = useToggleState(false)
  const navigate = useNavigate()
  const submitHandler = async (
    values: RecruiterSignupDTO & {
      full_name: string
    },
    {
      setSubmitting,
      resetForm,
    }: FormikHelpers<
      RecruiterSignupDTO & {
        full_name: string
      }
    >
  ) => {
    const { error, response } = await withAsync(() =>
      recruiterSignUp({
        ...values,
      }).unwrap()
    )
    if (error) {
      return ToastNotify('error', {
        message: (error as any).message,
      })
    }
    if (response) {
      const { data } = response || {}
      closeOTPPromt()
      goToNextStep()
      navigate(`${pathname}?page=${step + 1}&user=${(data as any).id}`, {
        replace: true,
      })
      setSubmitting(false)
      resetForm()
    }
  }
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    isValid,
    setFieldError,
    setFieldValue,
    submitForm,
  } = useFormik<
    RecruiterSignupDTO & {
      full_name: string
    }
  >({
    initialValues: initialState,
    onSubmit: submitHandler,
    validationSchema: primarySchema,
  })

  const handleFullName = (e: ChangeEvent<HTMLInputElement>) => {
    const letters = e.currentTarget.value
    const words = letters.match(/\b\w+\b/g)

    if (words && words.length < 2) {
      //   setFieldError('last_name', 'Last name is required')

      const firstName = words[0]

      if (firstName) {
        setFieldValue('first_name', firstName)
        setFieldError('first_name', undefined)
        setFieldValue('last_name', '', true)
      }
      return
    } else if (words && words.length >= 2) {
      const firstName = words[0]
      const lastName = words[1]
      if (firstName) {
        setFieldValue('first_name', firstName, true)
        setFieldError('first_name', undefined)
      }
      if (lastName) {
        setFieldValue('last_name', lastName, true)
        setFieldError('last_name', undefined)
      }
    } else {
      setFieldError('last_name', 'Last name is required')
      setFieldError('first_name', 'First name is required')
      setFieldValue('first_name', '', true)
      setFieldValue('last_name', '', true)
    }
  }

  return (
    <div className='w-full flex flex-col gap-4 justify-start items-start'>
      {' '}
      <header className='flex flex-col gap-1 w-full'>
        <Typography as='h2' className='h3 text-jaa-shades-black  sm:h2'>
          Sign Up
        </Typography>
        <Typography as='p' className='text-jaa-shades-gray-2.5 font-normal sm'>
          Find the perfect fit for your company on JA Career Connect. Sign up
          now and start hiring Africa&apos;s top talent.
        </Typography>
      </header>
      <form
        className='grid grid-rows-4 grid-cols-1 md:grid-cols-2 w-full gap-4'
        onSubmit={handleSubmit}
      >
        <FormGroup id='fullNmae' label='Full Name' className='col-span-2  '>
          <InputWithAdornment
            name='full_name'
            onChange={handleChange}
            onBlur={composeEventHandlers(handleFullName, handleBlur)}
            value={values.full_name}
            error={
              ((!values.first_name ||
                !values.last_name) as unknown as string) &&
              (touched.full_name as unknown as string) &&
              'Enter First and Last name'
            }
          />
        </FormGroup>
        <FormGroup id='phone_no' label='Phone Number' className='col-span-2 '>
          <InputWithAdornment
            name='phone_number'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone_number}
            error={
              errors.phone_number &&
              (touched.phone_number as unknown as string) &&
              errors.phone_number
            }
          />
        </FormGroup>
        <FormGroup
          id='email'
          label='Email Address'
          className='col-span-2 md:col-span-1 md:col-start-1'
        >
          <InputWithAdornment
            type='email'
            name='email'
            style={{
              height: '48px',
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={
              errors.email &&
              (touched.email as unknown as string) &&
              errors.email
            }
          />
        </FormGroup>
        <FormGroup
          id='gender'
          label='Gender'
          className='col-span-2 md:col-span-1 md:col-start-2'
        >
          <SelectInput
            menuClassName='bg-white w-[230px]'
            placeholder='Select...'
            name='gender'
            className='select w-full'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.gender}
            options={[
              { label: 'Male', value: 'male' },
              { label: 'Female', value: 'female' },
            ]}
            optionsText={(item) => item?.label}
            optionsValue={(item) => item?.value}
            error={
              errors.gender &&
              (touched.gender as unknown as string) &&
              errors.gender
            }
          />
        </FormGroup>
        <FormGroup
          id='password'
          label='Create Password'
          className='col-span-2 '
        >
          <InputWithAdornment
            name='password'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={
              errors.password &&
              (touched.password as unknown as string) &&
              errors.password
            }
            type={showPassword ? 'text' : 'password'}
            style={{
              background: '#F1F3F4',
              padding: '0px 12px',
            }}
            className='bg-[#F1F3F4] w-full'
            right={
              <span onClick={toggleShow} className=''>
                {showPassword ? (
                  <iconComponents.util.EyeCloseIcon className='stroke-jaa-shades-black fill-jaa-shades-black' />
                ) : (
                  <iconComponents.util.EyeOpenIcon className='stroke-jaa-shades-black fill-jaa-shades-black' />
                )}
              </span>
            }
          />
        </FormGroup>
        <Button
          loading={isSending}
          disabled={!isValid || isSending}
          label='Continue'
          className='mt-6 col-span-2'
          onClick={openVerificationPromt}
        />
      </form>
      <VerificationPrompt
        open={isValid && showVerificationPrompt && !!isValidEmail(values.email)}
        onClose={closeVerificationPromt}
        nextAction={() => {
          closeVerificationPromt()
          openOTPPromt()
        }}
        email={values.email}
      />
      <VerificationCodeEntry
        open={showOTPPrompt && !!values.email}
        onClose={closeOTPPromt}
        email={values.email}
        type='signup'
        nextAction={submitForm}
      />
    </div>
  )
}
type IOpenable = {
  open: boolean
  onClose: () => void
  nextAction: () => void
  email: string
  phone?: string
  type?: 'user' | 'signup'
}
const redactEmail = (email: string, size = 5) => {
  if (!email) return email
  const firstThreeChar = email.substring(0, size)
  return (
    firstThreeChar + '***@***.' + email.split('.')[email.split('.').length - 1]
  )
}
export const VerificationPrompt = (prop: IOpenable) => {
  const { open, onClose, email, nextAction } = prop
  const [sendOtp, { isLoading: isSending }] = useRecruiterRequestOTPMutation()
  const handleVerification = async () => {
    const { error } = await withAsync(() =>
      sendOtp({
        email,
      }).unwrap()
    )
    if (error) {
      return ToastNotify('error', {
        message: (error as any).message || 'Ooops... Sorry, could not send now',
      })
    }
    ToastNotify('info', {
      message: `Email sent to ${redactEmail(email)}`,
      timeout: 15_000,
    })
    nextAction()
  }

  return (
    <Modal variant='md' onClose={onClose} open={open}>
      <div className='w-full flex flex-col  gap-2 p-8'>
        <ModalHeader title='' onClose={onClose} />

        <header className='flex flex-col gap-1 w-full justify-center items-center'>
          <div className='w-[72px] h-[72px] bg-jaa-teal-20 rounded-full sm:w-[96px] sm:h-[96px] flex justify-center items-center'>
            <div className='w-[52px] h-[52px] bg-jaa-teal-40 rounded-full sm:w-[66px] sm:h-[66px] flex justify-center items-center'>
              <iconComponents.util.EmailIcon
                stroke='var(--teal-100)'
                className='w-[28px] h-[31px] sm:w-8 sm:h-h-9'
              />
            </div>
          </div>
          <Typography as='h2' className='h3 text-jaa-shades-black  sm:h2'>
            Email Verification Code
          </Typography>
          <Typography
            as='p'
            className='text-jaa-shades-gray-2.5 font-normal sm'
          >
            An OTP would be sent to the email (
            <Typography as='strong' className='text-jaa-shades-black'>
              {redactEmail(email)}
            </Typography>
            ) linked to this account
          </Typography>
        </header>
        <Button
          disabled={isSending}
          loading={isSending}
          onClick={handleVerification}
          label='Send Verification link'
          className='w-full !uppercase my-10'
        />
      </div>
    </Modal>
  )
}

export const VerificationCodeEntry = (props: IOpenable) => {
  const { open, onClose, email, nextAction, type = 'user' } = props
  const [userInput, setUserInput] = useState('')
  const [verifyOTP, { isLoading: isSending }] = useRecruiterVerifyOTPMutation()
  const [sendOtp, { isLoading: isSendingOtp }] =
    useRecruiterRequestOTPMutation()
  const resendOtp = async () => {
    const { error, response } = await withAsync(() =>
      sendOtp({
        email,
      }).unwrap()
    )
    if (error) {
      return ToastNotify('error', {
        message: (error as any).message || 'Ooops... Sorry, could not send now',
      })
    }
    if (response)
      ToastNotify('info', {
        message: `Email sent to ${redactEmail(email)}`,
        timeout: 15_000,
      })
  }
  const numberOfkeyInput = 6
  const handleChange = (e: any) => {
    setUserInput((e.currentTarget as any).value)
  }
  const handleOtp = async () => {
    if (userInput.length !== numberOfkeyInput) {
      return ToastNotify('warn', {
        message: 'Please enter complete code',
      })
    }
    const { error, response } = await withAsync(() =>
      verifyOTP({
        otp: userInput,
        email,
        type,
      }).unwrap()
    )
    if (error) {
      return ToastNotify('error', {
        message: (error as any).message || 'Ooops... Sorry, could not send now',
      })
    }
    if (response) {
      await nextAction()
      ToastNotify('success', {
        message: `Email has been verified!`,
        timeout: 10_000,
      })
    }
  }

  return (
    <Modal variant='md' onClose={onClose} open={open}>
      <div className='w-full flex flex-col  gap-2 p-8'>
        <ModalHeader title='' onClose={onClose} />

        <header className='flex flex-col gap-1 w-full justify-center items-center'>
          <div className='w-[72px] h-[72px] bg-jaa-teal-20 rounded-full sm:w-[96px] sm:h-[96px] flex justify-center items-center'>
            <div className='w-[52px] h-[52px] bg-jaa-teal-40 rounded-full sm:w-[66px] sm:h-[66px] flex justify-center items-center'>
              <iconComponents.util.EmailIcon
                stroke='var(--teal-100)'
                className='w-[28px] h-[31px] sm:w-8 sm:h-h-9'
              />
            </div>
          </div>
          <Typography as='h2' className='h3 text-jaa-shades-black  sm:h2'>
            Please check your email.
          </Typography>
          <Typography
            as='p'
            className='text-jaa-shades-gray-2.5 font-normal sm'
          >
            We&apos;ve sent a code to{' '}
            <Typography as='strong' className='text-jaa-shades-gray-3'>
              {email}
            </Typography>
          </Typography>
        </header>
        <div className='flex flex-col gap-4 justify-center w-ful'>
          <KeyCodeInput
            onChange={(e) => handleChange(e)}
            numberOfChar={numberOfkeyInput}
            fullWidth
            className='!justify-center !gap-1'
            value={userInput}
          />
          <Typography
            as='p'
            className='text-jaa-gray-600 sm font-normal w-full text-center'
          >
            Didn&apos;t get a code?{' '}
            <Typography
              as='span'
              role='button'
              onClick={resendOtp}
              className='underline hover:text-jaa-teal-100'
            >
              Click to resend.
            </Typography>
          </Typography>
        </div>
        <div className='w-full flex items-center justify-between gap-4'>
          <Button
            disabled={isSending}
            loading={isSending}
            onClick={handleOtp}
            label='Verify'
            className='w-full !uppercase my-10'
          />
          <Button
            disabled={isSending}
            onClick={onClose}
            label='Cancel'
            variant='cancel'
            className='w-full !uppercase my-10'
          />
        </div>
      </div>
    </Modal>
  )
}

export default PrimaryAdminDetails
