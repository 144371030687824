import { useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from './../../store/hooks'
import { LOGOUT } from './../endpoints'

import api from '../api'
import { logOut } from '@/store/authSlice'
import { BackendToUI } from '@/pages/conversion'
import { persistor } from '@/store'

const useLogout = () => {
  const dispatch = useAppDispatch()
  const userType = useAppSelector((state) => state.auth.user_type)
  const navigate = useNavigate()
  const logout = async () => {
    dispatch(logOut())
    persistor.flush()
    persistor.purge()
    try {
      api.post(
        LOGOUT,
        {},
        {
          withCredentials: true,
        }
      )
    } catch (err) {
      console.log(err)
    }
    navigate(`/${BackendToUI[userType as 'admin']}/auth/sign-in`)
  }
  return logout
}

export default useLogout
