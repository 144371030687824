import { Media, POST } from '@/apis/forumApis'
import iconComponents from '@/assets/icons/iconComponents'
import { createElement, FC, useMemo } from 'react'

const FILE_MAP = {
  video: 'video',
  image: 'img',
}
const FeedGallery: FC<
  Pick<POST, 'media'> & { onGalleryClick?: (index: number) => void }
> = ({ media, onGalleryClick }) => {
  const getFileType = (file: Media): 'image' | 'video' => {
    if (!file) return 'image'
    const imageType = ['.jpeg', '.png', '.jpg', '.svg']
    return file.file_type
      ? (file.file_type as 'image' | 'video')
      : imageType.filter((validType) => file.file.includes(validType)).length
      ? 'image'
      : 'video'
  }

  const oneView = useMemo(
    () => (
      <div
        onClick={() => onGalleryClick?.(0)}
        className='w-full rounded-lg max-h-full h-[251px] md:h-[200px] relative'
      >
        {createElement(FILE_MAP[getFileType(media[0])] || 'img', {
          src: media[0]?.file || '',
          className:
            'w-full max-h-full h-full max-w-full object-cover object-center rounded-lg',
        })}
        {getFileType(media[0]) === 'video' && (
          <button
            type='button'
            className='w-12 h-12 absolute z-10 left-[calc(50%_-_24px)] top-[calc(50%_-_24px)] active:scale-95 hover:scale-110'
          >
            <iconComponents.profile.PlayVideoIcon className='w-full h-full' />
          </button>
        )}
      </div>
    ),
    [media, onGalleryClick]
  )
  const twoView = useMemo(
    () => (
      <div className='w-full gap-1 h-[251px] md:h-[200px] flex justify-start'>
        <div
          className='flex-1 h-[251px] md:h-[200px] relative'
          onClick={() => onGalleryClick?.(0)}
        >
          {createElement(FILE_MAP[getFileType(media[0])] || 'img', {
            src: media[0]?.file || '',
            className:
              'w-full max-h-full h-full max-w-full object-cover object-center rounded-tl-lg rounded-bl-lg',
          })}
          {getFileType(media[0]) === 'video' && (
            <button
              type='button'
              className='w-12 h-12 absolute z-10 left-[calc(50%_-_24px)] top-[calc(50%_-_24px)] active:scale-95 hover:scale-110'
            >
              <iconComponents.profile.PlayVideoIcon className='w-full h-full' />
            </button>
          )}
        </div>
        <div
          className='flex-1 h-[251px] md:h-[200px] relative'
          onClick={() => onGalleryClick?.(1)}
        >
          {createElement(FILE_MAP[getFileType(media[1])] || 'img', {
            src: media[1]?.file || '',
            className:
              'w-full h-full max-h-full max-w-full object-cover object-center rounded-tr-lg rounded-br-lg',
          })}
          {getFileType(media[1]) === 'video' && (
            <button
              type='button'
              className='w-12 h-12 absolute z-10 left-[calc(50%_-_24px)] top-[calc(50%_-_24px)] active:scale-95 hover:scale-110'
            >
              <iconComponents.profile.PlayVideoIcon className='w-full h-full' />
            </button>
          )}
        </div>
      </div>
    ),
    [media, onGalleryClick]
  )

  const moreThanTwo = useMemo(() => {
    const [first, second, third, ...rest] = media
    return (
      <div className='w-full gap-1 h-[251px] md:h-[200px] flex justify-start'>
        <div
          className='flex-1 h-[251px] md:h-[200px] relative'
          onClick={() => onGalleryClick?.(0)}
        >
          {createElement(FILE_MAP[getFileType(first)] || 'img', {
            src: first?.file || '',
            className:
              'w-full max-h-full h-full max-w-full object-cover object-center rounded-tl-lg rounded-bl-lg',
          })}
          {getFileType(first) === 'video' && (
            <button
              type='button'
              className='w-12 h-12 absolute z-10 left-[calc(50%_-_24px)] top-[calc(50%_-_24px)] active:scale-95 hover:scale-110'
            >
              <iconComponents.profile.PlayVideoIcon className='w-full h-full' />
            </button>
          )}
        </div>
        <div className='flex-1 h-[251px] md:h-[200px]  flex gap-1 flex-col'>
          <div
            className='flex-1 h-[124px] md:h-[98px] relative'
            onClick={() => onGalleryClick?.(1)}
          >
            {createElement(FILE_MAP[getFileType(second)] || 'img', {
              src: second?.file || '',
              className:
                'w-full max-h-full h-full max-w-full object-cover object-center rounded-tr-lg',
            })}
            {getFileType(second) === 'video' && (
              <button
                type='button'
                className='w-12 h-12 absolute z-10 left-[calc(50%_-_24px)] top-[calc(50%_-_24px)] active:scale-95 hover:scale-110'
              >
                <iconComponents.profile.PlayVideoIcon className='w-full h-full' />
              </button>
            )}
          </div>
          <div
            className='flex-1 h-[124px] md:h-[98px] relative'
            onClick={() => onGalleryClick?.(2)}
          >
            {createElement(FILE_MAP[getFileType(third)] || 'img', {
              src: third?.file || '',
              className:
                'w-full max-h-full h-full max-w-full object-cover object-center rounded-br-lg',
            })}
            {getFileType(third) === 'video' && (
              <button
                type='button'
                className='w-12  h-12 absolute z-10 left-[calc(50%_-_24px)] top-[calc(50%_-_24px)] active:scale-95 hover:scale-110'
              >
                <iconComponents.profile.PlayVideoIcon className='w-full h-full' />
              </button>
            )}
            {!!rest.length && (
              <div className='w-full absolute top-0 left-0 bg-[#00000099] h-full flex flex-col justify-center items-center max-h-full max-w-full object-cover object-center rounded-br-lg'>
                <span className='h2 font-medium text-jaa-shades-white'>
                  +{rest.length}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }, [media, onGalleryClick])
  const viewsMap: Record<number, React.ReactElement> = {
    1: oneView,
    2: twoView,
  }
  return media.length ? (
    <section className='w-full h-[251px] md:h-[200px] cursor-pointer'>
      {viewsMap[media.length] || moreThanTwo}
    </section>
  ) : (
    <></>
  )
}

export default FeedGallery
