import iconComponents from '@/assets/icons/iconComponents'
import { WEB_SOCKET_URL } from '@/constants/api'
import { getUniques } from '@/helpers/array'
import { BackendToUI } from '@/pages/conversion'
import { getAccessTokens } from '@/store/authSlice'
import {
  invalidateApi,
  useGetUnreadEngagementQuery,
} from '@/store/engagementSlice'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { w3cwebsocket as W3CWebSocket } from 'websocket'

const Chats = () => {
  const navigate = useNavigate()
  const appDispatch = useAppDispatch()
  const { data: messages } = useGetUnreadEngagementQuery({ page: 1 })
  const unreadCount = messages?.unread_chats

  const { user_type } = useAppSelector((state) => state.auth)
  const token = useAppSelector(getAccessTokens)
  useEffect(() => {
    const client = new W3CWebSocket(`${WEB_SOCKET_URL}/${token}/chats/`)

    client.onopen = () => {
      console.log('WebSocket Client Connected TO chats')
    }
    client.onmessage = (ev: any) => {
      let dataFromServer
      console.log(ev.data, 'RECEIVED')
      try {
        dataFromServer = JSON.parse(ev.data)
        if (dataFromServer) {
          const { action } = dataFromServer
          console.log(dataFromServer, 'DATA')
          if (action === 'message' || action === 'read') {
            // initialiseEngagements({ page: 1 })
            appDispatch(invalidateApi(['Engagement', 'Messages']))
          }
        }
      } catch (err) {
        console.log(ev.data, 'DATA FROM SERVER')
      }
    }
  }, [])

  return (
    <li
      className='flex gap-3 items-center py-2 cursor-pointer'
      onClick={() =>
        navigate(
          `/${BackendToUI[user_type as 'alumnus']}/app/engagement?view=unread`
        )
      }
    >
      <div className='relative w-8 h-8'>
        <iconComponents.navigation.ChatIcon />
        {unreadCount ? (
          <span className='absolute -top-0 left-[11px] border border-jaa-shades-white bg-jaa-red-500 align-middle text-jaa-shades-white rounded-full font-medium text-[12px] w-5 h-5 p-1 flex justify-center items-center'>
            {unreadCount > 9 ? '9+' : unreadCount}
          </span>
        ) : null}
      </div>
      <span className='text-jaa-shades-gray-3 font-normal md lg:sm lg:hidden inline-block capitalize -mt-2'>
        Messages
      </span>
    </li>
  )
}

export default Chats
