import {
  Language,
  LanguageDTO,
  LanguageList,
  getLanguageList,
  setPreferredLanguage,
} from '@/apis/languageApis'
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'

export const languageApiSlice = createApi({
  reducerPath: 'languageApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['GetLanguage', 'SetLanguage'],

  endpoints: (builder) => ({
    getLanguageList: builder.query<any, void>({
      queryFn: async (data) => {
        try {
          return await getLanguageList()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['GetLanguage'],
    }),
    setPreferredLanguage: builder.mutation<{ data: LanguageDTO }, LanguageDTO>({
      queryFn: async (data) => {
        try {
          return await setPreferredLanguage(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['SetLanguage'],
    }),
  }),
})

export const { useGetLanguageListQuery, useSetPreferredLanguageMutation } =
  languageApiSlice

export const resetLanguageApiSlice = () => languageApiSlice.util.resetApiState()

export const invalidateLanguageApiSlice = languageApiSlice.util.invalidateTags
