import { IRecruiterProfile } from '@/apis/profileApis'
import { Job } from '@/apis/recruitementApis'
import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button'
import Image from '@/common/Image'
import Modal from '@/common/modal/Modal'
import Typography from '@/common/Typography'
import { useOutsideClick } from '@/hooks/useOutsideClick'
import useStepper from '@/hooks/useStepper'
import { useToggleState } from '@/hooks/useToggleState'
import clsx from 'clsx'
import { formatDistance } from 'date-fns'
import React, { FC, useRef } from 'react'
import CreateOrUpdateJob from '../CreateOrUpdateJob'
import DeleteJob from '../DeleteJob'

const JobDescription: FC<{ data: Job }> = (props) => {
  const { data } = props
  return (
    <div className='p-4 sm:p-6 w-full flex flex-col gap-4 justify-start items-start rounded-lg bg-jaa-shades-white  h-full z-10'>
      <Typography className='h4 lg:h3 text-jaa-shades-gray-4 font-medium'>
        Job Description
      </Typography>
      <div className='flex flex-col justify-start items-start gap-6'>
        <Creator
          {...(data.recruiter as any)}
          created_at={data?.created_at || ''}
        />
        <JobTitle title={data?.title || ''} />
        <Description description={data?.description || ''} />
        <JobImages data={data || {}} />
        <JobType job_type={data?.job_type || ''} />
      </div>
      <div className='w-full grid grid-cols-2 gap-y-6 justify-items-start place-content-start place-items-start pb-8'>
        <DataDisplay
          className='col-span-1'
          icon={
            <iconComponents.profile.BriefCaseIcon className='stroke-jaa-shades-gray-2.5' />
          }
          value={`${data.years_of_experience} Years`}
          title='Years of Experience'
        />
        <DataDisplay
          className='col-span-1'
          icon={
            <iconComponents.profile.BriefCaseIcon className='stroke-jaa-shades-gray-2.5' />
          }
          value={data.location}
          title='Location'
        />
        <DataDisplay
          className='col-span-1'
          icon={
            <iconComponents.profile.MoneyStackIcon className='stroke-jaa-shades-gray-2.5' />
          }
          value={
            <span className='flex items-start gap-1'>
              <span>{data?.salary_range}</span>
              <span className='text-jaa-shades-gray-2.5 font-normal'>
                {data?.salary_currency?.split(' ')?.[0]}
              </span>
            </span>
          }
          title='Salary Range'
        />
        {/* <DataDisplay
          className='col-span-1'
          icon={
            <iconComponents.profile.DocumentIcon className='stroke-jaa-shades-gray-2.5' />
          }
          value={
            <span className='flex flex-col justify-center items-start gap-2'>
              <span className=''>Resume</span>
              <span className=''>Cover Letter</span>
              <span className=''>Reference Letter</span>
            </span>
          }
          title='Required Documents'
        /> */}
      </div>
    </div>
  )
}

const HEADER_STYLE = 'font-normal text-jaa-shades-gray-2.5 xs sm:md !capitalize'
type ICreatorProps = {
  created_at: string
} & IRecruiterProfile
const Creator: FC<ICreatorProps> = (props) => {
  const {
    created_at,
    profile_pic = '/defaultImage.png',
    first_name,
    last_name,
    company_role,
  } = props
  return (
    <div className='flex flex-col justify-center gap-1 w-full items-start'>
      <Typography as='h6' className={clsx(HEADER_STYLE)}>
        Created By:
      </Typography>
      <div className='flex flex-col items-start justify-start gap-1'>
        <div className='flex justify-start gap-2 items-start'>
          <img
            src={profile_pic || '/defaultImage.png'}
            alt='representation of creator'
            className='w-11 h-11 rounded-full'
          />
          <div className='flex flex-col items-start'>
            <Typography
              className='font-semibold sm  text-jaa-shades-black'
              as='strong'
            >
              {`${first_name} ${last_name}`}
            </Typography>
            <Typography className='font-normal sm  text-jaa-teal-100' as='span'>
              {company_role}
            </Typography>
          </div>
        </div>
        <div className='flex items-center justify-center w-full'>
          <Typography className='text-jaa-shades-gray-2.5 font-medium xs text-right pl-4'>
            {formatDistance(new Date(created_at), new Date())} ago
          </Typography>
        </div>
      </div>
    </div>
  )
}

const JobTitle = (props: Pick<Job, 'title'>) => {
  return (
    <div className='flex flex-col items-start gap-1'>
      <Typography as='h6' className={clsx(HEADER_STYLE)}>
        Job title
      </Typography>
      <Typography as='span' className='font-normal leading-8 sm sm:lg'>
        {props.title}
      </Typography>
    </div>
  )
}

const Description = (props: Pick<Job, 'description'>) => {
  const { toggle, state: showMore } = useToggleState(false)
  return (
    <section className='flex flex-col items-start gap-1'>
      <Typography as='h6' className={clsx(HEADER_STYLE)}>
        Description
      </Typography>
      <div
        className='font-normal sm sm:lg text-jaa-shades-gray-4'
        dangerouslySetInnerHTML={{
          __html: `<div style='max-height: ${
            showMore ? 'auto' : '160px'
          }; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; overflow: hidden;'>
          <p style='max-height: ${
            showMore ? 'auto' : '100px'
          }; width: 100%; overflow: hidden;'>${props.description}</p>
          ${props.description.length > 195 && !showMore ? '<p>...</p>' : ''}
          </div>`,
        }}
      ></div>
      <div className='flex justify-center sm:justify-start pt-2 w-full'>
        {props.description.length > 195 && (
          <Button
            onClick={toggle}
            label={
              <span className='uppercase flex justify-center items-center py-1 gap-2.5 rounded'>
                <span className='text-jaa-shades-gray-2.5 sm'>
                  Show {showMore ? 'less' : 'more'}
                </span>
                <iconComponents.util.ChevronDownIcon
                  className={clsx(
                    'stroke-[#979797]',
                    showMore ? 'rotate-180' : 'rotate-0'
                  )}
                />
              </span>
            }
            variant='empty'
            className='!px-0'
          />
        )}
      </div>
    </section>
  )
}
export const JobImages = (props: { data: Job; isRecruiter?: boolean }) => {
  const {
    data: { images, title },
    isRecruiter = true,
  } = props
  const {
    close: closeLargeView,
    open: openLargeView,
    state: showLargeView,
  } = useToggleState()
  const { step, goToNextStep, goToPrevStep, setStep } = useStepper(0)
  const {
    state: IsOpen,
    toggle: toggleMore,
    close: closeMore,
  } = useToggleState()
  const {
    state: showDelete,

    open: openDelete,
    close: closeDelete,
  } = useToggleState()
  const {
    state: showUpdate,

    open: openUpdate,
    close: closeUpdate,
  } = useToggleState()
  const moreRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLUListElement>(null)
  useOutsideClick([moreRef, menuRef], closeMore)
  return !images.length ? (
    <></>
  ) : (
    <section className='flex flex-col items-start gap-1 '>
      <Typography as='h6' className={clsx(HEADER_STYLE)}>
        Attached Images
      </Typography>
      <main className='w-full'>
        <ol className='flex items-center gap-4 py-0.5 overflow-auto '>
          {images.map((img, idx) => (
            <Image
              key={img.id}
              src={img.image}
              onClick={() => {
                setStep(idx)
                openLargeView()
              }}
              className='w-[202px] rounded-md h-[122px] object-cover object-center cursor-pointer'
            />
          ))}
        </ol>
      </main>
      <Modal
        open={showLargeView}
        onClose={closeLargeView}
        variant='xl'
        className='p-4 sm:p-6 flex flex-col gap-10'
      >
        <div className='w-full flex flex-col justify-start items-start  gap-5 sm:flex-row sm:justify-between sm:items-center'>
          <Button
            variant='empty'
            className='!px-0'
            label={
              <span className='justify-start flex items-center '>
                <iconComponents.util.ArrowLeftIcon className='stroke-jaa-shades-black' />
                <span className='xs font-medium uppercase text-jaa-shades-black'>
                  Back to Job
                </span>
              </span>
            }
            onClick={closeLargeView}
          />
          <div className='w-full grid grid-cols-2 gap-3 sm:flex sm:w-auto sm:justify-end sm:items-center'>
            <Button
              variant='cancel'
              disabled={step === 0}
              onClick={() => goToPrevStep()}
              className='col-span-1 stroke-jaa-shades-black disabled:!text-jaa-shades-gray-2.5 disabled:!stroke-jaa-shades-gray-2.5'
              label={
                <span className='justify-start flex items-center '>
                  <iconComponents.util.ChevronLeftIcon className='' />
                  <span className='xs font-medium uppercase'>PREV</span>
                </span>
              }
            />
            <Button
              variant='cancel'
              onClick={() => goToNextStep()}
              disabled={step === images.length - 1}
              className='col-span-1 stroke-jaa-shades-black disabled:text-jaa-shades-gray-2.5 disabled:stroke-jaa-shades-gray-2.5'
              label={
                <span className='justify-start flex items-center '>
                  <span className='xs font-medium uppercase'>NEXT</span>
                  <iconComponents.util.ChevronRightIcon className='' />
                </span>
              }
            />
          </div>
        </div>
        <div className='lg:px-[14%] flex flex-col w-full gap-4 justify-start items-start'>
          <div className='flex justify-between items-start lg:px-[4%] w-full'>
            <div className='flex flex-col justify-start items-start'>
              <Typography
                as='h4'
                className='h4 sm:h3 font-medium text-jaa-shades-black'
              >
                {title}
              </Typography>
            </div>
            {isRecruiter && (
              <div ref={moreRef} className='relative justify-self-end'>
                <button
                  type='button'
                  aria-haspopup='listbox'
                  aria-controls='options'
                  onClick={toggleMore}
                  className='w-11 h-11 flex justify-center items-center'
                >
                  <iconComponents.util.MoreVertIcon className='stroke-jaa-shades-gray-4 fill-jaa-shades-gray-4' />
                </button>
                {IsOpen && (
                  <ul
                    ref={menuRef}
                    id='options'
                    className='absolute right-4 mb-2 top-[39px] z-20 bg-jaa-shades-white rounded-bl rounded-br shadow-[0px_4px_24px_rgba(4,_4,_4,_0.05)]'
                  >
                    <li
                      onClick={() => {
                        openUpdate()
                        closeLargeView()
                      }}
                      className='px-4 w-[180px] py-2.5 hover:bg-jaa-shades-bg sm text-jaa-shades-black cursor-pointer font-normal'
                    >
                      Edit
                    </li>
                    <li
                      onClick={() => {
                        openDelete()
                        closeLargeView()
                      }}
                      className='px-4 text-jaa-red-500 py-2.5 hover:bg-jaa-shades-bg sm font-normal cursor-pointer'
                    >
                      Delete
                    </li>
                  </ul>
                )}
              </div>
            )}
          </div>
          <section
            id='image'
            className='w-full flex items-center justify-center gap-4 h-[600px] -ml-4'
          >
            <button
              type='button'
              className='p-2'
              onClick={goToPrevStep}
              disabled={step === 0}
            >
              <iconComponents.util.ChevronLeftIcon
                className={clsx(
                  'stroke-jaa-shades-black',
                  step == 0 ? '!stroke-jaa-shades-gray-2' : ''
                )}
              />
            </button>
            <div className='h-full relative w-full max-w-[100%]'>
              <img
                key={images[step]?.image}
                src={images[step]?.image}
                alt={images[step].id + ' Rep'}
                className=' h-full w-full max-h-full object-cover rounded-lg'
              />
            </div>
            <button
              type='button'
              className='p-2'
              onClick={goToNextStep}
              disabled={step === images.length - 1}
            >
              <iconComponents.util.ChevronRightIcon
                className={clsx(
                  'stroke-jaa-shades-black',
                  step === images.length - 1 ? '!stroke-jaa-shades-gray-2' : ''
                )}
              />
            </button>
          </section>
        </div>
      </Modal>
      <CreateOrUpdateJob
        onClose={() => {
          closeUpdate()
        }}
        data={props.data}
        open={showUpdate}
      />
      <DeleteJob
        open={showDelete}
        onClose={() => {
          closeDelete()
        }}
        data={props.data}
      />
    </section>
  )
}
const JobType = (props: Pick<Job, 'job_type'>) => {
  return (
    <div className='flex flex-col items-start gap-1'>
      <Typography as='h6' className={clsx(HEADER_STYLE)}>
        Job type
      </Typography>
      <Typography
        as='span'
        className='font-normal uppercase text-jaa-orange-100 sm flex justify-center items-center rounded py-1 px-5 bg-[#EFCEAF4D] h-[33px]'
      >
        {props.job_type}
      </Typography>
    </div>
  )
}

type DisplayProps = {
  icon: React.ReactElement
  title: string
  value: React.ReactNode
  className?: string
}
const DataDisplay = (props: DisplayProps) => {
  const { className = '', icon, title, value } = props
  return (
    <div
      className={clsx(
        'flex flex-col justify-center item-start gap-2',
        className
      )}
    >
      <div className='flex gap-1 w-full items-center'>
        {icon}
        <Typography
          as='h6'
          className='font-normal normal-case tracking-normal text-jaa-shades-gray-2.5 xs sm:md'
        >
          {title}
        </Typography>
      </div>
      <div className='w-full text-jaa-shades-black font-normal sm sm:lg'>
        {value}
      </div>
    </div>
  )
}

export default JobDescription
