import React from 'react'
import { Button } from '../components/Forms/button'
import * as Icon from 'react-feather'
import {
  CallingIcon,
  FacBookIcon,
  InstaIcon,
  LinkedinIcon,
  MailBoxIcon,
  MapPinIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../Icons/generated'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
  const { t } = useTranslation()
  return (
    <footer id='footer' className='w-full bg-jaa-main'>
      <div className='pt-[88px] px-7 md:px-[70px] xl:px-[144px] pb-6 space-y-8 md:space-y-0'>
        <div className='w-full flex md:flex-row items-start justify-between flex-col md:space-y-0 space-y-24'>
          <section className='space-y-4 max-w-xs'>
            <h1 className='w-max text-jaa-white font-bold text-xs text-center rounded-md'>
              {t('app.landing.about')}
            </h1>
            <p className='text-jaa-white font-normal text-base'>
              {t('app.landing.about_text')}
            </p>
            <a
              href='https://ja-africa.org/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button
                className='text-jaa-white border border-jaa-white px-6 my-2 py-2'
                variant='contrast'
              >
                {t('app.landing.learn_more')}
              </Button>
            </a>
          </section>

          <section className='space-y-4 max-w-xs text-jaa-white'>
            <h1 className='w-max font-bold text-xs text-center rounded-md'>
              Location
            </h1>
            <p className='font-light flex items-start space-x-2'>
              <MapPinIcon className='w-10 h-10' />
              <span>
                Freetown Road near American House, East Legon, Accra, Ghana.
              </span>
            </p>
          </section>

          <section className='space-y-4 max-w-xs'>
            <h1 className='w-max text-jaa-white font-bold text-xs text-center rounded-md'>
              Contacts
            </h1>
            <p className='text-jaa-white font-light text-base flex items-start justify-start space-x-3'>
              <MailBoxIcon className='w-6 h-6' />
              <div className='flex flex-col item-center'>
                <span className='font-semibold'>Email</span>
                <span>jacareerconnect@ja-africa.org</span>
              </div>
            </p>
            <p className='text-jaa-white font-light text-base flex items-start justify-start space-x-3'>
              <CallingIcon className='w-6 h-6' />
              <div className='flex flex-col item-center'>
                <span className='font-semibold'>Phone</span>
                <span>+233 577 680 373</span>
              </div>
            </p>
          </section>
        </div>
      </div>
      <div className='px-7 py-4 md:px-[70px] xl:px-[144px] w-full bg-jaa-footergray flex items-center justify-between text-sm text-jaa-fgray font-medium'>
        <p>©2023 JA Africa. {t('app.landing.rights')}</p>
        <ul className='flex items-center justify-between space-x-6'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://web.facebook.com/juniorachievementafrica'
            className='py-2 px-3 rounded bg-blue-500'
          >
            <FacBookIcon />
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://twitter.com/JA_Africa_'
            className='cursor-pointer py-2.5 px-2.5 rounded bg-blue-500'
          >
            <TwitterIcon />
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.youtube.com/channel/UCMdZL-jwVQ984cU_vjWn2Yw'
            className='cursor-pointer py-2.5 px-2 rounded bg-red-500'
          >
            <YoutubeIcon />
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.linkedin.com/company/junior-achivement-africa/'
            className='cursor-pointer py-2.5 px-2.5 rounded bg-blue-500'
          >
            <LinkedinIcon />
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.instagram.com/ja_africa/'
            className='cursor-pointer py-2.5 px-3 rounded bg-black'
          >
            <InstaIcon />
          </a>
        </ul>
      </div>
    </footer>
  )
}
