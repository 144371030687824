import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button'
import FormGroup from '@/common/Input/FormGroup'
import Typography from '@/common/Typography'
import useToggleFields from '@/hooks/useToggleFields'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormField } from './form.type'
import { useTranslation } from 'react-i18next'
import { flushSync } from 'react-dom'

const fixedArr: any[] = []
const UploadImage = (
  props: FormField & { className?: string; title?: string }
) => {
  const { t } = useTranslation()

  const { values, setFieldValue } = props
  const [isChangeMadeByUser, setIsChangeMadeByUser] = useState(false)
  const [removedFieldCount, setRemovedFieldCount] = useState(0)
  const fieldCountRef = useRef(0)
  const { fields, addField, setFields, removeField, updateField } =
    useToggleFields<{ src: string; file?: File; default?: boolean }>()
  useEffect(() => {
    if (!values.images.length || isChangeMadeByUser) return
    setFields(values.images)
    fieldCountRef.current = values.images.length
    // setRemovedFieldCount(values.images.length)
  }, [isChangeMadeByUser, setFields, values.images])

  useEffect(() => {
    if (!fields.length) {
      // setFieldValue?.('images', fixedArr)
      return
    }
    setFieldValue?.('images', fields)
  }, [fields])
  useEffect(() => {
    if (!removedFieldCount) return
    if (removedFieldCount >= fieldCountRef.current) {
      setFieldValue?.('images', fixedArr)
    }
  }, [removedFieldCount, fields, setFieldValue])

  const inputRef = useRef<HTMLInputElement>(null)
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const readFile = (file: File) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result
        addField(
          {
            src: binaryStr as string,
            file,
          },
          'src'
        )
        setRemovedFieldCount((prev) => (prev > 0 ? prev - 1 : prev))
      }
      reader.readAsDataURL(file)
    }
    for (const file of e.target.files || []) {
      readFile(file)
    }
    setIsChangeMadeByUser(true)
  }
  return (
    <FormGroup
      id='image'
      label={t('app.button.upload_image')}
      subtext={
        fields.length ? (
          <span>
            <input
              type='file'
              accept='"image/*"'
              hidden
              ref={inputRef}
              onChange={changeHandler}
            />
            <button
              type='button'
              onClick={() => inputRef.current?.click()}
              className='sm text-[#5995D2] flex gap-2 justify-center items-center normal-case'
            >
              <iconComponents.util.AddIcon className='stroke-[#5995D2] fill-[#5995D2]' />
              <span>Add</span>
            </button>
          </span>
        ) : (
          ''
        )
      }
      className={props.className}
    >
      {fields.length ? (
        <ShowUploads
          fields={fields}
          removeField={(field, key) => {
            flushSync(() => removeField(field, key))
            setRemovedFieldCount((prev) => prev + 1)
          }}
          updateField={updateField}
        />
      ) : (
        <InitialUpload
          addField={(field, key) => {
            addField(field, key)
            setRemovedFieldCount(0)
          }}
          onAdd={() => setIsChangeMadeByUser(true)}
        />
      )}
    </FormGroup>
  )
}

const InitialUpload = (props: {
  addField: (
    field: {
      src: string
      file?: File | undefined
    },
    key?: string | undefined
  ) => void
  onAdd: () => void
}) => {
  const { addField, onAdd } = props
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const readFile = (file: File) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result
        addField(
          {
            src: binaryStr as string,
            file,
          },
          'src'
        )
        onAdd()
      }
      reader.readAsDataURL(file)
    }
    for (const file of acceptedFiles) {
      readFile(file)
    }
  }, [])
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileExplorer,
  } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/svg+xml': ['.svg'],
    },
    noClick: true,
  })
  const { t } = useTranslation()

  return (
    <div
      className='flex w-full flex-col justify-center items-center  font-medium  h-[140px] '
      {...getRootProps({
        role: 'button',
      })}
    >
      <input
        {...getInputProps({
          multiple: false,
        })}
      />
      <div className='flex flex-col gap-3 items-center w-full'>
        <Button
          label={
            <span className='flex justify-center items-center gap-2.5'>
              <iconComponents.util.UploadIcon className='stroke-jaa-shades-black  fill-jaa-shades-black w-4 h-4' />
              <span>{t('app.button.click_to_upload')}</span>
            </span>
          }
          className='font-normal w-[145px] !text-jaa-shades-black sm !bg-jaa-shades-white border !h-8 py-1.5 px-3'
          onClick={openFileExplorer}
        />
        <Typography
          as='span'
          className='font-normal w-[165px] text-jaa-gray-500 text-[10px] leading-3 text-center'
        >
          {isDragActive
            ? 'Drop file here...'
            : 'or drag and drop (Format: SVG, PNG, JPG or JPEG )'}
        </Typography>
      </div>
    </div>
  )
}

const ShowUploads = (props: {
  fields: {
    src: string
    file?: File
    default?: boolean
  }[]
  removeField: (
    field:
      | string
      | number
      | {
          src: string
          file?: File
          default?: boolean
        },
    key?: string
  ) => void
  updateField: (
    update: Partial<{
      src: string
      file?: File | undefined
      default?: boolean | undefined
    }>,
    field: string | number,
    key?: string | undefined
  ) => void
}) => {
  const { fields, removeField, updateField } = props
  const makeCoverHandler = (field: (typeof fields)[0]) => {
    for (const field of fields) {
      updateField({ default: false }, field.src, 'src')
    }
    updateField({ ...field, default: true }, field.src, 'src')
  }
  return (
    <div className='max-w-full h-[140px] flex gap-5 items-center justify-start overflow-auto'>
      {fields.map((field) => (
        <div
          key={field.src}
          className='relative min-w-[290px] w-[296px] max-h-full rounded-lg'
        >
          <img
            src={field.src}
            alt='project'
            className='object-cover object-center w-full max-w-full h-[140px]'
          />
          <div className='absolute w-full h-full top-0 left-0 right-0 bg-[#00000066] py-[35px] px-[77px] gap-2.5 flex flex-col justify-center rounded-lg'>
            {!field.default && (
              <button
                type='button'
                onClick={() => makeCoverHandler(field)}
                className='flex normal-case tracking-normal items-center justify-center gap-1 xs font-medium text-jaa-shades-white'
              >
                <span>Make cover image</span>
                <iconComponents.profile.MakeDefaultIcon className='stroke-jaa-shades-white w-4 h-4' />
              </button>
            )}
            <button
              type='button'
              onClick={() => removeField(field.src, 'src')}
              className='flex normal-case tracking-normal items-center gap-1 justify-center xs font-medium text-jaa-shades-white'
            >
              <span>Delete</span>
              <iconComponents.util.CancelIcon className='stroke-jaa-shades-white w-4 h-4' />
            </button>
          </div>
        </div>
      ))}
    </div>
  )
}

export default UploadImage
