/* eslint-disable no-useless-escape */
import { object, string } from 'yup'

export const format = /^[\!@\#\$\%\^\&\*\_\(\)\-\+\=\~\`\.\w]*$/
export const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/
const memeberSchema = object().shape({
  phone_number: string().matches(/^\+?[01-9]+$/, 'Phone number is not valid'),
  password: string()
    .required('Password is required')
    .matches(
      passwordFormat,
      'Password must include both lower and upper case characters, include at least one number, include at least one special character, be at least 8 characters long.'
    )
    .test(
      'special-chars',
      'Password must contain special characters',
      function (value) {
        return format.test(value as unknown as string)
      }
    ),
  name: string()
    .required()
    .matches(/\b\w+\b/g, 'Name must contain 2 or more words'),
  company_role: string().required(),
  code: string().required().min(5),
  first_name: string().min(3).required('First Name Required'),
  last_name: string().min(3).required('Last Name Required'),
})

export default memeberSchema
