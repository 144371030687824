import { PaginatedData } from './api.types'
import api from './api'
import { addDataToQueryString } from '@/helpers/strings'
import { ALL_ENGAGEMENT, ENGAGEMENT_MESSAGES } from './endpoints'
export interface Engagement {
  id: number
  date_created: string
  applied: boolean
  alumnus: AlumniSummary
  recruiter: AlumniSummary
  last_message: {
    id: number
    message: string
    time_sent: string
    read: boolean
    chat: number
    sender: AlumniSummary
  }
}
interface AlumniSummary {
  id: number
  first_name: string
  last_name: string
  profile_pic: string
  role: string
  company: string
  company_role: string
}
export type EngagementDTO = {
  applied: boolean
  alumnus: number
}
export type PageDTO = {
  page?: number
  alumnus_id?: number
}
export interface Message {
  id: number
  message: string
  time_sent: string
  read: boolean
  chat: number
  sender: AlumniSummary
}
export type MessagePageDTO = {
  page: number
  chat_id: number
  search?: string
}

export type MessageDTO = {
  message: string
  chat: number
}
export interface UnreadMessages {
  unread_chats: number
  unread_messages: Unreadmessage[]
}

interface Unreadmessage {
  id: number
  count: number
}
export const getUnreadEngagement = async (payload: PageDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: UnreadMessages }>(
      ALL_ENGAGEMENT + 'unread-count/' + (qs ? '?' + qs : '')
    )
  ).data
}
export const getEngagements = async (payload: PageDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<Engagement> }>(
      ALL_ENGAGEMENT + (qs ? '?' + qs : '')
    )
  ).data
}
export const createEngagement = async (payload: EngagementDTO) => {
  return await api.post<{ data: Engagement }>(ALL_ENGAGEMENT, payload)
}

export const getEngagementMessages = async (payload: MessagePageDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<Message> }>(
      ENGAGEMENT_MESSAGES + (qs ? '?' + qs : '')
    )
  ).data
}
export const createEngagementMessage = async (payload: MessageDTO) => {
  return await api.post<{ data: Message }>(ENGAGEMENT_MESSAGES, payload)
}
