import { FC } from 'react'

type BannerProps = {
  image: string
}
const Banner: FC<BannerProps> = ({ image }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100svh',
        backgroundPosition: '50% 100%',
      }}
      className='h-screen relative overlay bg-white z-0'
    >
      {/* <img
        className='w-full h-full  object-contain object-[100%_100%] '
        src={image}
        loading='lazy'
        alt='representation of page'
      /> */}
    </div>
  )
}

export default Banner
