import { AlumniUser } from '@/apis/authApis'
import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import SelectInput from '@/common/Input/SelectInput'
import Title from '@/common/Title'
import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import { withAsync } from '@/helpers/withAsync'

import useStepper from '@/hooks/useStepper'
import { useToggleState } from '@/hooks/useToggleState'
import { useAcceptAlumniInviteMutation } from '@/store/authSlice'
import { FormikErrors, FormikHelpers, FormikTouched, useFormik } from 'formik'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import signUpSchema from '../../validationschemas/alumni.signup.validator'

const initialValues: AlumniUser & { password_confirm: string } = {
  first_name: '',
  last_name: '',
  // cohort: '',
  country: '',
  // program: '',
  password: '',
  email: '',
  password_confirm: '',
  gender: '' as 'male',
}
const AlumniSignUp = () => {
  const { code, email } = useParams()
  const navigate = useNavigate()
  const { step, goToNextStep, goToPrevStep } = useStepper(1)
  const [acceptInvite, { isLoading: isAccepting }] =
    useAcceptAlumniInviteMutation()
  const submitHandler = async (
    values: AlumniUser & { password_confirm: string },
    {
      setSubmitting,
      resetForm,
    }: FormikHelpers<AlumniUser & { password_confirm: string }>
  ) => {
    if (
      !values.password?.trim() ||
      (values.password && values.password !== values.password_confirm)
    ) {
      return ToastNotify('warn', {
        message: 'Password Does not match',
      })
    }
    // if (values.program === 'Social Equity Project' && !values.cohort) {
    //   return ToastNotify('warn', {
    //     message: 'Select a Cohort for Social Equity Project',
    //   })
    // }
    const { password_confirm, country, cohort, ...other } = values
    const { error } = await withAsync(
      () =>
        acceptInvite({
          ...other,
          country: country,
          // program: program,
          ...(cohort
            ? {
                cohort: cohort.split(' ')[1],
              }
            : {}),
          code: code as string,
        }).unwrap(),
      () => {
        setSubmitting(false)
      }
    )

    if (error) {
      return ToastNotify('error', {
        message:
          (error as any).message ||
          'Retry clicking on the link and enter all details',
      })
    }
    ToastNotify('success', {
      message: 'Successfully Created Account',
    })
    resetForm()
    navigate(`/alumni/success/create-account`, {
      replace: true,
    })
  }
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    isValid,
    dirty,
    isSubmitting,
  } = useFormik({
    initialValues: useMemo(
      () => ({
        ...initialValues,
        email: email || '',
      }),
      [email]
    ),
    onSubmit: submitHandler,
    validationSchema: signUpSchema(step),
  })

  return (
    <div className='w-full px-4 sm:px-[19%] lg:px-[10%]  h-full flex flex-col gap-10'>
      <Title>JA Africa - Create Account</Title>
      {step === 2 && (
        <Button
          className='font-light !xl !text-jaa-shades-gray-2 !justify-self-start self-start -ml-8'
          onClick={goToPrevStep}
          variant='empty'
          label={
            <span className='w-full flex justify-center items-center gap-2'>
              <iconComponents.util.ArrowLeftIcon
                stroke='var(--shades-gray-2)'
                strokeWidth={1}
              />
              <span>Back</span>
            </span>
          }
        />
      )}
      <div className='flex flex-col gap-1 '>
        <Typography
          as='h2'
          className='text-[#435156] text-[28px] leading-[140%] font-medium sm:leading-[130%] sm:text-[38px] whitespace-nowrap'
        >
          Welcome Alumni!
        </Typography>
        <Typography
          as='p'
          className='text-sm text-jaa-shades-gray-2.5 sm:text-lg text-normal '
        >
          We&apos;re excited to have you join the platform, To get started,
          simply complete the registration form below.
        </Typography>
      </div>
      <form onSubmit={handleSubmit} className='mb-16 flex-col flex gap-12'>
        {step === 1 && (
          <BasicDetails
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        )}
        {step === 2 && (
          <PasswordInfo
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        )}
        <Button
          loading={isSubmitting}
          type={step === 1 ? 'button' : 'submit'}
          onClick={() => {
            if (step !== 1) return
            goToNextStep()
          }}
          disabled={
            !dirty ||
            !isValid ||
            isAccepting ||
            values.password !== values.password_confirm
          }
          label={step === 1 ? 'NEXT' : 'REGISTER'}
          className='w-full !uppercase'
        />
      </form>
    </div>
  )
}

type INeeded = {
  values: AlumniUser & { password_confirm: string }
  errors: FormikErrors<AlumniUser & { password_confirm: string }>
  handleChange: (e: React.ChangeEvent<any>) => void
  handleBlur: (e: React.FocusEvent<any, Element>) => void
  touched: FormikTouched<AlumniUser & { password_confirm: string }>
}
const BasicDetails = (props: INeeded) => {
  const { values, errors, touched, handleBlur, handleChange } = props
  return (
    <div className='grid w-full grid-cols-2 grid-rows-4 gap-4 mb-8'>
      <FormGroup
        id='firstName'
        label='First Name'
        className='col-span-2 sm:col-span-1 '
      >
        <InputWithAdornment
          name='first_name'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.first_name}
          error={
            errors.first_name &&
            (touched.first_name as unknown as string) &&
            errors.first_name
          }
        />
      </FormGroup>
      <FormGroup
        id='lastName'
        label='Last Name'
        className='col-span-2 sm:col-span-1 sm:col-start-2'
      >
        <InputWithAdornment
          name='last_name'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.last_name}
          error={
            errors.last_name &&
            (touched.last_name as unknown as string) &&
            errors.last_name
          }
        />
      </FormGroup>
      <FormGroup
        id='email'
        label='Email'
        className='col-span-2 sm:col-span-1 sm:col-start-1'
      >
        <InputWithAdornment
          name='email'
          type='email'
          style={{
            height: '48px',
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          readOnly={!!values.email}
          error={
            errors.email && (touched.email as unknown as string) && errors.email
          }
        />
      </FormGroup>
      <FormGroup
        id='gender'
        label='Gender'
        className='col-span-2 sm:col-span-1 sm:col-start-2'
      >
        <SelectInput
          menuClassName='bg-white w-[230px]'
          placeholder='Select...'
          name='gender'
          className='select'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.gender}
          options={[
            { label: 'Male', value: 'male' },
            { label: 'Female', value: 'female' },
          ]}
          optionsText={(item) => item?.label}
          optionsValue={(item) => item?.value}
          error={
            errors.gender &&
            (touched.gender as unknown as string) &&
            errors.gender
          }
        />
      </FormGroup>
      <FormGroup id='phone' label='Phone Number' className='col-span-2'>
        <InputWithAdornment
          name='phone_number'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phone_number}
          error={
            errors.phone_number &&
            (touched.phone_number as unknown as string) &&
            errors.phone_number
          }
        />
      </FormGroup>
      <FormGroup id='country' label='Country' className='col-span-2'>
        <SelectInput
          menuClassName='bg-white w-[230px]'
          placeholder='Select...'
          name='country'
          onChange={handleChange}
          value={values.country}
          error={
            errors.country &&
            (touched.country as unknown as string) &&
            errors.country
          }
          options={[
            'Burkina Faso',
            'Côte d’Ivoire',
            'Democratic Republic of Congo',
            'Eswatini',
            'Gabon',
            'Ghana',
            'Kenya',
            'Mauritius',
            'Madagascar',
            'Nigeria',
            'Rwanda',
            'Senegal',
            'South Africa',
            'Tanzania',
            'Uganda',
            'Zambia',
            'Zimbabwe',
          ]}
          className='select'
        />
      </FormGroup>
      {/* <FormGroup
        id='program'
        label='Program'
        className='col-span-2 lg:col-span-1'
      >
        <SelectInput
          menuClassName='bg-white w-[240px] top-[0px] z-30 !max-h-[220px]'
          placeholder='Select...'
          name='program'
          onChange={handleChange}
          value={values.program}
          error={
            errors.program &&
            (touched.program as unknown as string) &&
            errors.program
          }
          className='select'
          options={[
            'Social Equity Project',
            'Passeport Pour Le Futur',
            'Company Program',
            'JA DEEP',
            'Google Career Certs Program',
            'Other',
          ]}
        />
      </FormGroup> */}
      {/* <FormGroup
        id='cohort'
        label='Cohort'
        className='col-span-2 lg:col-span-1'
      >
        <SelectInput
          disabled={values.program !== 'Social Equity Project'}
          menuClassName='bg-white w-[270px] top-[0px] z-30 !max-h-[220px]'
          placeholder='Select...'
          name='cohort'
          onChange={handleChange}
          value={values.cohort}
          error={
            errors.cohort &&
            (touched.cohort as unknown as string) &&
            errors.cohort
          }
          options={Array.from({ length: 100 }).map(
            (el, idx) => 'Cohort ' + (idx + 1)
          )}
          className='select'
        />
      </FormGroup> */}
    </div>
  )
}

const PasswordInfo = (props: INeeded) => {
  const { state: showPassword, toggle: toggleShow } = useToggleState()
  const { values, errors, touched, handleBlur, handleChange } = props
  return (
    <div className='w-full flex flex-col gap-4'>
      <FormGroup id='password' label='Create Password' className='w-full '>
        <InputWithAdornment
          name='password'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          error={
            (errors.password &&
              (touched.password as unknown as string) &&
              errors.password) ||
            ((touched.password as unknown as string) &&
              ((values.password !==
                values.password_confirm) as unknown as string) &&
              'Password does not match')
          }
          type={showPassword ? 'text' : 'password'}
          style={{
            background: '#F1F3F4',
            padding: '0px 12px',
          }}
          className='bg-[#F1F3F4] w-full'
          right={
            <span onClick={toggleShow} className=''>
              {showPassword ? (
                <iconComponents.util.EyeCloseIcon className='stroke-jaa-shades-black fill-jaa-shades-black' />
              ) : (
                <iconComponents.util.EyeOpenIcon className='stroke-jaa-shades-black fill-jaa-shades-black' />
              )}
            </span>
          }
        />
      </FormGroup>
      <FormGroup
        id='password_confirm'
        label='Confirm Password'
        className='w-full'
      >
        <InputWithAdornment
          name='password_confirm'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password_confirm}
          error={
            (errors.password_confirm &&
              (touched.password_confirm as unknown as string) &&
              errors.password_confirm) ||
            ((touched.password as unknown as string) &&
              ((values.password !==
                values.password_confirm) as unknown as string) &&
              'Password does not match')
          }
          type={showPassword ? 'text' : 'password'}
          style={{
            background: '#F1F3F4',
            padding: '0px 12px',
          }}
          className='bg-[#F1F3F4] w-full'
          right={
            <span onClick={toggleShow} className=''>
              {showPassword ? (
                <iconComponents.util.EyeCloseIcon className='stroke-jaa-shades-black fill-jaa-shades-black' />
              ) : (
                <iconComponents.util.EyeOpenIcon className='stroke-jaa-shades-black fill-jaa-shades-black' />
              )}
            </span>
          }
        />
      </FormGroup>
    </div>
  )
}

export default AlumniSignUp
