import { BackendToUI } from './../../pages/conversion'
import { UserTypes } from '@/apis/authApis'
import iconComponents from '@/assets/icons/iconComponents'

import { useAppSelector } from '@/store/hooks'
import { useMemo } from 'react'

import { MenuItem } from './dashboard.types'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

const MENU_ITEMS: Record<UserTypes, MenuItem[]> = {
  alumni: [
    {
      icon: iconComponents.navigation.DashboardIcon,
      name: 'Dashboard',
      url: '/alumni/app/dashboard',
    },
    {
      icon: iconComponents.navigation.RecruitementIcon,
      name: 'Recruitment',
      url: '/alumni/app/recruitment',
    },
    {
      icon: iconComponents.navigation.SkilltestIcon,
      name: 'Skill Tests',
      url: '/alumni/app/skill-test',
    },
    {
      icon: iconComponents.navigation.EngagementIcon,
      name: 'Engagements',
      url: '/alumni/app/engagement',
    },
    {
      icon: iconComponents.navigation.ForumIcon,
      name: 'Forums',
      url: '/alumni/app/forum',
    },
  ],
  recruiter: [
    {
      icon: iconComponents.navigation.DashboardIcon,
      name: 'Dashboard',
      url: '/recruiter/app/dashboard',
    },
    {
      icon: iconComponents.navigation.RecruitementIcon,
      name: 'Recruitment',
      url: '/recruiter/app/recruitment',
    },
    {
      icon: iconComponents.navigation.EngagementIcon,
      name: 'Engagements',
      url: '/recruiter/app/engagement',
    },
    {
      icon: iconComponents.navigation.AlumniIcon,
      name: 'Members',
      url: '/recruiter/app/member',
      key: 'recruiterMembers',
    },
  ],
  admin: [
    {
      icon: iconComponents.navigation.DashboardIcon,
      name: 'Dashboard',
      url: '/admin/app/dashboard',
    },
    {
      icon: iconComponents.navigation.RecruitersIcon,
      name: 'Recruiters',
      url: '/admin/app/recruiter',
    },
    {
      icon: iconComponents.navigation.AlumniIcon,
      name: 'Alumni',
      url: '/admin/app/alumni',
    },
    {
      icon: iconComponents.navigation.AuditlogsIcon,
      name: 'Audit Logs',
      url: '/admin/app/audit-logs',
    },
    {
      icon: iconComponents.navigation.ForumIcon,
      name: 'Forums',
      url: '/admin/app/forum',
    },
    {
      icon: iconComponents.navigation.ReportsIcon,
      name: 'Reports',
      url: '/admin/app/report',
    },
    {
      icon: iconComponents.navigation.SkilltestIcon,
      name: 'Skill Tests',
      url: '/admin/app/skill-test',
    },
  ],
}
// export const useMenuItems = () => {
//   const { t } = useTranslation()

//   const { user_type } = useAppSelector((state) => state.auth)
//   const userType: UserTypes = BackendToUI[user_type as 'alumnus'] as UserTypes

//   const menuItems = useMemo<MenuItem[]>(() => {
//     return MENU_ITEMS[userType] || []
//   }, [userType])
//   return {
//     menuItems,
//     userType,
//   } as const
// }
export const useMenuItems = () => {
  const { t } = useTranslation()
  const { user_type } = useAppSelector((state) => state.auth)
  const userType: UserTypes = BackendToUI[user_type as 'alumnus'] as UserTypes

  const menuItems = useMemo<MenuItem[]>(() => {
    return (MENU_ITEMS[userType] || []).map((menuItem) => {
      const trimedMenuItem = menuItem.name.replace(/\s+/g, '')
      const translatedName = t(`app.menu.${trimedMenuItem}`)
      return {
        ...menuItem,
        name: translatedName,
      }
    })
  }, [userType, t])

  return {
    menuItems,
    userType,
  } as const
}
