/* eslint-disable prettier/prettier */
import React, { useState } from 'react'

interface DataProps {
  data: any
  text: string
  setText: (e: any) => void
}

export const InputDataList = ({ data, text, setText }: DataProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const onChange = (event: any) => {
    setText(event?.target?.value)
  }

  return (
    <div className='flex items-center space-x-4 w-full'>
      <input
        type='search'
        list='list'
        autoComplete='on'
        value={text}
        onChange={onChange}
        className='w-full px-4 py-3 text-jaa-shades-black placeholder-jaa-dark-teal-60 bg-[#F1F3F4] rounded border-none focus:outline-none'
        placeholder='Select or Add an Industry'
      />
      <datalist id='list' className='w-full'>
        {data.map((d: any) => (
          <option className='bg-slate-600 p-2' key={d} value={d} />
        ))}
      </datalist>
    </div>
  )
}
