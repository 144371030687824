import { array, string, number, object } from 'yup'

const createCompanySchema = (countriesName: string[]) => {
  return object().shape({
    registration_number: string().min(5),
    country: string()
      .required()
      .oneOf(
        countriesName || [],
        'Enter a valid country name, name should be capitalize'
      ),
    name: string().min(2).required('Company Name is required'),
    company_role: string().min(2).required('Enter Your Role in Organization'),
    industry: string(),
    roles_hiring: array().of(string()),
    user_id: number().min(0),
  })
}

export default createCompanySchema
