import { AUDIT_LOGS } from './endpoints'
import api from './api'
import { PaginatedData } from './api.types'
import { addDataToQueryString } from '@/helpers/strings'

export type AuditPageDTO = {
  page: number
  search?: string
  datetime?: string
}
export type AuditLog = {
  id: number
  admin: Admin
  action: string
  subject_type?: any
  ipaddress: string
  subject_id?: any
  subject?: any
  date_logged: string
}

interface Admin {
  id: number
  user_id: number
  first_name: string
  last_name: string
  email: string
  phone_number: string
  profile_pic: string
  date_joined: string
  online: boolean
  last_seen: string
  user_type: string
  permission_role: string
  admin_role: string
}

export const getAuditLogs = async (payload: AuditPageDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<AuditLog> }>(
      AUDIT_LOGS + (qs ? `?${qs}` : '')
    )
  ).data
}

export const getAuditLog = async (id: number) => {
  return (await api.get<{ data: AuditLog }>(AUDIT_LOGS + `${id}/`)).data
}
