import React, { useEffect, useState } from 'react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import enTranslation from './locales/en.json'
import frTranslation from './locales/fr.json'
import {
  useAdminProfileQuery,
  useAlumniProfileQuery,
  useRecruiterProfileQuery,
} from './store/profileSlice'
import { useAppSelector } from './store/hooks'
import { Storage } from './helpers/Storage'
import { BackendToUI } from './pages/conversion'

function useI18n() {
  const [language, setLanguage] = useState<string>()
  const { user_type } = useAppSelector((state) => state.auth)
  const userData: any = useAppSelector((state) => state.auth)
  const { data: admin_profile } = useAdminProfileQuery(undefined, {
    skip: user_type !== 'admin',
  })
  const { data: alumni_profile } = useAlumniProfileQuery(userData.id, {
    skip: BackendToUI[user_type as 'alumnus'] !== 'alumni',
  })
  const { data: recruiter_profile } = useRecruiterProfileQuery(undefined, {
    skip: user_type !== 'recruiter',
  })

  const [preferredLanguage, setPreferredLanguage] = useState(
    Storage.getItem('PREFERRED_LANGUAGE') || 'en'
  )
  useEffect(() => {
    setLanguage(Storage.getItem('PREFERRED_LANGUAGE') || preferredLanguage)
  }, [user_type, Storage.getItem('PREFERRED_LANGUAGE')])

  React.useEffect(() => {
    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        resources: {
          en: {
            translation: enTranslation,
          },
          fr: {
            translation: frTranslation,
          },
        },
        lng: language,
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false,
        },
      })
    // refetch()
  }, [language])

  return i18n
}

export default useI18n
