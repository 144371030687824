import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { DashboardData, getDashboardData } from '@/apis/dashboardApis'

export const dashboardApiSlice = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Dashboard'],

  endpoints: (builder) => ({
    getDashboardData: builder.query<DashboardData, void>({
      queryFn: async () => {
        try {
          return await getDashboardData()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Dashboard'],
    }),
  }),
})

export const { useGetDashboardDataQuery } = dashboardApiSlice

export const resetDashboardApiSlice = () =>
  dashboardApiSlice.util.resetApiState()
