import iconComponents from '@/assets/icons/iconComponents'
import Typography from '@/common/Typography'
import React from 'react'

type IUSer = {
  name: string
  position: string
}
const UserRepresentation = (props: IUSer) => {
  return (
    <div className='flex flex-col justify-start items-center gap-4'>
      <div className='w-[72px] h-[72px] bg-jaa-teal-20 rounded-full sm:w-[96px] sm:h-[96px] flex justify-center items-center'>
        <iconComponents.util.UserIcon
          stroke='var(--teal-100)'
          className='w-[28px] h-[31px] sm:w-8 sm:h-h-9'
        />
      </div>
      <Typography as='h5' className='h5 text-jaa-shades-black sm:h4 capitalize'>
        {props.name}
      </Typography>
      <Typography
        as='p'
        className='md font-normal text-jaa-shades-gray-2.5 capitalize '
      >
        {props.position}
      </Typography>
    </div>
  )
}

export default UserRepresentation
