import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import { ToastNotify } from '@/common/toastManager'
import { generateId } from '@/common/toastManager/helpers'
import { isValidHttpUrl } from '@/helpers/strings'
import useToggleFields from '@/hooks/useToggleFields'
import clsx from 'clsx'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { FormField } from './form.type'
import { useTranslation } from 'react-i18next'

const Links = (props: FormField) => {
  const { t } = useTranslation()

  const { values, setFieldValue } = props
  const [isChangeInternal, setIsChangeInternal] = useState(false)
  const { fields, addField, setFields, removeField, updateField } =
    useToggleFields({ link: '', id: generateId(), error: '', touched: false })
  useEffect(() => {
    if (isChangeInternal || !values.links || !values.links.length) return
    setFields(
      values.links.map((link) => ({
        link,
        id: generateId(),
        error: '',
        touched: false,
      }))
    )
  }, [isChangeInternal])

  useEffect(() => {
    setFieldValue?.(
      'links',
      fields
        .filter((field) => isValidHttpUrl(field.link))
        .map((field) => field.link)
    )
  }, [fields])
  const changeHandler =
    (link: { link: string; id: string }) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e
      updateField({ link: value, error: '', touched: true }, link.id, 'id')
      setIsChangeInternal(true)
    }
  const blurHandler =
    (link: { link: string; id: string }) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      updateField(
        {
          touched: true,
          error: isValidHttpUrl(link.link)
            ? ''
            : `${t('app.form_labels.enter_valid_link')}`,
        },
        link.id,
        'id'
      )
    }
  const addHandler = () => {
    for (const field of fields) {
      if (!isValidHttpUrl(field.link)) {
        ToastNotify('warn', { message: 'Ensure All URL are valid' })
        return
      }
    }
    setIsChangeInternal(true)
    addField({ link: '', id: generateId(), error: '', touched: false }, 'link')
  }

  return (
    <FormGroup id='links' label={t('app.form_labels.external_link')}>
      <div className='flex flex-col w-full justify-start items-start gap-4'>
        <div className='flex flex-col gap-3 w-full pb-1'>
          {fields.map((link) => (
            <div
              key={link.id}
              className={clsx(
                'flex w-full justify-between items-center gap-4',
                fields.length === 1 ? '' : 'pr-2'
              )}
            >
              <div className='flex-1'>
                <InputWithAdornment
                  placeholder='http(s)://'
                  onChange={changeHandler(link)}
                  onBlur={blurHandler(link)}
                  value={link.link}
                  className='!h-11'
                  error={
                    link.error &&
                    (link.touched as unknown as string) &&
                    link.error
                  }
                />
              </div>
              {fields.length !== 1 && (
                <button
                  type='button'
                  className='p-1'
                  onClick={() => removeField(link.id, 'id')}
                >
                  <iconComponents.util.CancelIcon className='w-4 h-4 stroke-jaa-red-600' />
                </button>
              )}
            </div>
          ))}
        </div>
        <div className='flex justify-start'>
          <Button
            onClick={addHandler}
            className='!bg-jaa-dark-teal-100 rounded  py-1 px-6 !h-8'
            label={
              <span className='flex justify-center items-center gap-2 '>
                <iconComponents.util.AddIcon className='stroke-white fill-white' />
                <span>{t('app.button.add')}</span>
              </span>
            }
          />
        </div>
      </div>
    </FormGroup>
  )
}

export default Links
