import { POST } from '@/apis/forumApis'

import { useToggleState } from '@/hooks/useToggleState'
import {
  useDownVotePostMutation,
  useUpVotePostMutation,
} from '@/store/forumSlice'
import { useAppSelector } from '@/store/hooks'
import clsx from 'clsx'

import { FC } from 'react'
import { useNavigate } from 'react-router'
import FeedContent from '../../components/FeedContent'
import FeedHeader from '../../components/FeedHeader'
import Interaction from '../../components/Interaction'
import ReportButton from './ReportButton'
import RepostFeed from './RepostFeed'

type FeedCardProps = {
  post: POST
}
const FeedCard = ({
  post,
  onGalleryClick,
  url,
  showInteractions = true,
}: FeedCardProps & {
  onGalleryClick: (index: number) => void
  showInteractions?: boolean
  url?: string
}) => {
  return (
    <article className='flex w-full flex-col items-start gap-4 md:gap-1 rounded-lg shadow-[0px_1px_2px_0px_#1018280D] bg-jaa-shades-white py-5 px-4 md:p-[20px_24px_20px_16px]'>
      <FeedHeader post={post} />
      <FeedContent post={post} onGalleryClick={onGalleryClick} url={url} />
      <FeedAction post={post} showInteractions={showInteractions} />
    </article>
  )
}

const FeedAction: FC<FeedCardProps & { showInteractions: boolean }> = ({
  post,
  showInteractions,
}) => {
  const currentAlumni: any = useAppSelector((state) => state.auth)
  const [upvotePost] = useUpVotePostMutation()
  const [downvotePost] = useDownVotePostMutation()
  const {
    state: showShareModal,
    open: openShowShareModal,
    close: closeShowShareModal,
  } = useToggleState()
  const navigate = useNavigate()
  return (
    <>
      <footer
        className={clsx(
          'w-full flex justify-between items-center ',
          !showInteractions && '!justify-end'
        )}
      >
        {showInteractions && (
          <div className='flex items-center gap-5'>
            <Interaction
              text={post.num_comments}
              type='comment'
              onClick={() =>
                navigate(`/alumni/app/forum/${post.id}?type=${post.category}`)
              }
            />
            <Interaction
              text={post.num_upvotes}
              type='upvote'
              status={post.vote === 'upvoted'}
              onClick={() => upvotePost(post.id)}
            />
            <Interaction
              text={post.num_downvotes}
              type='downvote'
              status={post.vote === 'downvoted'}
              onClick={() => downvotePost(post.id)}
            />
            {post.category === 'general' && !!currentAlumni.cohort && (
              <Interaction
                text='Share'
                type='share'
                onClick={openShowShareModal}
              />
            )}
          </div>
        )}
        {post.user_id !== currentAlumni.user_id && (
          <ReportButton type='post' object_id={post.id} />
        )}
      </footer>
      <RepostFeed
        open={showShareModal}
        onClose={closeShowShareModal}
        post={post}
      />
    </>
  )
}

export default FeedCard
