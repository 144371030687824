import { COMMENT, Media, POST } from '@/apis/forumApis'
import iconComponents from '@/assets/icons/iconComponents'
import iconUrls from '@/assets/icons/iconUrls'
import Button from '@/common/button'

import Textarea from '@/common/Input/Textarea'
import LazySpinner from '@/common/spinners/LazySpinner'
import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import { capitalize } from '@/helpers/strings'
import { withAsync } from '@/helpers/withAsync'
import useMismatchPages from '@/hooks/table/useMismatchPages'
import useMediaQuery from '@/hooks/useMediaQuery'
import useStepper from '@/hooks/useStepper'
import { BackendToUI } from '@/pages/conversion'
import {
  useCreateCommentMutation,
  useDownVotePostMutation,
  useGetFeedCommentsQuery,
  useGetFeedQuery,
  useGetPostUpvotesQuery,
  useUpVotePostMutation,
} from '@/store/forumSlice'
import { useAppSelector } from '@/store/hooks'
import { format } from 'date-fns'
import React, {
  createElement,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useInView } from 'react-hook-inview'
import { useNavigate } from 'react-router'
import { BeatLoader, ScaleLoader } from 'react-spinners'
import CommentCard from './CommentCard'
import Interaction from '../../components/Interaction'
import ReportButton from './ReportButton'
import ExpandedFeedGallery from '../../components/ExpandedFeedGallery'
import { useTranslation } from 'react-i18next'
type IOpenable = {
  open: boolean
  data?: POST & { index: number }
  onClose: () => void
}
const FeedGalleryView = (props: IOpenable) => {
  const { open, data, onClose } = props

  return open && data ? (
    <section
      style={{
        zIndex: 100,
      }}
      className='fixed top-0 left-0 flex flex-col lg:flex-row items-start isolate bg-jaa-shades-white w-screen h-screen'
    >
      <ExpandedFeedGallery
        media={data.media}
        index={data.index}
        onClose={onClose}
      />
      <FeedDetail post={data} key={data.id} onClose={onClose} />
    </section>
  ) : (
    <></>
  )
}

const FeedDetail = (props: { post: POST; onClose: () => void }) => {
  const { post, onClose } = props
  const CommentInputRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  const { user_type } = useAppSelector((state) => state.auth)
  const goToPost = () => {
    navigate(`/${BackendToUI[user_type as 'alumnus']}/app/forum/${post.id}`)
    onClose()
  }

  return (
    <article className='flex w-full flex-col bg-jaa-shades-white h-2/3 md:h-1/2 lg:h-full lg:w-1/3 relative'>
      <header className='flex items-center py-3 px-6 gap-2 border-b border-[#d9d9d9]  w-full'>
        <img
          src={iconUrls.forum.CommentAltIcon}
          alt='representation of link to post'
          className='w-[19px] h-[16px]'
        />
        <Typography
          as='h1'
          className='flex-1 text-jaa-shades-gray-2.5 font-medium xs'
        >
          This photo is from a post.
        </Typography>
        <Button
          onClick={goToPost}
          variant='empty'
          label='View post'
          className='!p-0 !normal-case sm text-[#285f74]'
        />
      </header>
      <main className='flex-1 overflow-auto'>
        <PostPreview post={post} commentInputRef={CommentInputRef} />
        <UpVotes postId={post.id} onClose={onClose} />
        <Comments postId={post.id} />
      </main>
      <AddComment postId={post.id} commentInputRef={CommentInputRef} />
    </article>
  )
}

const PostPreview = ({
  post,
  commentInputRef,
}: {
  post: POST
  commentInputRef: MutableRefObject<any>
}) => {
  const { data: updatedPost } = useGetFeedQuery(post.id)
  const fullName = `${post.first_name} ${post.last_name}`
  const [upvotePost] = useUpVotePostMutation()
  const [downvotePost] = useDownVotePostMutation()
  return (
    <>
      <section className='w-full flex justify-between items-center border-b border-[#d9d9d9] py-2 px-6 gap-[33px]'>
        <header className='flex items-center gap-3'>
          <div className='w-11 h-11'>
            <img
              src={post.profile_pic || '/defaultImage.png'}
              alt={post.first_name}
              onError={(e) => {
                e.currentTarget.src = '/defaultImage.png'
                const target: any = e.target
                target.src = '/defaultImage.png'
                e.target = target
              }}
              className='w-full h-full max-h-full max-w-full object-cover object-center rounded-full'
            />
          </div>
          <hgroup className='flex flex-col items-start gap-0.5 flex-1'>
            <Typography
              as='h5'
              className='flex items-center gap-1 whitespace-nowrap'
            >
              <span className='capitalize font-semibold md text-jaa-black '>
                {fullName}
              </span>
            </Typography>
            <Typography
              as='p'
              className='h6 text-jaa-shades-gray-2.5 capitalize'
            >
              {post.cohort ? `Cohort ${post.cohort}` : post.program}
            </Typography>
          </hgroup>
        </header>
        <footer className='flex items-center justify-center'>
          <ReportButton object_id={post.id} type='post' />
        </footer>
      </section>
      <section className='w-full bg-jaa-shades-white mt-4 isolate flex flex-col items-start rounded-lg  md:gap-1 px-4 py-2 md:px-6'>
        <header className='flex flex-col items-start gap-2 md:p-[8px_0px_4px] md:gap-2.5 w-full'>
          <Typography
            as='h2'
            className='md font-semibold text-[#040404] w-full'
          >
            {post.title}
          </Typography>
        </header>
        <main className='w-full flex flex-col gap-1'>
          <p
            dangerouslySetInnerHTML={{ __html: post.body }}
            className='sm md:md font-normal text-jaa-shades-black'
          ></p>
        </main>
        <footer className='flex items-center py-3 gap-1'>
          <span className='w-1 h-1 rounded-full bg-jaa-shades-gray-2.5'></span>
          <time
            dateTime={post.created_at}
            className='font-normal sm text-jaa-shades-gray-2.5'
          >
            {Date.parse(post.created_at)
              ? format(new Date(post.created_at), 'h:mm aa, MMM dd, yyyy')
              : ''}
          </time>
        </footer>
        <div className='flex items-center gap-5'>
          <Interaction
            text={updatedPost?.num_comments || '0'}
            type='comment'
            onClick={() => {
              commentInputRef.current?.focus?.()
            }}
          />
          <Interaction
            text={updatedPost?.num_upvotes || '0'}
            type='upvote'
            status={updatedPost?.vote === 'upvoted'}
            onClick={() => upvotePost(post.id)}
          />
          <Interaction
            text={updatedPost?.num_downvotes || '0'}
            type='downvote'
            status={updatedPost?.vote === 'downvoted'}
            onClick={() => downvotePost(post.id)}
          />
        </div>
      </section>
    </>
  )
}

const UpVotes = ({
  postId,
  onClose,
}: {
  postId: number
  onClose: () => void
}) => {
  const { data: paginatedUpvotes, isLoading } = useGetPostUpvotesQuery({
    page: 1,
    postId: postId,
  })
  const getDescription = () => {
    if (!paginatedUpvotes) return ''
    if (!paginatedUpvotes?.count) return ''
    const [first, second, ...rest] = paginatedUpvotes.results || []
    return `${capitalize(first.first_name)} ${capitalize(first.last_name)}${
      second
        ? ', ' +
          `${capitalize(second.first_name)} ${capitalize(second.last_name)}`
        : ''
    } ${rest.length ? `and ${paginatedUpvotes.count - 2} others` : ''}`
  }
  const getFewVotes = () => {
    if (!paginatedUpvotes) return []
    if (!paginatedUpvotes?.count) return []
    return (paginatedUpvotes.results || []).slice(0, 3)
  }
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width: 600px)')
  const { user_type } = useAppSelector((state) => state.auth)

  const goToUpvote = () => {
    if (isMobile) {
      navigate(
        `/${BackendToUI[user_type as 'alumnus']}/app/forum/upvotes/${postId}`
      )
    } else {
      navigate(`/${BackendToUI[user_type as 'alumnus']}/app/forum/${postId}`)
    }
    onClose()
  }
  return (
    <section className='flex flex-col items-start gap-4 px-6'>
      <header className='flex items-center gap-2'>
        <iconComponents.forum.UpVoteIcon className='stroke-jaa-shades-black fill-transparent w-[36px] h-[36px]' />
        <Typography as='h5' className='font-medium text-jaa-shades-black lg'>
          Upvotes
        </Typography>
      </header>
      <main className='w-full gap-3 flex items-start'>
        {isLoading ? (
          ''
        ) : paginatedUpvotes?.count ? (
          <>
            <div className='flex items-start h-8'>
              {getFewVotes().map((vote) => (
                <img
                  className='w-8 h-8 rounded-full border-2 border-jaa-shades-white first-of-type:ml-0 -ml-2 object-cover object-center'
                  key={vote.profile_pic + vote.first_name}
                  src={vote.profile_pic || '/defaultImage.png'}
                  onError={(err) => {
                    err.currentTarget.src = '/defaultImage.png'
                    const target: any = err.target
                    target.src = '/defaultImage.png'
                    err.target = target
                  }}
                  alt='representation of voter'
                />
              ))}
            </div>
            <Typography
              as='p'
              className='text-jaa-shades-gray-2.5 font-normal sm flex-1'
            >
              {getDescription()}
            </Typography>
            <div>
              <Button
                onClick={goToUpvote}
                variant='empty'
                label='View'
                className='!p-0 !normal-case sm text-[#285f74] text-right'
              />
            </div>
          </>
        ) : (
          <small className='w-full text-center text-jaa-shades-gray-2.5 md'>
            {' '}
            No Upvotes has been made
          </small>
        )}
      </main>
    </section>
  )
}

const Comments = ({ postId }: { postId: number }) => {
  const [loadMoreRef, inView] = useInView()
  const [allComments, setAllComments] = useState<Array<COMMENT[]>>([])
  const { requestPage, setRequestPage } = useMismatchPages({
    requestPerPage: 20,
  })

  const {
    data: paginatedComments,
    isFetching,
    isLoading,
  } = useGetFeedCommentsQuery(
    {
      page: requestPage,
      postId: postId,
    },
    {
      skip: !postId,
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    if (!(inView && paginatedComments)) return
    const nextPage = paginatedComments.next
      ? new URL(paginatedComments.next).searchParams.get('page')
      : 0

    if (!nextPage) return

    setRequestPage(Number(nextPage))
  }, [inView, paginatedComments, setRequestPage])
  useEffect(() => {
    if (!paginatedComments) return
    setAllComments((prev) => {
      const newState = [...prev]
      newState[requestPage - 1] = paginatedComments.results
      const maxPages = Math.ceil(paginatedComments.count / 20)
      return newState.slice(0, maxPages)
    })
  }, [isLoading, paginatedComments, requestPage])

  const collapsedComments = allComments.flat(2)
  return isLoading ? (
    <LazySpinner show>
      <div className='w-full h-full min-h-[50vh] flex flex-col items-center justify-center bg-jaa-shades-white rounded-lg gap-4'>
        <ScaleLoader loading color='var(--teal-100)' />
      </div>
    </LazySpinner>
  ) : (
    <section className='w-full flex flex-col items-start gap-6 px-6 py-3'>
      <header className='flex flex-col items-start'>
        <hgroup className='flex items-center gap-2'>
          <iconComponents.forum.CommentIcon className='stroke-jaa-shades-black fill-jaa-shades-black  w-[36px] h-[36px]' />
          <Typography as='h5' className='font-medium text-jaa-shades-black lg'>
            Comments
          </Typography>
        </hgroup>
      </header>
      <main className='w-full flex flex-col gap-6'>
        {collapsedComments.map((el) => (
          <CommentCard comment={el} key={el.id} />
        ))}
        {isFetching ? (
          <div className='w-full flex justify-center items-center'>
            <BeatLoader color='var(--teal-100)' size={10} />
          </div>
        ) : null}{' '}
      </main>
      <div id='top' ref={loadMoreRef}></div>
    </section>
  )
}

const AddComment = ({
  postId,
  commentInputRef,
}: {
  postId: number
  commentInputRef: MutableRefObject<any>
}) => {
  const [addComment, { isLoading: isCreating }] = useCreateCommentMutation()
  const currentAlumni = useAppSelector((state) => state.auth)
  const [comment, setComment] = useState('')
  const createComment = async () => {
    if (!comment || !postId) return
    const { error, response } = await withAsync(() =>
      addComment({
        post: postId,
        body: comment,
      })
    )
    if (error) {
      return ToastNotify('error', {
        message: (error as any)?.message || 'Ooops... could not create comment',
      })
    }
    if (response) {
      setComment('')
    }
  }
  const { t } = useTranslation()

  return (
    <footer className='w-full bg-jaa-shades-white border-t border-[#d9d9d9] flex items-center py-4 px-6 order-3 self-stretch gap-4'>
      <div className='flex items-center gap-3 flex-1'>
        <img
          src={currentAlumni.profile_pic || '/defaultImage.png'}
          onError={(e) => {
            e.currentTarget.src = '/defaultImage.png'
            const target: any = e.target
            target.src = '/defaultImage.png'
            e.target = target
          }}
          className='w-8 h-8 rounded-full object-cover object-center'
          alt='representation of logged in user'
        />
        <div className='flex-1'>
          <Textarea
            placeholder={`${t('app.say_something')}`}
            ref={commentInputRef}
            value={comment}
            onChange={(e) => setComment(e.currentTarget.value)}
            style={{
              height: '37px',
            }}
            className='appearance-none !h-[37px] max-h-full focus:active:outline-none outline-none md !leading-5 px-4  border-none text-jaa-shades-black placeholder:jaa-dark-teal-60 bg-[#F1F3F4]'
          />
        </div>
      </div>
      <Button
        onClick={createComment}
        loading={isCreating}
        label={
          <span>
            <iconComponents.forum.SendIcon className='w-5 h-5 ' />
          </span>
        }
        className='!w-11 h-9 !stroke-jaa-shades-white '
      />
    </footer>
  )
}
export default FeedGalleryView
