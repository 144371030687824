import { MemberType, RecruiterSignupDTO } from '@/apis/authApis'
import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button'

import InputWithAdornment from '@/common/Input/InputWithAdornment'
import SelectInput from '@/common/Input/SelectInput'
import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import { isValidEmail } from '@/helpers/strings'
import { withAsync } from '@/helpers/withAsync'
import useToggleFields from '@/hooks/useToggleFields'
import { useRecruiterInviteMembersMutation } from '@/store/authSlice'
import clsx from 'clsx'

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { INest } from './user.type'

// const initialState: RecruiterSignupDTO & {
//   full_name: string
// } = {
//   email: '',
//   password: '',
//   first_name: '',
//   last_name: '',
//   gender: '' as 'male',
//   phone_number: '',
//   full_name: '',
// }
const generateField = () => Math.random().toString(16).substring(2, 22)
const AddMembers = (props: INest) => {
  const { pathname } = useLocation()
  const { goToNextStep, step } = props
  const navigate = useNavigate()
  const [inviteMembers, { isLoading: isSending }] =
    useRecruiterInviteMembersMutation()
  const { fields, updateField, removeField, addField } = useToggleFields({
    email: '',
    role: 'member',
    id: generateField(),
  })

  const sendInviteHandlers = async () => {
    if (!isValid()) {
      return ToastNotify('warn', {
        message: 'Please Ensure all emails provided are valid',
      })
    }
    const { error } = await withAsync(() =>
      inviteMembers({
        members: fields
          .filter((el) => isValidEmail(el.email))
          .map((el) => ({
            role: el.role as MemberType,
            email: el.email,
          })),
      }).unwrap()
    )
    if (error) {
      return ToastNotify('error', {
        message: (error as any)?.message || "Ooops... Couldn't add members",
      })
    }
    goToNextStep()
    navigate(pathname + '?page=' + (step + 1), {
      replace: true,
    })
    ToastNotify('success', {
      message: 'Invitation has been sent successfully',
    })
  }

  const isValid = () => {
    const field = fields.find((field) => !isValidEmail(field.email))
    if (field) {
      if (field.email?.trim?.()) return false
      return true
    }
    return true
  }

  return (
    <div className='w-full flex flex-col gap-4 justify-start items-start'>
      {' '}
      <header className='flex flex-col gap-1 w-full'>
        <Typography as='h2' className='h3 text-jaa-shades-black  sm:h2'>
          Add your colleagues to your team
        </Typography>
        <Typography as='p' className='text-jaa-shades-gray-2.5 font-normal sm'>
          Unlock the power of collaboration and stay connected with your
          professional network.
        </Typography>
      </header>
      <div className='w-full flex flex-col gap-y-5 justify-start items-start  '>
        {fields.map((field, idx) => {
          return (
            <MemberInfo
              email={field.email}
              role={field.role as MemberType}
              key={field.id}
              removeField={() => removeField(field.id, 'id')}
              updateField={updateField}
              id={field.id}
              isInitial={fields.length === 1}
            />
          )
        })}
      </div>
      <Button
        variant='empty'
        onClick={() =>
          addField({
            email: '',
            role: 'member',
            id: generateField(),
          })
        }
        className='border rounded w-full h-auto mt-10'
        label={
          <span className='flex justify-center items-center gap-1.5 text-jaa-shades-black !uppercase sm font-semibold '>
            <iconComponents.util.AddIcon className='stroke-jaa-shades-black' />
            <span>ADD MEMEBER</span>
          </span>
        }
        fullWidth
      />
      <Button
        label='Continue'
        className='w-full !uppercase'
        disabled={!isValid()}
        loading={isSending}
        onClick={sendInviteHandlers}
      />
      <div className='w-full justify-center items-center flex'>
        <Button
          onClick={goToNextStep}
          label='SKIP'
          variant='empty'
          className='mt-6 !uppercase'
        />
      </div>
    </div>
  )
}

type IMemberProps = {
  email: string
  role: MemberType
  removeField: () => void
  updateField: (update: any, fieldValue: string, key: string) => void
  isInitial?: boolean
  id: string
}
const MemberInfo = (props: IMemberProps) => {
  const [error, setError] = useState('')
  const {
    role = 'member',
    email,
    removeField,
    isInitial,
    updateField,
    id,
  } = props
  const [member, setMember] = useState({
    role,
    email,
  })
  const handleChange = (e: any) => {
    const { value, name } = e.currentTarget
    setMember((prev) => ({
      ...prev,
      [name]: value,
    }))
    setError('')
  }
  useEffect(() => {
    updateField(
      {
        ...member,
      },
      id,
      'id'
    )
  }, [member, id])

  const blurHandler = (e: any) => {
    if (!isValidEmail(member.email)) {
      setError('Please Enter a valid email')
    } else {
      setError('')
    }
  }
  return (
    <div className='w-full grid grid-cols-12 gap-4 grid-rows-1 items-center'>
      <div
        className={clsx('!h-[53px]', isInitial ? 'col-span-9' : 'col-span-7')}
      >
        <InputWithAdornment
          name='email'
          type='email'
          onBlur={blurHandler}
          onChange={handleChange}
          className='w-full !h-[53px]'
          error={error && error}
        />
      </div>
      <div className='col-span-3'>
        <SelectInput
          style={{
            width: '100%',
          }}
          menuClassName='bg-white w-[140px]'
          className={'select  !h-[53px] '}
          options={[
            {
              value: 'member',
              label: 'Member',
            },
            {
              value: 'secondary_admin',
              label: 'Admin',
            },
          ]}
          optionsText={(el) => {
            return el.label
          }}
          optionsValue={(el) => el.value}
          name='recruiter_role'
          value={member.role}
          onChange={handleChange}
        />
      </div>
      {!isInitial && (
        <button
          onClick={removeField}
          className='flex justify-center items-center bg-jaa-red-50 w-[38px] h-[38px] rounded-full col-span-2'
        >
          <iconComponents.util.DeleteIcon className='fill-jaa-red-600' />
        </button>
      )}
    </div>
  )
}

export default AddMembers
