import { useAppSelector } from '@/store/hooks'

import UserWelcome from '@/pages/auth/components/UserWelcome'

import { useNavigate } from 'react-router'
import Button from '@/common/button'
import DoneCreating from '@/assets/images/checkmark.gif'
const AlumniCreateAccount = () => {
  const navigate = useNavigate()

  return (
    <div className='p-4 sm:px-[20%] flex flex-col lg:px-[30%] gap-14'>
      <div className='flex justify-center items-center'>
        <img
          src={DoneCreating}
          alt='Successfully Created Account'
          className='rounded-full w-[250x] h-[250px]'
        />
      </div>
      <UserWelcome
        welcomeText='Account Created'
        userType=''
        description={`Awesome, you can now access job postings and network with other alumni.
         We can't wait to see what you will achieve with this platform `}
      />
      <Button
        className='!uppercase'
        fullWidth
        label="Let's Get Started"
        onClick={() => navigate('/alumni/auth/sign-in')}
      />
    </div>
  )
}

export default AlumniCreateAccount
