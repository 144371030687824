import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button'
import RadioButton from '@/common/Input/RadioButton'
import Modal from '@/common/modal/Modal'
import ModalHeader from '@/common/modal/ModalHeader'
import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import { withAsync } from '@/helpers/withAsync'
import { useOutsideClick } from '@/hooks/useOutsideClick'
import useStepper from '@/hooks/useStepper'
import { useToggleState } from '@/hooks/useToggleState'
import { useReportContentMutation } from '@/store/forumSlice'
import { useMemo, useRef, useState } from 'react'
import { ReasonOptions, ReportingForOptions } from '../../constants'
import { useTranslation } from 'react-i18next'

type ReportProps = {
  onComplete?: () => void
  type: 'post' | 'comment'
  object_id: number
}

const ReportButton = (props: ReportProps) => {
  const { type, object_id, onComplete } = props
  const { t } = useTranslation()

  const {
    state: showMore,
    open: openShowMore,
    close: closeShowMore,
  } = useToggleState()
  const {
    state: showModal,
    open: openShowModal,
    close: closeShowModal,
  } = useToggleState()
  const OptionsRef = useRef<HTMLUListElement>(null)
  useOutsideClick([OptionsRef], closeShowMore)

  return (
    <>
      <div className='relative '>
        <button type='button' onClick={openShowMore} className='p-2'>
          <iconComponents.util.MoreHorizIcon className='stroke-[#040404] fill-[#040404] ' />
        </button>
        {showMore && (
          <ul
            ref={OptionsRef}
            className='absolute marker:content-[""] shadow-md bg-jaa-shades-white rounded-lg w-[180px] top-2.5 right-0'
          >
            <li
              onClick={() => {
                closeShowMore()
                openShowModal()
              }}
              className='text-jaa-red-500 cursor-pointer hover:bg-jaa-red-50 py-1 px-4 h-[33px] font-normal sm w-full capitalize whitespace-nowrap'
            >
              {`${t('app.forum.report_button')} ${type}`}
            </li>
          </ul>
        )}
        <ReportModal
          open={showModal}
          onComplete={onComplete}
          onClose={closeShowModal}
          type={type}
          object_id={object_id}
        />
      </div>
    </>
  )
}

type IReport = {
  open: boolean
  onClose: () => void
}

const ReportModal = (props: IReport & ReportProps) => {
  const [reportContent, { isLoading: isReporting }] = useReportContentMutation()
  const { t } = useTranslation()
  const {
    state: showSuccesModal,
    close: closeShowSuccesModal,
    open: openShowSuccessModal,
  } = useToggleState()
  const { step, goToNextStep, goToPrevStep } = useStepper(1)
  const { open, onClose, type, object_id, onComplete } = props
  const [optionsSelected, setOptionsSelected] = useState<{
    reported_for: 'myself' | 'someone_else' | 'specific_group' | 'everyone'
    reason: 'identity' | 'violence' | 'spam' | 'self_harm' | 'deceptive'
  }>({
    reported_for: 'myself',
    reason: 'identity',
  })
  const stepOne = useMemo(
    () => (
      <section className='flex flex-col items-start gap-4 rounded-lg'>
        <Typography as='h5' className='text-jaa-shades-black lg font-medium'>
          {t('app.forum.reporting_for_label')},
        </Typography>
        <main className='flex flex-col gap-4 w-full'>
          {ReportingForOptions.map((option) => (
            <div className='w-full' key={option.value}>
              <RadioButton
                name='reported_for'
                label={option.label}
                id={option.value}
                checked={option.value === optionsSelected.reported_for}
                onChange={({ currentTarget: { id, checked } }) => {
                  setOptionsSelected((prev) => ({
                    ...prev,
                    reported_for: id as any,
                  }))
                }}
                checkComponent={
                  <iconComponents.forum.CheckedRadioIcon className='w-10 h-10' />
                }
                uncheckedComponent={
                  <iconComponents.forum.UnCheckedRadioIcon className='w-8 h-8' />
                }
              />
            </div>
          ))}
        </main>
      </section>
    ),
    [optionsSelected.reported_for]
  )
  const stepTwo = useMemo(
    () => (
      <section className='flex flex-col items-start gap-4 rounded-lg flex-1 overflow-auto'>
        <main className='flex flex-col gap-4 w-full'>
          {ReasonOptions.map((option) => (
            <div className='w-full' key={option.value}>
              <RadioButton
                name='reason'
                customLabel={
                  <span className='flex flex-col gap-0.5 w-full'>
                    <span className='text-jaa-shades-black font-semibold md'>
                      {option.label}
                    </span>
                    <span className='font-normal md text-jaa-shades-black'>
                      {option.subText}
                    </span>
                  </span>
                }
                id={option.value}
                checked={option.value === optionsSelected.reason}
                onChange={({ currentTarget: { id, checked } }) => {
                  setOptionsSelected((prev) => ({
                    ...prev,
                    reason: id as any,
                  }))
                }}
                checkComponent={
                  <iconComponents.forum.CheckedRadioIcon className='w-10 h-10' />
                }
                uncheckedComponent={
                  <iconComponents.forum.UnCheckedRadioIcon className='w-8 h-8' />
                }
              />
            </div>
          ))}
        </main>
      </section>
    ),
    [optionsSelected.reason]
  )
  const stepHeaders = [
    `${t('app.forum.stepheaders_label1')}`,
    `${t('app.forum.stepheaders_label2')}`,
  ]
  const stepSubHeaders = [`${t('app.forum.stepheaders_desc')}`, , '']
  const stepsForwardText = [
    `${t('app.button.next')}`,
    `${t('app.button.submit')}`,
  ]
  const stepsBackwardText = [
    `${t('app.button.cancel')}`,
    `${t('app.button.back')}`,
  ]
  const stepsView = [stepOne, stepTwo]

  const reportHandler = async () => {
    const { error, response } = await withAsync(() =>
      reportContent({
        object_id,
        content: type,
        reason: optionsSelected.reason,
        reported_for: optionsSelected.reported_for,
      }).unwrap()
    )
    if (error) {
      return ToastNotify('error', {
        message:
          (error as any)?.message ||
          `${t('app.forum.report_error_msg')} ` + type,
      })
    }
    if (response?.data) {
      onClose()
      openShowSuccessModal()
      onComplete?.()
    }
  }
  return (
    <>
      <Modal
        reEvaluate
        open={open}
        onClose={onClose}
        variant='md'
        className='p-4 md:p-6'
      >
        <ModalHeader
          title={stepHeaders[step - 1]}
          onClose={onClose}
          subText={stepSubHeaders[step - 1]}
        />
        {stepsView[step - 1]}
        <footer className='flex w-full items-center gap-2'>
          <Button
            className='min-w-[191px] flex-1 md:flex-none !uppercase'
            label={stepsForwardText[step - 1]}
            onClick={step === 1 ? goToNextStep : reportHandler}
            disabled={isReporting}
            loading={isReporting}
          />
          <Button
            variant='cancel'
            disabled={isReporting}
            label={stepsBackwardText[step - 1]}
            onClick={step === 1 ? onClose : goToPrevStep}
            className='min-w-[191px] flex-1 md:flex-none '
          />
        </footer>
      </Modal>
      <Modal
        open={showSuccesModal}
        onClose={closeShowSuccesModal}
        variant='md'
        className='p-4 md:p-6'
      >
        <ModalHeader onClose={closeShowSuccesModal} />
        <main className='w-full'>
          <hgroup className='flex flex-col items-start gap-6 w-full'>
            <Typography as='h3' className='text-jaa-shades-black font-medium'>
              {t('app.forum.report_success_msg_title')}
            </Typography>
            <Typography as='p' className='text-jaa-shades-black font-normal'>
              {t('app.forum.report_success_msg_desc')}
            </Typography>
          </hgroup>
        </main>
        <footer className='w-full'>
          <Button
            variant='cancel'
            label={`${t('app.forum.cancel_report')}`}
            className='!uppercase xs w-[224px] my-4'
            onClick={closeShowSuccesModal}
          />
        </footer>
      </Modal>
    </>
  )
}

export default ReportButton
