import ModalHeader from '@/common/modal/ModalHeader'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'
import Modal from './Modal'
import { NotificationCard } from './NotificationCard'
import {
  invalidateNotificationApiSlice,
  useGetNotificationQuery,
  useMarkNotificationAsReadMutation,
} from '@/store/notificationSlice'
import { INotifications } from '@/apis/notificationApis'
import { getAccessTokens } from '@/store/authSlice'
import { WEB_SOCKET_URL } from '@/constants/api'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { useTranslation } from 'react-i18next'

type IViewProps = {
  open: boolean
  onClose: () => void
}
const NotificationsList = (props: IViewProps) => {
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation()
  const { t } = useTranslation()
  const { open, onClose } = props
  const appDispatch = useAppDispatch()
  const { data } = useGetNotificationQuery()
  const token = useAppSelector(getAccessTokens)
  useEffect(() => {
    const client = new W3CWebSocket(`${WEB_SOCKET_URL}/${token}/notifications/`)

    client.onopen = () => {
      console.log('WebSocket Client Connected TO notifications')
    }
    client.onmessage = (ev: any) => {
      let dataFromServer
      console.log(ev.data, 'RECEIVED')
      appDispatch(invalidateNotificationApiSlice(['Notifications']))
    }

    return () => {
      client.close()
    }
  }, [])

  useEffect(() => {
    if (open) {
      markNotificationAsRead()
    }
  }, [open])
  const closeHandler = () => {
    appDispatch(invalidateNotificationApiSlice(['Notifications']))
    onClose()
  }
  const notifications = useMemo(
    () =>
      (data?.results || [])
        .map((el, idx) => ({
          ...el,
        }))
        .sort(
          (a, b) =>
            Number(new Date(b.timestamp)) - Number(new Date(a.timestamp))
        ),

    [data?.results]
  )
  return (
    <Modal blur={false} open={open} onClose={closeHandler}>
      <section className='h-[89vh] bg-jaa-shades-white lg:h-[642px] rounded-lg shadow-[0px_1px_2px_0px_#1018280D] flex  flex-col items-end py-3 px-4 gap-8 w-[350px]   '>
        <ModalHeader
          title={t('app.notifications')}
          onClose={closeHandler}
          subText={t('app.filter.today')}
        />
        <div className='overflow-y-auto pb-4 scrollbar scrollbar-w-1 scrollbar-track-rounded-lg scrollbar-thumb-rounded-lg scrollbar-thumb-jaa-dark-teal-80 scrollbar-track-gray-100 mb-4'>
          {notifications?.map((notification: INotifications, idx: number) => (
            <NotificationCard
              tag={notification?.tag}
              title={notification.title}
              message={notification?.message}
              timestamp={notification?.timestamp}
              onClose={closeHandler}
              unread={notification?.unread}
              key={idx}
            />
          ))}
        </div>
      </section>
    </Modal>
  )
}
export default NotificationsList
