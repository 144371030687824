/* eslint-disable no-useless-escape */
import { lazy, mixed, object, string } from 'yup'

export const format = /^[\!@\#\$\%\^\&\*\_\(\)\-\+\=\~\`\.\w]*$/
export const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/
const signUpSchema = (step: number) =>
  object().shape({
    email: string()
      .typeError('Email must be string')
      .min(2)
      .email()
      .required('Email is a required field'),
    // cohort: mixed().notRequired(),
    phone_number: string()
      .min(9)
      .matches(/^\+?[01-9]+$/, 'Phone number is not valid'),
    country: string().required(),
    // program: string().required(),
    first_name: string().required(),
    last_name: string().required(),
    gender: string().required().oneOf(['male', 'female']),
    password: lazy(() => {
      if (step > 1) {
        return string()
          .required('Password is required')
          .matches(
            passwordFormat,
            'Password must include both lower and upper case characters, include at least one number, include at least one special character, be at least 8 characters long.'
          )
          .test(
            'special-chars',
            'Password must contain special characters',
            function (value) {
              return format.test(value as unknown as string)
            }
          )
          .strict(true)
      }
      return mixed().notRequired()
    }),
    password_confirm: lazy(() => {
      if (step > 1) {
        return string().min(5).required('Enter Password that matches')
      }
      return mixed().notRequired()
    }),
  })

export default signUpSchema
