import iconComponents from '@/assets/icons/iconComponents'

import { Link } from 'react-router-dom'
import { useMenuItems } from '../../dashboard.hooks'
import Chats from './Chats'

import MobileMenu from './MobileMenu'
import Notifications from './Notifications'
import TabletMenu from './TabletMenu'
import Image from '@/common/Image'

const Header = () => {
  const { menuItems, userType } = useMenuItems()

  return (
    <>
      <header className='w-full min-w-0 flex justify-between px-5 py-2.5 items-center max-h-12 gap-[86px] sm:py-3 sm:px-8 sm:max-h-[76px] lg:max-h-[64px] lg:border-b border-b-jaa-shades-gray-1 lg:justify-end '>
        <Link
          to={`/${userType}/app/dashboard`}
          className='active:scale-95 lg:hidden'
        >
          {/* <iconComponents.util.LogoIcon className='w-[74px] h-[37px] sm:w-[92px] sm:h-[47px]' /> */}
          <Image
            src='/Logo_Full Color.png'
            className='w-[134px] h-[37px] sm:min-w-[140px] sm:h-[38px] object-cover object-center'
          />
        </Link>
        <div className='hidden flex-1 sm:flex lg:hidden '>
          <TabletMenu menuOptions={menuItems} />
        </div>
        <div className='hidden lg:flex items-center justify-end gap-9 '>
          {userType !== 'admin' && <Chats />}
          <Notifications />
        </div>
        <div className='sm:hidden'>
          <MobileMenu menuOptions={menuItems} />
        </div>
      </header>
    </>
  )
}

export default Header
