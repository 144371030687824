import { RecruiterMemberSignupDTO } from '@/apis/authApis'
import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import SelectInput from '@/common/Input/SelectInput'
import Title from '@/common/Title'

import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import composeEventHandlers from '@/helpers/composeEventHandlers'
import { isValidEmail } from '@/helpers/strings'
import { withAsync } from '@/helpers/withAsync'
import useQueryParams from '@/hooks/useQueryParams'
import { useToggleState } from '@/hooks/useToggleState'
import memeberSchema from '@/pages/auth/validationschemas/memberrecruiter.validator'

import { useRecruiterAcceptMembersMutation } from '@/store/authSlice'
import { FormikHelpers, useFormik } from 'formik'
import { ChangeEvent, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

const initialState: RecruiterMemberSignupDTO & {
  first_name: string
  last_name: string
} = {
  password: '',
  company_role: '',
  phone_number: '',
  gender: '' as 'male',
  name: '',
  code: '',
  first_name: '',
  last_name: '',
}
const MemberAcceptInvitation = (props: {}) => {
  const [recruiterSignUp, { isLoading: isSending }] =
    useRecruiterAcceptMembersMutation()
  const { code } = useParams()
  const { company } = useQueryParams()
  const { state: showPassword, toggle: toggleShow } = useToggleState(false)

  const navigate = useNavigate()
  const submitHandler = async (
    values: RecruiterMemberSignupDTO & {
      first_name: string
      last_name: string
    },
    {
      setSubmitting,
      resetForm,
    }: FormikHelpers<
      RecruiterMemberSignupDTO & {
        first_name: string
        last_name: string
      }
    >
  ) => {
    if (!values.code) {
      return ToastNotify('error', {
        message:
          'Kindly Revisit the page by clicking on the link send to your email',
      })
    }
    const { first_name, last_name, ...other } = values
    const { error, response } = await withAsync(() =>
      recruiterSignUp({
        ...other,
      }).unwrap()
    )

    if (error) {
      return ToastNotify('error', {
        message: (error as any).message || 'Ooops... Could not create account',
      })
    }
    const { data } = response || {}
    navigate('/recruiter/auth/sign-in', {
      replace: true,
    })
    setSubmitting(false)
    resetForm()
  }
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    isValid,
    setFieldError,
    setFieldValue,
    submitForm,
  } = useFormik<
    RecruiterMemberSignupDTO & {
      first_name: string
      last_name: string
    }
  >({
    initialValues: initialState,
    onSubmit: submitHandler,
    validationSchema: memeberSchema,
  })

  useEffect(() => {
    setFieldValue('code', code)
  }, [code])
  const handleFullName = (e: ChangeEvent<HTMLInputElement>) => {
    const letters = e.currentTarget.value
    const words = letters.match(/\b\w+\b/g)

    if (words && words.length < 2) {
      const firstName = words[0]

      if (firstName) {
        setFieldValue('first_name', firstName)
        setFieldError('first_name', undefined)
        setFieldValue('last_name', '', true)
      }
      return
    } else if (words && words.length >= 2) {
      const firstName = words[0]
      const lastName = words[1]
      if (firstName) {
        setFieldValue('first_name', firstName, true)
        setFieldError('first_name', undefined)
      }
      if (lastName) {
        setFieldValue('last_name', lastName, true)
        setFieldError('last_name', undefined)
      }
    } else {
      setFieldError('last_name', 'Last name is required')
      setFieldError('first_name', 'First name is required')
      setFieldValue('first_name', '', true)
      setFieldValue('last_name', '', true)
    }
  }
  const isTouched = () => {
    return Object.values(touched).filter((touch) => touch).length > 0
  }
  return (
    <div className='px-4 w-full flex flex-col gap-10 justify-start items-center sm:px-[19%] mb-12 overflow-scroll'>
      <Title>JA Africa - Create Member </Title>
      <div className='w-full flex flex-col gap-4 justify-start items-start'>
        {' '}
        <header className='flex flex-col gap-1 w-full'>
          <Typography as='h2' className='h3 text-jaa-shades-black  sm:h2'>
            Member Sign Up
          </Typography>
          <Typography
            as='p'
            className='text-jaa-shades-gray-2.5 font-normal sm'
          >
            You have been invited to be a member of the{' '}
            <Typography
              as='strong'
              className='font-medium text-jaa-shades-black'
            >
              ({company})
            </Typography>{' '}
            recruitment team.
          </Typography>
        </header>
        <form
          className='grid grid-rows-5 grid-cols-1 w-full gap-y-4'
          onSubmit={handleSubmit}
        >
          <FormGroup id='fullNmae' label='Full Name' className='col-span-1 '>
            <InputWithAdornment
              name='name'
              onChange={handleChange}
              onBlur={composeEventHandlers(handleFullName, handleBlur)}
              value={values.name}
              error={
                ((!values.first_name ||
                  !values.last_name) as unknown as string) &&
                (touched.name as unknown as string) &&
                'Enter First and Last name'
              }
            />
          </FormGroup>
          <FormGroup id='phone_no' label='Phone Number' className='col-span-1 '>
            <InputWithAdornment
              name='phone_number'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone_number}
              error={
                errors.phone_number &&
                (touched.phone_number as unknown as string) &&
                errors.phone_number
              }
            />
          </FormGroup>
          <FormGroup
            id='company_role'
            label='Company role'
            className='col-span-1 '
          >
            <InputWithAdornment
              name='company_role'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.company_role}
              error={
                errors.company_role &&
                (touched.company_role as unknown as string) &&
                errors.company_role
              }
            />
          </FormGroup>
          <FormGroup id='gender' label='Gender' className='col-span-1'>
            <SelectInput
              menuClassName='bg-white w-[230px]'
              placeholder='Select...'
              name='gender'
              className='select w-full'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.gender}
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
              ]}
              optionsText={(item) => item?.label}
              optionsValue={(item) => item?.value}
              error={
                errors.gender &&
                (touched.gender as unknown as string) &&
                errors.gender
              }
            />
          </FormGroup>
          <FormGroup
            id='password'
            label='Create Password'
            className='col-span-1 '
          >
            <InputWithAdornment
              name='password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={
                errors.password &&
                (touched.password as unknown as string) &&
                errors.password
              }
              type={showPassword ? 'text' : 'password'}
              style={{
                background: '#F1F3F4',
                padding: '0px 12px',
              }}
              className='bg-[#F1F3F4] w-full'
              right={
                <span onClick={toggleShow} className=''>
                  {showPassword ? (
                    <iconComponents.util.EyeCloseIcon className='stroke-jaa-shades-black fill-jaa-shades-black' />
                  ) : (
                    <iconComponents.util.EyeOpenIcon className='stroke-jaa-shades-black fill-jaa-shades-black' />
                  )}
                </span>
              }
            />
          </FormGroup>
          <Button
            loading={isSending}
            disabled={(isTouched() && !isValid) || isSending}
            label='Continue'
            className='mt-6'
            type='submit'
          />
        </form>
        <Typography
          as='span'
          className='md text-jaa-shades-gray-2.5 text-center w-full my-8'
        >
          Already have an account?{' '}
          <Typography
            role='link'
            as='button'
            className='text-jaa-teal-100  font-semibold hover:text-jaa-teal-80 capitalize hover:underline '
            onClick={() => {
              navigate('/recruiter/auth/sign-in')
            }}
          >
            {' '}
            Sign In
          </Typography>
        </Typography>
      </div>
    </div>
  )
}

const redactEmail = (email: string, size = 5) => {
  const firstThreeChar = email.substring(0, size)
  return (
    firstThreeChar + '***@***.' + email.split('.')[email.split('.').length - 1]
  )
}

export default MemberAcceptInvitation
