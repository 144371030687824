import Typography from '@/common/Typography'
import { FormikHelpers, useFormik } from 'formik'
import { AuthUser, SigninDTO } from '@/apis/authApis'
import signInSchema from '../../validationschemas/signin.validator'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import Button from '@/common/button'
import { setCredentials, useSignInMutation } from '@/store/authSlice'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useLocation, useNavigate } from 'react-router'
import { withAsync } from '@/helpers/withAsync'
import { ToastNotify } from '@/common/toastManager'
import { useToggleState } from '@/hooks/useToggleState'
import ForgotPassword from './ForgotPassword'
import { BackendToUI } from '@/pages/conversion'
import iconComponents from '@/assets/icons/iconComponents'
import { useTranslation } from 'react-i18next'

const initialState: SigninDTO = {
  email: '',
  password: '',
}

const AlumniSignIn = () => {
  const { t } = useTranslation()

  const {
    open: openModal,
    close: closeModal,
    state: isModalOpen,
  } = useToggleState(false)
  const { state: showPassword, toggle: toggleShowPassword } =
    useToggleState(false)
  const { state: RouteState } = useLocation()
  const { from } = RouteState ?? {}

  const navigate = useNavigate()
  const [signIn, { isLoading }] = useSignInMutation()
  const submitHandler = async (
    values: SigninDTO,
    { setSubmitting, resetForm }: FormikHelpers<SigninDTO>
  ) => {
    const { error, response } = await withAsync(() =>
      signIn({ ...values, user_type: 'alumnus' }).unwrap()
    )
    if (error) {
      return ToastNotify('error', {
        message: (error as any).message,
      })
    }
    const { data } = response || {}
    resetForm()
    setSubmitting(false)
    navigate(
      `/${
        BackendToUI[data?.user_type as 'alumnus'] || 'alumni'
      }/success/welcome`,
      {
        replace: true,
      }
    )
  }
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    isValid,
  } = useFormik<SigninDTO>({
    initialValues: initialState,
    onSubmit: submitHandler,
    validationSchema: signInSchema,
  })
  return (
    <div className='px-4 w-full flex flex-col gap-6 sm:gap-10 justify-start items-center sm:px-[15%] mb-12'>
      <header className='flex flex-col gap-1 w-full'>
        <Typography as='h2' className='h3 text-jaa-shades-black  sm:h2'>
          {t('app.auth.signin')}
        </Typography>
        <Typography as='p' className='text-jaa-shades-gray-2.5 font-normal sm'>
          {t('app.auth.alumni_signin_desc')}
        </Typography>
      </header>
      <form
        onSubmit={handleSubmit}
        className='grid grid-rows-3 grid-cols-1 w-full gap-y-4'
      >
        <FormGroup
          id='email'
          label={t('app.table.email')}
          className='row-start-1 row-span-1'
        >
          <InputWithAdornment
            name='email'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={
              errors.email &&
              (touched.email as unknown as string) &&
              errors.email
            }
          />
        </FormGroup>
        <div className='row-start-2 row-span-1 flex flex-col justify-start items-end gap-3'>
          <FormGroup
            id='password'
            label={t('app.form_labels.password')}
            className='w-full'
          >
            <InputWithAdornment
              type={showPassword ? 'text' : 'password'}
              right={
                <button
                  type='button'
                  onClick={toggleShowPassword}
                  className='h-full flex items-center justify-center'
                >
                  {showPassword ? (
                    <iconComponents.util.EyeCloseIcon className='stroke-jaa-shades-black fill-jaa-shades-black' />
                  ) : (
                    <iconComponents.util.EyeOpenIcon className='stroke-jaa-shades-black fill-jaa-shades-black' />
                  )}
                </button>
              }
              name='password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={
                errors.password &&
                (touched.password as unknown as string) &&
                errors.password
              }
            />
          </FormGroup>
          {!isLoading && (
            <div className='w-full flex justify-end items-center'>
              <Typography
                as='button'
                type='button'
                onClick={openModal}
                className='xs font-medium capitalize text-[#8DA0A5] hover:text-jaa-teal-80'
              >
                {t('app.form_labels.forgot_password')}
              </Typography>
            </div>
          )}
        </div>
        <Button
          disabled={isLoading || !isValid}
          loading={isLoading}
          fullWidth
          type='submit'
          label={t('app.auth.signin')}
          className='row-start-3 row-span-1 !uppercase mt-10'
        />
      </form>
      <ForgotPassword open={isModalOpen} onClose={closeModal} />
    </div>
  )
}

export default AlumniSignIn
