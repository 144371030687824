import * as Icon from 'react-feather'
import { JaaLogo } from '../../Icons/generated'
import { Button } from '../Forms/button'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Storage } from '@/helpers/Storage'
import { useToggleState } from '@/hooks/useToggleState'
import iconComponents from '@/assets/icons/iconComponents'
import Typography from '@/common/Typography'
import useI18n from '@/i18n'

export const Navbar = () => {
  useI18n()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const {
    state: showLanguage,
    open: openLanguage,
    close: closeLanguage,
  } = useToggleState()
  const [preferredLanguage, setPreferredLanguage] = useState(
    Storage.getItem('PREFERRED_LANGUAGE') || 'en'
  )

  const flag = {
    en: <iconComponents.language.EnglishIcon />,
    fr: <iconComponents.language.FrenchIcon />,
  }
  const code = {
    en: 'EN',
    fr: 'FR',
  }

  const handleToggle = () => {
    setOpen((prev) => !prev)
  }
  useI18n()
  const handleSetLanguage = async (lang) => {
    Storage.setItem('PREFERRED_LANGUAGE', lang)
    setPreferredLanguage(() => lang)
    console.log(preferredLanguage)
  }

  return (
    <div className='w-full flex items-center justify-center fixed top-0 left-0 z-10 bg-jaa-main'>
      <div className='relative h-16 bg-jaa-white w-full flex items-center justify-between py-4 px-7 md:px-10'>
        <JaaLogo />
        <ul
          className={`bg-jaa-white xl:bg-none text-jaa-white text-sm space-x-0 space-y-6 xl:space-y-0 
                xl:space-x-7 xl:flex xl:items-center xl:pb-0 pb-12 absolute xl:static xl:z-auto 
                z-[-1] left-0 w-full xl:w-auto xl:pl-0 pl-9 transition-all duration-500 ease-in-out 
                ${open ? 'top-16 ' : 'top-[-490px]'}`}
        >
          <NavItem
            onClick={handleToggle}
            link='home'
            text={t('app.landing.home')}
          />
          <NavItem
            onClick={handleToggle}
            link='why'
            text={t('app.landing.why_us')}
          />
          <NavItem
            onClick={handleToggle}
            link='skills'
            text={t('app.landing.pool')}
          />
          <NavItem
            onClick={handleToggle}
            link='impact'
            text={t('app.landing.impact')}
          />
          <NavItem onClick={handleToggle} link='faq' text='FAQs' />
          <NavItem onClick={handleToggle} link='footer' text='Contact Us' />
        </ul>

        <div
          className={`pt-4 xl:pt-0 flex flex-col bg-jaa-white xl:bg-none text-jaa-white text-sm space-x-0 space-y-6 xl:space-y-0 
                xl:space-x-7 xl:flex-row xl:items-center xl:pb-0 pb-12 absolute xl:static xl:z-auto 
                z-[-1] left-0 w-full xl:w-auto xl:pl-0 pl-9 transition-all duration-500 ease-in-out 
                ${open ? 'top-72 ' : 'top-[-890px]'}`}
        >
          <a
            href='https://mycareerconnect.org/recruiter/auth/sign-in'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button variant='primary'>Sign in as Recruiter</Button>
          </a>
          <a
            href='https://mycareerconnect.org/alumni/auth/sign-in'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button variant='default'>Sign in as Alumni</Button>
          </a>

          <div>
            <button
              type='button'
              onClick={(e) => {
                e.stopPropagation()
                openLanguage()
              }}
              className='p-2'
            >
              {flag[preferredLanguage] || 'en'}
              {/* <iconComponents.util.ArrowDownIcon /> */}
            </button>
            {showLanguage && (
              <ul
                onClick={(e) => {
                  e.stopPropagation()
                  closeLanguage()
                }}
                className=' flex flex-col absolute gap-[10px] z-20 marker:content-[""] shadow-md bg-jaa-shades-white py-[10px] top-[20px] right-0 w-[180px]'
              >
                {['en', 'fr'].map((el, idx) => (
                  <li
                    key={idx}
                    onClick={() => {
                      handleSetLanguage(el)
                    }}
                    className='flex items-center justify-between text-jaa-shades-black cursor-pointer hover:bg-jaa-gray-500 py-1 px-4 h-[33px] font-normal sm w-full capitalize whitespace-nowrap'
                  >
                    <div className='flex items-center gap-1 justify-center'>
                      {flag[el] || 'en'}
                      <Typography className='text-[14px]'>{el}</Typography>
                    </div>

                    <Typography className='capitalize text-[14px] font-medium'>
                      {code[el || 'en']}
                    </Typography>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div
          onClick={handleToggle}
          className='flex items-center justify-center space-x-2 text-jaa-black text-base absolute right-9 cursor-pointer xl:hidden'
        >
          <span className='text-base text-jaa-white'>MENU</span>
          {!open ? <Icon.Menu size={20} /> : <Icon.X size={20} />}
        </div>
      </div>
    </div>
  )
}

export const NavItem = ({ text, link, onClick }) => {
  return (
    <div onClick={onClick} className='cursor-pointer flex items-center'>
      <li className='text-jaa-black text-sm'>
        <a href={`#${link}`}>{text}</a>
      </li>
    </div>
  )
}

NavItem.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
}
