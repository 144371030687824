import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import Modal from '@/common/modal/Modal'
import ModalHeader from '@/common/modal/ModalHeader'
import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import { isValidEmail } from '@/helpers/strings'
import { withAsync } from '@/helpers/withAsync'
import { useForgotPasswordMutation } from '@/store/authSlice'
import React, { FormEvent, useState } from 'react'

type IForgot = {
  open: boolean
  onClose: () => void
}
const ForgotPassword = (props: IForgot) => {
  const { open, onClose } = props
  const [forgotPassword, { isLoading: isSending }] = useForgotPasswordMutation()
  const [email, setEmail] = useState('')
  const submitHandler = async (e: FormEvent) => {
    e.preventDefault()
    if (!isValidEmail(email)) {
      return ToastNotify('warn', {
        message: 'Please Enter A valid Email',
      })
    }
    const { error } = await withAsync(() => forgotPassword({ email }))
    if (error) {
      return ToastNotify('error', {
        message: (error as any).message || 'Could not send Email',
      })
    }
    const redactedEmail = (() => {
      const firstThreeChar = email.substring(0, 3)
      return (
        firstThreeChar +
        '***@***.' +
        email.split('.')[email.split('.').length - 1]
      )
    })()
    ToastNotify('info', {
      message: `Recovery mail sent to ${redactedEmail}`,
      allowCancel: false,
      timeout: 15000,
    })
    setEmail('')
  }
  return (
    <Modal variant='md' onClose={onClose} open={open}>
      <div className='w-full flex flex-col  gap-2 p-8'>
        <ModalHeader title='' onClose={onClose} />

        <header className='flex flex-col gap-1 w-full justify-center items-center'>
          <div className='w-[72px] h-[72px] bg-jaa-teal-20 rounded-full sm:w-[96px] sm:h-[96px] flex justify-center items-center'>
            <div className='w-[52px] h-[52px] bg-jaa-teal-40 rounded-full sm:w-[66px] sm:h-[66px] flex justify-center items-center'>
              <iconComponents.util.KeyIcon
                stroke='var(--teal-100)'
                className='w-[28px] h-[31px] sm:w-8 sm:h-h-9'
              />
            </div>
          </div>
          <Typography as='h2' className='h3 text-jaa-shades-black  sm:h2'>
            Forgot Password
          </Typography>
          <Typography
            as='p'
            className='text-jaa-shades-gray-2.5 font-normal sm'
          >
            Don&apos;t worry, we&apos;ll send you a link to reset your password.
          </Typography>
        </header>
        <form
          onSubmit={submitHandler}
          className='flex flex-col w-full justify-center items-center gap-4'
        >
          <FormGroup id='email' label='Email Address'>
            <InputWithAdornment
              value={email}
              type='email'
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </FormGroup>
          <Button
            disabled={isSending}
            loading={isSending}
            type='submit'
            label='Send Verification link'
            className='w-full !uppercase my-10'
          />
        </form>
      </div>
    </Modal>
  )
}

export default ForgotPassword
