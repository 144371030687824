import { CreateJobDTO, Job } from '@/apis/recruitementApis'
import Button from '@/common/button'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import SelectInput from '@/common/Input/SelectInput'
import Textarea from '@/common/Input/Textarea'
import Modal from '@/common/modal/Modal'
import ModalHeader from '@/common/modal/ModalHeader'
import { ToastNotify } from '@/common/toastManager'
import { withAsync } from '@/helpers/withAsync'
import { useAppSelector } from '@/store/hooks'
import {
  useDeleteJobImageMutation,
  useJobFiltersQuery,
  useJobPipelinesQuery,
  useRecruiterCreateJobMutation,
  useUpdateRecruiterJobMutation,
} from '@/store/recruitementSlice'
import { FormikHelpers, useFormik } from 'formik'
import { useMemo } from 'react'
import jobSchema from '../validators/job.validator'
import { useTranslation } from 'react-i18next'

import UploadImage from '@/pages/profile/alumni/portfolio/createupdate/UploadImage'
import { prepareImage } from '@/pages/profile/alumni/portfolio/createupdate'

type IOpenable = {
  open: boolean
  onClose: () => void
  data?: Job
}

const CreateOrUpdateJob = (props: IOpenable) => {
  const { open, onClose, data } = props

  const authUser = useAppSelector((state: any) => state.auth)
  const { data: allFilters } = useJobFiltersQuery()
  const { data: paginatedPipelines, isLoading: isFetchingPipelines } =
    useJobPipelinesQuery({ page: 1 })
  const [createJob] = useRecruiterCreateJobMutation()
  const [updateJob] = useUpdateRecruiterJobMutation()
  const [deleteImage] = useDeleteJobImageMutation()
  const initialData: CreateJobDTO = useMemo(() => getInitialData(data), [data])
  const submitHandler = async (
    values: CreateJobDTO,
    { setSubmitting, resetForm }: FormikHelpers<CreateJobDTO>
  ) => {
    const { newImages, deletedImages } = prepareImage(
      values.images as any,
      (initialData.images || []) as any
    )

    const defaultImage = newImages.findIndex((image) => image.default)
    if (defaultImage > -1) {
      newImages.unshift({ ...newImages[defaultImage] })
      newImages.splice(defaultImage, 1)
    }

    const fd = new FormData()
    const finalObj = data
      ? {
          ...values,
          id: data.id,
          company: (authUser as any).company.id as number,
        }
      : {
          ...values,
          company: (authUser as any).company.id as number,
        }
    Object.entries(finalObj).forEach(([key, value]) => {
      fd.set(key, value as string)
    })
    newImages.forEach((img, idx) => {
      fd.set(`image${idx}`, img.file as File)
    })

    const { response, error } = await withAsync(
      () => (data ? updateJob(fd).unwrap() : createJob(fd).unwrap()),
      () => setSubmitting(false)
    )
    if (error) {
      ToastNotify('error', {
        message: (error as any).message || 'Ooops...Could not create job',
      })
      return
    }
    if (!response) return
    await withAsync(() =>
      Promise.all(
        deletedImages.map((image) => {
          return deleteImage({ id: image.id || 0 }).unwrap()
        })
      )
    )
    ToastNotify('success', {
      message: (response as any).message || 'Successfully Created Job',
    })
    resetForm()
    onClose()
  }
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    setFieldValue,
    isValid,
    isSubmitting,
  } = useFormik({
    onSubmit: submitHandler,
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: jobSchema,
  })
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose} variant='md'>
      <div className='flex flex-col p-4 sm:p-6 gap-9 bg-white min-h-[700px] w-full'>
        <ModalHeader
          title={data ? 'Update Job' : 'Create New Job'}
          onClose={onClose}
        />
        <form
          onSubmit={handleSubmit}
          className='max-h-[600px] flex flex-col gap-[33px] w-full'
        >
          <div className='max-h-full pb-4 overflow-x-hidden grid grid-cols-2 gap-4 w-full overflow-auto scrollbar !scrollbar-w-1 scrollbar-track-rounded-lg scrollbar-thumb-rounded-lg scrollbar-thumb-jaa-dark-teal-80 scrollbar-track-gray-100'>
            <FormGroup id='title' label='Job title' className='col-span-2'>
              <InputWithAdornment
                name='title'
                placeholder='Product Designer'
                value={values.title}
                error={
                  errors.title &&
                  (touched.title as unknown as string) &&
                  (errors.title as unknown as string)
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              id='description'
              label='Description'
              className='col-span-2'
              subtext={`${values?.description?.length || 0} / 200`}
            >
              <Textarea
                name='description'
                className='select md !normal-case'
                rows={3}
                placeholder={`${t('app.say_something')}`}
                value={values.description}
                error={
                  errors.description &&
                  (touched.description as unknown as string) &&
                  (errors.description as unknown as string)
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              id='type'
              label='Job type'
              className='col-span-2 md:col-span-1'
            >
              <SelectInput
                className='select !h-12'
                menuClassName='bg-white '
                options={allFilters?.job_type || []}
                name='job_type'
                placeholder='Select'
                value={values.job_type}
                error={
                  errors.job_type &&
                  (touched.job_type as unknown as string) &&
                  (errors.job_type as unknown as string)
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              id='work_policy'
              label='Work Policy'
              className='col-span-2 md:col-span-1'
            >
              <SelectInput
                className='select !h-12'
                menuClassName='bg-white '
                options={allFilters?.work_policy || []}
                name='work_policy'
                placeholder='Select'
                value={values.work_policy}
                error={
                  errors.work_policy &&
                  (touched.work_policy as unknown as string) &&
                  (errors.work_policy as unknown as string)
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              id='experience'
              label='Years of Experience'
              className='col-span-2 md:col-span-1'
            >
              <InputWithAdornment
                type='number'
                name='years_of_experience'
                placeholder='Enter Numbers'
                value={
                  values.years_of_experience === 0
                    ? ''
                    : values.years_of_experience
                }
                error={
                  errors.years_of_experience &&
                  (touched.years_of_experience as unknown as string) &&
                  (errors.years_of_experience as unknown as string)
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              id='location'
              label='Location'
              className='col-span-2 md:col-span-1'
            >
              <InputWithAdornment
                name='location'
                placeholder='Enter Location'
                value={values.location}
                error={
                  errors.location &&
                  (touched.location as unknown as string) &&
                  (errors.location as unknown as string)
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup id='pipeline' label='Pipeline' className='col-span-2'>
              <SelectInput
                className='select !h-12'
                menuClassName='bg-white min-w-[208px]'
                options={paginatedPipelines?.results || []}
                optionsValue={(item) => item.id}
                optionsText={(item) => item?.name}
                name='pipeline'
                placeholder='Select'
                value={
                  String(values.pipeline) === '0' ? '' : String(values.pipeline)
                }
                error={
                  (isFetchingPipelines
                    ? ''
                    : paginatedPipelines?.results.length === 0
                    ? 'Pipeline is a required field. Please make sure you have created a pipeline to add to the job'
                    : '') ||
                  (errors.pipeline &&
                    (touched.pipeline as unknown as string) &&
                    (errors.pipeline as unknown as string))
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <div className='w-full col-span-2 grid grid-cols-8 gap-4 pr-1'>
              <FormGroup
                id='salary_range'
                label='Salary Range'
                className='col-span-4 sm:col-span-6'
              >
                <InputWithAdornment
                  name='salary_range'
                  placeholder='Enter'
                  value={values.salary_range}
                  error={
                    errors.salary_range &&
                    (touched.salary_range as unknown as string) &&
                    (errors.salary_range as unknown as string)
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>

              <FormGroup
                id='currency'
                label='Currency'
                className='col-span-4 sm:col-span-2'
              >
                <SelectInput
                  className='select !h-[53px]'
                  menuClassName='bg-white !min-w-[140px] !right-0 left-auto'
                  options={allFilters?.currency || []}
                  optionsText={(item) => item?.name}
                  optionsValue={(item) => item?.value}
                  name='salary_currency'
                  placeholder='Select'
                  value={values.salary_currency}
                  error={
                    errors.salary_currency &&
                    (touched.salary_currency as unknown as string) &&
                    (errors.salary_currency as unknown as string)
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>
            </div>
            <FormGroup
              id='closed_date'
              label='Closing date'
              className='col-span-2'
            >
              <InputWithAdornment
                name='closed_date'
                placeholder='Enter closing date'
                type='date'
                value={values.closed_date}
                error={
                  errors.closed_date &&
                  (touched.closed_date as unknown as string) &&
                  (errors.closed_date as unknown as string)
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <UploadImage
              values={{ images: values.images } as any}
              errors={errors}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched as any}
              setFieldValue={setFieldValue}
              className='w-full col-span-2 pl-4 max-w-full'
            />
          </div>
          <div className='w-full flex items-center pt-3 pb-1 gap-2'>
            <Button
              type='submit'
              label={!data ? 'POST JOB' : 'UPDATE JOB'}
              loading={isSubmitting}
              disabled={isSubmitting || !isValid}
              className='!uppercase flex-1 sm:flex-none'
            />
            <Button
              label='Cancel'
              onClick={onClose}
              disabled={isSubmitting}
              type='reset'
              className='!uppercase flex-1 sm:flex-none'
              variant='cancel'
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}
const getInitialData = (
  data?: Job
): CreateJobDTO & {
  images: {
    file: undefined
    src: string
    id: number
  }[]
} => {
  if (!data) {
    return {
      requirements: [],
      closed_date: '',
      description: '',
      job_type: '',
      location: '',
      pipeline: 0,
      title: '',
      salary_currency: '',
      salary_range: '',
      years_of_experience: 0,
      work_policy: '',
      images: [],
    }
  } else {
    return {
      ...data,
      pipeline: data.pipeline.id,
      images: data.images.map((image) => ({
        file: undefined,
        src: image.image,
        id: image.id,
      })),
    } as any
  }
}

export default CreateOrUpdateJob
