import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CircleIcon1, CircleIcon2, CircleIcon3 } from '../Icons/generated'

export const Workforce = () => {
  const { t } = useTranslation()
  const Stats = [
    {
      id: 1,
      text: (
        <p>
          {t('app.landing.data_proficiency')}
          <b className='ml-1'>{t('app.landing.data_proficiency_bold')}</b>
        </p>
      ),
      icon: <CircleIcon1 />,
    },
    {
      id: 2,
      text: (
        <p>
          {t('app.landing.data_proficiency')}
          <b className='ml-1'>{t('app.landing.market_research')}</b>
        </p>
      ),
      icon: <CircleIcon3 />,
    },
    {
      id: 3,
      text: (
        <p>
          {t('app.landing.acquired_soft_skills')}
          <b className='ml-1'>{t('app.landing.acquired_skills')}</b>
        </p>
      ),
      icon: <CircleIcon2 />,
    },
  ]

  return (
    <div className='w-full flex items-center justify-center flex-col space-y-12 p-7 md:p-24'>
      <section className='w-full max-w-screen-2xl flex flex-col items-center lg:flex-row gap-10'>
        <h1 className='w-full lg:w-1/2 text-[36px] lg:text-4xl font-semibold '>
          {t('app.landing.workforce')}
        </h1>
        <p className='w-full lg:w-1/2 text-sm lg:text-lg'>
          {t('app.landing.workforce_text')}
        </p>
      </section>

      <section className='w-full max-w-screen-2xl grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3 items-start'>
        {Stats?.map((item) => (
          <ProgressCard key={item.id} text={item.text} icon={item.icon} />
        ))}
      </section>
    </div>
  )
}

export const ProgressCard = ({ icon, text }) => {
  return (
    <div className='flex items-center justify-center flex-col gap-4'>
      {icon}
      <p className='text-center text-sm lg:text-lg'>{text}</p>
    </div>
  )
}

ProgressCard.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
}
