import { sub } from 'date-fns'
import { isToday, isYesterday } from './date'

const getValueOfObj = (obj: any, key: string) => {
  const splitKey = key.includes('.') ? key.split('.') : [key]
  let value = obj
  for (let key of splitKey) {
    value = value[key]
  }
  return value
}
export const getUniques = (arr: any[], key: string = 'id') => {
  return arr
    .map((item) => {
      return getValueOfObj(item, key)
    })
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((item) => {
      return {
        key: item,
        ...arr.find((s) => {
          return getValueOfObj(s, key) === item
        }),
      }
    })
}

export const union = (firstArr: any[], secondArr: any[], key?: string) => {
  if (key) {
    return getUniques([...firstArr, secondArr], key)
  }
  return [...new Set([...firstArr, ...secondArr])]
}
export const intersection = (
  firstArr: any[],
  secondArr: any[],
  key?: string
) => {
  const firstSet = key
    ? new Set(firstArr.map((item) => getValueOfObj(item, key)))
    : new Set(firstArr)
  const secondSet = key
    ? new Set(secondArr.map((item) => getValueOfObj(item, key)))
    : new Set(secondArr)
  return [
    ...new Set([...firstSet].filter((firstItem) => secondSet.has(firstItem))),
  ].map((item) =>
    key
      ? [...firstArr, ...secondArr].find(
          (el) => getValueOfObj(el, key) === item
        )
      : item
  )
}

export const difference = (firstArr: any[], secondArr: any[], key?: string) => {
  const firstSet = key
    ? new Set(firstArr.map((item) => getValueOfObj(item, key)))
    : new Set(firstArr)
  const secondSet = key
    ? new Set(secondArr.map((item) => getValueOfObj(item, key)))
    : new Set(secondArr)
  return [
    ...new Set([...firstSet].filter((firstItem) => !secondSet.has(firstItem))),
  ].map((item) =>
    key
      ? [...firstArr, ...secondArr].find(
          (el) => getValueOfObj(el, key) === item
        )
      : item
  )
}

export const formatDataByDate = <T = unknown>(
  data: T[],
  key: keyof T,
  order: 'ASC' | 'DESC' = 'ASC'
) => {
  const groupByKey = (key: keyof T) => (acc: Record<string, T[]>, cur: T) => {
    if (!Date.parse(cur[key] as string)) return acc
    if (isToday(new Date(cur[key] as string))) {
      acc['Today'] = (acc['Today'] || []).concat(cur)
    } else if (isYesterday(new Date(cur[key] as string))) {
      acc['Yesterday'] = (acc['Yesterday'] || []).concat(cur)
    } else {
      acc[new Date(cur[key] as string).toLocaleDateString()] = (
        acc[new Date(cur[key] as string).toLocaleDateString()] || []
      ).concat(cur)
    }
    return acc
  }
  const sortItemsInGroupByKey =
    (byKey: keyof T) =>
    (acc: Record<string, T[]>, cur: Record<string, T[]>) => {
      for (let key in cur) {
        acc[key] = (acc[key] || []).concat(cur[key])
        acc[key].sort((a, b) =>
          new Date(a[byKey] as string) > new Date(b[byKey] as string) ? -1 : 1
        )
      }
      return acc
    }
  const pagesFormatted = data.reduce(groupByKey(key), {})
  const allPages = [pagesFormatted].reduce(sortItemsInGroupByKey(key), {})
  const getDate = (a: string) => {
    return a.startsWith('Today')
      ? new Date().toLocaleDateString()
      : a.startsWith('Yesterday')
      ? sub(new Date(), {
          days: 1,
        }).toLocaleDateString()
      : a
  }
  const arrangedAlphabets = Object.keys(allPages).sort(
    (a: string, b: string) => {
      const [dayFirst, monthFirst, yearFirst] = getDate(a)
        .split('/')
        .map(Number)
      const [daySecond, monthSecond, yearSecond] = getDate(b)
        .split('/')
        .map(Number)
      const dateFirst = new Date(yearFirst, monthFirst - 1, dayFirst)
      const dateSecond = new Date(yearSecond, monthSecond - 1, daySecond)
      return dateFirst > dateSecond
        ? order === 'ASC'
          ? 1
          : -1
        : order === 'ASC'
        ? -1
        : 1
    }
  )
  const sortedObj: Record<string, T[]> = {}
  for (let alpha of arrangedAlphabets) {
    sortedObj[alpha] = getUniques(allPages[alpha], key as string)
  }
  return sortedObj
}
