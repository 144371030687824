import { RouteAwareCrumbs } from '@/common/breadcrumbs'
import Title from '@/common/Title'
import useQueryParams from '@/hooks/useQueryParams'
import { useParams } from 'react-router'
import Upvotes from '../components/Upvotes'

const NameMap = new Map()
const PostUpvotesPage = () => {
  const { postId } = useParams()
  const { alumnusName } = useQueryParams()
  const { alumnusId } = useParams()

  NameMap.set(
    `/alumni/app/forum/alumni/${alumnusId}?alumnusName=${alumnusName}`,
    `${alumnusName}`
  )
  NameMap.set(
    `/alumni/app/forum/alumni/${alumnusId}/activity?alumnusName=${alumnusName}`,
    `Activity`
  )
  NameMap.set(
    `/alumni/app/forum/alumni/${alumnusId}/activity/${postId}?alumnusName=${alumnusName}`,
    `Post`
  )
  NameMap.set(
    `/alumni/app/forum/alumni/${alumnusId}/activity/vote/${postId}?alumnusName=${alumnusName}`,
    'Upvotes'
  )
  return (
    <section className='w-full bg-jaa-shades-bg px-6 pb-8 flex flex-col gap-4 justify-start items-start relative lg:max-h-[90vh] lg:overflow-auto '>
      <header className='flex justify-between items-center w-full'>
        <RouteAwareCrumbs nameMap={NameMap} />
        <Title>JA Africa - Forum Feed votes</Title>
      </header>
      <main className='w-full rounded-lg'>
        <Upvotes />
      </main>
    </section>
  )
}

export default PostUpvotesPage
