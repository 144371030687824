import Typography from '@/common/Typography'
import React from 'react'

type UserWelcomeProps = {
  welcomeText: string
  userType: string
  description: string
  header?: React.ReactNode
}
const UserWelcome = (props: UserWelcomeProps) => {
  const { welcomeText, userType, description } = props
  return (
    <header className='w-full flex flex-col justify-center items-center text-center gap-1'>
      <Typography
        as='h2'
        className='h3 !font-light text-jaa-shades-black sm:h2 capitalize'
      >
        {welcomeText}{' '}
        <Typography as='strong' className='font-medium'>
          {userType}
        </Typography>
      </Typography>
      <Typography
        as='p'
        className='w-full sm text-jaa-shades-gray-2.5 font-normal'
      >
        {description}
      </Typography>
    </header>
  )
}

export default UserWelcome
