import { PaginatedData } from '@/apis/api.types'
import { IRating, RatingPageDTO, getAccountRating } from '@/apis/ratingApis'
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'

export const ratingApiSlice = createApi({
  reducerPath: 'ratingApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Rating'],

  endpoints: (builder) => ({
    accountRating: builder.query<PaginatedData<IRating>, RatingPageDTO>({
      queryFn: async (payload) => {
        try {
          return await getAccountRating(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Rating'],
    }),
  }),
})

export const { useAccountRatingQuery } = ratingApiSlice
export const resetRatingApiSlice = () => ratingApiSlice.util.resetApiState()
export const invalidateRatingApiSlice = ratingApiSlice.util.invalidateTags
