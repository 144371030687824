import classNames from 'classnames'
import PropTypes from 'prop-types'

export const Button = ({
  id,
  color,
  radius,
  tColor,
  icon,
  loadColor,
  isLoading,
  fullWidth,
  className,
  children,
  leftIcon,
  rightIcon,
  rounded,
  variant,
  onClick,
  disabled,
  type,
  ref,
  ...rest
}) => (
  <button
    ref={ref}
    type='button'
    onClick={onClick}
    id={id}
    disabled={disabled}
    className={classNames(
      `md:text-sm text-xs font-semibold cursor-pointer select-none [outline:none] disabled:cursor-not-allowed
        disabled:bg-neutral-300 disabled:text-neutral-100 disabled:shadow-inner
        ${
          fullWidth
            ? 'w-full text-center flex items-center justify-center'
            : 'flex items-center justify-between'
        }
        transition-all ease-in-out duration-300`,
      rounded ? 'rounded' : '',
      variant === 'alumni'
        ? 'text-jaa-black bg-jaa-yellow space-x-2 px-4 py-[10px] 2xl:py-[18px]'
        : '',
      variant === 'primary'
        ? ' space-x-2 px-4 py-[10px] 2xl:py-[18px] bg-jaa-main focus:ring-blue-300 text-jaa-white hover:bg-jaa-hovermain hover:text-jaa-white focus:bg-jaa-focusmain focus:ring-jaa-focusmain'
        : '',
      variant === 'default'
        ? ' space-x-2 px-4 py-[10px] 2xl:py-[18px] bg-jaa-bgray focus:ring-blue-300 text-jaa-black focus:bg-jaa-focusmain focus:ring-jaa-focusmain'
        : '',
      className
    )}
    {...rest}
  >
    {!!leftIcon && leftIcon}
    {children ? <span className='whitespace-nowrap'>{children}</span> : null}
    {icon ? <span>{icon}</span> : null}
    {!!rightIcon && rightIcon}
  </button>
)

Button.defaultProps = {
  rounded: true,
  variant: 'primary',
}

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  rounded: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['primary', 'alumni', 'default']),
  id: PropTypes.string,
  color: PropTypes.string,
  radius: PropTypes.string,
  tColor: PropTypes.string,
  icon: PropTypes.node,
  loadColor: PropTypes.string,
  isLoading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  ref: PropTypes.any,
}
