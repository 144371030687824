import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import { withAsync } from '@/helpers/withAsync'
import { useRecoverPasswordMutation } from '@/store/authSlice'
import { ChangeEvent, FormEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

const RecruiterRecoverPassword = () => {
  const { code } = useParams()
  const [passwords, setPasswords] = useState({
    new_password: '',
    verify_password: '',
  })

  const navigate = useNavigate()
  const [recoverPassword, { isLoading: isSending }] =
    useRecoverPasswordMutation()
  const submitHandler = async (e: FormEvent) => {
    e.preventDefault()
    if (
      passwords.new_password !== passwords.verify_password ||
      !passwords.new_password.trim()
    ) {
      return ToastNotify('warn', {
        message: 'Password does not match',
      })
    }
    const { error } = await withAsync(() =>
      recoverPassword({
        code: code as string,
        new_password: passwords.new_password,
      })
    )
    if (error) {
      return ToastNotify('error', {
        message: (error as any)?.message || 'Ooops try again.',
      })
    }
    ToastNotify('success', {
      message: 'Password has been changed Successfully',
    })
    navigate('/recruiter/auth/sign-in', {
      replace: true,
    })
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e
    setPasswords((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <div className='px-4 w-full flex flex-col gap-6 sm:gap-10 justify-start items-center sm:px-[19%] mb-12'>
      <header className='flex flex-col gap-1 w-full self-start my-10'>
        <Typography
          as='h2'
          className='h3 text-jaa-shades-black  sm:h2 whitespace-nowrap'
        >
          Enter New Password
        </Typography>
        <Typography as='p' className='text-jaa-shades-gray-2.5 font-normal sm'>
          Enter a new passowrd to continue.
        </Typography>
      </header>
      <form
        onSubmit={submitHandler}
        className='flex flex-col w-full justify-center items-center gap-4'
      >
        <FormGroup id='new_password' label='New Password'>
          <InputWithAdornment
            name='new_password'
            type='password'
            value={passwords.new_password}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup id='verify_password' label='Re-Enter New Password'>
          <InputWithAdornment
            name='verify_password'
            type='password'
            value={passwords.verify_password}
            onChange={handleChange}
          />
        </FormGroup>
        <Button
          disabled={isSending}
          loading={isSending}
          type='submit'
          label='Proceed'
          className='w-full xl:w-1/2 !uppercase mt-10'
        />
      </form>
    </div>
  )
}

export default RecruiterRecoverPassword
