import iconComponents from '@/assets/icons/iconComponents'
import Typography from '@/common/Typography'
import useHover from '@/hooks/useHover'
import { getUserType } from '@/store/authSlice'
import { useAppSelector } from '@/store/hooks'
import clsx from 'clsx'
import React from 'react'

type InteractionType = 'comment' | 'upvote' | 'downvote' | 'share'
type InteractionProps = {
  type: InteractionType
  status?: boolean
  text: string
  onClick?: () => void
}

const IconTypeMap: Record<
  InteractionType,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
> = {
  comment: iconComponents.forum.CommentIcon,
  downvote: iconComponents.forum.DownVoteIcon,
  upvote: iconComponents.forum.UpVoteIcon,
  share: iconComponents.forum.ShareIcon,
}

const ColorTypeMap: Record<InteractionType, string> = {
  comment:
    'text-jaa-shades-gray-4 stroke-jaa-shades-gray-4 fill-jaa-shades-white',
  downvote: '!text-[#E65846] stroke-[#E65846] !fill-[#E65846]',
  upvote: '!text-jaa-green-100 stroke-jaa-green-100 !fill-jaa-green-100',
  share:
    'text-jaa-shades-gray-2.5 stroke-jaa-shades-gray-2.5 fill-jaa-shades-white',
}
const TooltipTypeMap: Record<InteractionType, string> = {
  comment: 'bg-jaa-shades-gray-4',
  downvote: '!bg-[#E65846]',
  upvote: '!bg-jaa-green-100',
  share: '!bg-transparent',
}
const Interaction = (props: InteractionProps) => {
  const { type, status, text, onClick } = props
  const [hoverRef, isHovering] = useHover<HTMLDivElement>()
  const hasTooltip = type !== 'share' && isHovering
  const Icon = IconTypeMap[type]
  const userType = useAppSelector(getUserType)
  return (
    <div
      onClick={onClick}
      className='relative flex flex-col items-start'
      ref={hoverRef}
    >
      <button
        className={clsx(
          'flex items-center gap-0.5 cursor-pointer text-jaa-shades-gray-2.5',
          status || isHovering
            ? userType === 'admin'
              ? ''
              : ColorTypeMap[type]
            : 'stroke-jaa-shades-gray-2.5 fill-transparent'
        )}
      >
        <Icon />
        <Typography as='span' className='font-normal sm normal-case'>
          {text}
        </Typography>
      </button>
      {hasTooltip && (
        <div className='absolute top-[100%] left-0 transform-translateX-[-50%]  ml-[-60%] flex flex-col justify-center items-center shadow-[0px_4px_24px_0px_#0404040F]'>
          <iconComponents.forum.TipIcon
            className={TooltipTypeMap[type].replace('bg', 'fill')}
          />
          <span
            className={clsx(
              TooltipTypeMap[type],
              'capitalize text-jaa-shades-white rounded py-1 px-4 gap-2.5'
            )}
          >
            {type}
          </span>
        </div>
      )}
    </div>
  )
}

export default Interaction
