import BounceLoader from 'react-spinners/BounceLoader'
import { FC, ReactNode, Suspense } from 'react'
import AppErrorBoundary from '@/hoc/AppErrorBoundary'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './store'
import { I18nextProvider } from 'react-i18next'
import useI18n from './i18n'

const I18nWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const i18n = useI18n()

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

const CombineProviders: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <AppErrorBoundary>
      <Suspense
        fallback={
          <div className='w-screen h-screen flex justify-center items-center bg-white'>
            <BounceLoader color='#00A0AE' loading size={75} />
          </div>
        }
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <I18nWrapper>{children}</I18nWrapper>
            {/* <I18nextProvider i18n={i18n}>{children}</I18nextProvider>, */}
          </PersistGate>
        </Provider>
      </Suspense>
    </AppErrorBoundary>
  )
}

export default CombineProviders
