//Authentication Endpoint
export const SIGNIN = '/account/auth/login/'
export const SIGNOUT = '/account/auth/logout/'
export const REFRESH = '/account/auth/token/refresh/'
export const LOGOUT = '/account/auth/logout/'
export const TOGGLEBLOCK = '/account/auth/block/'
export const GET_ADMINS = '/account/admins/'
export const GET_ROLES = '/account/permission-roles/'
export const GET_PERMISSIONS = '/account/admins/permissions/'
export const FORGOT_PASSWORD = '/account/auth/forgot-password/'
export const CREATE_PASSWORD_FROM_LINK = '/account/admins/accept-invitation/'
export const CHANGE_PASSWORD_FROM_LINK =
  '/account/auth/change-password-from-link/'
export const ALUMNI_DETAIL = '/account/alumni/'
export const INVITE_ALUMNIS = '/account/alumni/send-invites/'
export const ACCEPT_ALUMNI = '/account/alumni/accept-invite/'

export const RECRUITER_SEND_OTP = '/account/auth/send-otp/'
export const RECRUITER_VERIFY_OTP = '/account/auth/verify-otp/'
export const RECRUITER_SIGNUP = '/account/auth/recruiter/'
export const RECRUITER_ACCEPT_INVITE = '/account/recruiters/accept-invitation/'
export const RECRUITER_INVITE = '/account/recruiters/invite-by-email/'
export const RECRUITER_PRIMARY_OWNER = '/recruitment/companies/'
export const RECRUITER_MEMBERS = '/account/recruiters/members/'

//Profile Endpoints
export const CHANGE_PASSWORD = '/account/auth/change-password/'
export const ADMIN_PROFILE = '/account/admins/profile/'
export const RECRUITER_PROFILE = '/account/recruiters/profile/'
export const RECRUITER_COMPANY = '/account/recruiters/company/'
export const ALUMNI_PROFILE = '/account/alumni/'
export const ALUMNI_WORK_EXPERIENCE = '/account/alumni/work-experience/'

//Recruitment Enpoints
export const ALL_JOBS = '/recruitment/jobs/'
export const JOB_IMAGES = '/recruitment/job-images/'
export const JOB_APPLICATIONS = '/recruitment/job-applications/'
export const ALL_PIPELINES = '/recruitment/pipelines/'
export const RECRUITER_RATE = '/account/rating/'
export const RECRUITMENT_DASHBOARD = '/account/dashboard/'
export const GET_ALL_INDUSTRIES = '/recruitment/jobs/filters/?fields=industry,'

//Recruiters EndPoints
export const ALL_RECRUITERS = '/account/recruiters/members'

//Options Endpoint
export const ALL_COUNTRIES = '/account/data/countries/'
export const WORK_ROLES = '/account/data/work-roles/'

//Dashboard Data Enpoint
export const DASHBOARD_DATA = '/account/dashboard/'

//Reports Endpoint
export const RECRUITER_REPORT = '/account/admins/report/recruiters/'
export const ALUMNI_REPORT = '/account/admins/report/alumni/'
export const GENDER_REPORT = '/account/admins/report/gender/'
export const USERS_GROWTH_REPORT = '/account/users/growth/'
export const USERS_PER_COUNTRY_REPORT = '/account/users/country/'
export const GET_FORUM_ENGAGEMENTS = '/forum/engagements/'

//Alumni Endpoint
export const ALL_ALUMNI_LIST = '/account/alumni/'

//Engagement Endpoint
export const ALL_ENGAGEMENT = '/engagement/'
export const ENGAGEMENT_MESSAGES = '/engagement/messages/'

//Ratings Endpoint
export const GET_RATINGS = '/account/rating/'

//Notifications Endpoint
export const GET_NOTIFICATIONS = '/account/notifications'
export const GET_UNREAD_NOTIFICATIONS_COUNT =
  '/account/notifications/unread-count/'
export const READ_NOTIFICATIONS = '/account/notifications/read/'

//Test Endpoint
export const SKILL_TEST = '/skill-tests/'
export const SKILL_TEST_PROFILE = '/skill-tests/profile/'
export const SKILL_TEST_ATTEMPTS = '/skill-tests/alumnus/attempts/'
export const SKILL_TEST_LEADERBOARD = '/skill-tests/leaderboard/'
export const SKILL_TEST_CATEGORIES = '/skill-tests/job-roles/categories/'
export const SKILL_TEST_CATEGORIES_FILTERS =
  '/skill-tests/job-roles/categories/filters/'

export const AUDIT_LOGS = '/audit-logs/'

export const POSTS = '/forum/posts/'
export const POST_MEDIA = '/forum/postmedia/'
export const POST_COMMENT = '/forum/comments/'
export const REPORT_CONTENT = '/forum/reports/'
export const DELISTED_CONTENT = '/forum/delisted/'
export const FORUM_ACTIVITY = '/forum/activity/'
export const FORUM_RESTRICTION = '/forum/restrictions/'
export const POST_BY_COHORT = '/forum/posts/today-counts/'

//Language Setting endpoints
export const GET_LANGUAGE_LIST = '/account/settings/language/'
export const SET_PREFERRED_LANGUAGE = '/account/settings/language/'
