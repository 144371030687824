import React from 'react'
import * as Icon from 'react-feather'
import { useTranslation } from 'react-i18next'
import { QuoteIcon } from '../Icons/generated'
import IyinImage from '../Icons/iyin.png'

export const Testimonial = () => {
  const { t } = useTranslation()
  return (
    <div
      id='impact'
      className='w-full flex items-center justify-center flex-col p-7 md:px-24'
    >
      <div className='max-w-screen-2xl lg:relative w-full flex flex-col items-start justify-between lg:flex-row rounded-lg bg-jaa-main'>
        <section className='w-full lg:w-2/3 space-y-12 p-8'>
          <header className='flex items-center gap-4 text-jaa-white'>
            <Icon.Heart color='#EEE82C' fill='#EEE82C' />
            {t('app.landing.testimonial')}
          </header>
          <p className='font-semibold text-[40px] text-jaa-white'>
            JA Alumni Create Value!
          </p>
          <main className='space-y-24'>
            <p className='text-[24px] text-jaa-white'>
              {t('app.landing.testimonial_text')}
            </p>
            <footer className='flex items-start flex-col relative space-y-2'>
              <span className='text-jaa-white font-medium text-[60px]'>
                Iyin Aboyeji
              </span>
              <span className='text-jaa-yellow text-[20px]'>
                {t('app.landing.testimonial_person')}
              </span>
              <QuoteIcon className='absolute -top-20' />
            </footer>
          </main>
        </section>
        <img
          src={IyinImage}
          style={{
            // background: `url(${IyinImage})`,
            backgroundSize: 'cover',
            objectFit: 'cover',
          }}
          className='lg:absolute top-0 bottom-0 right-0 w-full h-full lg:w-1/3 rounded-br-lg rounded-bl-lg md:rounded-tr-lg md:rounded-br-lg overflow-hidden'
        ></img>
      </div>
    </div>
  )
}
