import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import { isValidEmail } from '@/helpers/strings'
import { withAsync } from '@/helpers/withAsync'
import { useForgotPasswordMutation } from '@/store/authSlice'
import { FormEvent, useState } from 'react'
import { useNavigate } from 'react-router'

const AdminForgotPassword = () => {
  const navigate = useNavigate()
  const [forgotPassword, { isLoading: isSending }] = useForgotPasswordMutation()
  const [email, setEmail] = useState('')
  const submitHandler = async (e: FormEvent) => {
    e.preventDefault()
    if (!isValidEmail(email)) {
      return ToastNotify('warn', {
        message: 'Please Enter A valid Email',
      })
    }
    const { error } = await withAsync(() => forgotPassword({ email }))
    if (error) {
      return ToastNotify('error', {
        message: (error as any).message || 'Could not send Email',
      })
    }
    ToastNotify('info', {
      message: 'A reset link has been sent to your mail',
      allowCancel: false,
    })
    setEmail('')
  }
  return (
    <div className='px-4 w-full flex flex-col gap-6 sm:gap-10 justify-start items-center sm:px-[19%] mb-12'>
      <div className='w-[72px] h-[72px] bg-jaa-teal-20 rounded-full sm:w-[96px] sm:h-[96px] flex justify-center items-center'>
        <iconComponents.util.KeyIcon
          stroke='var(--teal-100)'
          className='w-[28px] h-[31px] sm:w-8 sm:h-h-9'
        />
      </div>
      <header className='flex flex-col gap-1 w-full self-start'>
        <Typography as='h2' className='h3 text-jaa-shades-black  sm:h2'>
          Forgot Password
        </Typography>
        <Typography as='p' className='text-jaa-shades-gray-2.5 font-normal sm'>
          Don&apos;t worry, we&apos;ll send you a link to reset your password.
        </Typography>
      </header>
      <form
        onSubmit={submitHandler}
        className='flex flex-col w-full justify-center items-center gap-4'
      >
        <FormGroup id='email' label='Email Address'>
          <InputWithAdornment
            value={email}
            type='email'
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </FormGroup>
        <Button
          disabled={isSending}
          loading={isSending}
          type='submit'
          label='Send to mail'
          className='w-full lg:w-1/2'
        />
      </form>
      <Button
        disabled={isSending}
        onClick={() => navigate(-1)}
        variant='empty'
        label={
          <span className='flex justify-center items-center gap-2 text-jaa-shades-gray-3 md font-normal'>
            <iconComponents.util.ArrowLeftIcon
              strokeWidth={'1'}
              stroke='var(--shades-gray-3)'
            />
            <span>Back to log in </span>
          </span>
        }
      />
    </div>
  )
}

export default AdminForgotPassword
