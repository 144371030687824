import Typography from '@/common/Typography'
import { FormikHelpers, useFormik } from 'formik'
import { SigninDTO } from '@/apis/authApis'
import signInSchema from '../../validationschemas/signin.validator'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import Button from '@/common/button'
import { useSignInMutation } from '@/store/authSlice'

import { useLocation, useNavigate } from 'react-router'
import { withAsync } from '@/helpers/withAsync'
import { ToastNotify } from '@/common/toastManager'
import { useToggleState } from '@/hooks/useToggleState'
import ForgotPassword from './ForgotPassword'
import { BackendToUI } from '@/pages/conversion'
import { VerificationCodeEntry } from '../signup/primary/PrimaryAdminDetails'
import iconComponents from '@/assets/icons/iconComponents'
import { useTranslation } from 'react-i18next'

const initialState: SigninDTO = {
  email: '',
  password: '',
}

const RecruiterSignIn = () => {
  const { t } = useTranslation()

  const {
    open: openModal,
    close: closeModal,
    state: isModalOpen,
  } = useToggleState(false)
  const {
    state: showOtpEntry,
    open: openOtpEntry,
    close: closeOtpEntry,
  } = useToggleState(false)
  const { state: showPassword, toggle: toggleShowPassword } =
    useToggleState(false)
  const { state: RouteState } = useLocation()
  const { from } = RouteState ?? {}

  const navigate = useNavigate()
  const [signIn, { isLoading }] = useSignInMutation()
  const submitHandler = async (
    values: SigninDTO,
    { setSubmitting, resetForm }: FormikHelpers<SigninDTO>
  ) => {
    const { error, response } = await withAsync(() =>
      signIn({ ...values, user_type: 'recruiter' }).unwrap()
    )
    if (error) {
      return ToastNotify('error', {
        message: (error as any).message,
      })
    }
    const { data, message } = (response as any) || {}

    setSubmitting(false)
    if ((data as any).send_otp) {
      ToastNotify('info', {
        message,
      })
      openOtpEntry()
      return
    }
    if (!data.company) {
      navigate(
        `/${
          BackendToUI[data?.user_type as 'recruiter']
        }/auth/sign-up/primary?page=2&user=${data.user_id}`
      )
      return
    }
    navigate(
      from || `/${BackendToUI[data?.user_type as 'recruiter']}/success/welcome`,
      {
        replace: true,
      }
    )
    console.log(data, 'HERE IS RESULT', from)
    // resetForm()
  }
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    isValid,
  } = useFormik<SigninDTO>({
    initialValues: initialState,
    onSubmit: submitHandler,
    validationSchema: signInSchema,
  })
  return (
    <div className='px-4 w-full flex flex-col gap-1 sm:gap-1 justify-start items-center sm:px-[15%] mb-12'>
      <header className='flex flex-col gap-1 w-full'>
        <Typography as='h2' className='h3 text-jaa-shades-black sm:h2'>
          {t('app.auth.signin')}
        </Typography>
        <Typography as='p' className='text-jaa-shades-gray-2.5 font-normal sm'>
          {t('app.auth.signin_desc')}
        </Typography>
      </header>
      <form
        onSubmit={handleSubmit}
        className='grid grid-rows-3 grid-cols-1 w-full gap-y-2 mt-2'
      >
        <FormGroup
          id='email'
          label={t('app.table.email')}
          className='row-start-1 row-span-1'
        >
          <InputWithAdornment
            name='email'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={
              errors.email &&
              (touched.email as unknown as string) &&
              errors.email
            }
          />
        </FormGroup>
        <div className='row-start-2 row-span-1 flex flex-col justify-start items-end gap-3'>
          <FormGroup
            id='password'
            label={t('app.form_labels.password')}
            className='w-full'
          >
            <InputWithAdornment
              type={showPassword ? 'text' : 'password'}
              right={
                <button
                  type='button'
                  onClick={toggleShowPassword}
                  className='h-full flex items-center justify-center'
                >
                  {showPassword ? (
                    <iconComponents.util.EyeCloseIcon className='stroke-jaa-shades-black fill-jaa-shades-black' />
                  ) : (
                    <iconComponents.util.EyeOpenIcon className='stroke-jaa-shades-black fill-jaa-shades-black' />
                  )}
                </button>
              }
              name='password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={
                errors.password &&
                (touched.password as unknown as string) &&
                errors.password
              }
            />
          </FormGroup>
          {!isLoading && (
            <div className='w-full flex justify-end items-center'>
              <Typography
                as='button'
                type='button'
                onClick={openModal}
                className='xs font-medium capitalize text-[#8DA0A5] hover:text-jaa-teal-80'
              >
                {t('app.form_labels.forgot_password')}
              </Typography>
            </div>
          )}
        </div>
        <Button
          disabled={isLoading || !isValid}
          loading={isLoading}
          fullWidth
          type='submit'
          label={t('app.auth.signin')}
          className='row-start-3 row-span-1 !uppercase mt-6'
        />
      </form>
      <Typography
        as='span'
        className='md text-jaa-shades-gray-2.5 text-center w-full'
      >
        {t('app.auth.dont_have_account')}{' '}
        <Typography
          role='link'
          as='button'
          type='button'
          className='text-jaa-teal-100  font-semibold hover:text-jaa-teal-80 capitalize hover:underline '
          onClick={() => {
            navigate('/recruiter/auth/sign-up/primary')
          }}
        >
          {' '}
          {t('app.auth.sign_up')}
        </Typography>
      </Typography>
      <ForgotPassword open={isModalOpen} onClose={closeModal} />
      <VerificationCodeEntry
        email={values.email}
        open={showOtpEntry}
        onClose={closeOtpEntry}
        nextAction={() => {
          closeOtpEntry()
        }}
      />
    </div>
  )
}

export default RecruiterSignIn
