import { addDataToQueryString } from './../helpers/strings'
import { PaginatedData } from './api.types'
import api from './api'
import {
  ACCEPT_ALUMNI,
  CHANGE_PASSWORD_FROM_LINK,
  CREATE_PASSWORD_FROM_LINK,
  FORGOT_PASSWORD,
  GET_ADMINS,
  GET_PERMISSIONS,
  GET_ROLES,
  INVITE_ALUMNIS,
  RECRUITER_ACCEPT_INVITE,
  RECRUITER_INVITE,
  RECRUITER_MEMBERS,
  RECRUITER_PRIMARY_OWNER,
  RECRUITER_SEND_OTP,
  RECRUITER_SIGNUP,
  RECRUITER_VERIFY_OTP,
  SIGNIN,
  SIGNOUT,
  TOGGLEBLOCK,
} from './endpoints'

export type Tokens = {
  access: string
  refresh_token: string
}

export type UserTypes = 'admin' | 'recruiter' | 'alumni'

export type SigninDTO = {
  email: string
  password: string
  user_type?: string
}
export interface RecruiterSignupDTO {
  email: string
  password: string
  first_name: string
  last_name: string
  phone_number: string
  gender: Gender
}

export interface RecruiterPrimaryDTO {
  user_id: number
  name: string
  industry: string
  company_role: string
  country: string
  registration_number: string
  roles_hiring: string[]
}
export type RecruiterMemberSignupDTO = Omit<
  RecruiterSignupDTO,
  'first_name' | 'last_name' | 'email'
> & {
  company_role: string
  name: string
  code: string
}
export interface OTP {
  email?: string
  phone?: string
}

export interface VerifyOTP extends OTP {
  otp: string
  type: 'user' | 'signup'
}

export interface InviteRecruiterMembers {
  members: Member[]
}

export type MemberType = 'primary_admin' | 'secondary_admin' | 'member'

type Gender = 'male' | 'female'
interface Member {
  email: string
  role: MemberType
}

export type PageDTO = {
  search: string
  page: number
}

export type AuthUser = {
  user_id: number
  first_name: string
  last_name: string
  email: string
  phone_number: string
  profile_pic?: any
  user_type: UserTypes
  admin_role?: string
  permissions?: Permission[]
  access: Tokens['access']
}

export type AlumniUser = {
  user_id?: number
  first_name: string
  last_name: string
  email: string
  gender: 'male' | 'female'
  phone_number?: string
  country?: string
  // program: string
  cohort?: string
  password?: string
}

export interface Permission {
  id: number
  name: string
}

export interface AdminRoles {
  id: number
  name: string
  permissions: Permission[]
  description: string
  admins?: AdminUser[]
  no_of_admins?: number
}
export interface AdminUser {
  id: number
  user_id: number
  first_name: string
  last_name: string
  email: string
  phone_number: string
  profile_pic: string
  date_joined: string
  online: boolean
  last_seen: string
  user_type: string
  permission_role: string
  admin_role: string
}
export interface IRecruiter {
  id: number
  user_id: number
  first_name: string
  last_name: string
  email: string
  email_verified: string
  phone_number: string
  phone_verified: string
  profile_pic: string
  date_joined: string
  online: boolean
  user_type: string
  recruiter_role: string
  company: Company
  company_role: string
  banner: string
  last_seen?: string
}

interface Company {
  id: number
  total_engaged_alumni: string
  total_hired_alumni: string
  logo: string
  name: string
  registration_number: string
  industry: Industry
  roles_hiring: Roleshiring[]
  email: string
  address: string
  size: CompanySize
  about: string
  date_joined: string
}

enum Roleshiring {
  'UI UX' = 'UI UX',
}

export enum Industry {
  'Information Technology' = 'Information Technology',
  Healthcare = 'Healthcare',
  Finance = 'Finance',
  Retail = 'Retail',
  Manufacturing = 'Manufacturing',
  Education = 'Education',
  Hospitality = 'Hospitality',
  Government = 'Government',
  'Non-profit' = 'Non-profit',
  'Legal' = 'Legal',
  'Media and Entertainment' = 'Media and Entertainment',
  'Real Estate' = 'Real Estate',
  'Energy and Utilities' = 'Energy and Utilities',
  Transportation = 'Transportation',
  Construction = 'Construction',
}

export const IndustryOptions = [
  'Information Technology',
  'Healthcare',
  'Finance',
  'Retail',
  'Manufacturing',
  'Education',
  'Hospitality',
  'Government',
  'Non-profit',
  'Legal',
  'Media and Entertainment',
  'Real Estate',
  'Energy and Utilities',
  'Transportation',
  'Construction',
]

export enum CompanySize {
  'Less than 10' = 'Less than 10',
  '10 - 50' = '10 - 50',
  '51 - 200' = '51 - 200',
  'More than 200' = 'More than 200',
}

export interface BlockUserDTO {
  user: number
  block: Boolean
  reason?: string
}

export type AlumniInvite = {
  alumni: Alumnus[]
}

interface Alumnus {
  email: string
  cohort: number | string
}
export const recruiterSignup = async (data: RecruiterSignupDTO) => {
  return await api.post<{ data: AuthUser }>(RECRUITER_SIGNUP, data)
}
export const recruiterRequestOTP = async (data: OTP) => {
  return await api.post<{ data: OTP }>(RECRUITER_SEND_OTP, data)
}
export const recruiterVerifyOTP = async (data: VerifyOTP) => {
  return await api.post<{ data: OTP }>(RECRUITER_VERIFY_OTP, data)
}
export const recruiterCreateCompany = async (data: RecruiterPrimaryDTO) => {
  return await api.post<{ data: AuthUser }>(RECRUITER_PRIMARY_OWNER, data)
}

export const recruiterInviteMembers = async (data: InviteRecruiterMembers) => {
  return await api.post<{ data: InviteRecruiterMembers }>(
    RECRUITER_INVITE,
    data
  )
}

export const recruiterAcceptMembers = async (
  data: RecruiterMemberSignupDTO
) => {
  return await api.post<{ data: RecruiterMemberSignupDTO }>(
    RECRUITER_ACCEPT_INVITE,
    data
  )
}

export const signIn = async (data: SigninDTO) => {
  return await api.post<{ data: AuthUser }>(SIGNIN, data)
}
export const signOut = async () => {
  return await api.post<{ data: AuthUser }>(
    SIGNOUT,
    {},
    {
      withCredentials: true,
    }
  )
}
export const forgotPassword = async (data: { email: string }) => {
  return await api.post<{ data: any }>(FORGOT_PASSWORD, data)
}
export const changePasswordFromLink = async (data: {
  new_password: string
  code: string
}) => {
  return await api.post<{ data: any }>(CHANGE_PASSWORD_FROM_LINK, data, {
    withCredentials: false,
  })
}
export const acceptInvitationLink = async (data: {
  password: string
  code: string
}) => {
  return await api.post<{ data: any }>(CREATE_PASSWORD_FROM_LINK, data)
}

export const alumniAcceptInvitation = async (
  data: AlumniUser & {
    code: string
  }
) => {
  return await api.post<{ data: AlumniUser }>(ACCEPT_ALUMNI, data)
}
export const inviteAdmin = async (data: FormData) => {
  return await api.post<{ data: AuthUser }>(GET_ADMINS, data)
}
export const inviteAlumni = async (data: AlumniInvite) => {
  return await api.post<{ data: any }>(INVITE_ALUMNIS, data)
}

export const getAdmins = async (input: PageDTO) => {
  const queryString = addDataToQueryString('', input)
  return (
    await api.get<{ data: PaginatedData<AuthUser> }>(
      GET_ADMINS + `?${queryString}`
    )
  ).data
}

export const updateAdmin = async (role: { data: FormData; id: number }) => {
  const { id, data } = role
  return await api.patch<{ data: AuthUser }>(GET_ADMINS + id + '/', data)
}
export const deleteAdmin = async (id: number) => {
  return await api.delete<{ data: any }>(GET_ADMINS + id + '/')
}
export const changeSuperAdmin = async (role: FormData) => {
  return await api.patch<{ data: AdminUser }>(
    GET_ADMINS + 'change-owner/',
    role
  )
}
export const getRoles = async (input: PageDTO) => {
  const queryString = addDataToQueryString('', input)
  return (
    await api.get<{ data: PaginatedData<AdminRoles> }>(
      GET_ROLES + `?${queryString}`
    )
  ).data
}
export const getRole = async (id: number) => {
  return (await api.get<{ data: AdminRoles }>(GET_ROLES + `${id}/`)).data
}
export const createRole = async (
  role: Omit<AdminRoles, 'permissions' | 'id'> & {
    permissions: number[]
  }
) => {
  return await api.post<{ data: AdminRoles }>(GET_ROLES, role)
}
export const updateRole = async (
  role: Partial<
    Omit<AdminRoles, 'permissions' | 'id'> & {
      permissions: number[]
    }
  > & {
    id: number
  }
) => {
  const { id, ...rest } = role
  return await api.patch<{ data: AdminRoles }>(GET_ROLES + id + '/', rest)
}

export const deleteRole = async (id: number) => {
  return await api.delete<{ data: any }>(GET_ROLES + id + '/')
}
export const getAdminPermissions = async () => {
  return (await api.get<{ data: Permission[] }>(GET_PERMISSIONS)).data
}

export const getRecruiterMembers = async (input: PageDTO) => {
  const queryString = addDataToQueryString('', input)
  return (
    await api.get<{ data: PaginatedData<IRecruiter> }>(
      RECRUITER_MEMBERS + `?${queryString}`
    )
  ).data
}
export const recruiterUpdateMemberRole = async (payload: {
  data: FormData
  id: number
}) => {
  return await api.patch<{ data: IRecruiter }>(
    RECRUITER_MEMBERS + `${payload.id}/update-role/`,
    payload.data
  )
}
export const recruiterDeleteMember = async (payload: { id: number }) => {
  return await api.delete<any>(RECRUITER_MEMBERS + `${payload.id}/`, {})
}

export const blockUser = async (data: BlockUserDTO) => {
  return await api.post<{ data: BlockUserDTO }>(`${TOGGLEBLOCK}`, data)
}
