import { Navbar } from './components/Navbar/Navbar'
import { Connect } from './Connect/connect'
import { Faqs } from './Faqs/faq'
import { Footer } from './Footer/footer'
import { Started } from './GetStarted/started'
import { Hero } from './Hero/hero'
import { Skills } from './Skills/skills'
import { Testimonial } from './Testimonial/testimonial'
import { Workforce } from './Workforce/workforce'

function App() {
  return (
    <div className='App'>
      <Navbar />
      <Hero />
      <Connect />
      <Skills />
      <Workforce />
      <Testimonial />
      <Started />
      <Faqs />
      <Footer />
    </div>
  )
}

export default App
