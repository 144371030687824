import { PaginatedData } from '@/apis/api.types'
import {
  INotifications,
  IUnreadCount,
  getNotifications,
  getNotificationsUnread,
  readNotifications,
} from '@/apis/notificationApis'
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'

export const notificationApiSlice = createApi({
  reducerPath: 'notificationApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Notifications'],

  endpoints: (builder) => ({
    getNotification: builder.query<PaginatedData<INotifications>, void>({
      queryFn: async (data) => {
        try {
          return await getNotifications()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Notifications'],
    }),
    getNotificationUnread: builder.query<IUnreadCount, void>({
      queryFn: async (data) => {
        try {
          return await getNotificationsUnread()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Notifications'],
    }),
    markNotificationAsRead: builder.mutation<{ data: void }, void>({
      queryFn: async (data) => {
        try {
          return await readNotifications()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
    }),
  }),
})

export const {
  useGetNotificationQuery,
  useGetNotificationUnreadQuery,
  useMarkNotificationAsReadMutation,
} = notificationApiSlice

export const resetNotificationApiSlicee = () =>
  notificationApiSlice.util.resetApiState()

export const invalidateNotificationApiSlice =
  notificationApiSlice.util.invalidateTags
