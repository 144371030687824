import CircularProgress from '@/common/spinners/CircularProgress'
import Typography from '@/common/Typography'
import React from 'react'

type LoadingProps = {
  text: string
  loading: boolean
}
const LoadingText = (props: LoadingProps) => {
  return (
    <div className='flex flex-col justify-center items-center'>
      <Typography as='strong' className='text-jaa-teal-100 md font-normal'>
        {props.text}
      </Typography>
      {props.loading && (
        <CircularProgress color='var(--teal-100)' size='25px' />
      )}
    </div>
  )
}

export default LoadingText
