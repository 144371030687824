import { COMMENT } from '@/apis/forumApis'
import iconComponents from '@/assets/icons/iconComponents'
import { RouteAwareCrumbs } from '@/common/breadcrumbs'
import Button from '@/common/button'
import Textarea from '@/common/Input/Textarea'
import LazySpinner from '@/common/spinners/LazySpinner'
import Title from '@/common/Title'
import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import { withAsync } from '@/helpers/withAsync'
import useMismatchPages from '@/hooks/table/useMismatchPages'
import useMediaQuery from '@/hooks/useMediaQuery'
import useQueryParams from '@/hooks/useQueryParams'
import { useToggleState } from '@/hooks/useToggleState'
import { BackendToUI } from '@/pages/conversion'
import { getUserType } from '@/store/authSlice'
import {
  useCreateCommentMutation,
  useGetFeedCommentsQuery,
  useGetFeedQuery,
  useGetPostUpvotesQuery,
} from '@/store/forumSlice'
import { useAppSelector } from '@/store/hooks'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import { useInView } from 'react-hook-inview'
import { useNavigate, useParams } from 'react-router'
import { BeatLoader, ScaleLoader } from 'react-spinners'
import CommentCard from './components/CommentCard'
import FeedCard from './components/FeedCard'
import FeedGalleryView from './components/FeedGalleryView'
import Upvotes from './components/Upvotes'
import { useTranslation } from 'react-i18next'

const NameMap = new Map()

const AlumniFeedDetails = () => {
  const { postId } = useParams()
  const userType = useAppSelector(getUserType)

  NameMap.set(`/${BackendToUI[userType as 'alumnus']}/app/forum`, 'Forum')
  NameMap.set(
    `/${BackendToUI[userType as 'alumnus']}/app/forum/${postId}`,
    'Feed Details'
  )

  return (
    <section className='w-full bg-jaa-shades-bg px-6 pb-8 flex flex-col gap-4 justify-start items-start relative lg:max-h-[90vh] lg:overflow-auto '>
      <header className='flex justify-between items-center w-full'>
        <RouteAwareCrumbs nameMap={NameMap} />
        <Title>JA Africa - Forum Detail</Title>
      </header>
      <FeedDetailsBody />
    </section>
  )
}

export const FeedDetailsBody = (props: { upvotesUrl?: string }) => {
  const { upvotesUrl } = props
  const { t } = useTranslation()
  const [clickedMedia, setClickedMedia] = useState(0)
  const { postId } = useParams()
  const {
    state: showExpandedView,
    open: openShowExpandedView,
    close: closeShowExpandedView,
  } = useToggleState()
  const { type = 'general' } = useQueryParams()
  const { data: fullPost, isLoading } = useGetFeedQuery(Number(postId))
  return (
    <>
      <main className='grid grid-cols-12 gap-4 w-full'>
        <section className='col-span-12 lg:col-span-8 flex flex-col gap-4'>
          {isLoading ? (
            <LazySpinner show>
              <div className='w-full h-full min-h-[50vh] flex flex-col items-center justify-center bg-jaa-shades-white rounded-lg gap-4'>
                <ScaleLoader loading color='var(--teal-100)' />
              </div>
            </LazySpinner>
          ) : (
            fullPost && (
              <FeedCard
                post={fullPost!}
                onGalleryClick={(index) => {
                  flushSync(() => setClickedMedia(index))
                  openShowExpandedView()
                }}
              />
            )
          )}
          <div className='hidden lg:flex flex-col gap-4'>
            <PostComments />
          </div>
        </section>
        <section className='col-span-12 lg:col-span-4 grid grid-cols-12 gap-4'>
          <div className='row-start-3 md:row-start-2 row-span-1 col-span-12  md:col-span-6 lg:col-span-12 lg:hidden flex flex-col gap-4'>
            <PostComments />
          </div>
          <aside className='col-span-12 row-start-2 lg:row-start-1  row-span-1 md:col-span-6 lg:col-span-12'>
            <PostUpVotesPreview upvotesUrl={upvotesUrl} />
          </aside>
        </section>
      </main>
      {fullPost && (
        <FeedGalleryView
          open={showExpandedView}
          onClose={closeShowExpandedView}
          data={{
            ...fullPost,
            index: clickedMedia,
          }}
        />
      )}
    </>
  )
}

const PostUpVotesPreview = (props: { upvotesUrl?: string }) => {
  const { upvotesUrl } = props
  const isMobile = useMediaQuery('(max-width: 600px)')
  const { postId } = useParams()
  const { t } = useTranslation()

  const { data: paginatedUpvotes } = useGetPostUpvotesQuery({
    page: 1,
    postId: Number(postId),
  })
  const navigate = useNavigate()
  const userType = useAppSelector(getUserType)

  return isMobile ? (
    <section className='w-full flex bg-jaa-shades-white items-center justify-between gap-2.5 rounded-lg py-2 px-4'>
      <hgroup className='flex flex-col items-start'>
        <Typography as='h3' className='font-semibold sm text-jaa-shades-black'>
          Upvoted by
        </Typography>
        <Typography as='p'>
          {`${paginatedUpvotes?.count} ${t('app.forum.upvote_count')}`}
        </Typography>
      </hgroup>
      <button
        type='button'
        className='w-[28px] h-[28px] flex justify-center items-center'
        onClick={() =>
          navigate(
            upvotesUrl ||
              `/${BackendToUI[userType as 'alumnus']}/app/forum/votes/${postId}`
          )
        }
      >
        <iconComponents.util.ChevronRightIcon className='stroke-jaa-shades-black' />
      </button>
    </section>
  ) : (
    <section className='lg:sticky top-3 bg-jaa-shades-white h-[89vh] lg:h-[762px] rounded-lg shadow-[0px_1px_2px_0px_#1018280D] flex flex-col items-start  gap-8   '>
      <Upvotes />
    </section>
  )
}

const PostComments = () => {
  const { postId } = useParams()
  const CommentInputRef = useRef<HTMLTextAreaElement>(null)
  const { t } = useTranslation()

  const [loadMoreRef, inView] = useInView()
  const [allComments, setAllComments] = useState<Array<COMMENT[]>>([])
  const { requestPage, setRequestPage } = useMismatchPages({
    requestPerPage: 20,
  })

  const {
    data: paginatedComments,
    isFetching,
    isLoading,
  } = useGetFeedCommentsQuery(
    {
      page: requestPage,
      postId: Number(postId),
    },
    {
      skip: !postId,
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    if (!(inView && paginatedComments)) return
    const nextPage = paginatedComments.next
      ? new URL(paginatedComments.next).searchParams.get('page')
      : 0

    if (!nextPage) return

    setRequestPage(Number(nextPage))
  }, [inView, paginatedComments, setRequestPage])
  useEffect(() => {
    if (!paginatedComments) return
    setAllComments((prev) => {
      const newState = [...prev]
      newState[requestPage - 1] = paginatedComments.results
      const maxPages = Math.ceil(paginatedComments.count / 20)
      return newState.slice(0, maxPages)
    })
  }, [isLoading, paginatedComments, requestPage])

  useEffect(() => {
    CommentInputRef.current?.focus?.()
  }, [])
  const collapsedComments = allComments.flat(2)
  return isLoading ? (
    <LazySpinner show>
      <div className='w-full h-full min-h-[50vh] flex flex-col items-center justify-center bg-jaa-shades-white rounded-lg gap-4'>
        <ScaleLoader loading color='var(--teal-100)' />
      </div>
    </LazySpinner>
  ) : (
    <section className='bg-jaa-shades-white h-[89vh] lg:h-[762px] rounded-lg shadow-[0px_1px_2px_0px_#1018280D] flex flex-col items-start  gap-8 p-4   '>
      <header className='flex flex-col items-start'>
        <hgroup className='flex flex-col items-start'>
          <Typography
            as='h5'
            className='font-medium text-jaa-shades-black lg md:h4'
          >
            {t('app.forum.comment_label')}
          </Typography>
          <Typography as='p' className='flex items-center gap-1'>
            <span className='w-1 h-1 rounded-full bg-jaa-shades-gray-2.5'></span>
            <span className='font-normal text-jaa-shades-gray-2.5'>
              {`${paginatedComments?.count} ${t('app.forum.comment_count')}`}
            </span>
          </Typography>
        </hgroup>
      </header>
      <AddComment commentInputRef={CommentInputRef} postId={Number(postId)} />
      <main className='w-full flex flex-col gap-6 flex-1 overflow-auto'>
        {collapsedComments.map((el) => (
          <CommentCard comment={el} key={el.id} />
        ))}
        {isFetching ? (
          <div className='w-full flex justify-center items-center'>
            <BeatLoader color='var(--teal-100)' size={10} />
          </div>
        ) : null}{' '}
      </main>
      {paginatedComments?.count === 0 && (
        <div className='w-full h-full min-h-[30vh] bg-jaa-shades-white flex items-center justify-center'>
          <Typography className='h-full flex-1 text-jaa-shades-black w-full flex justify-center items-center h5 min-h-[60vh] bg-jaa-shades-white'>
            {t('app.forum.no_comment_label')}
          </Typography>
        </div>
      )}
      <div id='top' ref={loadMoreRef}></div>
    </section>
  )
}

const AddComment = ({
  postId,
  commentInputRef,
}: {
  postId: number
  commentInputRef: MutableRefObject<any>
}) => {
  const { t } = useTranslation()

  const [addComment, { isLoading: isCreating }] = useCreateCommentMutation()
  const currentAlumni = useAppSelector((state) => state.auth)
  const [comment, setComment] = useState('')
  const createComment = async () => {
    if (!comment || !postId) return
    const { error, response } = await withAsync(() =>
      addComment({
        post: postId,
        body: comment,
      })
    )
    if (error) {
      return ToastNotify('error', {
        message: (error as any)?.message || 'Ooops... could not create comment',
      })
    }
    if (response) {
      setComment('')
    }
  }
  return (
    <footer className='w-full bg-jaa-shades-white  flex items-center py-4   self-stretch gap-4'>
      <div className='flex items-center gap-3 flex-1'>
        <img
          src={currentAlumni.profile_pic || '/defaultImage.png'}
          onError={(e) => {
            e.currentTarget.src = '/defaultImage.png'
            const target: any = e.target
            target.src = '/defaultImage.png'
            e.target = target
          }}
          className='w-8 h-8 rounded-full object-cover object-center hidden lg:inline-block'
          alt='representation of logged in user'
        />
        <div className='flex-1'>
          <Textarea
            placeholder={`${t('app.forum.comment_placeholder')}`}
            ref={commentInputRef}
            value={comment}
            onChange={(e) => setComment(e.currentTarget.value)}
            style={{
              height: '40px',
            }}
            className='appearance-none !h-[40px] max-h-full focus:active:outline-none outline-none md !leading-5 px-4  border-none text-jaa-shades-black placeholder:jaa-dark-teal-60 bg-[#F1F3F4]'
          />
        </div>
      </div>
      <Button
        onClick={createComment}
        loading={isCreating}
        label={
          <span>
            <iconComponents.forum.SendIcon className='w-5 h-5 md:hidden' />
            <span className='hidden md:inline-block'>
              {t('app.forum.send')}
            </span>
          </span>
        }
        className='!w-11 h-9 !stroke-jaa-shades-white md:!w-[84px] '
      />
    </footer>
  )
}
export default AlumniFeedDetails
