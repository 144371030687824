import { IPortfolio } from '@/apis/profileApis'
import Button from '@/common/button'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import Textarea from '@/common/Input/Textarea'
import Modal from '@/common/modal/Modal'
import ModalHeader from '@/common/modal/ModalHeader'
import { ToastNotify } from '@/common/toastManager'
import { isValidHttpUrl } from '@/helpers/strings'
import { withAsync } from '@/helpers/withAsync'
import portfolioSchema from '@/pages/profile/validationSchemas/portfolio.validator'
import {
  useCreateAlumniPortfolioImageMutation,
  useCreateAlumniPortfolioMutation,
  useDeleteAlumniPortfolioImageMutation,
  useDeleteAlumniPortfolioMutation,
  useUpdateAlumniPortfolioImageMutation,
  useUpdateAlumniPortfolioMutation,
} from '@/store/profileSlice'

import { FormikHelpers, useFormik } from 'formik'
import { useMemo } from 'react'
import { PortFolio } from './form.type'
import Links from './Links'
import UploadImage from './UploadImage'
import { useTranslation } from 'react-i18next'

type IOpenable = {
  open: boolean
  onClose: () => void
  data?: IPortfolio
}

const CreateOrUpdatePortfolio = (props: IOpenable) => {
  const { open, onClose, data } = props
  const [createPortfolio] = useCreateAlumniPortfolioMutation()
  const [updatePortfolio] = useUpdateAlumniPortfolioMutation()

  const [createImage] = useCreateAlumniPortfolioImageMutation()
  const [updateImage] = useUpdateAlumniPortfolioImageMutation()
  const [deleteImage] = useDeleteAlumniPortfolioImageMutation()
  const initialValues: PortFolio = useMemo(
    () => getFormattedData(data) as PortFolio,
    [data]
  )
  const prepareRequest = (values: PortFolio) => {
    const req: Partial<IPortfolio> = {}
    if (!data) {
      req.title = values.title
      req.description = values.description
      if (values.links?.length) req.links = values.links
    } else {
      if (values.title !== data.title) {
        req.title = values.title
      }
      if (values.description !== data.description) {
        req.description = values.description
      }
      if (values.links?.length) req.links = values.links
      req.links = values.links
    }
    return req as IPortfolio
  }
  const submitHandler = async (
    values: PortFolio,
    {}: FormikHelpers<PortFolio>
  ) => {
    const req = prepareRequest(values)
    const fd = new FormData()
    Object.entries(req).forEach(([key, value]) => {
      fd.set(key, value)
    })
    const { error, response } = await withAsync(() =>
      data
        ? updatePortfolio({
            id: data.id,
            data: fd,
          }).unwrap()
        : createPortfolio(fd).unwrap()
    )
    if (error) {
      return ToastNotify('error', {
        message:
          (error as any).message ||
          "Ooops couldn't create or update portfolio ",
      })
    }
    if (!response) return
    const { data: result } = response || {}
    const { newImages, deletedImages, modifiedImages } = prepareImage(
      values.images,
      initialValues.images
    )
    if (!data) {
      const defaultImage = newImages.findIndex((image) => image.default)
      if (defaultImage === -1) newImages[0].default = true
    }
    const { error: newImageError, response: newImageResponse } =
      await withAsync(() =>
        Promise.all(
          newImages.map((image) => {
            const form = new FormData()
            form.set('image', image.file || '')
            form.set('default', (image.default || false) as unknown as string)
            form.set('portfolio', result.id as unknown as string)
            return createImage(form).unwrap()
          })
        )
      )

    console.log(newImageResponse, 'IMage Response', newImages)
    const { error: deletedError, response: deletedResponse } = await withAsync(
      () =>
        Promise.all(
          deletedImages.map((image) => {
            return deleteImage(image.id || 0).unwrap()
          })
        )
    )
    console.log(deletedResponse, 'Image Deleted Response', deletedImages)
    const { error: updatedError, response: updatedResponse } = await withAsync(
      () =>
        Promise.all(
          modifiedImages.map((image) => {
            const form = new FormData()

            form.set('default', image.default as unknown as string)

            return updateImage({
              id: image.id as number,
              data: form,
            }).unwrap()
          })
        )
    )
    console.log(updatedResponse, 'Image Updated Response', modifiedImages)
    ToastNotify('success', {
      message: (response as any).message || 'Successfully created Porfolio',
    })
    closeHandler()
  }
  const {
    values,
    errors,
    touched,
    submitForm,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    setValues,
    resetForm,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues,
    enableReinitialize: !!data,
    onSubmit: submitHandler,
    validationSchema: portfolioSchema,
  })
  const closeHandler = () => {
    onClose()
    // setValues({
    //   links: [],
    //   description: '',
    //   images: [],
    //   title: '',
    //   created_at: '',
    // })
    resetForm()
  }
  const { t } = useTranslation()
  return (
    <Modal
      open={open}
      onClose={onClose}
      variant='md'
      className='p-4 sm:p-6 relative'
    >
      <ModalHeader
        title={
          data
            ? t('app.profile.edit_project')
            : t('app.profile.upload_portfolio')
        }
        subText={data ? '' : t('app.profile.upload_portfolio_desc')}
        onClose={closeHandler}
      />
      <div className='flex-1 flex flex-col items-start justify-start w-full bg-jaa-shades-white  '>
        <form
          onSubmit={handleSubmit}
          className='flex flex-col justify-start items-start w-full max-h-[500px]  scrollbar-track-jaa-teal-20 scrollbar-w-2 scrollbar-thumb-jaa-teal-100  overflow-auto pb-8 gap-8'
        >
          <FormGroup
            id={'title'}
            label={t('app.form_labels.title')}
            required
            className=''
          >
            <InputWithAdornment
              value={values.title}
              error={
                errors.title &&
                (touched.title as unknown as string) &&
                errors.title
              }
              name='title'
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGroup>
          <UploadImage values={values} setFieldValue={setFieldValue} />
          <FormGroup
            id={'description'}
            label={t('app.form_labels.description')}
            required
            subtext={`${values.description.length} / 1500`}
            className=''
          >
            <Textarea
              rows={7}
              className='bg-[#F7F7F7] rounded p-3.5 gap-1 md'
              value={values.description}
              error={
                errors.description &&
                (touched.description as unknown as string) &&
                errors.description
              }
              name='description'
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={`${t('app.say_something')}`}
            />
          </FormGroup>
          <Links values={values} setFieldValue={setFieldValue} />
        </form>
      </div>

      <div className='pb-4 bg-jaa-shades-white sm:pb-4 grid grid-cols-2 gap-x-4 w-[calc(100%_-_32px)] sm:w-[calc(100%_-_48px)] sm:flex sm:justify-start sm:items-center '>
        <Button
          onClick={submitForm}
          label={data ? t('app.button.save') : t('app.button.upload')}
          className='!uppercase sm:w-[191px]'
          loading={isSubmitting}
          disabled={!isValid || isSubmitting}
        />
        <Button
          label={t('app.button.cancel')}
          disabled={isSubmitting}
          variant='cancel'
          className='!uppercase sm:w-[191px]'
          onClick={closeHandler}
        />
      </div>
    </Modal>
  )
}

const getFormattedData = (data?: IPortfolio) => {
  if (!data)
    return {
      links: [],
      description: '',
      images: [],
      title: '',
    }
  return {
    ...data,
    images: data.images.map((image) => ({
      file: undefined,
      src: image.image,
      default: image.default,
      id: image.id,
    })),
  }
}

type Images = {
  file?: File
  src: string
  default?: boolean
  id?: number
}[]
type ReturnImages = {
  newImages: Images
  modifiedImages: Images
  deletedImages: Images
}
export const prepareImage = (
  images: Images,
  originalImages: Images
): ReturnImages => {
  const req: ReturnImages = {
    newImages: [],
    modifiedImages: [],
    deletedImages: [],
  }
  images.forEach((image) => {
    //For new image
    if (image.file) {
      req.newImages.push(image)
    }
  })
  //if old images where change from default
  const oldDefault = originalImages.find((image) => image.default)
  const newDefault = images.find((image) => image.default)
  if (oldDefault?.src !== newDefault?.src) {
    if (isValidHttpUrl(oldDefault?.src || '') && oldDefault) {
      req.modifiedImages.push({
        ...oldDefault,
        default: false,
      })
    }
    if (isValidHttpUrl(newDefault?.src || '') && newDefault) {
      req.modifiedImages.push({
        ...(newDefault || {}),
        src: newDefault?.src,
        id:
          originalImages.find((el: any) => newDefault?.src === el.src)?.id || 0,
      })
    }
  }
  //if old images were present, delete the old images and put the new ones

  const deletedImages = originalImages.filter((originalImage: any) => {
    const presentImage = images.find(
      (workingImage) => workingImage.src === originalImage.src
    )
    return !presentImage
  })
  req.deletedImages = req.deletedImages.concat(deletedImages)
  return req
}
export default CreateOrUpdatePortfolio
