import { COMMENT } from '@/apis/forumApis'
import Typography from '@/common/Typography'
import {
  useDownVoteCommentMutation,
  useUpVoteCommentMutation,
} from '@/store/forumSlice'
import { useAppSelector } from '@/store/hooks'
import CommentHeader from '../../components/CommentHeader'
import Interaction from '../../components/Interaction'
import ReportButton from './ReportButton'

type CommentProps = {
  comment: COMMENT
}

const CommentCard = (props: CommentProps) => {
  const { comment } = props

  return (
    <section className='flex w-full flex-col gap-1'>
      <CommentHeader comment={comment} />
      <main className='w-full p-[0px_0px_0px_44px]'>
        <div className='flex flex-col items-start px-3 py-3 bg-jaa-shades-bg rounded-lg w-full self-stretch'>
          <Typography as='p' className='sm font-normal text-jaa-shades-black'>
            {comment.body}
          </Typography>
          <CommentActions comment={comment} />
        </div>
      </main>
    </section>
  )
}

const CommentActions = (props: CommentProps) => {
  const { comment } = props
  const currentAlumni: any = useAppSelector((state) => state.auth)
  const [upvoteComment] = useUpVoteCommentMutation()
  const [downvoteComment] = useDownVoteCommentMutation()
  return (
    <footer className='w-full justify-between flex items-center gap-8'>
      <div className='flex items-center gap-5'>
        <Interaction
          text={Number(comment.num_upvotes || '0').toLocaleString('en-GB')}
          type='upvote'
          status={comment.vote === 'upvoted'}
          onClick={() => upvoteComment(comment.id)}
        />
        <Interaction
          text={Number(comment.num_downvotes || '0').toLocaleString('en-GB')}
          type='downvote'
          status={comment.vote === 'downvoted'}
          onClick={() => downvoteComment(comment.id)}
        />
      </div>
      <div>
        {comment.user_id !== currentAlumni.user_id && (
          <ReportButton object_id={comment.id} type='comment' />
        )}
      </div>
    </footer>
  )
}

export default CommentCard
