import {
  AdminUser,
  AlumniInvite,
  changeSuperAdmin,
  deleteAdmin,
  deleteRole,
  getRecruiterMembers,
  getRole,
  InviteRecruiterMembers,
  IRecruiter,
  OTP,
  recruiterAcceptMembers,
  recruiterCreateCompany,
  recruiterDeleteMember,
  recruiterInviteMembers,
  RecruiterMemberSignupDTO,
  RecruiterPrimaryDTO,
  recruiterRequestOTP,
  recruiterUpdateMemberRole,
  recruiterVerifyOTP,
  updateAdmin,
  updateRole,
  VerifyOTP,
} from '@/apis/authApis'
import { PaginatedData } from '@/apis/api.types'

import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AuthUser,
  signIn,
  forgotPassword,
  SigninDTO,
  getAdmins,
  getRoles,
  AdminRoles,
  createRole,
  getAdminPermissions,
  inviteAdmin,
  PageDTO,
  Permission,
  changePasswordFromLink,
  acceptInvitationLink,
  signOut,
  inviteAlumni,
  AlumniUser,
  alumniAcceptInvitation,
  recruiterSignup,
  RecruiterSignupDTO,
} from '@/apis/authApis'
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { Storage } from '@/helpers/Storage'

export type AuthState = Partial<AuthUser>
const userDetails = Storage.getItem('USER_DETAILS')
// const token = Storage.getItem('TOKEN_KEY')
const permissions = Storage.getItem('PERMISSIONS')
const initialState: AuthState = {
  permissions: permissions || [],
  ...(userDetails || {}),
  // access: token,
}

//The builder.query method expects two types,
//the first one is the type for the data that will be stored in the API slice
//and the second one is for an argument that will be passed to the query method.

export const authApiSlice = createApi({
  reducerPath: 'authApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Authentications', 'Admins', 'Recruiters'],
  endpoints: (builder) => ({
    fetchAdmins: builder.query<PaginatedData<AuthUser>, PageDTO>({
      queryFn: async (data) => {
        try {
          return await getAdmins(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Admins'],
    }),
    updateAdmin: builder.mutation<
      { data: AuthUser },
      { id: number; data: FormData }
    >({
      queryFn: async (data) => {
        try {
          return await updateAdmin(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Admins'],
    }),
    changeSuperAdmin: builder.mutation<{ data: AdminUser }, FormData>({
      queryFn: async (data) => {
        try {
          return await changeSuperAdmin(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Admins', 'Authentications'],
    }),
    deleteAdmin: builder.mutation<{ data: any }, number>({
      queryFn: async (data) => {
        try {
          return await deleteAdmin(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Admins'],
    }),
    fetchAdminRoles: builder.query<PaginatedData<AdminRoles>, PageDTO>({
      queryFn: async (data) => {
        try {
          return await getRoles(data)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
      providesTags: ['Admins'],
    }),
    getAdminRole: builder.query<AdminRoles, number>({
      queryFn: async (data) => {
        try {
          return await getRole(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Admins'],
    }),
    updateRole: builder.mutation<
      { data: AdminRoles },
      Partial<
        Omit<AdminRoles, 'permissions' | 'id'> & {
          permissions: number[]
        }
      > & {
        id: number
      }
    >({
      queryFn: async (data) => {
        try {
          return await updateRole(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Admins'],
    }),
    deleteRole: builder.mutation<{ data: any }, number>({
      queryFn: async (data) => {
        try {
          return await deleteRole(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Admins'],
    }),
    fetchAllAdminPermissions: builder.query<Permission[], void>({
      queryFn: async () => {
        try {
          return await getAdminPermissions()
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
      providesTags: ['Admins'],
    }),

    recruiterSignup: builder.mutation<{ data: AuthUser }, RecruiterSignupDTO>({
      queryFn: async (user) => {
        try {
          return await recruiterSignup(user)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
    }),
    recruiterRequestOTP: builder.mutation<{ data: OTP }, OTP>({
      queryFn: async (otp) => {
        try {
          return await recruiterRequestOTP(otp)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
    }),
    recruiterVerifyOTP: builder.mutation<{ data: OTP }, VerifyOTP>({
      queryFn: async (otp) => {
        try {
          return await recruiterVerifyOTP(otp)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
    }),
    recruiterCreateCompany: builder.mutation<
      { data: AuthUser },
      RecruiterPrimaryDTO
    >({
      queryFn: async (user) => {
        try {
          return await recruiterCreateCompany(user)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
      onQueryStarted: async (user, { dispatch, queryFulfilled, getState }) => {
        await queryFulfilled
        let resp: any
        const { mutations } = getState().authApi
        Object.keys(mutations).forEach((key: string) => {
          const mutation = mutations[key]!
          if (
            mutation.endpointName === 'recruiterCreateCompany' &&
            mutation.status === 'fulfilled'
          ) {
            resp = (mutation.data as { data: AuthUser })?.data
          }
        })
        if (resp)
          dispatch(
            setCredentials({
              ...resp,
              ...(resp.primary_admin ? resp.primary_admin : {}),
            })
          )
      },
    }),
    recruiterInviteMembers: builder.mutation<
      { data: InviteRecruiterMembers },
      InviteRecruiterMembers
    >({
      queryFn: async (members) => {
        try {
          return await recruiterInviteMembers(members)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
    }),
    recruiterAcceptMembers: builder.mutation<
      { data: RecruiterMemberSignupDTO },
      RecruiterMemberSignupDTO
    >({
      queryFn: async (member) => {
        try {
          return await recruiterAcceptMembers(member)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
    }),
    recruiterMembers: builder.query<PaginatedData<IRecruiter>, PageDTO>({
      queryFn: async (data: PageDTO) => {
        try {
          return await getRecruiterMembers(data)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
      providesTags: ['Recruiters'],
    }),
    recruiterUpdateMemberRole: builder.mutation<
      { data: IRecruiter },
      { data: FormData; id: number }
    >({
      queryFn: async (payload) => {
        try {
          return await recruiterUpdateMemberRole(payload)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
      invalidatesTags: ['Recruiters'],
    }),
    recruiterDeleteMember: builder.mutation<any, { id: number }>({
      queryFn: async (payload) => {
        try {
          return await recruiterDeleteMember(payload)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
      invalidatesTags: ['Recruiters'],
    }),
    signIn: builder.mutation<{ data: AuthUser }, SigninDTO>({
      queryFn: async (user) => {
        try {
          return await signIn(user)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
      onQueryStarted: async (user, { dispatch, queryFulfilled, getState }) => {
        await queryFulfilled
        let resp
        const { mutations } = getState().authApi
        Object.keys(mutations).forEach((key: string) => {
          const mutation = mutations[key]!
          if (
            mutation.endpointName === 'signIn' &&
            mutation.status === 'fulfilled'
          ) {
            resp = (mutation.data as { data: AuthUser })?.data
          }
        })
        if (resp) dispatch(setCredentials(resp))
      },
    }),
    signOut: builder.mutation<{ data: AuthUser }, void>({
      queryFn: async () => {
        try {
          return await signOut()
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
    }),
    forgotPassword: builder.mutation<{ data: any }, { email: string }>({
      queryFn: async (email) => {
        try {
          return await forgotPassword(email)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
      invalidatesTags: ['Authentications'],
    }),
    recoverPassword: builder.mutation<
      { data: any },
      { new_password: string; code: string }
    >({
      queryFn: async (data) => {
        try {
          return await changePasswordFromLink(data)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
      invalidatesTags: ['Authentications'],
    }),
    acceptInvitation: builder.mutation<
      { data: any },
      { password: string; code: string }
    >({
      queryFn: async (data) => {
        try {
          return await acceptInvitationLink(data)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
      invalidatesTags: ['Authentications'],
    }),
    inviteAdmin: builder.mutation<{ data: AuthUser }, FormData>({
      queryFn: async (user) => {
        try {
          return await inviteAdmin(user)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
      invalidatesTags: ['Authentications', 'Admins'],
    }),
    inviteAlumni: builder.mutation<{ data: any }, AlumniInvite>({
      queryFn: async (emails) => {
        try {
          return await inviteAlumni(emails)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
      invalidatesTags: ['Authentications', 'Admins'],
    }),
    acceptAlumniInvite: builder.mutation<
      { data: AlumniUser },
      AlumniUser & { code: string }
    >({
      queryFn: async (data) => {
        try {
          return await alumniAcceptInvitation(data)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
      invalidatesTags: ['Authentications', 'Admins'],
    }),
    createAdminRole: builder.mutation<
      { data: AdminRoles },
      Omit<AdminRoles, 'permissions' | 'id'> & {
        permissions: number[]
      }
    >({
      queryFn: async (role) => {
        try {
          return await createRole(role)
        } catch (error) {
          throw new Error(
            (error as any).response?.data?.message || (error as any).message
          )
        }
      },
      invalidatesTags: ['Authentications', 'Admins'],
    }),

    // removeUser: builder.mutation<boolean, undefined >({ queryFn: async (user) => {
    // await  await new Promise((resolve, reject) => {})
    // },
    // invalidatesTags: ['Authentications'],
    // onQueryStarted: async (user, { dispatch, queryFulfilled }) => {
    // dispatch(setDeletingUserId(user.id)) await queryFulfilled dispatch(setDeletingUserId(null))
    // }, }),
  }),
})
export const {
  useSignInMutation,
  useSignOutMutation,
  useFetchAdminsQuery,
  useUpdateAdminMutation,
  useDeleteAdminMutation,
  useFetchAdminRolesQuery,
  useChangeSuperAdminMutation,
  useGetAdminRoleQuery,
  useDeleteRoleMutation,
  useUpdateRoleMutation,
  useFetchAllAdminPermissionsQuery,
  useInviteAdminMutation,
  useCreateAdminRoleMutation,
  useForgotPasswordMutation,
  useRecoverPasswordMutation,
  useAcceptInvitationMutation,
  useInviteAlumniMutation,
  useAcceptAlumniInviteMutation,
  useRecruiterSignupMutation,
  useRecruiterRequestOTPMutation,
  useRecruiterVerifyOTPMutation,
  useRecruiterCreateCompanyMutation,
  useRecruiterInviteMembersMutation,
  useRecruiterAcceptMembersMutation,
  useRecruiterMembersQuery,
  useRecruiterDeleteMemberMutation,
  useRecruiterUpdateMemberRoleMutation,
  usePrefetch,
} = authApiSlice
export const authUser = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<AuthState>) => {
      const { access, permissions, ...other } = action.payload
      console.log(action.payload)
      Storage.setItem('USER_DETAILS', other)
      Storage.setItem('PERMISSIONS', permissions)
      // Storage.setItem('TOKEN_KEY', access)
      return action.payload
    },
    logOut: (state) => {
      Storage.removeItem('USER_DETAILS')
      Storage.removeItem('PERMISSIONS')
      Storage.removeItem('USER_TYPE')
      return {
        access: '',
        user_type: state.user_type,
      }
    },
  },
})
export const resetAuthApiSlice = () => authApiSlice.util.resetApiState()
// export const initialiseAuthApi = () =>
//   authApiSlice.endpoints.fetchUser.initiate()
export const { logOut, setCredentials } = authUser.actions
export const getPermissions = (state: RootState) => state.auth.permissions
export const getAccessTokens = (state: RootState) => state.auth.access
export const getUserType = (state: RootState) => state.auth.user_type
export default authUser.reducer
