import { VOTE } from '@/apis/forumApis'
import Typography from '@/common/Typography'
import { useAppSelector } from '@/store/hooks'

const VoteCard = (props: { vote: VOTE }) => {
  const { vote } = props
  const currentUser: any = useAppSelector((state) => state.auth)
  return (
    <section className='w-full flex items-center gap-3'>
      <img
        src={vote.profile_pic || '/defaultImage.png'}
        className='w-10 h-10 rounded-full object-cover object-center'
        alt={vote.first_name}
        onError={(err) => {
          err.currentTarget.src = '/defaultImage.png'
          const target: any = err.target
          target.src = '/defaultImage.png'
          err.target = target
        }}
      />
      <hgroup className='flex flex-col justify-center items-start gap-1'>
        <Typography
          as='h4'
          className='text-jaa-shades-black font-normal md flex justify-start items-center '
        >
          <span>{`${vote.first_name} ${vote.last_name}`}</span>
          {vote.just_posted && (
            <span className='flex items-center gap-1'>
              <span className='w-1 h-1 rounded-full bg-jaa-shades-gray-2.5'></span>
              <span className='text-jaa-shades-gray-2.5 font-normal sm'>
                just now
              </span>
            </span>
          )}
        </Typography>
        {currentUser?.cohort == vote.cohort && (
          <Typography as='p' className='flex items-center gap-1'>
            <span className='w-1 h-1 bg-jaa-shades-gray-2.5 rounded-full'></span>
            <span className='font-normal xs text-jaa-shades-gray-2.5'>
              Same Batch
            </span>
          </Typography>
        )}
      </hgroup>
    </section>
  )
}

export default VoteCard
