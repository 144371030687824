import { Button } from '../components/Forms/button'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const Started = () => {
  const { t } = useTranslation()

  const items = [
    {
      id: 1,
      question: `${t('app.landing.are_you_recruiter')}`,
      title: `${t('app.landing.create_account')}`,
      text: `${t('app.landing.join_community')}`,
    },
    {
      id: 2,
      question: `${t('app.landing.are_you_alumnus')}`,
      title: `${t('app.landing.connect_leading')}`,
      text: `${t('app.landing.professionals')}`,
    },
  ]

  return (
    <div className='w-full flex items-center justify-center p-7 md:px-24'>
      <div className='w-full max-w-screen-2xl md:divide-x divide-jaa-gray space-y-12 md:space-y-0 flex flex-col md:flex-row items-start justify-start'>
        {items.map((item) => (
          <StartCard
            key={item.id}
            question={item.question}
            title={item.title}
            text={item.text}
            node={
              item.id === 1 ? (
                <a
                  className='bg-jaa-yellow py-3 px-5 rounded-md font-semibold text-sm'
                  href='https://mycareerconnect.org/recruiter/auth/sign-up/primary'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('app.landing.sign_up')}
                </a>
              ) : (
                <a
                  className='bg-jaa-yellow py-3 px-5 rounded-md font-semibold text-sm'
                  href='https://forms.gle/ureRgC9spjuLDRWL9'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('app.landing.get_started')}
                </a>
              )
            }
          />
        ))}
      </div>
    </div>
  )
}

export const StartCard = ({ question, title, text, node }) => {
  return (
    <div className='flex items-start justify-between space-y-4 flex-col w-full md:w-1/2 p-o md:p-8 min-h-[400px]'>
      <div className='flex items-start space-y-6 flex-col'>
        <span className='text-lg font-medium'>{question}</span>
        <h1 className='text-[30px] md:text-4xl font-semibold'>{title}</h1>
      </div>
      <p className='text-lg font-normal'>{text}</p>
      {!!node && node}
    </div>
  )
}

StartCard.propTypes = {
  question: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  node: PropTypes.node,
}
