/* eslint-disable react-hooks/exhaustive-deps */
import { RouterProvider } from 'react-router-dom'

import { ToastContainer } from '@/common/toastManager/'

import { useEffect, useState } from 'react'
import useAutomaticRefresh from './apis/hooks/useAutomaticRefresh'
import { useAppSelector } from './store/hooks'
import useRefreshToken from './apis/hooks/useRefreshToken'
import { getAccessTokens } from './store/authSlice'
import HashLoader from 'react-spinners/HashLoader'
import useNetworkStatus from './hooks/useNetworkStatus'
import RouteConfig from './routes'

function App() {
  const [_, isDone] = useAutomaticRefresh()
  const isOnline = useNetworkStatus()
  const [isLoading, setIsLoading] = useState(true)
  const accessToken = useAppSelector(getAccessTokens)
  const refresh = useRefreshToken()
  useEffect(() => {
    if (!isDone) return
    if (window.location.pathname.includes('auth')) {
      setIsLoading(false)
      return
    }
    const verifyRefreshToken = async () => {
      try {
        await refresh()
      } catch (err) {
        console.error(err, 'HERE IS MY ERROR')
      } finally {
        setIsLoading(false)
      }
    }
    !isOnline
      ? setIsLoading(false)
      : accessToken
      ? setIsLoading(false)
      : verifyRefreshToken()
  }, [accessToken, isDone])

  return (
    <div>
      {isLoading ? (
        <div className='w-screen h-screen bg-white flex justify-center items-center'>
          <HashLoader color='#00A0AE' loading={isLoading} size={100} />
        </div>
      ) : (
        <RouterProvider router={RouteConfig} />
      )}
      <ToastContainer />
    </div>
  )
}

export default App
