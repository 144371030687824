import Typography from '@/common/Typography'
import Button from '@/common/button'
import { useNavigate } from 'react-router'

const AppCrashPage = () => {
  const navigate = useNavigate()

  const handleCancelClick = () => {
    window.location.reload()
  }
  return (
    <div className='w-full h-[100vh] px-8 flex flex-col xl:flex-row gap-16 items-center justify-center '>
      <div className='w-full justify-center flex flex-col md:items-center'>
        <div className='flex flex-col gap-4'>
          <div className='flex md:justify-center'>
            <Typography className='text-[#535C5F] text-[80px] text-[#8DA0A5] md:text-[142px] lg:text-[160px] font-light'>
              Oops!{' '}
            </Typography>
          </div>
          <div className='w-full'>
            <Typography className='text-[#535C5F] text-[16px] font-medium'>
              Trust Us
            </Typography>
            <Typography className='text-[#535C5F] font-light'>
              This rarely happens
            </Typography>
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col md:items-center  gap-8'>
        <div className=' md:w-[424px] flex flex-col gap-4'>
          <Typography className='text-[#535C5F] text-[14px] md:text-[18px] lg:text-[28px] font-light'>
            Yikes! It seems this application just crashed.
          </Typography>
          <Typography className='text-[#535C5F] text-[14px] md:text-[18px] lg:text-[28px] font-light'>
            Of course this is no fault of yours, our engineers will be looking
            into this shortly.
          </Typography>
        </div>
        <div className='w-full  md:w-[424px] flex flex-col md:flex-row gap-4'>
          <Button
            variant='cancel'
            className='!uppercase w-full  h-[48px]  md:w-[203px] bg-[#F7F7F7] text-point-primary-text whitespace-nowrap'
            label='Go Back'
            onClick={handleCancelClick}
          />
          <Button
            className='!uppercase w-full  h-[48px]  md:w-[203px] whitespace-nowrap'
            label='Go To Homepage'
            onClick={() => navigate('/')}
          />
        </div>
      </div>
    </div>
  )
}

export default AppCrashPage
