import { array, object, string } from 'yup'

const portfolioSchema = object().shape({
  links: array().of(string()),
  description: string().required().min(5),
  images: array().min(1).required(),
  title: string().required(),
})

export default portfolioSchema
