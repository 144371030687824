import { addDataToQueryString } from '@/helpers/strings'
import api from './api'
import { PaginatedData } from './api.types'
import {
  SKILL_TEST,
  SKILL_TEST_ATTEMPTS,
  SKILL_TEST_CATEGORIES,
  SKILL_TEST_CATEGORIES_FILTERS,
  SKILL_TEST_LEADERBOARD,
  SKILL_TEST_PROFILE,
} from './endpoints'
import { IAlumniProfile, IRecruiterRating } from './profileApis'

export interface ISkillTest {
  id: number
  image: string
  duration_str?: string
  duration?: number
  badges?: string[]
  test_takers_by_cohort?: ITestTakers
  test_takers_by_world?: ITestTakers
  questions?: Question[]
  title: string
  job_role?: string
  description?: string
  no_of_questions?: string
  category?: ISkillTestCategory
}

export interface ISkillTestProfile {
  user_id: number
  alumnus_id: number
  first_name: string
  last_name: string
  profile_pic?: string
  badges: IBadge[]
  best_badge: IBadge
  rating: IRating
  skill_level: string
  cohort: number
  world_ranking: number
  cohort_ranking: number
}

export interface ITestAttempts {
  id: number
  test: string
  alumnus: IAlumniProfile
  attempts: number
  score: number
  badges: IBadge
}
export interface ITestLeaderBoard {
  // status: boolean
  // message: string
  data: LeaderBoard[]
}

export interface LeaderBoard {
  ranking: number
  alumnus: IAlumnusLeaderBoard[]
}

export interface ITest {
  id: number
  test: ISkillTest
  alumnus: IAlumnusLeaderBoard
  questions?: IQuestion[]
  answers?: TOption[]
  world_percentile?: number
  cohort_percentile?: number
  attempts: number
  score?: number
  badges: string[]
}
export interface IAnswer {
  id: number
  description: string
  correct: boolean
  question: number
}
export interface IQuestion {
  id: number
  options: TOption[]
  description: string
  selectedOptionId?: number
}

type TOption = {
  id: number
  description: string
  correct?: boolean
  question?: number
}

export interface IAlumnusLeaderBoard {
  id?: number
  user_id: number
  alumnus_id?: number
  first_name: string
  last_name: string
  profile_pic?: string
  email?: string
  average_rating?: IRating
  country?: string
  region?: string
  program?: string
  best_badge?: IBadge
  rating?: IRating
  skill_level: string
  cohort: number
  world_ranking: number
  cohort_ranking: number
}

type IBadge = {
  badge: string
  amount: string
}
type IRating = {
  count: number
  average: number
}

type ITestTakers = {
  alumni: string[]
  count: number
}

export type TestListDTO = {
  page?: number
  search?: string
  category?: number
  recommended?: boolean
  page_size?: number
  job_roles?: string[]
}
export type TestLeaderBoardDTO = {
  cohort?: number
  view?: string
  page?: number
  page_size?: number
}

export type TestAttemptsDTO = {
  page?: number
}

export interface SubmitTestDTO {
  answers: number[]
  id: number
}

export type ITestFilter = {
  job_role: string[]
}

export interface SkillTestCategoryDTO {
  title: string
  job_role: string
}
export interface TestCategory {
  id: number
  no_of_skill_tests: number
  title: string
  job_role: string
}

export interface ISkillTestCategory {
  id: number
  no_of_skill_tests: number
  title: string
  job_role: string
  date_added?: string
  date_modified?: string
}

export interface IFetchSkillTestDTO {
  page?: number
  page_size?: number
  search?: string
  job_roles?: string[]
  sort_by?: string
}

export interface SkillTestDTO {
  id?: number
  title: string
  description: string
  category?: number
  image?: string
}

export interface ESkillTestDTO {
  image?: string
}

export type Image = {
  file?: File
  src: string
}

export interface IAddQuestionDTO {
  id?: number
  questions: Question[]
}
export type Question = {
  id?: number
  description: string
  options: IOption[]
}

export interface IOption {
  description: string
  correct: boolean
}
export interface IOption {
  id?: number
  description: string
  correct: boolean
}

export interface ILeaderBoard {
  ranking: number
  alumnus: IAlumniLeaderBoardProfile[]
}

export interface ILeaderBoardData {
  data: ILeaderBoard[]
}

export interface IAlumniLeaderBoardProfile {
  alumnus_id: number
  best_badge?: number
  cohort: number
  cohort_ranking: number
  first_name: string
  last_name: string
  profile_pic: string
  rating: IRecruiterRating
  skill_level: string
  user_id: number

  world_ranking: number
}

export const getTestList = async (payload: TestListDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<ISkillTest> }>(`${SKILL_TEST}?${qs}`)
  ).data
}
export const getTestDetail = async (id: number) => {
  return (await api.get<{ data: ISkillTest }>(`${SKILL_TEST}${id}`)).data
}
export const getTestProfile = async (user_id?: number) => {
  return (
    await api.get<{ data: ISkillTestProfile }>(
      `${SKILL_TEST_PROFILE}${user_id ? '?user_id=' + user_id : ''}`
    )
  ).data
}
export const getTestAttempts = async (payload: TestAttemptsDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<ITest> }>(
      `${SKILL_TEST_ATTEMPTS}?${qs}`
    )
  ).data
}
export const getTestLeaderBoard = async (payload: TestLeaderBoardDTO) => {
  const qs = addDataToQueryString('', payload)
  return (await api.get<{ data: any }>(`${SKILL_TEST_LEADERBOARD}?${qs}`)).data
}
export const getStartTest = async (id: number) => {
  return (await api.post<{ data: ITest }>(`${SKILL_TEST}${id}/start/`, null))
    .data
}

export const submitTestData = async (payload: SubmitTestDTO) => {
  return await api.post<{ data: ITest }>(
    `${SKILL_TEST_ATTEMPTS}${payload.id}/end/`,
    {
      answers: payload.answers,
    }
  )
}

export const getSkillTestFilters = async () => {
  return (await api.get<{ data: ITestFilter }>(SKILL_TEST + 'filters/')).data
}
export const getSkillTestCategoryFilters = async () => {
  return (await api.get<{ data: ITestFilter }>(SKILL_TEST_CATEGORIES_FILTERS))
    .data
}

export const createTestCategory = async (payload: SkillTestCategoryDTO) => {
  return await api.post<{ data: TestCategory }>(SKILL_TEST_CATEGORIES, payload)
}

export const getTestCategory = async (id: number) => {
  return (
    await api.get<{ data: TestCategory }>(`${SKILL_TEST_CATEGORIES}${id}`)
  ).data
}
export const createSkillTest = async (data: FormData) => {
  return await api.post<{ data: ISkillTest }>(SKILL_TEST, data)
}

export const getSkillTestCategories = async (payload: IFetchSkillTestDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: ISkillTestCategory }>(
      `${SKILL_TEST_CATEGORIES}?${qs}`
    )
  ).data
}

export const createQuestion = async (payload: IAddQuestionDTO) => {
  return await api.post<{ data: ISkillTest }>(
    `${SKILL_TEST}${payload.id}/questions/`,
    {
      questions: payload.questions,
    }
  )
}

export const updateSkillTest = async (data: { id: number; test: FormData }) => {
  return await api.patch<{ data: ISkillTest }>(
    `${SKILL_TEST}${data.id}/`,
    data.test
  )
}
