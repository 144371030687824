import {
  AuditLog,
  AuditPageDTO,
  getAuditLog,
  getAuditLogs,
} from './../apis/auditLogApis'

import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { PaginatedData } from '@/apis/api.types'

export const auditLogApiSlice = createApi({
  reducerPath: 'auditLogsApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Audit'],

  endpoints: (builder) => ({
    getAuditLogs: builder.query<PaginatedData<AuditLog>, AuditPageDTO>({
      queryFn: async (payload) => {
        try {
          return await getAuditLogs(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Audit'],
    }),
    getAuditLog: builder.query<AuditLog, number>({
      queryFn: async (id) => {
        try {
          return await getAuditLog(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Audit'],
    }),
  }),
})
export const {
  useGetAuditLogQuery,
  useGetAuditLogsQuery,
  usePrefetch: usePrefetchAudit,
} = auditLogApiSlice
export const resetoptionsApiSlice = () => auditLogApiSlice.util.resetApiState()
