import { NumberFormat } from 'xlsx'
import { GET_RATINGS } from './endpoints'
import { PaginatedData } from './api.types'
import { addDataToQueryString } from '@/helpers/strings'
import api from './api'
import { StringDecoder } from 'string_decoder'

export type RatingPageDTO = {
  page?: number
  alumnus?: number
  recruiter?: number
  company?: NumberFormat
}

export interface Rater {
  user_id: number
  first_name: string
  last_name: string
  email: string
  profile_pic?: string
  user_type: string
  recruiter_role: string
  company: string
  cohort?: number
  company_role: string
}

export interface IRating {
  id: number
  rater: Rater
  user: string
  job: string
  number: number
  company?: string
  reasons: string[]
  date_rated: string
}

export const getAccountRating = async (payload: RatingPageDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<IRating> }>(`${GET_RATINGS}?${qs}`)
  ).data
}
