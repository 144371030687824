import { useAppSelector } from '@/store/hooks'
import UserRepresentation from '@/pages/auth/components/UserRepresentation'
import UserWelcome from '@/pages/auth/components/UserWelcome'
import LoadingText from '../../components/LoadingText'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { BackendToUI } from '@/pages/conversion'
import { useTranslation } from 'react-i18next'

const AlumniWelcome = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)
  const { first_name, last_name, admin_role, user_type } = useAppSelector(
    (state) => state.auth
  )
  const navigate = useNavigate()
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
      navigate(`/${BackendToUI[user_type as 'alumnus']}/app/dashboard`, {
        replace: true,
      })
    }, 5000)
    return () => {
      clearInterval(timer)
    }
  }, [])
  //Todo: Do any neceessary setup here before entering application
  return (
    <div className='p-4 sm:px-[20%] flex flex-col lg:px-[30%] gap-24 sm:gap-28'>
      <UserWelcome
        welcomeText={t('app.auth.welcome_text')}
        userType={first_name || ''}
        description={``}
      />

      <LoadingText loading={isLoading} text={t('app.auth.finishing_setup')} />
    </div>
  )
}

export default AlumniWelcome
