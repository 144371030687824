import {
  IAdminEducationProfile,
  ProfilePageDTO,
  getAdminAlumniEducation,
  getAdminAlumniPortfolio,
  getAdminAlumniProfile,
  getAdminAlumniWorkExperience,
  getAlumniDashboardDetails,
  getAlumniProfileScore,
} from './../apis/profileApis'
import {
  Company,
  createAlumniEducation,
  createAlumniPortfolio,
  createAlumniPortfolioImage,
  createAlumniPreferences,
  createAlumniWorkExperience,
  deleteAlumniEducation,
  deleteAlumniPortfolio,
  deleteAlumniPortfolioImage,
  deleteAlumniWorkExperience,
  getAlumniEducation,
  getAlumniPortfolio,
  getAlumniPreferences,
  getAlumniProfile,
  getAlumniWorkExperience,
  getAllIndustries,
  getRecruiterProfile,
  IAlumniProfile,
  IEducation,
  Image,
  IPortfolio,
  IPreference,
  IRecruiterProfile,
  IWorkExperience,
  updateAlumniEducation,
  updateAlumniPortfolio,
  updateAlumniPortfolioImage,
  updateAlumniPreferences,
  updateAlumniProfile,
  updateAlumniWorkExperience,
  updateRecruiterCompany,
  updateRecruiterProfile,
  uploadAlumniResume,
  changePassword,
  getAdminProfile,
  IAdminProfile,
  PasswordDTO,
  updateAdminProfile,
} from '@/apis/profileApis'

import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { PaginatedData } from '@/apis/api.types'

export const profileApiSlice = createApi({
  reducerPath: 'profileApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Profile', 'Admins', 'score'],

  endpoints: (builder) => ({
    changePassword: builder.mutation<{ data: PasswordDTO }, PasswordDTO>({
      queryFn: async (data) => {
        try {
          return await changePassword(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Admins', 'Profile'],
    }),
    updateAdmins: builder.mutation<{ data: IAdminProfile }, FormData>({
      queryFn: async (data) => {
        try {
          return await updateAdminProfile(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Admins', 'Profile'],
    }),
    adminProfile: builder.query<IAdminProfile, void>({
      queryFn: async (data) => {
        try {
          return await getAdminProfile()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Profile', 'Admins'],
    }),
    recruiterProfile: builder.query<IRecruiterProfile, void>({
      queryFn: async (data) => {
        try {
          return await getRecruiterProfile()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Profile'],
    }),
    updateRecruiters: builder.mutation<{ data: IRecruiterProfile }, FormData>({
      queryFn: async (data) => {
        try {
          return await updateRecruiterProfile(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Admins', 'Profile'],
    }),
    updateRecruiterCompany: builder.mutation<
      { data: Company },
      Partial<Company>
    >({
      queryFn: async (data) => {
        try {
          return await updateRecruiterCompany(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile'],
    }),
    alumniProfile: builder.query<IAlumniProfile, number>({
      queryFn: async (id) => {
        try {
          return await getAlumniProfile(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Profile'],
    }),
    adminAlumniProfile: builder.query<IPreference, number>({
      queryFn: async (id) => {
        try {
          return await getAdminAlumniProfile(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Profile'],
    }),
    adminAlumniEducation: builder.query<
      PaginatedData<IAdminEducationProfile>,
      number
    >({
      queryFn: async (id) => {
        try {
          return await getAdminAlumniEducation(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Profile'],
    }),
    adminAlumniPorfolio: builder.query<PaginatedData<IPortfolio>, number>({
      queryFn: async (id) => {
        try {
          return await getAdminAlumniPortfolio(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Profile'],
    }),
    adminAlumniWorkExperience: builder.query<
      PaginatedData<IWorkExperience>,
      number
    >({
      queryFn: async (id) => {
        try {
          return await getAdminAlumniWorkExperience(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Profile'],
    }),
    alumniDashboardDetails: builder.query<IAlumniProfile, number>({
      queryFn: async (id) => {
        try {
          return await getAlumniDashboardDetails(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Profile'],
    }),
    alumniPreference: builder.query<PaginatedData<IPreference>, ProfilePageDTO>(
      {
        queryFn: async (payload) => {
          try {
            return await getAlumniPreferences(payload)
          } catch (error) {
            throw new Error((error as any).response.data.message)
          }
        },
        providesTags: ['Profile'],
      }
    ),
    alumniWorkExperience: builder.query<
      PaginatedData<IWorkExperience>,
      ProfilePageDTO
    >({
      queryFn: async (payload) => {
        try {
          return await getAlumniWorkExperience(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Profile'],
    }),
    alumniEducation: builder.query<PaginatedData<IEducation>, ProfilePageDTO>({
      queryFn: async (payload) => {
        try {
          return await getAlumniEducation(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Profile'],
    }),
    alumniPortfolio: builder.query<PaginatedData<IPortfolio>, ProfilePageDTO>({
      queryFn: async (payload) => {
        try {
          return await getAlumniPortfolio(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Profile'],
    }),
    uploadAlumniResume: builder.mutation<{ data: { cv: string } }, FormData>({
      queryFn: async (data) => {
        try {
          return await uploadAlumniResume(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    updateAlumniProfile: builder.mutation<
      { data: IAlumniProfile },
      {
        data: FormData
        id: number
      }
    >({
      queryFn: async (payload) => {
        try {
          return await updateAlumniProfile(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    createAlumniPreference: builder.mutation<
      { data: IPreference },
      IPreference
    >({
      queryFn: async (payload) => {
        try {
          return await createAlumniPreferences(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    updateAlumniPreference: builder.mutation<
      { data: IPreference },
      { data: IPreference; id: number }
    >({
      queryFn: async (payload) => {
        try {
          return await updateAlumniPreferences(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    createAlumniWorkExperience: builder.mutation<
      { data: IWorkExperience },
      FormData
    >({
      queryFn: async (payload) => {
        try {
          return await createAlumniWorkExperience(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    updateAlumniWorkExperience: builder.mutation<
      { data: IWorkExperience },
      { data: FormData; id: number }
    >({
      queryFn: async (payload) => {
        try {
          return await updateAlumniWorkExperience(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    deleteAlumniWorkExperience: builder.mutation<unknown, number>({
      queryFn: async (id) => {
        try {
          return await deleteAlumniWorkExperience(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    createAlumniEducation: builder.mutation<{ data: IEducation }, IEducation>({
      queryFn: async (payload) => {
        try {
          return await createAlumniEducation(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    updateAlumniEducation: builder.mutation<
      { data: IEducation },
      { data: Partial<IEducation>; id: number }
    >({
      queryFn: async (payload) => {
        try {
          return await updateAlumniEducation(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    deleteAlumniEducation: builder.mutation<unknown, number>({
      queryFn: async (id) => {
        try {
          return await deleteAlumniEducation(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    getAllIndustriesData: builder.query<any, void>({
      queryFn: async () => {
        try {
          return await getAllIndustries()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
    }),
    createAlumniPortfolio: builder.mutation<{ data: IPortfolio }, FormData>({
      queryFn: async (payload) => {
        try {
          return await createAlumniPortfolio(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    updateAlumniPortfolio: builder.mutation<
      { data: IPortfolio },
      { data: FormData; id: number }
    >({
      queryFn: async (payload) => {
        try {
          return await updateAlumniPortfolio(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    deleteAlumniPortfolio: builder.mutation<unknown, number>({
      queryFn: async (id) => {
        try {
          return await deleteAlumniPortfolio(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile'],
    }),
    createAlumniPortfolioImage: builder.mutation<{ data: Image }, FormData>({
      queryFn: async (payload) => {
        try {
          return await createAlumniPortfolioImage(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    updateAlumniPortfolioImage: builder.mutation<
      { data: Image },
      { data: FormData; id: number }
    >({
      queryFn: async (payload) => {
        try {
          return await updateAlumniPortfolioImage(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    deleteAlumniPortfolioImage: builder.mutation<unknown, number>({
      queryFn: async (id) => {
        try {
          return await deleteAlumniPortfolioImage(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Profile', 'score'],
    }),
    getAlumniProfileCompletionScore: builder.query<any, void>({
      queryFn: async (id) => {
        try {
          return await getAlumniProfileScore(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['score'],
    }),
  }),
})

export const {
  usePrefetch: usePrefetchProfile,
  useAdminProfileQuery,
  useUpdateAdminsMutation,
  useChangePasswordMutation,
  useRecruiterProfileQuery,
  useUpdateRecruiterCompanyMutation,
  useUpdateRecruitersMutation,
  useGetAllIndustriesDataQuery,
  useAlumniProfileQuery,
  useAlumniPreferenceQuery,
  useAlumniWorkExperienceQuery,
  useAlumniEducationQuery,
  useAlumniPortfolioQuery,
  useUploadAlumniResumeMutation,
  useUpdateAlumniProfileMutation,
  useCreateAlumniPreferenceMutation,
  useUpdateAlumniPreferenceMutation,
  useCreateAlumniWorkExperienceMutation,
  useUpdateAlumniWorkExperienceMutation,
  useDeleteAlumniWorkExperienceMutation,
  useCreateAlumniEducationMutation,
  useUpdateAlumniEducationMutation,
  useDeleteAlumniEducationMutation,
  useCreateAlumniPortfolioMutation,
  useUpdateAlumniPortfolioMutation,
  useDeleteAlumniPortfolioMutation,
  useCreateAlumniPortfolioImageMutation,
  useUpdateAlumniPortfolioImageMutation,
  useDeleteAlumniPortfolioImageMutation,
  useAdminAlumniProfileQuery,
  useAdminAlumniWorkExperienceQuery,
  useAdminAlumniEducationQuery,
  useAdminAlumniPorfolioQuery,
  useAlumniDashboardDetailsQuery,
  useGetAlumniProfileCompletionScoreQuery,
} = profileApiSlice

export const resetProfileApiSlice = () => profileApiSlice.util.resetApiState()
export const invalidateProfileApiSlice = profileApiSlice.util.invalidateTags
