/* eslint-disable react-hooks/exhaustive-deps */
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom'
import PublicRoutes from '@/routes/PublicRoutes'
import ProtectedRoutes from '@/routes/ProtectedRoutes'

import DashboardLayout from '@/layouts/dashboard'
import AuthLayout from '@/layouts/auth'

import SuccessLayout from '@/layouts/success'
import {
  AdminCreatePassword,
  AdminForgotPassword,
  AdminRecoverPassword,
  AdminSignIn,
  AdminWelcome,
  AlumniCreateAccount,
  AlumniRecoverPassword,
  AlumniSignIn,
  AlumniSignUp,
  AlumniWelcome,
} from '@/pages/auth'
import { Storage } from '@/helpers/Storage'
import React from 'react'

import {
  PrimaryUserSignUp,
  RecruiterRecoverPassword,
  RecruiterSignIn,
} from '@/pages/auth/recruiter'
import RecruiterAccountCreated from '@/pages/auth/recruiter/succeess/AccountCreated'
import MemberAcceptInvitation from '@/pages/auth/recruiter/signup/member'
import OngoingDetailPage from '@/pages/recruitment/alumni/applications/details/OngoingDetailPage'
import Permission from '@/common/permissions/Permission'
import PostDetailsPage from '@/pages/forum/alumni/profile/PostDetailsPage'
import PostUpvotesPage from '@/pages/forum/alumni/profile/PostUpvotesPage'
import LandingPage from '@/pages/landing-page'
import UnauthorizedPage from '@/pages/errors/unauthorization'
import AppCrashPage from '@/pages/errors/app-crash'
import NotFoundPage from '@/pages/errors/404'

const AdminAuditLogs = React.lazy(() => import('@/pages/auditlogs/admin'))

const Forum = React.lazy(() => import('@/pages/forum'))
const FeedDetails = React.lazy(() => import('@/pages/forum/FeedDetails'))
const FeedUpVotes = React.lazy(() => import('@/pages/forum/FeedVotes'))
const FlaggedContent = React.lazy(
  () => import('@/pages/forum/admin/FlaggedContent')
)
const ContentDetailPage = React.lazy(
  () => import('@/pages/forum/admin/ContentDetailPage')
)
const FlaggedDetailPage = React.lazy(
  () => import('@/pages/forum/admin/FlaggedDetailPage')
)

const FlaggedFeedVotes = React.lazy(
  () => import('@/pages/forum/admin/FlaggedFeedVotes')
)

const JobBoard = React.lazy(() => import('@/pages/recruitment/alumni/jobs'))
const JobDetailPage = React.lazy(
  () => import('@/pages/recruitment/alumni/jobs/details/JobDetailPage')
)

const OngoingApplications = React.lazy(
  () => import('@/pages/recruitment/alumni/applications')
)

const JobPostings = React.lazy(
  () => import('@/pages/recruitment/recruiter/jobsposting')
)
const ArchivedJobs = React.lazy(
  () => import('@/pages/recruitment/recruiter/jobsposting/ArchivedJobs')
)
const Engagement = React.lazy(() => import('@/pages/engagement'))
const MessageDetailPage = React.lazy(
  () => import('@/pages/engagement/MessageDetailPage')
)

const Pipeline = React.lazy(
  () => import('@/pages/recruitment/recruiter/pipeline')
)
const PipelineDetails = React.lazy(
  () => import('@/pages/recruitment/recruiter/pipeline/details')
)
const Recruitment = React.lazy(() => import('@/pages/recruitment'))
const Dashboard = React.lazy(() => import('@/pages/dashboards'))

const ForumReportsList = React.lazy(
  () => import('@/pages/dashboards/admin/forumreportslist')
)
const Reports = React.lazy(() => import('@/pages/reports'))
const LocationDemographic = React.lazy(
  () => import('@/pages/reports/admin/dashboard/LocationDemographic')
)
const SkillsTest = React.lazy(() => import('@/pages/skills_test'))
// const AdminSkillsTest = React.lazy(() => import('@/pages/skills_test/admin'))
const TestDetailPage = React.lazy(() => import('@/pages/skills_test/detail'))
const AdminTestDetail = React.lazy(
  () => import('@/pages/skills_test/admin/AdminTestDetail')
)
const BlockedAlumni = React.lazy(
  () => import('@/pages/reports/admin/alumni/blockedalumni')
)
const RejectedAlumni = React.lazy(
  () => import('@/pages/reports/admin/alumni/rejectedalumni')
)
const EngagedAlumni = React.lazy(
  () => import('@/pages/reports/admin/alumni/engagedalumni')
)
const AlumniRegistered = React.lazy(
  () => import('@/pages/reports/admin/alumni/alumniregistered')
)
const JobApplications = React.lazy(
  () => import('@/pages/reports/admin/alumni/jobapplications')
)
const ProfileCompletionRate = React.lazy(
  () => import('@/pages/reports/admin/alumni/profilecompletionrate')
)
const BlockedRecruiters = React.lazy(
  () => import('@/pages/reports/admin/recruiters/blockedrecruiters')
)
const RegisteredRecruiters = React.lazy(
  () => import('@/pages/reports/admin/recruiters/registeredrecruiters')
)
const HiredAlumni = React.lazy(
  () => import('@/pages/reports/admin/recruiters/hiredalumni')
)

const JobPosted = React.lazy(
  () => import('@/pages/reports/admin/recruiters/jobposted')
)

const JobDetails = React.lazy(
  () => import('@/pages/recruitment/recruiter/jobsposting/detail')
)
const Settings = React.lazy(() => import('@/pages/setting'))
const Profile = React.lazy(() => import('@/pages/profile'))
const AlumniActivities = React.lazy(
  () => import('@/pages/profile/alumni/AlumniActivities')
)
const AlumniActivityDetails = React.lazy(
  () => import('@/pages/profile/alumni/AlumniActivityDetail')
)
const AlumniActivityUpvotes = React.lazy(
  () => import('@/pages/profile/alumni/AlumniActivityUpvotes')
)
const FellowAlumniProfile = React.lazy(
  () => import('@/pages/forum/alumni/profile')
)
const AllActivitiesPage = React.lazy(
  () => import('@/pages/forum/alumni/profile/AllActivitiesPage')
)

const AllRecruiters = React.lazy(() => import('@/pages/recruiter/admin'))
const AllAvailableAlumnis = React.lazy(
  () => import('@/pages/dashboards/recruiter/components/AllAlumni')
)
const AdminPipelineDetail = React.lazy(
  () => import('@/pages/details/recruiter/pipelinedetails')
)
const AdminJobPostingDetail = React.lazy(
  () => import('@/pages/details/recruiter/jobposting')
)
const AllAlumnis = React.lazy(() => import('@/pages/alumni/admin'))
const AlumniDetail = React.lazy(() => import('@/pages/details/alumni'))
const RecruiterDetail = React.lazy(() => import('@/pages/details/recruiter'))
const RecruiterMembers = React.lazy(() => import('@/pages/recruiter/recruiter'))

const userType = Storage.getItem('USER_TYPE') || 'alumni'
const userId = (Storage.getItem('USER_DETAILS') || {}).user_id
const CatchAllAuthRoute = () => {
  return <Navigate to={`/${userType}/auth/sign-in`} />
}

const RouteConfig = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<AppCrashPage />}>
      <Route path='/' element={<LandingPage />} />
      <Route path='authorization' element={<UnauthorizedPage />} />
      <Route element={<PublicRoutes />}>
        <Route path='alumni'>
          <Route index element={<div>404 Not Found</div>} />
          <Route path='success' element={<SuccessLayout userType='alumni' />}>
            <Route index element={<div>404 Not Found</div>} />
            <Route path='create-account' element={<AlumniCreateAccount />} />
            <Route path='welcome' element={<AlumniWelcome />} />
          </Route>
          <Route path='auth' element={<AuthLayout userType='alumni' />}>
            <Route index element={<div>404 Not Found</div>} />
            <Route path='sign-in' element={<AlumniSignIn />} />
            <Route
              path=':code/:email/accept-invite'
              element={<AlumniSignUp />}
            />
            {/* <Route
              path='create-password'
              element={<div> Alumni This is Password Creation Page</div>}
            /> */}
            <Route
              path=':code/reset-password'
              element={<AlumniRecoverPassword />}
            />
          </Route>
        </Route>
        <Route path='recruiter'>
          <Route index element={<Navigate to='/recruiter/auth/sign-in' />} />
          <Route
            path='success'
            element={<SuccessLayout userType='recruiter' />}
          >
            <Route index element={<Navigate to='/recruiter/auth/sign-in' />} />
            <Route
              path='create-account'
              element={<RecruiterAccountCreated />}
            />
            <Route path='welcome' element={<RecruiterAccountCreated />} />
          </Route>
          <Route path='auth' element={<AuthLayout userType='recruiter' />}>
            <Route index element={<Navigate to='/recruiter/auth/sign-in' />} />
            <Route path='sign-in' element={<RecruiterSignIn />} />
            <Route path='sign-up'>
              <Route
                index
                element={<Navigate to='/recruiter/auth/sign-up/primary' />}
              />
              <Route path='primary' element={<PrimaryUserSignUp />} />
              <Route path=':code/member' element={<MemberAcceptInvitation />} />
            </Route>

            <Route
              path=':code/reset-password'
              element={<RecruiterRecoverPassword />}
            />
          </Route>
        </Route>
        <Route path='admin'>
          <Route index element={<Navigate to='/admin/auth/sign-in' />} />
          <Route path='success' element={<SuccessLayout userType='admin' />}>
            <Route index element={<Navigate to='/admin/auth/sign-in' />} />
            <Route
              path='create-account'
              element={<div>Admin Account successfully created</div>}
            />
            <Route path='welcome' element={<AdminWelcome />} />
          </Route>
          <Route path='auth' element={<AuthLayout userType='admin' />}>
            <Route index element={<Navigate to='/admin/auth/sign-in' />} />
            <Route path='sign-in' element={<AdminSignIn />} />

            <Route
              path=':code/create-password'
              element={<AdminCreatePassword />}
            />

            <Route path='forgot-password' element={<AdminForgotPassword />} />
            <Route
              path=':code/reset-password'
              element={<AdminRecoverPassword />}
            />
          </Route>
        </Route>
        <Route path='*' element={<CatchAllAuthRoute />} />
      </Route>
      <Route path='/:userType' element={<ProtectedRoutes />}>
        <Route path='app' element={<DashboardLayout />}>
          <Route path='audit-logs' element={<AdminAuditLogs />} />
          <Route path='dashboard'>
            <Route
              index
              element={
                <Permission
                  noAccess={<Navigate to='/authorization' />}
                  permissions={[
                    'user_type=alumni',
                    'user_type=recruiter',
                    'Can View Dashboard',
                  ]}
                  type='one-of'
                >
                  <Dashboard />
                </Permission>
              }
            />
            <Route path='all-alumni'>
              <Route index element={<AllAvailableAlumnis />} />
            </Route>
            <Route path='forum-reports' element={<ForumReportsList />} />
          </Route>
          <Route path='report'>
            <Route
              index
              element={
                <Permission
                  noAccess={<Navigate to='/authorization' />}
                  permissions={['Can View Reports']}
                  type='all-of'
                >
                  <Reports />
                </Permission>
              }
            />
            <Route path='demograghic' element={<LocationDemographic />} />
            <Route path='blocked-alumni' element={<BlockedAlumni />} />
            <Route path='rejected-alumni' element={<RejectedAlumni />} />
            <Route path='engaged-alumni' element={<EngagedAlumni />} />
            <Route path='alumni-registered' element={<AlumniRegistered />} />
            <Route path='job-applications' element={<JobApplications />} />
            <Route
              path='profile-completion-rate'
              element={<ProfileCompletionRate />}
            />
            <Route path='blocked-recruiters' element={<BlockedRecruiters />} />
            <Route
              path='registered-recruiters'
              element={<RegisteredRecruiters />}
            />
            <Route path='jobs-posted' element={<JobPosted />} />
            <Route path='hired-alumni' element={<HiredAlumni />} />
          </Route>
          <Route path='setting' element={<Settings />} />
          <Route path='profile'>
            <Route index element={<Profile />} />
            <Route path='activity'>
              <Route
                index
                element={
                  <Permission
                    noAccess={<Navigate to='/authorization' />}
                    entityOwnerId={userId}
                    permissions={['owner', 'user_type=alumni']}
                    type='all-of'
                  >
                    <AlumniActivities />
                  </Permission>
                }
              />
              <Route
                path=':postId'
                element={
                  <Permission
                    noAccess={<Navigate to='/authorization' />}
                    entityOwnerId={userId}
                    permissions={['user_type=alumni']}
                    type='all-of'
                  >
                    <AlumniActivityDetails />
                  </Permission>
                }
              />
              <Route
                path='vote/:postId'
                element={
                  <Permission
                    noAccess={<Navigate to='/authorization' />}
                    entityOwnerId={userId}
                    permissions={['user_type=alumni']}
                    type='all-of'
                  >
                    <AlumniActivityUpvotes />
                  </Permission>
                }
              />
            </Route>
          </Route>

          <Route path='engagement'>
            <Route index element={<Engagement />} />
            <Route path=':chat' element={<MessageDetailPage />} />
          </Route>
          <Route path='recruiter'>
            <Route index element={<AllRecruiters />} />
            <Route path='profile'>
              <Route index element={<RecruiterDetail />} />
            </Route>
            <Route path='pipeline'>
              <Route path=':pipeline' element={<AdminPipelineDetail />} />
            </Route>
            <Route path='job-posting' element={<AdminJobPostingDetail />} />
            {/* <Route path=':job' element={<AdminJobPostingDetail />} />
            </Route> */}
          </Route>

          <Route path='skill-test'>
            <Route index element={<SkillsTest />} />
            <Route path=':test' element={<TestDetailPage />} />
            <Route path=':title/:id' element={<AdminTestDetail />} />
          </Route>

          <Route path='recruitment'>
            <Route index element={<Recruitment />} />
            <Route path='job-postings' element={<JobPostings />} />
            <Route path='archived-jobs' element={<ArchivedJobs />} />
            <Route path='job-posting' element={<JobDetails />} />
            <Route path='job-board'>
              <Route index element={<JobBoard />} />
              <Route path=':job' element={<JobDetailPage />} />
            </Route>
            <Route path='ongoing-application'>
              <Route index element={<OngoingApplications />} />
              <Route path=':application' element={<OngoingDetailPage />} />
            </Route>
            <Route path='archived-application'>
              <Route index element={<OngoingApplications />} />
              <Route path=':application' element={<OngoingDetailPage />} />
            </Route>
            <Route path='pipeline'>
              <Route index element={<Pipeline />} />
              <Route path=':pipeline' element={<PipelineDetails />} />
            </Route>
          </Route>
          <Route path='forum'>
            <Route index element={<Forum />} />
            <Route path='alumni/:alumnusId'>
              <Route index element={<FellowAlumniProfile />} />
              <Route path='activity'>
                <Route
                  index
                  element={
                    <Permission
                      noAccess={<Navigate to='/authorization' />}
                      permissions={['user_type=alumni']}
                      type='all-of'
                    >
                      <AllActivitiesPage />
                    </Permission>
                  }
                />
                <Route
                  path=':postId'
                  element={
                    <Permission
                      noAccess={<Navigate to='/authorization' />}
                      entityOwnerId={userId}
                      permissions={['user_type=alumni']}
                      type='all-of'
                    >
                      <PostDetailsPage />
                    </Permission>
                  }
                />
                <Route
                  path='vote/:postId'
                  element={
                    <Permission
                      noAccess={<Navigate to='/authorization' />}
                      entityOwnerId={userId}
                      permissions={['user_type=alumni']}
                      type='all-of'
                    >
                      <PostUpvotesPage />
                    </Permission>
                  }
                />
              </Route>
            </Route>
            <Route path='flagged'>
              <Route index element={<FlaggedContent />} />
              <Route path=':reportId'>
                <Route index element={<FlaggedDetailPage />} />
                <Route path='content/:postId' element={<ContentDetailPage />} />
                <Route
                  path='content/:postId/votes'
                  element={<FlaggedFeedVotes />}
                />
              </Route>
            </Route>
            <Route path=':postId' element={<FeedDetails />} />
            <Route path='votes/:postId' element={<FeedUpVotes />} />
          </Route>
          <Route path='alumni'>
            <Route index element={<AllAlumnis />} />

            <Route
              path='profile'
              element={
                <Permission
                  noAccess={<Navigate to='/authorization' />}
                  permissions={['Can View Alumni']}
                  type='all-of'
                >
                  <AlumniDetail />
                </Permission>
              }
            />
          </Route>
          <Route path='member' element={<RecruiterMembers />} />
        </Route>
      </Route>
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  )
)

export default RouteConfig
