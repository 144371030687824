import iconComponents from '@/assets/icons/iconComponents'
import { useComposeRefs } from '@/hooks/useComposeRefs'
import useHover from '@/hooks/useHover'
import clsx from 'clsx'
import {
  AllHTMLAttributes,
  ChangeEvent,
  FormEvent,
  forwardRef,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import useMountPortal from '../toastManager/hooks/useMountPortal'

interface ICheaps extends AllHTMLAttributes<HTMLDivElement> {
  className?: string
  values?: string[]
  setSelectedValues?: (values: string[]) => void
}
const InputWithChips = (props: ICheaps) => {
  const [elements, setElements] = useState<string[]>([])
  const {
    className,
    values,
    setSelectedValues = (values: string[]) => {},
  } = props
  const setSelectedValuesProxy = setSelectedValues
  useEffect(() => {
    setElements(values || [])
  }, [])
  useEffect(() => {
    setSelectedValuesProxy?.(elements)
  }, [elements])
  const internalRef = useRef<HTMLDivElement>(null)

  const keyHandler: KeyboardEventHandler<HTMLDivElement> = (e) => {
    const { innerText } = e.currentTarget
    if (!['Enter', 'Tab', 'Backspace'].includes(e.key)) return
    const cleaned = innerText.trim()
    if (['Enter', 'Tab'].includes(e.key)) {
      setElements((prev) => {
        const newSelectedItem = [...prev]
        const duplicatedValues = newSelectedItem.indexOf(cleaned)

        if (duplicatedValues !== -1) {
          return newSelectedItem
        }
        return prev.concat(cleaned)
      })
      e.currentTarget.innerText = ''
      e.preventDefault()
    } else if (elements.length && !cleaned.length && e.key === 'Backspace') {
      setElements((prev) => prev.slice(0, prev.length - 1))
    }
  }
  const handleDelete = (item: string) => () => {
    const newSelectedItem = [...elements]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    setElements(newSelectedItem)
  }
  return (
    <div
      tabIndex={0}
      className={clsx(
        'px-2 py-1.5 outline-none flex gap-2 justify-start items-start content-start max-w-full flex-wrap ',
        className || ''
      )}
    >
      {elements.map((el) => (
        <button
          onClick={handleDelete(el)}
          key={el}
          className='!bg-jaa-teal-20 whitespace-nowrap flex justify-center h-[45px] p-2 gap-3 rounded items-center capitalize'
        >
          <span className='text-jaa-shades-gray-4 md'>{el}</span>
          <iconComponents.util.CancelIcon className='w-3 h-3 stroke-jaa-dark-teal-80' />
        </button>
      ))}
      <div
        ref={internalRef}
        contentEditable
        suppressContentEditableWarning
        onKeyDown={keyHandler}
        className='flex-1 outline-none caret-jaa-dark-teal-80 h-[45px] py-2 whitespace-nowrap'
      ></div>
    </div>
  )
}

export default InputWithChips
