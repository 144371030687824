import Title from '@/common/Title'
import useQueryParams from '@/hooks/useQueryParams'
import useStepper from '@/hooks/useStepper'
import clsx from 'clsx'
import { useEffect } from 'react'
import { Navigate } from 'react-router'
import AddMembers from './AddMembers'
import KnowRecruiter from './KnowRecruiter'
import PrimaryAdminDetails from './PrimaryAdminDetails'

const numberOfSteps = 4
const PrimaryUserSignUp = () => {
  const params = useQueryParams()
  const { step, goToNextStep, setStep } = useStepper(
    (parseInt(params.page) as any) || 1
  )
  useEffect(() => {
    if (step <= numberOfSteps) return
    setStep(1)
  }, [step, setStep])
  return (
    <div className='px-4 w-full flex flex-col gap-10 justify-start items-center sm:px-[19%] mb-12 overflow-scroll'>
      <Title>JA Africa - Create Recruiter </Title>
      {step === 1 && (
        <PrimaryAdminDetails goToNextStep={goToNextStep} step={step} />
      )}
      {step === 2 && <KnowRecruiter goToNextStep={goToNextStep} step={step} />}
      {step === 3 && <AddMembers goToNextStep={goToNextStep} step={step} />}
      {step === 4 && (
        <Navigate to='/recruiter/success/create-account' replace={true} />
      )}
      <div className='flex justify-center items-center gap-4 w-full'>
        {new Array(numberOfSteps).fill(0).map((_, idx) => (
          <span
            key={idx}
            className={clsx(
              `w-2.5 h-2.5 rounded-full`,
              idx === step - 1
                ? 'bg-jaa-teal-100'
                : 'bg-jaa-gray-100 mix-blend-multiply'
            )}
          ></span>
        ))}
      </div>
    </div>
  )
}

export default PrimaryUserSignUp
