import PropTypes from 'prop-types'

export const FaqCard = ({ title, text, linktext }) => {
  return (
    <div className='flex items-start flex-col space-y-6'>
      <h2 className='text-xl font-semibold'>{title}</h2>
      {text ? <p className='text-base font-normal'>{text}</p> : null}
      {linktext ? linktext : null}
    </div>
  )
}

FaqCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  linktext: PropTypes.node,
}
