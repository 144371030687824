import { COMMENT } from '@/apis/forumApis'
import Typography from '@/common/Typography'
import { formatDistanceToNow } from 'date-fns'

type CommentProps = {
  comment: COMMENT
}

const CommentHeader = (props: CommentProps) => {
  const { comment } = props
  const fullName = `${comment.first_name} ${comment.last_name}`
  const isNow = Date.parse(comment.created_at)
    ? formatDistanceToNow(new Date(comment.created_at), {
        includeSeconds: true,
      }) === 'less than 5 seconds'
    : false
  return (
    <header className='flex items-center gap-3 w-full'>
      <img
        src={comment.profile_pic || '/defaultImage.png'}
        alt='who made comment'
        onError={(e) => {
          e.currentTarget.src = '/defaultImage.png'
          const target: any = e.target
          target.src = '/defaultImage.png'
          e.target = target
        }}
        className='w-8 h-8 rounded-full object-cover object-center'
      />
      <hgroup className='flex items-center gap-1'>
        <Typography
          as='h5'
          className='text-jaa-shades-black font-semibold sm capitalize'
        >
          {fullName}
        </Typography>
        <Typography as='p' className='flex items-center gap-1'>
          <span className='w-1 h-1 rounded-full bg-jaa-shades-gray-2.5'></span>
          <time
            dateTime={comment.created_at}
            className='font-normal text-jaa-shades-gray-2.5'
          >
            {isNow
              ? 'just now'
              : Date.parse(comment.created_at)
              ? formatDistanceToNow(new Date(comment.created_at), {
                  includeSeconds: true,
                }) + ' ago'
              : ''}
          </time>
        </Typography>
      </hgroup>
    </header>
  )
}

export default CommentHeader
