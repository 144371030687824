import { UserTypes } from '@/apis/authApis'
import { BackendToUI } from '@/pages/conversion'
import { CheckPermissionConfig, Permissions, User } from './permission.types'

const permissionCheckTypeMethods = {
  'one-of': (Permissions: Permissions) => Permissions.some,
  'all-of': (Permissions: Permissions) => Permissions.every,
}

const checkPermission = (
  user: User,
  permissions: Permissions,
  config: CheckPermissionConfig = {}
) => {
  const { type = 'one-of', entityOwnerId, debug } = config
  // Get an array method for checking permissions
  const checkMethod =
    permissionCheckTypeMethods?.[type] || permissionCheckTypeMethods['one-of']
  const userPermissions = user?.permissions || []

  const hasAccess = checkMethod(permissions).bind(permissions)((permission) => {
    // Checks if user created a record
    if (permission === 'owner') {
      return String(user?.user_id) === String(entityOwnerId)
    }
    // Checks if user is authenticated
    if (permission === 'logged-in') {
      return Boolean(user?.user_id)
    }
    if (permission.startsWith('user_type')) {
      const userType = (permission.split('=')[1] as UserTypes) || 'alumni'
      return BackendToUI[user?.user_type as 'alumnus'] === userType
    }
    // Checks other Permissions
    return userPermissions.includes(permission)
  })
  debug &&
    console.log('PERMISSION_DEBUG', {
      hasAccess,
      requiredPermissions: permissions,
      userPermissions,
      type,
      entityOwnerId,
    })
  return hasAccess
}
export default checkPermission
