import InfoIcon from './toast/info.svg'
import SuccessIcon from './toast/success.svg'
import WarnIcon from './toast/warn.svg'
import ErrorIcon from './toast/error.svg'
import WorkExperienceIcon from './profile/workexperience.png'
import ResumeThumbnailIcon from './profile/resumethumbnail.png'
import UserIcon from './utils/user.svg'
import TwoUserAltIcon from './utils/twouseralt.png'
import ExpandIcon from './utils/expand.svg'
import ExpandVideoIcon from './recruitment/expand.svg'
import ArchiveIcon from './recruitment/archive.png'
import UserAltIcon from './recruitment/useralt.png'
import SettingsIcon from './navigation/setting.png'
import CommentAltIcon from './forum/comment-alt.png'
import GeneralIcon from './forum/general.png'
import Batch1Icon from './forum/Batch1.png'
import Batch2Icon from './forum/Batch2.png'
import Batch3Icon from './forum/Batch3.png'
import Batch4Icon from './forum/Batch4.png'
import Batch5Icon from './forum/Batch5.png'
import Batch6Icon from './forum/Batch6.png'
import Batch7Icon from './forum/Batch7.png'

const iconUrls = {
  toast: {
    InfoIcon,
    SuccessIcon,
    WarnIcon,
    ErrorIcon,
  },
  profile: {
    WorkExperienceIcon,
    ResumeThumbnailIcon,
  },
  util: {
    UserIcon,
    ExpandIcon,
    TwoUserAltIcon,
  },
  recruitment: {
    ExpandIcon: ExpandVideoIcon,
    ArchiveIcon,
    UserAltIcon,
  },
  navigation: {
    SettingsIcon,
  },
  forum: {
    CommentAltIcon,
    GeneralIcon,
    Batch1Icon,
    Batch2Icon,
    Batch3Icon,
    Batch4Icon,
    Batch5Icon,
    Batch6Icon,
    Batch7Icon,
  },
}

export default iconUrls
