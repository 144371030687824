import { VOTE } from '@/apis/forumApis'
import LazySpinner from '@/common/spinners/LazySpinner'
import Typography from '@/common/Typography'
import useMismatchPages from '@/hooks/table/useMismatchPages'
import { useGetPostUpvotesQuery } from '@/store/forumSlice'
import { useAppSelector } from '@/store/hooks'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-hook-inview'
import { useParams } from 'react-router'
import { BeatLoader, ScaleLoader } from 'react-spinners'
import VoteCard from '../../components/VoteCard'
import { useTranslation } from 'react-i18next'

const Upvotes = () => {
  const { postId } = useParams()
  const { t } = useTranslation()

  const [loadMoreRef, inView] = useInView()
  const [allUpvotes, setAllUpvotes] = useState<Array<VOTE[]>>([])
  const { requestPage, setRequestPage } = useMismatchPages({
    requestPerPage: 20,
  })

  const {
    data: paginatedUpvotes,
    isFetching,
    isLoading,
  } = useGetPostUpvotesQuery(
    {
      page: requestPage,
      postId: Number(postId),
    },
    {
      skip: !postId,
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    if (!(inView && paginatedUpvotes)) return
    const nextPage = paginatedUpvotes.next
      ? new URL(paginatedUpvotes.next).searchParams.get('page')
      : 0

    if (!nextPage) return

    setRequestPage(Number(nextPage))
  }, [inView, paginatedUpvotes, setRequestPage])
  useEffect(() => {
    if (!paginatedUpvotes) return
    setAllUpvotes((prev) => {
      const newState = [...prev]
      newState[requestPage - 1] = paginatedUpvotes.results
      const maxPages = Math.ceil(paginatedUpvotes.count / 20)
      return newState.slice(0, maxPages)
    })
  }, [isLoading, paginatedUpvotes, requestPage])

  const collapsedUpvotes = allUpvotes.flat(2)
  return isLoading ? (
    <LazySpinner show>
      <div className='w-full h-full min-h-[50vh] flex flex-col items-center justify-center bg-jaa-shades-white rounded-lg gap-4'>
        <ScaleLoader loading color='var(--teal-100)' />
      </div>
    </LazySpinner>
  ) : (
    <article className='w-full h-full rounded-lg flex flex-col bg-jaa-shades-white min-h-[60vh] max-h-[90vh] flex-1 p-4 gap-8 isolate '>
      <header className='w-full'>
        <hgroup className='flex flex-col items-start'>
          <Typography
            as='h3'
            className='font-semibold sm text-jaa-shades-black'
          >
            {t('app.forum.upvoted_by')}
          </Typography>
          <Typography
            as='p'
            className='text-jaa-shades-gray-2.5 font-normal sm'
          >
            {`${paginatedUpvotes?.count} ${t('app.forum.upvote_count')}`}
          </Typography>
        </hgroup>
      </header>
      <main className='w-full flex-1 overflow-auto flex flex-col gap-6'>
        {collapsedUpvotes.map((el) => (
          <VoteCard vote={el} key={el.first_name + el.last_name} />
        ))}
        {isFetching ? (
          <div className='w-full flex justify-center items-center'>
            <BeatLoader color='var(--teal-100)' size={10} />
          </div>
        ) : null}{' '}
      </main>
      {!collapsedUpvotes.length && <EmptyVotes />}
      <div id='top' ref={loadMoreRef}></div>
    </article>
  )
}

const EmptyVotes = () => {
  const { t } = useTranslation()

  return (
    <div className='h-full flex-1 text-jaa-shades-black w-full flex justify-center items-center h5 min-h-[50vh] bg-jaa-shades-white'>
      {t('app.forum.no_upvotes')}
    </div>
  )
}
export default Upvotes
