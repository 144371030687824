import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { PaginatedData } from '@/apis/api.types'
import {
  IAddQuestionDTO,
  IFetchSkillTestDTO,
  ISkillTest,
  ISkillTestCategory,
  ISkillTestProfile,
  ITest,
  ITestFilter,
  SkillTestCategoryDTO,
  SubmitTestDTO,
  TestAttemptsDTO,
  TestCategory,
  TestLeaderBoardDTO,
  TestListDTO,
  createQuestion,
  createSkillTest,
  createTestCategory,
  getSkillTestCategories,
  getSkillTestCategoryFilters,
  getSkillTestFilters,
  getStartTest,
  getTestAttempts,
  getTestCategory,
  getTestDetail,
  getTestLeaderBoard,
  getTestList,
  getTestProfile,
  submitTestData,
  updateSkillTest,
} from '@/apis/testApis'

export const testApiSlice = createApi({
  reducerPath: 'testApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: [
    'TestList',
    'TestProfile',
    'TestAttempts',
    'TestLeaderBoard',
    'TestDetail',
    'SubmitTest',
    'SkillTestCategories',
    'CreateQuestion',
  ],

  endpoints: (builder) => ({
    skillTestList: builder.query<PaginatedData<ISkillTest>, TestListDTO>({
      queryFn: async (payload) => {
        try {
          return await getTestList(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['TestList'],
    }),
    skillTestDetail: builder.query<ISkillTest, number>({
      queryFn: async (id) => {
        try {
          return await getTestDetail(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['TestDetail'],
    }),
    skillTestProfile: builder.query<ISkillTestProfile, number | void>({
      queryFn: async (userId?: number) => {
        try {
          return await getTestProfile(userId)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['TestProfile'],
    }),
    skillTestAttempts: builder.query<PaginatedData<ITest>, TestAttemptsDTO>({
      queryFn: async (payload) => {
        try {
          return await getTestAttempts(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['TestAttempts'],
    }),
    skillTestLeaderBoard: builder.query<any, TestLeaderBoardDTO>({
      queryFn: async (payload) => {
        try {
          return await getTestLeaderBoard(payload)
        } catch (error) {
          throw new Error((error as any).response)
        }
      },
      providesTags: ['TestLeaderBoard'],
    }),
    startSkillTest: builder.query<ITest, number>({
      queryFn: async (id) => {
        try {
          return await getStartTest(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['TestDetail'],
    }),
    submitSkillTest: builder.mutation<{ data: ITest }, SubmitTestDTO>({
      queryFn: async (data) => {
        try {
          return await submitTestData(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['SubmitTest', 'TestList', 'TestDetail'],
    }),
    skillTestFilters: builder.query<ITestFilter, void>({
      queryFn: async (data) => {
        try {
          return await getSkillTestFilters()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['TestList'],
    }),
    skillTestCategoryFilters: builder.query<ITestFilter, void>({
      queryFn: async (data) => {
        try {
          return await getSkillTestCategoryFilters()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['TestList'],
    }),
    createSkillTestCategory: builder.mutation<
      { data: TestCategory },
      SkillTestCategoryDTO
    >({
      queryFn: async (data) => {
        try {
          return await createTestCategory(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['SubmitTest', 'SkillTestCategories'],
    }),
    getCategoryDetail: builder.query<TestCategory, number>({
      queryFn: async (id) => {
        try {
          return await getTestCategory(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['TestDetail'],
    }),
    createSkillTest: builder.mutation<{ data: ISkillTest }, FormData>({
      queryFn: async (data) => {
        try {
          return await createSkillTest(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['SubmitTest'],
    }),
    skillTestCategories: builder.query<ISkillTestCategory, IFetchSkillTestDTO>({
      queryFn: async (payload) => {
        try {
          return await getSkillTestCategories(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['SkillTestCategories'],
    }),
    createSkillTestQuestion: builder.mutation<
      { data: ISkillTest },
      IAddQuestionDTO
    >({
      queryFn: async (data) => {
        try {
          return await createQuestion(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: [
        'CreateQuestion',
        'SkillTestCategories',
        'SubmitTest',
        'TestDetail',
        'TestList',
      ],
    }),
    updateSkillsTest: builder.mutation<
      { data: ISkillTest },
      { id: number; test: FormData }
    >({
      queryFn: async (data) => {
        try {
          return await updateSkillTest(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['SkillTestCategories', 'TestList'],
    }),
  }),
})

export const {
  useSkillTestListQuery,
  useSkillTestProfileQuery,
  useSkillTestAttemptsQuery,
  useSkillTestLeaderBoardQuery,
  useSkillTestDetailQuery,
  useStartSkillTestQuery,
  useSubmitSkillTestMutation,
  useSkillTestFiltersQuery,
  useCreateSkillTestCategoryMutation,
  useSkillTestCategoriesQuery,
  useCreateSkillTestMutation,
  useCreateSkillTestQuestionMutation,
  useGetCategoryDetailQuery,
  useUpdateSkillsTestMutation,
  useSkillTestCategoryFiltersQuery,
} = testApiSlice

export const resetProfileApiSlice = () => testApiSlice.util.resetApiState()
export const invalidateProfileApiSlice = testApiSlice.util.invalidateTags
