import { getUnreadEngagement, UnreadMessages } from './../apis/engagementApis'
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaginatedData } from '@/apis/api.types'
import {
  createEngagement,
  createEngagementMessage,
  Engagement,
  EngagementDTO,
  getEngagementMessages,
  getEngagements,
  Message,
  MessageDTO,
  MessagePageDTO,
  PageDTO,
} from '@/apis/engagementApis'
import { getUniques } from '@/helpers/array'

const initialState: {
  selectedMessage: Partial<Engagement>
} = {
  selectedMessage: {},
}

export const messagesApiSlice = createApi({
  reducerPath: 'messagesApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Messages', 'Engagement'],

  endpoints: (builder) => ({
    getUnreadEngagement: builder.query<UnreadMessages, PageDTO>({
      queryFn: async (data) => {
        try {
          return await getUnreadEngagement(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Engagement', 'Messages'],
    }),
    getEngagements: builder.query<PaginatedData<Engagement>, PageDTO>({
      queryFn: async (data) => {
        try {
          return await getEngagements(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      merge: (currentCache, newItems) => {
        const newCacheResults = currentCache.results
        newCacheResults.unshift(...newItems.results)
        const allResults = getUniques(newCacheResults, 'alumnus.id')
        currentCache.results = allResults
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page
      },
      serializeQueryArgs: ({ endpointName }) => endpointName,
      providesTags: ['Engagement'],
    }),
    createEngagement: builder.mutation<{ data: Engagement }, EngagementDTO>({
      queryFn: async (data) => {
        try {
          return await createEngagement(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Engagement'],
    }),
    getEngagementMessages: builder.query<
      PaginatedData<Message>,
      MessagePageDTO
    >({
      queryFn: async (data) => {
        try {
          return await getEngagementMessages(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      // merge: (currentCache, newItems) => {
      //   currentCache.results.push(...newItems.results)
      // },
      // forceRefetch({ currentArg, previousArg }) {
      //   return currentArg?.page !== previousArg?.page
      // },
      // serializeQueryArgs: ({ endpointName }) => endpointName,
      providesTags: ['Messages'],
    }),
    createEngagementMessage: builder.mutation<{ data: Message }, MessageDTO>({
      queryFn: async (data) => {
        try {
          return await createEngagementMessage(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Messages', 'Engagement'],
    }),
  }),
})

export const {
  usePrefetch: usePrefetchMessages,
  useGetEngagementsQuery,
  useCreateEngagementMutation,
  useGetEngagementMessagesQuery,
  useCreateEngagementMessageMutation,
  useGetUnreadEngagementQuery,
} = messagesApiSlice
export const initialiseEngagements =
  messagesApiSlice.endpoints.getEngagements.initiate
export const invalidateApi = messagesApiSlice.util.invalidateTags
export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setSelectedMessage(state, action: PayloadAction<Engagement>) {
      state.selectedMessage = action.payload
    },
  },
})

export const { setSelectedMessage } = messagesSlice.actions

export default messagesSlice.reducer
