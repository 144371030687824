import clsx from 'clsx'
import React, { AllHTMLAttributes, useEffect, useRef, useState } from 'react'

interface IRadio extends AllHTMLAttributes<HTMLInputElement> {
  label?: string
  orientation?: 'horizontal' | 'vertical'
  checkComponent?: React.ReactElement
  uncheckedComponent?: React.ReactElement
  customLabel?: React.ReactNode
}
function RadioButton(props: IRadio) {
  const {
    label,
    id,
    orientation = 'horizontal',
    checkComponent,
    uncheckedComponent,
    onChange,
    checked: passCheck,
    className,
    customLabel,
    ...rest
  } = props
  const [checked, setChecked] = useState(passCheck)
  const changeHandler = (e: any) => {
    setChecked(e.currentTarget.checked)
    onChange?.(e)
  }
  useEffect(() => {
    setChecked(passCheck)
  }, [passCheck])
  const InputRef = useRef<HTMLInputElement>(null)

  return (
    <div
      className={`flex relative  text-jaa-shades-gray-3 text-base leading-[180%] font-medium hover:text-jaa-shades-black 
    ${
      orientation === 'horizontal'
        ? 'flex-row justify-start gap-2 items-center'
        : 'flex-col justify-start items-start'
    } ${className ? className : ''}`}
    >
      <input
        type='radio'
        id={id}
        checked={checked}
        ref={InputRef}
        onChange={changeHandler}
        {...rest}
        className={clsx(
          `peer opacity-0 absolute outline-none border-none `,
          !checkComponent &&
            `opacity-100 static disabled:cursor-not-allowed disabled:border-2 disabled:border-jaa-shades-gray-2.5 disabled:bg-jaa-blue disabled:hover:after:bg-jaa-blue disabled:checked:after:bg-jaa-shades-gray-2.5 disabled:checked:hover:bg-jaa-blue disabled:checked:hover:after:bg-point-secondary-text
        appearance-none m-0 accent-jaa-blue flex justify-center items-center checked:after:bg-jaa-blue checked:hover:bg-white checked:hover:border-2 checked:hover:border-point-bg-blue checked:hover:after:bg-jaa-blue
        w-6 h-6 border-2 rounded-full border-jaa-blue after:block after:rounded-full after:w-3 after:h-3 after:m-[3px]`
        )}
      />
      <span onClick={() => InputRef.current?.click()}>
        {checked ? checkComponent : uncheckedComponent}
      </span>
      <label
        htmlFor={id}
        onClick={() => InputRef.current?.click()}
        className='peer-checked:text-jaa-text-black cursor-pointer peer:disabled:!cursor-not-allowed py-2 '
      >
        {customLabel ? customLabel : label}
      </label>
    </div>
  )
}

RadioButton.defaultProps = {
  orientation: 'horizontal',
}
export default RadioButton
