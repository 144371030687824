export const COLORS = [
  '#eee82c',
  '#8073d1',
  '#2fb574',
  '#00a0ae',
  '#5995d2',
  '#edf5f5',
  '#2d3639',
  '#285f74',
  '#59160d',
  '#97daba',
  '#efceaf',
  '#bfb9e8',
  '#accae9',
  '#ccecef',
  '#537f90',
  '#fcfad5',
  '#2c3335',
  '#181818',
]
