import { useState, useEffect } from 'react'

import countriesList from 'countrycitystatejson'

export const useCountries = () => {
  const [countriesStateList, setCountriesStateList] = useState([])

  useEffect(() => {
    const handleGetCountries = () => {
      const countries = countriesList?.getCountries() || []
      return countries
    }

    setCountriesStateList(handleGetCountries())
  }, [])

  return [countriesStateList]
}

export const useCountryStates = (country) => {
  const [statesList, setStatesList] = useState([])
  const handleGetStates = (country) => {
    if (!country) return []
    const states = countriesList?.getStatesByShort(country) || []
    return states
  }

  useEffect(() => {
    setStatesList(handleGetStates(country))
  }, [country])

  return [statesList]
}
