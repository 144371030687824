import { useCallback } from 'react'
import { useState } from 'react'

export type MismatchOptions = {
  initialTablePage?: number
  initialRequestPage?: number
  tablePerPage?: number
  requestPerPage?: number
}
const useMismatchPages = ({
  initialTablePage = 1,
  initialRequestPage = 1,
  tablePerPage = 10,
  requestPerPage = 30,
}: MismatchOptions) => {
  const [tablePage, setTablePage] = useState(initialTablePage)
  const [requestPage, setRequestPage] = useState(initialRequestPage)

  const pageChangeHandler = useCallback(
    (page: number) => {
      setTablePage(page)
      // if ((page - 1) % Math.floor(requestPerPage / tablePerPage) === 0) {
      setRequestPage((prev) => {
        return Math.ceil(page / (requestPerPage / tablePerPage))
        // if (page * tablePerPage > prev * requestPerPage) {
        //   return Math.ceil(page / (requestPerPage / tablePerPage));
        // } else {
        //   return Math.floor(page / (requestPerPage / tablePerPage)) || 1;
        // }
      })
      // }
    },
    [tablePerPage, requestPerPage]
  )
  const resetPages = useCallback((page?: number) => {
    setTablePage(page || 1)
    setRequestPage(page || 1)
  }, [])
  return {
    tablePage,
    requestPage,
    pageChangeHandler,
    resetPages,
    setRequestPage,
  }
}

export default useMismatchPages
