import { Job, Pipeline, Stage } from '@/apis/recruitementApis'
import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button'
import LazySpinner from '@/common/spinners/LazySpinner'
import Typography from '@/common/Typography'
import useQueryParams from '@/hooks/useQueryParams'
import { useToggleState } from '@/hooks/useToggleState'
import { COLORS } from '@/pages/recruitment/recruiter/pipeline/constant'
import { useAppSelector } from '@/store/hooks'
import { useGetJobApplicationQuery } from '@/store/recruitementSlice'
import clsx from 'clsx'
import { FC } from 'react'
import { ScaleLoader } from 'react-spinners'
import JobDetail from '../../jobs/details/JobDetail'
import ShowJobPost from './ShowJobPost'
import { useTranslation } from 'react-i18next'

const OngoingDetail = (props: { className?: string }) => {
  const { t } = useTranslation()

  const {
    open: openJobPost,
    state: showJobPost,
    close: closeJobPost,
  } = useToggleState(false)
  const archived = useQueryParams().archived
  const applicationId = useQueryParams().application
  const selectedId =
    useAppSelector((state) => state.jobs.selectedJob) || Number(applicationId)
  const { data: fullApplication, isLoading } = useGetJobApplicationQuery(
    selectedId,
    {
      skip: !selectedId,
    }
  )

  return isLoading ? (
    <LazySpinner show delay={300}>
      <div className='flex flex-col items-center justify-center gap-3 w-full border-l border-[#d9d9d9] min-h-[10vh] bg-white'>
        <ScaleLoader color='var(--teal-100)' />
      </div>
    </LazySpinner>
  ) : archived ? (
    <JobDetail hasApplied job={fullApplication?.job} />
  ) : (
    <section
      className={clsx(
        'flex flex-col items-start gap-3 w-full border-l border-[#d9d9d9]',
        props.className || ''
      )}
    >
      <div className='w-full'>
        {selectedId ? (
          <div className='min-w-[24px] w-full'>
            <header className=' flex flex-col items-start p-4 w-full border-b border-[#d9d9d9]'>
              <div className=' w-full flex justify-between items-start gap-10'>
                <div className='flex flex-col items-start gap-1 flex-1'>
                  <div className='flex flex-col items-start'>
                    <Typography
                      as='h4'
                      className='h4 font-medium text-jaa-shades-gray-4'
                    >
                      {fullApplication?.job.title}
                    </Typography>
                    <Typography
                      as='p'
                      className='md font-normal text-jaa-shades-gray-2.5'
                    >
                      {fullApplication?.job.company_name}{' '}
                      {/*  Todo: No company industry */}
                    </Typography>
                  </div>
                  <Button
                    variant='empty'
                    className='!p-0 !h-[21px]'
                    onClick={openJobPost}
                    label={
                      <span className='flex justify-center items-center gap-1 text-jaa-teal-100 stroke-jaa-teal-100'>
                        <span>
                          {t('app.ongoing_application.view_job_posting')}
                        </span>
                        <iconComponents.util.ArrowRightIcon />
                      </span>
                    }
                  />
                </div>
                <div
                  id='badge'
                  className='relative w-9 h-9 bg-[#f7f7f7] rounded flex items-center justify-center p-2'
                >
                  <iconComponents.recruitment.MessagesIcon className='w-5 h-5' />
                  <div className='flex justify-center items-center w-5 h-5 absolute left-[23px] -top-2 rounded-full bg-[#f04438] xs font-medium text-jaa-shades-white tracking-[0.08rem]'>
                    2
                  </div>
                </div>
              </div>
            </header>
            <PipelineInfo
              job={fullApplication?.job}
              stage_id={fullApplication?.stage_id}
            />
          </div>
        ) : (
          <NoDetailSelected />
        )}
      </div>

      <ShowJobPost
        open={showJobPost}
        onClose={closeJobPost}
        data={fullApplication?.job}
      />
    </section>
  )
}

const PipelineInfo: FC<{ job?: Job; stage_id?: number }> = (props) => {
  const { t } = useTranslation()

  const { job, stage_id } = props
  const getApplicantStage = () => {
    const pipeline = job?.pipeline
    if (!stage_id) return -1
    const stageInd = pipeline?.stage_order.indexOf(stage_id)
    if (stageInd === -1) return -1
    return stageInd as number
  }
  const stages = [...(job?.pipeline?.stages || [])].sort(
    (stageA, stageB) =>
      job?.pipeline?.stage_order?.indexOf?.(stageA?.id!)! -
      job?.pipeline?.stage_order?.indexOf?.(stageB?.id!)!
  )
  return (
    <main className='flex-1 w-full rounded-lg relative pl-4 pt-4 max-h-full pb-4'>
      <div className='flex flex-col items-start w-full z-20 relative'>
        <Typography
          as='h4'
          className='text-jaa-shades-black font-medium h5 lg:h4'
        >
          {t('app.ongoing_application.pipeline')} (
          {job?.pipeline?.stages?.length})
        </Typography>
        <Typography as='p' className='text-jaa-shades-gray-2.5 sm font-normal'>
          {t('app.ongoing_application.view_pipeline_details')}
        </Typography>
      </div>
      <div
        style={{
          background:
            'linear-gradient(180deg, #FFFFFF 49.37%, rgba(255, 255, 255, 0.02) 100%)',
        }}
        className='absolute left-0 top-8 w-full h-[86px] lg:h-[100px] z-10'
      ></div>
      <div className='flex min-w-[24px] flex-col items-start gap-20 border-l-2 border-jaa-teal-100 flex-1 overflow-y-auto py-8 my-2 max-h-[calc(100vh_-_216px)] md:max-h-[calc(100vh_-_286px)] scrollbar scrollbar-w-1 scrollbar-track-rounded-lg scrollbar-thumb-rounded-lg scrollbar-thumb-jaa-dark-teal-80 scrollbar-track-jaa-shades-white '>
        {stages.map((stage, idx) => (
          <StageItem
            key={stage.id}
            isActive={getApplicantStage() === idx}
            isReached={((stageInd) => {
              if (stageInd === -1) return false
              return stageInd >= idx
            })(getApplicantStage())}
            stage={stage}
            stage_order={job?.pipeline?.stage_order}
          />
        ))}
      </div>
    </main>
  )
}

const StageItem = (props: {
  isActive: boolean
  isReached: boolean
  stage: Stage
  stage_order?: number[]
}) => {
  const { isActive, isReached, stage, stage_order } = props
  const getStageOrder = () => {
    if (!stage_order) return 0
    const stageInd = stage_order.indexOf(stage.id as number)
    if (stageInd === -1) return 0
    return stageInd
  }

  return (
    <div className='flex justify-start items-start gap-1'>
      <div
        className={clsx(
          'border-[12px] border-transparent border-l-jaa-teal-100 relative -left-0.5 -top-[6px]',
          isActive ? 'visible' : 'invisible'
        )}
      ></div>
      <div className='flex flex-col items-start gap-5 flex-1'>
        <div
          style={{
            backgroundColor: isReached
              ? COLORS[getStageOrder()] + '42'
              : '#F7F7F7',
            color: isReached ? COLORS[getStageOrder()] : '#181818',
          }}
          className=' py-1 px-8 gap-2.5 flex items-center justify-center rounded h-[27px] capitalize whitespace-nowrap'
        >
          {stage.name}
        </div>
        <div className='flex flex-col items-start gap-2 w-full'>
          <Typography
            as='h4'
            className='md font-normal text-black whitespace-nowrap'
          >
            {stage.name}
          </Typography>
          <Typography
            as='p'
            className='sm font-normal text-jaa-shades-gray-2.5'
          >
            {stage.description}
          </Typography>
        </div>
      </div>
    </div>
  )
}

const NoDetailSelected: FC<{}> = () => {
  const { t } = useTranslation()

  return (
    <div className='min-h-[55vh] w-full h-full flex justify-center items-center flex-col bg-white rounded-lg self-stretch gap-6'>
      <iconComponents.recruitment.EmptyPageIcon className='w-[140px] h-[110px]' />
      <div className='flex flex-col gap-1 justify-center items-center'>
        <Typography as='h5' className='text-jaa-shades-black'>
          {t('app.ongoing_application.job_details')}
        </Typography>
        <Typography
          as='p'
          className='sm text-jaa-shades-gray-2.5 font-normal w-fu; text-center'
        >
          {t('app.ongoing_application.select_job')}
        </Typography>
      </div>
    </div>
  )
}
export default OngoingDetail
