import useLogout from '@/apis/hooks/useLogout'
import iconComponents from '@/assets/icons/iconComponents'
import Modal from '@/common/modal/Modal'
import { Storage } from '@/helpers/Storage'
import { useToggleState } from '@/hooks/useToggleState'

import { Link } from 'react-router-dom'
import { useMenuItems } from '../../dashboard.hooks'

import LogoutWarning, { DONT_SHOW_LOGOUT_MODAL } from '../LogoutWarning'
import NavItem from '../NavItem'
import UserInfo from '../UserInfo'
import { useTranslation } from 'react-i18next'
import Image from '@/common/Image'

const Sidebar = () => {
  const { userType, menuItems } = useMenuItems()

  const logout = useLogout()
  const { t } = useTranslation()

  const {
    state: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useToggleState(false)
  const dontShow = Storage.getItem(DONT_SHOW_LOGOUT_MODAL)
  const logoutHandler = () => {
    if (dontShow) {
      logout()
    } else {
      openModal()
    }
  }
  return (
    <>
      <div className='border-r border-jaa-shades-gray-1 h-screen bg-jaa-shades-white flex flex-col justify-start pt-8  px-0 gap-20 min-w-full'>
        <Link
          to={`/${userType}/app/dashboard`}
          className='active:scale-95 ml-[22px] mr-auto'
        >
          {/* <iconComponents.util.LogoIcon className='h-[39px]' /> */}
          <Image
            src='/Logo_Full Color.png'
            className='h-[49px]  object-cover object-center'
          />
        </Link>
        <nav className='pl-8'>
          <ul role='menu'>
            {menuItems.map((menu) => (
              <NavItem {...menu} key={menu.name} queryKey={menu.key} />
            ))}
          </ul>
        </nav>
        <div className=' flex-1 w-full flex justify-end flex-col gap-8 mb-6'>
          <nav className='w-full flex-1 flex justify-end flex-col pl-8'>
            <ul role='menu' className='flex flex-col justify-end  gap-2'>
              <NavItem
                icon={iconComponents.navigation.SettingsIcon}
                name={t('app.menu.Settings')}
                url={`/${userType}/app/setting`}
                queryKey={''}
              />
              <NavItem
                icon={iconComponents.navigation.LogoutIcon}
                name={t('app.menu.LogOut')}
                onClick={logoutHandler}
              />
            </ul>
          </nav>
          <UserInfo />
        </div>
      </div>
      <Modal
        style={{ zIndex: 10000000 }}
        variant='sm'
        open={isModalOpen}
        onClose={closeModal}
      >
        <LogoutWarning onClose={closeModal} logoutAction={logout} />
      </Modal>
    </>
  )
}

export default Sidebar
