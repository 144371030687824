import { addDataToQueryString } from '@/helpers/strings'
import api from './api'
import {
  ALUMNI_REPORT,
  GENDER_REPORT,
  GET_FORUM_ENGAGEMENTS,
  RECRUITER_REPORT,
  USERS_GROWTH_REPORT,
  USERS_PER_COUNTRY_REPORT,
} from './endpoints'

export type USER_TYPES = 'all' | 'alumnus' | 'recruiter'

export type UserGrowthDTO = {
  user_type: USER_TYPES
  period: 'daily' | 'monthly' | 'yearly'
  date: string
}

export type RecruiterReportData = {
  registered_recruiters: number
  jobs_posted: number
  hired_alumni: number
  blocked_recruiters: number
  hiring_rate: number
}
export type ForumEngagement = {
  total_posts: number
  total_comments: number
  total_upvotes: number
  total_reported_users: number
}

export type AlumniReportData = {
  total_alumni: number
  total_engaged_alumni: number
  total_hired_alumni: number
  total_rejected: number
  total_blocked_alumni: number
  alumni_profile_completion: {
    average: number
    count: number
  }
  total_job_applications: number
  pipeline_progression: {
    early_stage: {
      average: number
      count: number
    }
    mid_stage: {
      average: number
      count: number
    }
    end_stage: {
      average: number
      count: number
    }
  }
}

export type GenderReportResponse = {
  total_count: number
  male_count: number
  female_count: number
  male_percent: number
  female_percent: number
  male_degree: number
  female_degree: number
}
export type UserGrowthReportResponse =
  | {}
  | (Partial<Record<'month' | 'hour' | 'day', string>> & { count: number })
export type UserPerCountryReportResponse =
  | {}
  | {
      country: string
      country_code: string
      count: number
    }[]

export const getRecruiterReportData = async (id?: number) => {
  return (await api.get<{ data: RecruiterReportData }>(RECRUITER_REPORT)).data
}
export const getAlumniReportData = async (id?: number) => {
  return (await api.get<{ data: AlumniReportData }>(ALUMNI_REPORT)).data
}
export const getGenderReportData = async (payload: USER_TYPES) => {
  const qs = addDataToQueryString('', { user_type: payload })
  return (
    await api.get<{ data: GenderReportResponse }>(
      GENDER_REPORT + (qs ? `?${qs}` : '')
    )
  ).data
}
export const getUserGrowthReportData = async (payload: UserGrowthDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: UserGrowthReportResponse }>(
      USERS_GROWTH_REPORT + (qs ? `?${qs}` : '')
    )
  ).data
}
export const getUserPerCountryReportData = async (payload: USER_TYPES) => {
  const qs = addDataToQueryString('', { user_type: payload })
  return (
    await api.get<{ data: UserPerCountryReportResponse }>(
      USERS_PER_COUNTRY_REPORT + (qs ? `?${qs}` : '')
    )
  ).data
}

export const getFroumEngagementData = async () => {
  return (await api.get<{ data: ForumEngagement }>(GET_FORUM_ENGAGEMENTS)).data
}
