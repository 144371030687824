import { UserTypes } from '@/apis/authApis'
import { Storage } from '@/helpers/Storage'
import { getAccessTokens } from '@/store/authSlice'
import { useAppSelector } from '@/store/hooks'

import { Navigate, Outlet, useLocation } from 'react-router'

const ProtectedRoutes = () => {
  const accessToken = useAppSelector(getAccessTokens)

  const userType: UserTypes = Storage.getItem('USER_TYPE') || 'alumni'
  const location = useLocation()

  if (!['recruiter', 'alumni', 'admin'].includes(userType as string))
    return (
      <Navigate
        to={`/${userType}/auth/sign-in`}
        state={{ from: location.pathname + (location.search || '') }}
        replace
      />
    )
  return accessToken ? (
    <Outlet />
  ) : (
    <Navigate
      to={`/${userType}/auth/sign-in`}
      state={{ from: location.pathname + (location.search || '') }}
      replace
    />
  )
  // return <Outlet />
}

export default ProtectedRoutes
