import React, { useState } from 'react'
import * as Icon from 'react-feather'
import heroImage from '../Icons/hero.png'
import { Button } from '../components/Forms/button'
import Modal from '@/common/modal/Modal'
import Video from '../../../assets/videos/JACC.mp4'
import { useTranslation } from 'react-i18next'

export const Hero = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const toggleVideoModal = () => {
    setShow((prev) => !prev)
  }

  return (
    <>
      <div
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${heroImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          objectFit: 'contain',
        }}
        id='home'
        className='mt-[64px] h-[540px] pb-11 space-y-2 flex items-center justify-center text-jaa-white'
      >
        <section className='space-x-0 w-full flex flex-col-reverse justify-center items-center md:space-x-12 md:flex-row px-7'>
          <article
            data-aos='zoom-in'
            className='max-w-screen-xl py-4 lg:w-4/5 w-full space-y-8 flex items-center justify-center flex-col'
          >
            <div className='flex items-center flex-col justify-start'>
              <span className='text-[36px] md:text-[40px] leading-normal md:leading-[67px] font-semibold text-doc-white text-center'>
                {t('app.landing.connecting_talent_with_opportunity')}
              </span>
            </div>
            <p className='text-sm md:text-base text-jaa-white font-normal w-full 2xl:text-3xl text-center lg:w-4/5 '>
              {t('app.landing.hero_text')}
            </p>
            <Button onClick={toggleVideoModal} variant='primary'>
              {t('app.landing.watch_demo')}
            </Button>
          </article>
        </section>
      </div>
      <Modal variant='xl' open={show} onClose={toggleVideoModal} reEvaluate>
        <video
          className='w-full flex justify-center items-center'
          controls
          autoPlay
        >
          <source src={Video} type='video/mp4' />
        </video>
      </Modal>
    </>
  )
}
