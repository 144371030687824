import { Job } from '@/apis/recruitementApis'
import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button'
import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import { withAsync } from '@/helpers/withAsync'
import useQueryParams from '@/hooks/useQueryParams'
import { JobImages } from '@/pages/recruitment/recruiter/jobsposting/detail/JobDescription'
import { useAppSelector } from '@/store/hooks'
import {
  useAlumniApplyForJobMutation,
  useGetJobQuery,
} from '@/store/recruitementSlice'
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const JobDetail = (props: {
  className?: string
  hasApplied?: boolean
  job?: Job
}) => {
  const { hasApplied, job } = props
  const jobId = useQueryParams().job

  const selectedId =
    useAppSelector((state) => state.jobs.selectedJob) || Number(jobId)
  const { data: fullJob } = useGetJobQuery(selectedId, {
    skip: !!job || !selectedId,
  })
  return (
    <section
      className={clsx(
        'w-full flex flex-1  items-start gap-2.5 p-[16px_4px_0px_16px] overflow-y-auto  scrollbar scrollbar-w-1 scrollbar-track-rounded-lg scrollbar-thumb-rounded-lg scrollbar-thumb-jaa-dark-teal-80 scrollbar-track-jaa-shades-white',
        props.className || ''
      )}
    >
      <div className='w-full'>
        {selectedId || job ? (
          <div className='flex flex-col items-end gap-5 w-full h-full flex-1 '>
            <JobInfo hasApplied={hasApplied} job={job || fullJob} />
            <Typography
              as='p'
              className='font-normal sm text-black flex-1 !text-left w-full'
            >
              {(job || fullJob)?.description}
            </Typography>
          </div>
        ) : (
          <NoDetailSelected />
        )}
      </div>
    </section>
  )
}

const JobInfo = (props: { hasApplied?: boolean; job?: Job }) => {
  const { t } = useTranslation()

  const { hasApplied, job } = props
  const [isApplied, setIsApplied] = useState(hasApplied)
  const [applyForJob, { isLoading }] = useAlumniApplyForJobMutation()
  const applyHandler = async () => {
    if (!job?.id || hasApplied) return
    const { error, response } = await withAsync(() =>
      applyForJob(job.id).unwrap()
    )
    if (error) {
      ToastNotify('error', {
        message: (error as any).message || t('app.job_board.apply_error_msg'),
      })
      return
    }

    ToastNotify('success', {
      message:
        (response as any)?.message || t('app.job_board.apply_success_msg'),
    })
    setIsApplied(true)
  }
  useEffect(() => {
    setIsApplied(hasApplied)
  }, [hasApplied])
  return (
    <div className='flex flex-col items-start gap-10 w-full'>
      <div className='flex flex-col items-start gap-4'>
        <header className='flex flex-col items-start gap-1 w-full'>
          <div className='flex flex-col items-start w-full'>
            <Typography
              as='h5'
              className='text-jaa-shades-gray-2.5 sm font-normal'
            >
              {t('app.job_board.job_desc')}
            </Typography>
            <Typography
              as='h3'
              className='h5 lg:h3 font-medium text-jaa-shades-black'
            >
              {job?.title}
            </Typography>
          </div>
          <div className='flex flex-wrap items-center  gap-2 justify-start'>
            <Typography className='flex-1 sm text-jaa-shades-black font-normal'>
              {job?.company_name}
            </Typography>
            <span className='flex items-center gap-2'>
              <span className='w-0.5 h-0.5 rounded-full bg-jaa-shades-gray-2.5'></span>
              <span className='font-normal xs text-jaa-shades-gray-2.5'>
                {(job as any)?.company_industry}
              </span>
            </span>
          </div>
        </header>
        <main className='w-full flex flex-col items-start gap-3'>
          <div className='w-full gap-8 flex xl:gap-14 items-start'>
            <div className='flex items-center gap-2'>
              <iconComponents.util.TwoUserIcon className='w-[18px] h-[18px] stroke-jaa-shades-gray-2.5' />
              <Typography className='text-jaa-shades-black sm font-normal whitespace-nowrap'>
                {job?.company_size} employees
              </Typography>
            </div>
            <div className='flex items-center gap-2'>
              <iconComponents.util.LocationIcon className='w-[18px] h-[18px] stroke-jaa-shades-gray-2.5' />
              <Typography className='text-jaa-shades-black sm font-normal whitespace-nowrap'>
                {job?.location}
              </Typography>
            </div>
          </div>
          <div className='w-full gap-8 flex xl:gap-14 items-start '>
            <div className='flex items-center gap-2'>
              <iconComponents.profile.BriefCaseIcon className='w-[18px] h-[18px] stroke-jaa-shades-gray-2.5' />
              <Typography className='text-jaa-shades-black sm font-normal flex flex-1 gap-0.5 items-center flex-wrap'>
                <span>{job?.job_type}</span>
                <span className='text-jaa-shades-gray-2.5'>
                  + {job?.years_of_experience}
                  {t('app.job_board.yrs_experience')}
                </span>
              </Typography>
            </div>
            <div className='flex items-center gap-2'>
              <iconComponents.profile.MoneyStackIcon className='w-[18px] h-[18px] stroke-jaa-shades-gray-2.5' />
              <Typography className='text-jaa-shades-black sm font-normal flex flex-1 gap-0.5 items-center flex-wrap'>
                <span>{job?.salary_range}</span>
                <span className='text-jaa-shades-gray-2.5'>
                  {job?.salary_currency?.split?.(' ')?.[0]}
                </span>
              </Typography>
            </div>
          </div>
        </main>
      </div>
      <JobImages data={job! || { images: [] }} isRecruiter={false} />
      {!isApplied && (
        <Button
          onClick={applyHandler}
          loading={isLoading}
          disabled={isLoading}
          label={t('app.apply')}
          className=' w-full md:w-[263px] !h-9'
        />
      )}
    </div>
  )
}

const NoDetailSelected: FC<{}> = () => {
  const { t } = useTranslation()

  return (
    <div className='min-h-[55vh] w-full h-full flex justify-center items-center flex-col bg-white rounded-lg self-stretch gap-6'>
      <iconComponents.recruitment.EmptyPageIcon className='w-[140px] h-[110px]' />
      <div className='flex flex-col gap-1 justify-center items-center'>
        <Typography as='h5' className='text-jaa-shades-black'>
          {t('app.recruitement.job_details')}
        </Typography>
        <Typography
          as='p'
          className='sm text-jaa-shades-gray-2.5 font-normal w-fu; text-center'
        >
          {t('app.recruitement.job_details_desc')}
        </Typography>
      </div>
    </div>
  )
}

export default JobDetail
