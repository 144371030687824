import React from 'react'
import { ContactIcon, PersonIcon, PipeIcon, StarIcon } from '../Icons/generated'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const Connect = () => {
  const { t } = useTranslation()
  const connectData = [
    {
      id: 1,
      text: `${t('app.landing.streamlined_text')}`,
      icon: <PipeIcon />,
      header: `${t('app.landing.streamlined')}`,
    },
    {
      id: 2,
      text: `${t('app.landing.collaborate_text')}`,
      icon: <PersonIcon />,
      header: `${t('app.landing.collaborate')}`,
    },
    {
      id: 3,
      text: `${t('app.landing.comprehensive_text')}`,
      icon: <ContactIcon />,
      header: `${t('app.landing.comprehensive_profiles')}`,
    },
    {
      id: 4,
      text: `${t('app.landing.smart_text')}`,
      icon: <StarIcon />,
      header: `${t('app.landing.smart_recommendations')}`,
    },
  ]

  return (
    <div
      id='why'
      className='w-full flex items-center justify-center flex-col space-y-28 p-7 md:p-24'
    >
      <section className='w-full max-w-screen-2xl flex items-center justify-center flex-col space-y-24'>
        <section className='w-full flex flex-col lg:space-x-8 space-y-4 lg:flex-row lg:justify-between items-start'>
          <h1 className='w-full lg:w-1/2 font-semibold text-[40px]'>
            {t('app.landing.ja_connect_advantage')}
          </h1>
          <article className='w-full lg:w-1/2'>
            {t('app.landing.advantage_text')}
          </article>
        </section>

        <main className='w-full grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2'>
          {connectData?.map((item) => (
            <ConnectCard
              key={item.id}
              text={item.text}
              icon={item.icon}
              header={item.header}
            />
          ))}
        </main>
      </section>
    </div>
  )
}

export const ConnectCard = ({ text, icon, header }) => {
  return (
    <div className='w-full flex items-start flex-col space-y-6'>
      {icon}
      <h1 className='text-xl font-semibold'>{header}</h1>
      <article className='text-base font-normal'>{text}</article>
    </div>
  )
}

ConnectCard.propTypes = {
  text: PropTypes.string,
  header: PropTypes.string,
  icon: PropTypes.node,
}
