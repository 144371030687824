import { RouteAwareCrumbs } from '@/common/breadcrumbs'
import Title from '@/common/Title'
import useQueryParams from '@/hooks/useQueryParams'
import { useParams } from 'react-router'
import OngoingDetail from './OngoingDetail'

const NameMap = new Map()
NameMap.set('/alumni/app/recruitment', 'Recruitment')

const OngoingDetailPage = () => {
  const { application: applicationName } = useParams()
  const archived = useQueryParams().archived
  NameMap.set(
    `/alumni/app/recruitment/${archived ? 'archived' : 'ongoing'}-application`,
    archived ? 'Archived Application' : 'Ongoing Application'
  )
  NameMap.set(
    `/alumni/app/recruitment/${
      archived ? 'archived' : 'ongoing'
    }-application/${applicationName}`,
    archived ? 'Job details' : 'Details'
  )
  return (
    <div className='w-full bg-jaa-shades-bg px-6 pb-4 flex flex-col gap-4 justify-start items-start'>
      <div className='flex justify-between items-center w-full'>
        <RouteAwareCrumbs nameMap={NameMap} />
        <Title> JA Africa - Job Detail</Title>
      </div>
      <div className='max-h-screen h-screen  w-full shadow-[0px_1px_2px_0px_#1018280D] flex flex-col items-start bg-jaa-shades-white rounded-lg'>
        <OngoingDetail className='mb-4' />
      </div>
    </div>
  )
}

export default OngoingDetailPage
