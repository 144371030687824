import Typography from '@/common/Typography'
import { format } from 'date-fns'
// import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { FC, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { invalidateNotificationApiSlice } from '@/store/notificationSlice'

export const NotificationCard: FC<{
  tag: string
  title: string
  message: string
  timestamp: string
  onClose: any
  unread: boolean
}> = (props) => {
  const navigate = useNavigate()
  const { tag, title, message, timestamp, unread, onClose } = props

  const handleNavigate = () => {
    navigate('/alumni/app/profile', { replace: true })
    onClose()
  }
  const appDispatch = useAppDispatch()

  // useEffect(() => {
  //   appDispatch(invalidateNotificationApiSlice(['Notifications']))
  // }, [])

  return (
    <article className='px-4 w-full '>
      <div className='flex flex-col w-full gap-3 items-start py-4 border-b border-[#D9D9D9]'>
        <div className='flex items-center gap-1'>
          {unread ? (
            <span className='w-1 h-1 rounded-full bg-[#F04438]'></span>
          ) : (
            <span className='w-1 h-1 rounded-full bg-[#8DA0A5]'></span>
          )}
          <Typography className='xs font-normal text-[#8DA0A5]'>
            {tag}
          </Typography>
        </div>
        <div className='flex w-full gap-3 items-start'>
          <div className='flex flex-col items-start gap-2 flex-1'>
            <header className='flex w-full justify-between'>
              <div className='flex  items-center gap-1'>
                {/* {unread && (
                  <span className='w-1 h-1 rounded-full bg-[#F04438]'></span>
                )} */}

                <Typography
                  as='h6'
                  className='font-semibold xs normal-case text-jaa-shades-black'
                >
                  {title}
                </Typography>
              </div>
              <div className='w-auto'>
                <Typography className='font-normal xs text-[#5a6d72]'>
                  {format(new Date(timestamp), 'dd MMM yyyy')}
                </Typography>
              </div>
            </header>

            <Typography className='sm font-normal text-jaa-shades-black'>
              {message}
            </Typography>
            {message.includes('profile is incomplete') ? (
              <span
                onClick={handleNavigate}
                className='text-xs text-jaa-shades-gray-4 underline cursor-pointer'
              >
                Go to Profile
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </article>
  )
}
