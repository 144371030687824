export const ReportingForOptions = [
  {
    value: 'myself',
    label: 'Myself',
  },
  {
    value: 'someone_else',
    label: 'Someone else',
  },
  {
    value: 'specific_group',
    label: 'A specific group',
  },
  {
    value: 'everyone',
    label: 'Everyone',
  },
]
export const ReasonOptions = [
  {
    value: 'identity',
    label: 'Attacked because of identity',
    subText:
      'Slurs, misgendering, racist or sexist stereotypes, encouraging others to harass, sending hateful imagery',
  },
  {
    value: 'violence',
    label: 'Harassed or intimidated with violence',
    subText:
      'Sexual harassment, group harassment, insults or name-calling, posting private info, threatening to expose private info, violent event denial, violent threats, celebration of violent acts',
  },
  {
    value: 'spam',
    label: 'Spammed',
    subText:
      'Posting malicious links, misusing hashtags, fake engagement, repetitive replies, Retweets, or Direct Messages.',
  },
  {
    value: 'self_harm',
    label: 'Shown content related to or encouraged to self-harm',
    subText: '',
  },
  {
    value: 'deceptive',
    label:
      'Offered tips or currency — or encouraged to send them — in a way that"s deceptive or promotes or causes harm',
    subText: '',
  },
]
