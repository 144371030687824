import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  Skill1Icon,
  Skill2Icon,
  Skill3Icon,
  Skill4Icon,
} from '../Icons/generated'
import SkillsImage from '../Icons/Image.png'

export const Skills = () => {
  const { t } = useTranslation()
  const SkillSets = [
    {
      id: 1,
      skill: `${t('app.landing.cognitive_skills')}`,
      text: `${t('app.landing.cognitive_text')}`,
      icon: <Skill1Icon />,
    },
    {
      id: 2,
      skill: `${t('app.landing.self_leadership')}`,
      text: `${t('app.landing.leadership_text')}`,
      icon: <Skill2Icon />,
    },
    {
      id: 3,
      skill: `${t('app.landing.interpersonal_skills')}`,
      text: `${t('app.landing.interpersonal_text')}`,
      icon: <Skill3Icon />,
    },
    {
      id: 4,
      skill: `${t('app.landing.digital_skills')}`,
      text: `${t('app.landing.digital_text')}`,
      icon: <Skill4Icon />,
    },
  ]

  return (
    <div
      id='skills'
      className='w-full flex items-center justify-center p-7 md:px-24'
    >
      <div className='w-full max-w-screen-2xl space-y-6 lg:space-y-0 flex flex-col justify-center items-center lg:flex-row lg:justify-between lg:space-x-16'>
        <section className='w-full lg:w-1/2 space-y-6'>
          <header className='flex items-start flex-col space-y-4'>
            <h1 className='text-[40px] font-semibold'>
              {t('app.landing.equipped')}
            </h1>
            <p className='text-sm lg:text-lg'>
              {t('app.landing.skill_studies')}
            </p>
          </header>
          <div className='flex items-start justify-center flex-col space-y-8'>
            {SkillSets?.map((item) => (
              <SkillCard
                key={item.id}
                skill={item.skill}
                text={item.text}
                icon={item.icon}
              />
            ))}
          </div>
        </section>
        <div className='w-full lg:w-1/2'>
          <img className='w-full' src={SkillsImage} />
        </div>
      </div>
    </div>
  )
}

export const SkillCard = ({ icon, skill, text }) => {
  return (
    <div className='flex items-center justify-center space-x-6'>
      <div>{icon}</div>
      <div className=''>
        <h1 className='text-lg lg:text-xl font-semibold'>{skill}</h1>
        <p className='text-sm lg:text-base text-jaa-tgray'>{text}</p>
      </div>
    </div>
  )
}

SkillCard.propTypes = {
  icon: PropTypes.node,
  skill: PropTypes.string,
  text: PropTypes.string,
}
