import { POST } from '@/apis/forumApis'
import Button from '@/common/button'
import Modal from '@/common/modal/Modal'
import ModalHeader from '@/common/modal/ModalHeader'
import { ToastNotify } from '@/common/toastManager'
import Typography from '@/common/Typography'
import { withAsync } from '@/helpers/withAsync'
import { useRepostFeedMutation } from '@/store/forumSlice'
import { format, formatDistanceToNow } from 'date-fns'
import { FC, useState } from 'react'
import FeedGallery from '../../components/FeedGallery'

type IRepost = {
  open: boolean
  onClose: () => void
  post?: POST
}

const RepostFeed = (props: IRepost) => {
  const { open, onClose, post } = props
  const [repostFeed, { isLoading: isReposting }] = useRepostFeedMutation()
  const [comment, setComment] = useState('')
  const repostHandler = async () => {
    if (!post) return
    const { error, response } = await withAsync(() =>
      repostFeed({
        postId: post.id,
        body: comment,
      }).unwrap()
    )
    if (error) {
      return ToastNotify('error', {
        message: (error as any).message || 'Ooops... could not repost',
      })
    }
    if ((response as any)?.data) {
      ToastNotify('success', {
        message:
          (response as any)?.message || 'Successfully reposted to cohort',
      })
      closeHandler()
    }
  }
  const closeHandler = () => {
    setComment('')
    onClose()
  }
  return (
    <Modal
      open={open}
      onClose={closeHandler}
      variant='md'
      className='p-4 md:p-6'
    >
      <ModalHeader onClose={closeHandler} title='Share to your Batch Feed ?' />
      <main className='w-full flex flex-col items-start gap-4 flex-1 overflow-auto pb-5'>
        <input
          className='w-full focus:active:outline-none outline-none p-[12px_0px_8px] gap-2.5 border-b border-[#d9d9d9] h-[49px]'
          placeholder='Add a component'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        {post && <Feed post={post} />}
      </main>
      <footer className='flex items-center gap-2.5'>
        <Button
          label='SHARE'
          className='flex-1 min-w-[191px] sm:flex-none'
          disabled={isReposting}
          loading={isReposting}
          onClick={repostHandler}
        />
        <Button
          variant='cancel'
          label='Cancel'
          className='flex-1 min-w-[191px] sm:flex-none !normal-case'
          disabled={isReposting}
          onClick={closeHandler}
        />
      </footer>
    </Modal>
  )
}
const Feed: FC<{ post: POST }> = ({ post }) => {
  const fullName = `${post.first_name} ${post.last_name}`
  const isNow = Date.parse(post.created_at)
    ? formatDistanceToNow(new Date(post.created_at), {
        includeSeconds: true,
      }) === 'less than 5 seconds'
    : false
  return (
    <section className='w-full bg-jaa-shades-white mt-3 isolate flex flex-col items-start rounded-lg border border-[#D9D9D9] md:border-[#F9FAFB] md:gap-3 px-4 py-2 md:px-6'>
      <header className='flex items-center gap-3'>
        <div className='w-11 h-11'>
          <img
            src={post.profile_pic || '/defaultImage.png'}
            alt={post.first_name}
            onError={(e) => {
              e.currentTarget.src = '/defaultImage.png'
              const target: any = e.target
              target.src = '/defaultImage.png'
              e.target = target
            }}
            className='w-full h-full max-h-full max-w-full object-cover object-center rounded-full'
          />
        </div>
        <hgroup className='flex flex-col items-start gap-0.5 flex-1'>
          <Typography
            as='h5'
            className='flex items-center gap-1 whitespace-nowrap'
          >
            <span className='capitalize font-semibold md text-jaa-black '>
              {fullName}
            </span>
            {isNow && (
              <span className='flex items-center gap-1'>
                <span className='w-1 h-1 rounded-full bg-jaa-shades-gray-2.5'></span>
                <span className='text-jaa-shades-gray-2.5 font-normal sm'>
                  just now
                </span>
              </span>
            )}
          </Typography>

          <Typography as='p' className='h6 text-jaa-shades-gray-2.5 capitalize'>
            {post.cohort ? `Cohort ${post.cohort}` : post.program}
          </Typography>
        </hgroup>
      </header>
      <main className='w-full flex flex-col gap-1'>
        <FeedGallery media={post.media} />
        <p
          dangerouslySetInnerHTML={{ __html: post.body }}
          className='sm md:md font-normal text-jaa-shades-black'
        ></p>
      </main>
    </section>
  )
}

export default RepostFeed
