import { Media } from '@/apis/forumApis'
import iconComponents from '@/assets/icons/iconComponents'
import useStepper from '@/hooks/useStepper'
import { createElement } from 'react'

const FILE_MAP = {
  video: 'video',
  image: 'img',
}
const ExpandedFeedGallery = (props: {
  media: Media[]
  index: number
  onClose: () => void
}) => {
  const { index = 0, media, onClose } = props
  const { goToNextStep, goToPrevStep, step, setStep } = useStepper(index)
  const getFileType = (file: Media): 'image' | 'video' => {
    if (!file) return 'image'
    const imageType = ['.jpeg', '.png', '.jpg', '.svg']
    return file.file_type
      ? (file.file_type as 'image' | 'video')
      : imageType.filter((validType) => file.file.includes(validType)).length
      ? 'image'
      : 'video'
  }

  return (
    <header className='bg-[#0a0a0a] w-full h-1/3 md:h-1/2 lg:h-full lg:w-2/3 relative flex items-center'>
      <div className='absolute top-0 left-5 md:left-0 w-[10%] z-10 sm:flex-1 sm:relative h-full flex flex-col justify-center items-center'>
        <button
          type='button'
          onClick={onClose}
          className='absolute top-5 left-0 md:left-5 p-2 w-10 h-10 hover:bg-[#f7f7f7] stroke-jaa-shades-white hover:stroke-jaa-shades-black'
        >
          <iconComponents.util.CancelIcon className='w-5 h-5' />
        </button>
        {!!media.length && (
          <button
            type='button'
            onClick={goToPrevStep}
            disabled={step === 0}
            className='w-10 h-10 disabled:bg-[#ffffff09] rounded-full p-2 bg-[#FFFFFF1A] text-center stroke-jaa-shades-white'
          >
            <iconComponents.util.ChevronLeftIcon className='w-5 h-5' />
          </button>
        )}
      </div>
      <div className='w-full sm:w-4/5 h-full relative'>
        {createElement(FILE_MAP[getFileType(media[step])] || 'img', {
          src: media[step]?.file || '',
          className:
            'w-full max-h-full h-full max-w-full object-cover object-center',
          controls: true,
        })}
      </div>

      <div className='absolute  top-0 right-5 md:right-0 w-[10%] z-10 sm:flex-1 sm:relative h-full flex flex-col justify-center items-center'>
        {!!media.length && (
          <button
            type='button'
            onClick={goToNextStep}
            disabled={step >= media.length - 1}
            className='w-10 h-10 disabled:bg-[#ffffff09] rounded-full p-2 bg-[#FFFFFF1A] text-center stroke-jaa-shades-white'
          >
            <iconComponents.util.ChevronRightIcon className='w-5 h-5' />
          </button>
        )}
      </div>
    </header>
  )
}

export default ExpandedFeedGallery
