import React, { useMemo, forwardRef, HTMLAttributes, ChangeEvent } from 'react'
import { isEmpty } from '@/helpers/strings'

const getNumberFromCode = (code: number) => {
  if (code > 47 && code < 58) {
    return code - 48
  } else if (code > 95 && code < 106) {
    return code - 96
  } else return 0
}
const handleDeleteKeyBehaviour = (e: KeyboardEvent, fn: Function) => {
  if (e.keyCode !== 8) return
  const children = (e.currentTarget as any)?.parentNode?.childNodes
  const ind = Array.prototype.indexOf.call(children, e.target)
  if ((e.currentTarget as any)?.value?.length === 1) {
    children[ind].value = ''
    fn(ind)
  } else if ((e.target as any).value.length === 0) {
    if (ind === 0) {
      e.stopPropagation()
      e.preventDefault()
    } else if (children[ind - 1].value.length <= 1) {
      children[ind - 1].value = ''
      fn(ind - 1)
      children[ind - 1].click()
      children[ind - 1].focus()
    }
  }
}
const handleEnterKeyBehaviour = (e: KeyboardEvent, numberOfChar: number) => {
  const allowedCodes = [9, 13]
  if (!allowedCodes.includes(e.keyCode)) return
  const children = (e.target as any).parentNode.childNodes
  const ind = Array.prototype.indexOf.call(children, e.target)
  if (ind === numberOfChar - 1) {
    e.stopPropagation()
    e.preventDefault()
  } else {
    children[ind + 1].click()
    children[ind + 1].focus()
  }
}
const handleValidKey = (
  e: KeyboardEvent,
  numberOfChar: number,
  fn: Function
) => {
  const children = (e.target as any).parentNode.childNodes
  const ind = Array.prototype.indexOf.call(children, e.target)
  var key = e.which || e.keyCode
  var ctrl = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false

  const inRange = () =>
    (e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106)

  if ((e.target as any).value.length < 1 && inRange()) {
    children[ind].value = (e.target as any).value

    setTimeout(() => {
      if (ind === numberOfChar - 1) {
        e.stopPropagation()
        e.preventDefault()
        return false
      } else {
        children[ind + 1].click()
        children[ind + 1].focus()
      }
    }, 20)
    return true
  } else if ((e.target as any).value.length === 1 && inRange()) {
    if (ind === numberOfChar - 1) {
      children[ind].value = getNumberFromCode(e.keyCode)
      fn(getNumberFromCode(e.keyCode), ind)
      e.stopPropagation()
      e.preventDefault()
    } else {
      children[ind].value = getNumberFromCode(e.keyCode)
      fn(getNumberFromCode(e.keyCode), ind)
      setTimeout(() => {
        children[ind + 1].click()
        children[ind + 1].focus()
      }, 20)
      e.stopPropagation()
      e.preventDefault()
    }
  } else if ((e.metaKey || ctrl) && e.key.toLowerCase() === 'v') {
    return true
  } else {
    e.stopPropagation()
    e.preventDefault()
  }
  return false
}

interface IKeyCodeProps extends HTMLAttributes<HTMLDivElement> {
  numberOfChar: number
  fullWidth: boolean
  value: string
}
const KeyCodeInput = ({
  numberOfChar = 6,
  className,
  onChange,
  fullWidth,
  value,
  ...props
}: IKeyCodeProps) => {
  const numArr = useMemo(() => new Array(numberOfChar).fill(10), [numberOfChar])

  const updateState = (value = '', ind: number) => {
    numArr[ind] = value
    if (!value) {
      valueArr[ind] = value
    }
    let newArr = numArr.filter((num) => num < 10)
    onChange?.({
      currentTarget: {
        value: newArr.join('').trim(),
      },
      target: {
        value: newArr.join('').trim(),
      },
    } as any)
  }
  const onKeydownHandler = (e: KeyboardEvent) => {
    handleDeleteKeyBehaviour(e, updateState.bind(null, ''))

    handleEnterKeyBehaviour(e, numberOfChar)

    handleValidKey(e, numberOfChar, updateState)
  }
  const onChangeHandler = (e: ChangeEvent) => {
    const children = e.target?.parentNode?.childNodes
    const ind = Array.prototype.indexOf.call(children, e.target)
    updateState((e.target as any).value, ind)
  }
  const valueArr = useMemo(
    () =>
      value?.toString()?.split('')?.slice(0, numberOfChar) ||
      new Array(numberOfChar).fill(''),
    [value, numberOfChar]
  )
  const onPasteHandler = (e: any) => {
    e.preventDefault()

    let paste: string = (
      e.clipboardData || (window as any).clipboardData
    ).getData('text')
    if (!Number(paste.trim())) return

    const characters = paste.trim().slice(0, numberOfChar)
    characters.split('').forEach((char, ind) => {
      numArr[ind] = char
      if (!char) {
        valueArr[ind] = char
      }
    })

    onChange?.({
      currentTarget: {
        value: characters,
      },
      target: {
        value: characters,
      },
    } as any)
    const childElements = e.currentTarget.children

    childElements[characters.length - 1]?.focus?.()
  }

  return (
    <div
      className={`flex w-full gap-4 justify-between ${
        className ? className : ''
      }`}
      onPaste={onPasteHandler}
      {...props}
    >
      {numArr.map((_, ind) => {
        if (ind === 0)
          return (
            <KeyInput
              key={ind}
              onKeyDown={onKeydownHandler}
              autoFocus
              onChange={onChangeHandler}
              value={valueArr[ind]}
              name={`keyInput-${ind}`}
            />
          )
        else
          return (
            <KeyInput
              key={ind}
              onKeyDown={onKeydownHandler}
              onChange={onChangeHandler}
              value={valueArr[ind]}
              name={`keyInput-${ind}`}
            />
          )
      })}
    </div>
  )
}

interface IKeyInput extends HTMLAttributes<HTMLInputElement> {
  value: string
  name: string
  autoFocus?: boolean
  onKeyDown: (e: any) => void
  onChange: (e: any) => void
}
const KeyInput = forwardRef<HTMLInputElement, IKeyInput>(
  ({ className, style = {}, value, ...props }, ref) => {
    return (
      <input
        tabIndex={0}
        style={{ minWidth: '13px' }}
        value={value}
        {...props}
        ref={ref}
        className={`bg-[#f7f7f7] ${
          !isEmpty(value) ? 'border-jaa-teal-100' : 'border-transparent'
        } flex justify-center focus:border-jaa-teal-100 active:border-jaa-teal-100 text-center leading-8 xl items-center outline-none text-jaa-shades-black  rounded border h-[56px] w-[56px] ${className}`}
      />
    )
  }
)

KeyInput.displayName = 'KeyInput'

export default KeyCodeInput
